var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import AOMRequestError from "./../AOMRequestError";
import Datastore from "./../Datastore";
import AOMStatus from "./../Status";
import User from "./../basics/User";
export default class LPUser extends User {
    static getLPUsers(query, withReferencedHrefs = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadListFromServerWithClass(LPUser, query, withReferencedHrefs, usePersistentStorage);
        });
    }
    static getLPUsersCount(query, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadCountFromServer(LPUser, undefined, query, usePersistentStorage);
        });
    }
    static staticGetModuleName() {
        return "MyLearningPlatform";
    }
    static staticGetModelName() {
        return "LPUser";
    }
    get customMetaForClass() {
        return "";
    }
    static removeAllFromStorage() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorageByClass(LPUser);
        });
    }
    static deleteAllFromStorage(query) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorage(query, LPUser);
        });
    }
    static getLPUsersFromPersistentStorage(whereWithPlaceholders = "", whereValues = [], orderBy = "", limit, offset) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadFromPersistentStorage(LPUser, whereWithPlaceholders, whereValues, orderBy, limit, offset);
        });
    }
    static getLPUsersCountFromPersistentStorage(whereWithPlaceholders = "", whereValues = []) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.getCountFromPersistentStorage(LPUser, whereWithPlaceholders, whereValues);
        });
    }
    constructor(username, password) {
        super(username, password);
        const tmpDao = this.dao;
        this.dao = new LPUserDAO();
        for (const k of Object.keys(tmpDao)) {
            this.dao[k] = tmpDao[k];
        }
        this.hashmap.learnings = [];
        this.hashmap.team = [];
    }
    get modelName() { return "LPUser"; }
    get moduleName() { return "MyLearningPlatform"; }
    get allSupervisors() {
        return this.hashmap.allSupervisors !== null ? this.hashmap.allSupervisors : undefined;
    }
    loadAllSupervisors(withRefHref = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            let refUrl = this.dao.allSupervisorsHref;
            if (typeof refUrl === "undefined" || refUrl === null) {
                return Promise.reject(new AOMRequestError(AOMStatus.ATTACHED_HREF_MISSING, 200));
            }
            try {
                this.hashmap.allSupervisors = new LPSupervisors();
                const res = yield Datastore.Instance.loadFromServer(refUrl, this.hashmap.allSupervisors, withRefHref, usePersistentStorage, LPSupervisors, "", false);
                if (typeof res.responseText !== "undefined" && (Datastore.getCleanTypeFromTypeString(res.responseText["@type"])
                    !== Datastore.getCleanTypeFromTypeString(this.hashmap.allSupervisors["@type"])
                    || (res.responseText.modelName + "$" + res.responseText.moduleName) !==
                        Datastore.getCleanTypeFromTypeString(this.hashmap.allSupervisors["@type"]))) {
                    const clazz = yield Datastore.Instance.getClassFromJSON(res.responseText);
                    this.hashmap.allSupervisors = new clazz();
                    this.hashmap.allSupervisors.fromJson(res.responseText);
                }
                return this.hashmap.allSupervisors;
            }
            catch (error) {
                if (error.statusCode === 204 || error.statusCode === AOMStatus.ID_NOT_FOUND_OFFLINE
                    || error.statusCode === AOMStatus.ID_NOT_FOUND) {
                    this.hashmap.allSupervisors = undefined;
                }
                return Promise.reject(error);
            }
        });
    }
    loadAllSupervisorsAndRefHref(usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.loadAllSupervisors(true, usePersistentStorage);
        });
    }
    postAllSupervisors(_refData, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.parent !== null) {
                return Promise.reject(new AOMRequestError(AOMStatus.EMBEDDED_REF, 201));
            }
            if (typeof _refData.href === "undefined") {
                return Promise.reject(new AOMRequestError(AOMStatus.SAVE_REFERENECE_BEFORE_REFERENCING, 201));
            }
            try {
                let href = this.href + "/allSupervisors";
                if (typeof this.dao.allSupervisorsHref !== "undefined"
                    && this.dao.allSupervisorsHref !== null) {
                    href = this.dao.allSupervisorsHref;
                }
                const { returnedHref: refHref, offline } = yield Datastore.Instance.postOnServer(_refData, href, true, usePersistentStorage, false, "allSupervisors");
                if (offline === true) {
                    this.dao.allSupervisorsHref = href;
                    yield this.save(false, usePersistentStorage);
                }
                if (refHref) {
                    this.hashmap.allSupervisors = _refData;
                    return refHref;
                }
            }
            catch (error) {
                return Promise.reject(error);
            }
        });
    }
    removeAllSupervisors(_refData, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (_refData === null || typeof _refData === "undefined" || typeof _refData.href === "undefined") {
                return Promise.reject(new AOMRequestError(AOMStatus.HREF_NOT_FOUND, 204));
            }
            const id = _refData.href.substring(_refData.href.lastIndexOf("/") + 1);
            const deleteHref = this.dao.allSupervisorsHref + "/" + id;
            try {
                const response = yield Datastore.Instance.deleteOnServer(deleteHref, _refData, true, usePersistentStorage);
                this.hashmap.allSupervisors = undefined;
                if (typeof response !== "undefined" && response.offline === true) {
                    yield this.save(false, usePersistentStorage);
                }
                return response;
            }
            catch (error) {
                return Promise.reject(error);
            }
        });
    }
    get defaultLanguage() {
        return this.dao.defaultLanguage !== null ? this.dao.defaultLanguage : undefined;
    }
    set defaultLanguage(_defaultLanguage) {
        this.dao.defaultLanguage = _defaultLanguage;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get failedLoginAttempts() {
        return this.dao.failedLoginAttempts !== null ? this.dao.failedLoginAttempts : undefined;
    }
    set failedLoginAttempts(_failedLoginAttempts) {
        this.dao.failedLoginAttempts = _failedLoginAttempts;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get generalScore() {
        return this.dao.generalScore !== null ? this.dao.generalScore : undefined;
    }
    set generalScore(_generalScore) {
        this.dao.generalScore = _generalScore;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get hr3Id() {
        return this.dao.hr3Id !== null ? this.dao.hr3Id : undefined;
    }
    set hr3Id(_hr3Id) {
        this.dao.hr3Id = _hr3Id;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get hr3Synced() {
        return this.dao.hr3Synced !== null ? this.dao.hr3Synced : undefined;
    }
    set hr3Synced(_hr3Synced) {
        this.dao.hr3Synced = _hr3Synced;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get initialPassword() {
        return this.dao.initialPassword !== null ? this.dao.initialPassword : undefined;
    }
    set initialPassword(_initialPassword) {
        this.dao.initialPassword = _initialPassword;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get isActive() {
        return this.dao.isActive !== null ? this.dao.isActive : undefined;
    }
    set isActive(_isActive) {
        this.dao.isActive = _isActive;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get isAdmin() {
        return this.dao.isAdmin !== null ? this.dao.isAdmin : undefined;
    }
    set isAdmin(_isAdmin) {
        this.dao.isAdmin = _isAdmin;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get isBlocked() {
        return this.dao.isBlocked !== null ? this.dao.isBlocked : undefined;
    }
    set isBlocked(_isBlocked) {
        this.dao.isBlocked = _isBlocked;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get isInitialPW() {
        return this.dao.isInitialPW !== null ? this.dao.isInitialPW : undefined;
    }
    set isInitialPW(_isInitialPW) {
        this.dao.isInitialPW = _isInitialPW;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get learnings() {
        if (this.hashmap.learnings.length === 0) {
            const data = this.getDataOrKeyFromParent();
            if (typeof data !== "undefined" && this.dao.learnings instanceof Array && this.dao.learnings.length > 0) {
                const objects = [];
                this.dao.learnings.forEach((element) => {
                    const el = new LearnProgress().fromJson(element);
                    el.parent = this;
                    el.parentAttrName = "learnings";
                    el.parentAttrKey = objects.push(el) - 1;
                });
                this.hashmap.learnings = objects;
            }
        }
        return this.hashmap.learnings;
    }
    loadLearnings(withRefHref = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.load(undefined, usePersistentStorage);
                this.hashmap.learnings = [];
                return this.learnings;
            }
            catch (error) {
                if (error.statusCode === 204 || error.statusCode === AOMStatus.ID_NOT_FOUND_OFFLINE
                    || error.statusCode === AOMStatus.ID_NOT_FOUND) {
                    this.hashmap.learnings = undefined;
                }
                return Promise.reject(error);
            }
        });
    }
    loadLearningsAndRefHref(usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.loadLearnings(true, usePersistentStorage);
        });
    }
    postLearnings(_embData, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (_embData instanceof LearnProgress === false) {
                return Promise.reject(new AOMRequestError(AOMStatus.WRONG_REF_TYPE, 201));
            }
            if (typeof _embData.href !== "undefined" || _embData.parent !== null) {
                return Promise.reject(new AOMRequestError(AOMStatus.EMBEDDED_POST_EXISTING_OBJECT, 201));
            }
            if (typeof this.dao.learnings === "undefined" || this.dao.learnings === null) {
                this.dao.learnings = [];
            }
            if (typeof _embData.ID === "undefined" || _embData.ID === null) {
                _embData["dao"].embeddedCollectionId = Date.now().toString() + (Math.floor(Math.random() * 9000) + 1000).toString();
            }
            const parentAttrKey = this.dao.learnings.push(_embData.toJson()) - 1;
            this.hashmap.learnings = [];
            _embData.parentAttrKey = parentAttrKey;
            _embData.parentAttrName = "learnings";
            _embData.parent = this;
            const ret = yield this.setDataToParent(_embData, usePersistentStorage);
            const data = _embData.getDataOrKeyFromParent();
            this.dao.learnings[parentAttrKey] = data;
            _embData = _embData.fromJson(data);
            return ret;
        });
    }
    removeLearnings(_embData, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (_embData instanceof LearnProgress === false) {
                return Promise.reject(new AOMRequestError(AOMStatus.WRONG_REF_TYPE, 204));
            }
            if (_embData.parent === this) {
                return yield _embData.delete(usePersistentStorage);
            }
            else if (_embData.parent !== null && _embData.parent.ID === this.ID) {
                const ret = yield _embData.delete(usePersistentStorage);
                yield this.load(undefined, usePersistentStorage);
                return ret;
            }
            else {
                return Promise.reject(new AOMRequestError(AOMStatus.NOT_EMBEDDED_CHILD, 204));
            }
        });
    }
    getLearningsCount(usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.load(undefined, usePersistentStorage);
            return this.learnings.length;
        });
    }
    get nameSearchField() {
        return this.dao.nameSearchField !== null ? this.dao.nameSearchField : undefined;
    }
    set nameSearchField(_nameSearchField) {
        this.dao.nameSearchField = _nameSearchField;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get nextDueDate() {
        const retDate = this.dao.nextDueDate;
        return (typeof retDate !== "undefined") ? new Date(retDate) : undefined;
    }
    set nextDueDate(_nextDueDate) {
        if (_nextDueDate instanceof Date) {
            this.dao.nextDueDate = _nextDueDate.getTime();
        }
        else {
            this.dao.nextDueDate = undefined;
        }
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get position() {
        return this.dao.position !== null ? this.dao.position : undefined;
    }
    set position(_position) {
        this.dao.position = _position;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get team() {
        return this.hashmap.team;
    }
    loadTeam(query = "", withRefHref = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            let refUrl = this.dao.teamHref;
            if ((typeof refUrl === "undefined" || refUrl === null) && this.offline === true) {
                refUrl = this.href + "/team";
            }
            if (typeof refUrl === "undefined" || refUrl === null) {
                return Promise.reject(new AOMRequestError(AOMStatus.ATTACHED_HREF_MISSING, 200));
            }
            try {
                this.hashmap.team = yield Datastore.Instance.loadFromServer(refUrl, undefined, withRefHref, usePersistentStorage, LPTeam, query, true);
                return this.hashmap.team;
            }
            catch (error) {
                if (error.statusCode === 204 || error.statusCode === AOMStatus.ID_NOT_FOUND_OFFLINE
                    || error.statusCode === AOMStatus.ID_NOT_FOUND) {
                    this.hashmap.team = undefined;
                }
                return Promise.reject(error);
            }
        });
    }
    loadTeamAndRefHref(query = "", usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.loadTeam(query, true, usePersistentStorage);
        });
    }
    getTeamCountFromPersistentStorage(whereWithPlaceholders = "", whereValues = []) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.getCollectionCountFromPersistentStorage(LPTeam, this.href + "/Team", whereWithPlaceholders, whereValues);
        });
    }
    postTeam(_refData, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.parent !== null) {
                return Promise.reject(new AOMRequestError(AOMStatus.EMBEDDED_REF, 201));
            }
            if (typeof _refData.href === "undefined") {
                return Promise.reject(new AOMRequestError(AOMStatus.SAVE_REFERENECE_BEFORE_REFERENCING, 201));
            }
            try {
                let href = this.href + "/team";
                if (typeof this.dao.teamHref !== "undefined"
                    && this.dao.teamHref !== null) {
                    href = this.dao.teamHref;
                }
                const { returnedHref: refHref, offline } = yield Datastore.Instance.postOnServer(_refData, href, true, usePersistentStorage, true, "team");
                if (offline === true) {
                    this.dao.teamHref = href;
                    yield this.save(false, usePersistentStorage);
                }
                if (refHref) {
                    if (this.hashmap.team.filter((_elem) => _elem.href && refHref && _elem.href === refHref).length < 1) {
                        this.hashmap.team.push(_refData);
                    }
                    return refHref;
                }
            }
            catch (error) {
                return Promise.reject(error);
            }
        });
    }
    removeTeam(_refData, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (_refData === null || typeof _refData === "undefined" || typeof _refData.href === "undefined") {
                return Promise.reject(new AOMRequestError(AOMStatus.HREF_NOT_FOUND, 204));
            }
            const id = _refData.href.substring(_refData.href.lastIndexOf("/") + 1);
            const deleteHref = this.dao.teamHref + "/" + id;
            try {
                const response = yield Datastore.Instance.deleteOnServer(deleteHref, _refData, true, usePersistentStorage);
                if (typeof this.hashmap.team !== "undefined" && typeof this.hashmap.team.length !== "undefined"
                    && this.hashmap.team.length > 0) {
                    const i = this.hashmap.team.findIndex((el) => el.href === _refData.href);
                    if (i !== -1) {
                        this.hashmap.team.splice(i, 1);
                    }
                }
                return response;
            }
            catch (error) {
                return Promise.reject(error);
            }
        });
    }
    getTeamCount(_query = "", usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadCountFromServer(this.href, "team", _query, usePersistentStorage);
        });
    }
    get teamIds() {
        return this.dao.teamIds !== null ? this.dao.teamIds : undefined;
    }
    set teamIds(_teamIds) {
        this.dao.teamIds = _teamIds;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get userMail() {
        return this.dao.userMail !== null ? this.dao.userMail : undefined;
    }
    set userMail(_userMail) {
        this.dao.userMail = _userMail;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get userMailPrivate() {
        return this.dao.userMailPrivate !== null ? this.dao.userMailPrivate : undefined;
    }
    set userMailPrivate(_userMailPrivate) {
        this.dao.userMailPrivate = _userMailPrivate;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
}
import LPUserDAO from "./LPUserDAO";
import LPTeam from "./../mylearningplatform/LPTeam";
import LPSupervisors from "./../mylearningplatform/LPSupervisors";
import LearnProgress from "./../mylearningplatform/LearnProgress";
