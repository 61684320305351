import React from 'react';
import { Typography, makeStyles, Theme, Paper, Grid, Button, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LearnContentData, TranslatedString, TopicData } from '../../../store/learnContents';
import TranslatedStringForm from './TranslatedStringForm';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { v4 as uuid } from 'uuid';
import SelectionButton from '../../Buttons/SelectionButton';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  addMoreButton: {
    padding: theme.spacing(1, 3),
    margin: theme.spacing(3, 0),
  },
  deleteButton: {
    marginBottom: theme.spacing(1),
  },
}));

export interface TopicStepProps {
  learnContent: LearnContentData;
  setLearnContent: (learnContent: LearnContentData) => void;
}

const TopicStep = (props: TopicStepProps) => {
  const { learnContent, setLearnContent } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const updateTopic = (i: number, title: TranslatedString) => {
    const topics = [
      ...learnContent.topics.filter(t => t.id !== learnContent.topics[i]?.id),
      {
        ...learnContent.topics[i],
        title
      }
    ];

    setLearnContent({ ...learnContent, topics });
  };

  const addTopic = () => {
    setLearnContent({ ...learnContent, topics: [...learnContent.topics, { id: uuid(), title: {} } as TopicData] });
  };

  const deleteTopic = (id: string) => {
    setLearnContent({ ...learnContent, topics: learnContent.topics.filter(el => el.id !== id) });
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Paper elevation={0} className={classes.paper}>
          <Typography component="h2" variant="subtitle2" color="primary">
            {t('admin.create-learn-content.topics.define-topics')}
          </Typography>
          <Typography component="p" variant="body1" className={classes.description}>
            {t('admin.create-learn-content.topics.define-topics-description')}
          </Typography>
          <Grid container spacing={2} justify="center">
            <Grid item>
              <SelectionButton
                label={t('admin.create-learn-content.topics.yes')}
                selected={learnContent.topicsDefined}
                onSelect={() => setLearnContent({ ...learnContent, topicsDefined: true, topics: [{ id: uuid(), title: {} } as TopicData] })}
              />
            </Grid>
            <Grid item>
              <SelectionButton
                label={t('admin.create-learn-content.topics.no')}
                selected={learnContent.topicsDefined === false}
                onSelect={() => setLearnContent({ ...learnContent, topicsDefined: false, topics: [] })}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {learnContent.topicsDefined && (
        <>
          {learnContent.topics.map((topic, i) => (
            <Grid item key={topic.id}>
              <Paper elevation={0} className={classes.paper}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography component="h2" variant="subtitle2" color="primary" className={classes.description}>
                      {t('admin.create-learn-content.topics.topic')} {i + 1}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {learnContent.topics.length > 1 && (
                      <IconButton onClick={() => deleteTopic(topic.id)} className={classes.deleteButton}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
                <TranslatedStringForm
                  langs={learnContent.langs}
                  placeholderKey="admin.create-learn-content.topics.topic-placeholder"
                  translatedString={topic.title || {}}
                  setTranslatedString={title => updateTopic(i, title)}
                />
              </Paper>
            </Grid>
          ))}
          <Grid container justify="center">
            <Button color="primary" variant="contained" startIcon={<AddIcon />} className={classes.addMoreButton} onClick={addTopic}>
              {t('admin.create-learn-content.topics.add-next-topic')}
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default TopicStep;
