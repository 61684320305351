import React from 'react';
import { makeStyles, ListItem, Theme, Typography, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import LPTeam from '../../sdk/com/apiomat/frontend/mylearningplatform/LPTeam';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fontSize: theme.typography.body2.fontSize,
    marginRight: theme.spacing(1),
    display: 'block',
    marginTop: 3,
  },
  item: {
    padding: theme.spacing(2),
  },
}));

export interface TeamSearchItemProps {
  item: LPTeam;
  onItemClick?: (team: LPTeam) => void;
}

const TeamSearchItem = (props: TeamSearchItemProps) => {
  const { item } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ListItem divider={true} className={classes.item} component="div">
      <Grid container spacing={isSmall ? 1 : 2} alignItems="center">
        <Grid item xs={12} sm={4} container>
          <GroupIcon className={classes.icon} />
          <Typography component="span" variant="subtitle2">
            {item.name}
          </Typography>
        </Grid>
        <Grid item container xs={12} sm={8}>
          <Grid item container xs={12} sm={5}>
            <Typography variant="body2" component="span">
              {t('admin.assignment.employees', { count: item.usersCount || 0 })}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default TeamSearchItem;
