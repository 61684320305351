var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Datastore from "./../Datastore";
import AbstractClientDataModel from "./../AbstractClientDataModel";
export default class LPSupervisors extends AbstractClientDataModel {
    static getLPSupervisorss(query, withReferencedHrefs = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadListFromServerWithClass(LPSupervisors, query, withReferencedHrefs, usePersistentStorage);
        });
    }
    static getLPSupervisorssCount(query, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadCountFromServer(LPSupervisors, undefined, query, usePersistentStorage);
        });
    }
    static staticGetModuleName() {
        return "MyLearningPlatform";
    }
    static staticGetModelName() {
        return "LPSupervisors";
    }
    get customMetaForClass() {
        return "";
    }
    static removeAllFromStorage() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorageByClass(LPSupervisors);
        });
    }
    static deleteAllFromStorage(query) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorage(query, LPSupervisors);
        });
    }
    static getLPSupervisorssFromPersistentStorage(whereWithPlaceholders = "", whereValues = [], orderBy = "", limit, offset) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadFromPersistentStorage(LPSupervisors, whereWithPlaceholders, whereValues, orderBy, limit, offset);
        });
    }
    static getLPSupervisorssCountFromPersistentStorage(whereWithPlaceholders = "", whereValues = []) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.getCountFromPersistentStorage(LPSupervisors, whereWithPlaceholders, whereValues);
        });
    }
    constructor() {
        super();
        this.dao = new LPSupervisorsDAO();
    }
    get modelName() { return "LPSupervisors"; }
    get moduleName() { return "MyLearningPlatform"; }
    get directSupervisors() {
        return this.dao.directSupervisors !== null ? this.dao.directSupervisors : undefined;
    }
    set directSupervisors(_directSupervisors) {
        this.dao.directSupervisors = _directSupervisors;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get indirectSupervisors() {
        return this.dao.indirectSupervisors !== null ? this.dao.indirectSupervisors : undefined;
    }
    set indirectSupervisors(_indirectSupervisors) {
        this.dao.indirectSupervisors = _indirectSupervisors;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
}
import LPSupervisorsDAO from "./LPSupervisorsDAO";
