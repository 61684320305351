import LPUser from '../sdk/com/apiomat/frontend/mylearningplatform/LPUser';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import { TeamOverviewCategory } from '../enums/TeamOverviewCategory';
import { AppState } from '.';
import LearnContent from '../sdk/com/apiomat/frontend/mylearningplatform/LearnContent';
import { notificationActions } from './notification';
import { dateDaysFromNow } from '../utils/trainings.utils';
import LPTeam from '../sdk/com/apiomat/frontend/mylearningplatform/LPTeam';
import { ApiRequestState } from '../models/api-request-state';
import { LPUserFilter } from '../sdk/com/apiomat/frontend/mylearningplatform';

type LoadStatus = ApiRequestState | 'completed';
export type LearnContentCompletedFilterType = 'all' | 'open';
/* STATE */
export interface TeamOverviewState {
  learnContents: LearnContent[];
  teams: LPTeam[];
  loadingTeamsStatus: LoadStatus;
  learnContentCompletedFilter: LearnContentCompletedFilterType;
  autocompleteLearnContentCompletedFilter: LearnContentCompletedFilterType;
  users: {
    [TeamOverviewCategory.OVERDUE]: LPUser[];
    [TeamOverviewCategory.ALMOST_OVERDUE]: LPUser[];
    [TeamOverviewCategory.ALL]: LPUser[];
  };
  userCounts: {
    [TeamOverviewCategory.OVERDUE]: number;
    [TeamOverviewCategory.ALMOST_OVERDUE]: number;
    [TeamOverviewCategory.ALL]: number;
  };
  userNameFilters: string[];
  teamFilters: string[];
  learnContentFilters: string[];
  activeCategory: TeamOverviewCategory;
  loadingUsersStatus: {
    [TeamOverviewCategory.OVERDUE]: LoadStatus;
    [TeamOverviewCategory.ALMOST_OVERDUE]: LoadStatus;
    [TeamOverviewCategory.ALL]: LoadStatus;
  };
  loadingUserCounts: LoadStatus;
  loadingUsersPage: {
    [TeamOverviewCategory.OVERDUE]: number;
    [TeamOverviewCategory.ALMOST_OVERDUE]: number;
    [TeamOverviewCategory.ALL]: number;
  };
  userQuery: string;
  selectedUserCount: number;
}

const initialState: TeamOverviewState = {
  learnContents: [],
  teams: [],
  loadingTeamsStatus: 'idle',
  learnContentCompletedFilter: 'all',
  autocompleteLearnContentCompletedFilter: 'open',
  users: {
    [TeamOverviewCategory.OVERDUE]: [],
    [TeamOverviewCategory.ALMOST_OVERDUE]: [],
    [TeamOverviewCategory.ALL]: [],
  },
  userCounts: {
    [TeamOverviewCategory.OVERDUE]: 0,
    [TeamOverviewCategory.ALMOST_OVERDUE]: 0,
    [TeamOverviewCategory.ALL]: 0,
  },
  userNameFilters: [],
  teamFilters: [],
  learnContentFilters: [],
  activeCategory: TeamOverviewCategory.OVERDUE,
  loadingUsersStatus: {
    [TeamOverviewCategory.OVERDUE]: 'idle',
    [TeamOverviewCategory.ALMOST_OVERDUE]: 'idle',
    [TeamOverviewCategory.ALL]: 'idle',
  },
  loadingUserCounts: 'idle',
  loadingUsersPage: {
    [TeamOverviewCategory.OVERDUE]: 0,
    [TeamOverviewCategory.ALMOST_OVERDUE]: 0,
    [TeamOverviewCategory.ALL]: 0,
  },
  userQuery: '',
  selectedUserCount: 0,
};

/* TYPES */
interface UserLoadSuccess {
  users: LPUser[];
  category: TeamOverviewCategory;
  status?: LoadStatus;
}

interface UserCountsLoadSuccess {
  userCounts: {
    [TeamOverviewCategory.OVERDUE]: number;
    [TeamOverviewCategory.ALMOST_OVERDUE]: number;
    [TeamOverviewCategory.ALL]: number;
  };
  status?: LoadStatus;
}

const ROWS_PER_PAGE = 10;

/* SLICE */
const slice = createSlice({
  name: 'teamOverview',
  initialState,
  reducers: {
    loadUsers: () => {},
    loadUsersInit: state => {
      if (state.loadingUsersStatus[state.activeCategory] !== 'completed') {
        state.loadingUsersStatus[state.activeCategory] = 'pending';
      }
    },
    loadUsersSuccess: (state, action: PayloadAction<UserLoadSuccess>) => {
      const { category, status } = action.payload;

      if (status) {
        state.loadingUsersStatus[category] = status;
      }

      state.loadingUsersPage[category]++;
      state.users[action.payload.category] = [...state.users[category], ...action.payload.users];
    },
    loadUsersFailure: (state, action: PayloadAction<TeamOverviewCategory>) => {
      state.loadingUsersStatus[action.payload] = 'failed';
    },
    loadUserCounts: state => {
      state.loadingUserCounts = 'pending';
    },
    loadUserCountsSuccess: (state, action: PayloadAction<UserCountsLoadSuccess>) => {
      const { userCounts, status } = action.payload;

      if (status) {
        state.loadingUserCounts = status;
      }

      state.userCounts = userCounts;
    },
    loadUserCountsFailure: (state, action: PayloadAction<TeamOverviewCategory>) => {
      state.loadingUserCounts = 'failed';
    },
    loadTeams: state => {
      state.loadingTeamsStatus = 'pending';
    },
    loadTeamsSuccess: (state, action: PayloadAction<LPTeam[]>) => {
      state.teams = action.payload;
      state.loadingTeamsStatus = 'completed';
    },
    loadTeamFailure: state => {
      state.loadingTeamsStatus = 'failed';
    },
    setUserNameFilters: (state, action: PayloadAction<string[]>) => {
      state.users = { ...initialState.users };
      state.loadingUsersStatus = { ...initialState.loadingUsersStatus };
      state.loadingUsersPage = { ...initialState.loadingUsersPage };
      state.teamFilters = [...initialState.teamFilters];
      state.userNameFilters = action.payload;
    },
    setTeamFilters: (state, action: PayloadAction<string[]>) => {
      state.users = { ...initialState.users };
      state.loadingUsersStatus = { ...initialState.loadingUsersStatus };
      state.loadingUsersPage = { ...initialState.loadingUsersPage };
      state.userNameFilters = [...initialState.userNameFilters];
      state.teamFilters = action.payload;
    },
    clearUserAndTeamFilters: state => {
      state.users = { ...initialState.users };
      state.loadingUsersStatus = { ...initialState.loadingUsersStatus };
      state.loadingUsersPage = { ...initialState.loadingUsersPage };
      state.userNameFilters = [...initialState.userNameFilters];
      state.teamFilters = [...initialState.teamFilters];
    },
    setLearnContentFilters: (state, action: PayloadAction<string[]>) => {
      state.users = { ...initialState.users };
      state.loadingUsersStatus = { ...initialState.loadingUsersStatus };
      state.loadingUsersPage = { ...initialState.loadingUsersPage };
      state.learnContentFilters = action.payload;
    },
    clearLearnContentFilters: state => {
      state.users = { ...initialState.users };
      state.loadingUsersStatus = { ...initialState.loadingUsersStatus };
      state.loadingUsersPage = { ...initialState.loadingUsersPage };
      state.learnContentFilters = [ ...initialState.learnContentFilters ];
    },
    setActiveCategory: (state, action: PayloadAction<TeamOverviewCategory>) => {
      state.activeCategory = action.payload;
    },
    setLearnContents: (state, action: PayloadAction<LearnContent[]>) => {
      if (state.learnContents !== action.payload) {
        state.learnContents = action.payload;
        state.users = { ...initialState.users };
        state.loadingUsersStatus = { ...initialState.loadingUsersStatus };
        state.loadingUsersPage = { ...initialState.loadingUsersPage };
      }
    },
    setUserQuery: (state, action: PayloadAction<string>) => {
      state.userQuery = action.payload;
    },
    setSelectedUserCount: (state, action: PayloadAction<number>) => {
      state.selectedUserCount = action.payload;
    },
    setLearnContentCompletedFilter: (state, action: PayloadAction<LearnContentCompletedFilterType>) => {
      state.learnContentCompletedFilter = action.payload;
    },
    setAutocompleteLearnContentCompletedFilter: (state, action: PayloadAction<LearnContentCompletedFilterType>) => {
      state.autocompleteLearnContentCompletedFilter = action.payload;
    },
    clearAllFilters: state => {
      state.users = { ...initialState.users };
      state.loadingUsersStatus = { ...initialState.loadingUsersStatus };
      state.loadingUsersPage = { ...initialState.loadingUsersPage };
      state.learnContentFilters = [ ...initialState.learnContentFilters ];
      state.userNameFilters = [...initialState.userNameFilters];
      state.teamFilters = [...initialState.teamFilters];
    },
  },
});

export const teamOverviewReducer = slice.reducer;
export const teamOverviewActions = slice.actions;

const createLearnContentQueryPartial = (
  filters: string[],
  category: TeamOverviewCategory,
  learnContentCompletedFilter: LearnContentCompletedFilterType
): string => {
  let queryPartial: string;
  switch (category) {
    case TeamOverviewCategory.OVERDUE: {
      queryPartial = `nextDueDate < date(${dateDaysFromNow(0)
        .toDate()
        .getTime()})`;

      if (filters.length > 0) {
        queryPartial += ` AND learnings.learnContentId in [${filters.map(filter => `"${filter}"`).join(', ')}]`;
      }

      break;
    }
    case TeamOverviewCategory.ALMOST_OVERDUE: {
      queryPartial = `nextDueDate <= date(${dateDaysFromNow(14)
        .toDate()
        .getTime()}) AND nextDueDate >= date(${dateDaysFromNow(0)
        .toDate()
        .getTime()})`;

      if (filters.length > 0) {
        queryPartial += ` AND learnings.learnContentId in [${filters.map(filter => `"${filter}"`).join(', ')}]`;
      }

      break;
    }
    default: {
      queryPartial = filters.length > 0 ? `learnings.learnContentId in [${filters.map(filter => `"${filter}"`).join(', ')}]` : '';

      if (learnContentCompletedFilter === 'open') {
        queryPartial += (filters.length > 0 ? ' AND ' : '') + `nextDueDate != null`;
      }
    }
  }

  return queryPartial;
};

const createUserNameQueryPartial = (userNames: string[]): string => {
  // prettier-ignore
  return userNames.length > 0 
    ? `userName in [${userNames.map(userName => `"${userName}"`).join(', ')}]` 
    : '';
};

const createTeamQueryPartial = (teamIds: string[]): string => {
  // prettier-ignore
  return teamIds.length > 0 
    ? `teamIds in [${teamIds.map(teamId => `"${teamId}"`).join(', ')}]` 
    : '';
};

const fetchUserFilterCount = async (query: string): Promise<number> => {
  return await LPUserFilter.getLPUserFiltersCount(query);
}

/* SAGAS */
function* onLoadUsers() {
  yield put(teamOverviewActions.loadUsersInit());

  const {
    loadingUsersPage,
    learnContentFilters,
    userNameFilters,
    activeCategory,
    teamFilters,
    learnContentCompletedFilter,
  }: TeamOverviewState = yield select((state: AppState) => state.teamOverview);

  const currentPage = loadingUsersPage[activeCategory];
  const query = [
    createLearnContentQueryPartial(learnContentFilters, activeCategory, learnContentCompletedFilter),
    userNameFilters.length > 0 ? createUserNameQueryPartial(userNameFilters) : createTeamQueryPartial(teamFilters),
  ]
    .filter(partial => partial !== '')
    .join(' AND ');

  yield put(teamOverviewActions.setUserQuery(query));

  try {
    const usersWithUnfinishedContent = yield call(() =>
      LPUserFilter.getLPUserFilters(`${query} order by lastName offset ${currentPage * ROWS_PER_PAGE} limit ${ROWS_PER_PAGE}`)
    );
    const users: LPUser[] = (usersWithUnfinishedContent && usersWithUnfinishedContent[0]) ? usersWithUnfinishedContent[0].users : [];

    const status: LoadStatus = users.length < ROWS_PER_PAGE ? 'completed' : 'succeeded';

    yield put(teamOverviewActions.setSelectedUserCount(yield call(() => fetchUserFilterCount(query))));

    yield put(teamOverviewActions.loadUsersSuccess({ users, category: activeCategory, status }));
  } catch (err) {
    console.error(err);
    yield put(teamOverviewActions.loadUsersFailure(activeCategory));
    yield put(notificationActions.showError(err));
  }
}

function* onLoadUserCounts() {
  const { learnContentFilters, userNameFilters, teamFilters, learnContentCompletedFilter }: TeamOverviewState = yield select(
    (state: AppState) => state.teamOverview
  );

  const overdueQuery = [
    createLearnContentQueryPartial(learnContentFilters, TeamOverviewCategory.OVERDUE, learnContentCompletedFilter),
    userNameFilters.length > 0 ? createUserNameQueryPartial(userNameFilters) : createTeamQueryPartial(teamFilters),
  ]
    .filter(partial => partial !== '')
    .join(' AND ');

  const allQuery = [
    createLearnContentQueryPartial(learnContentFilters, TeamOverviewCategory.ALL, learnContentCompletedFilter),
    userNameFilters.length > 0 ? createUserNameQueryPartial(userNameFilters) : createTeamQueryPartial(teamFilters),
  ]
    .filter(partial => partial !== '')
    .join(' AND ');

  const almostOverdueQuery = [
    createLearnContentQueryPartial(learnContentFilters, TeamOverviewCategory.ALMOST_OVERDUE, learnContentCompletedFilter),
    userNameFilters.length > 0 ? createUserNameQueryPartial(userNameFilters) : createTeamQueryPartial(teamFilters),
  ]
    .filter(partial => partial !== '')
    .join(' AND ');

  try {
    const [overdueUserCount, almostOverdueUserCount, allUserCount] = yield all([
      call(() => fetchUserFilterCount(`${overdueQuery}`)),
      call(() => fetchUserFilterCount(`${almostOverdueQuery}`)),
      call(() => fetchUserFilterCount(`${allQuery}`)),
    ]);
    const userCounts = {
      overdue: overdueUserCount,
      almostOverdue: almostOverdueUserCount,
      all: allUserCount,
    };

    const status: LoadStatus = 'succeeded';

    yield put(teamOverviewActions.loadUserCountsSuccess({ userCounts, status }));
  } catch (err) {
    yield put(teamOverviewActions.loadUserCountsFailure());
    yield put(notificationActions.showError(err));
  }
}

function* onLoadTeams() {
  try {
    const teams: LPTeam[] = yield call(() => LPTeam.getLPTeams());

    yield put(teamOverviewActions.loadTeamsSuccess(teams));
  } catch (error) {
    yield put(teamOverviewActions.loadTeamFailure());
    yield put(notificationActions.showError(error));
  }
}

export function* teamOverviewSaga() {
  yield all([
    takeLatest(
      [
        teamOverviewActions.loadUsers.type,
        teamOverviewActions.setUserNameFilters.type,
        teamOverviewActions.setLearnContentFilters.type,
        teamOverviewActions.setTeamFilters.type,
        teamOverviewActions.clearUserAndTeamFilters.type,
        teamOverviewActions.clearLearnContentFilters.type,
        teamOverviewActions.setLearnContentCompletedFilter.type,
      ],
      onLoadUsers
    ),
    takeLatest(teamOverviewActions.loadTeams, onLoadTeams),
    takeLatest(teamOverviewActions.loadUserCounts, onLoadUserCounts),
  ]);
}
