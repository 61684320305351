var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, Column } from "../OfflineAdapter";
import AbstractClientDataModelDAO from "./../offline/AbstractClientDataModelDAO";
let LearnProgressDAO = class LearnProgressDAO extends AbstractClientDataModelDAO {
    constructor() {
        super();
        this.moduleName = "MyLearningPlatform";
        this.modelName = "LearnProgress";
    }
};
__decorate([
    Column("simple-json", { nullable: true }),
    __metadata("design:type", Array)
], LearnProgressDAO.prototype, "achievedScoreKeys", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], LearnProgressDAO.prototype, "attemptsCount", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], LearnProgressDAO.prototype, "certificateURL", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], LearnProgressDAO.prototype, "disclaimerApproved", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], LearnProgressDAO.prototype, "dueDate", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], LearnProgressDAO.prototype, "lastAccess", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], LearnProgressDAO.prototype, "learnContentId", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], LearnProgressDAO.prototype, "liveCount", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], LearnProgressDAO.prototype, "mailSent", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], LearnProgressDAO.prototype, "questionProgress", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], LearnProgressDAO.prototype, "result", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], LearnProgressDAO.prototype, "scormProgress", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], LearnProgressDAO.prototype, "successDate", void 0);
LearnProgressDAO = __decorate([
    Entity("MyLearningPlatform_LearnProgress"),
    __metadata("design:paramtypes", [])
], LearnProgressDAO);
export default LearnProgressDAO;
