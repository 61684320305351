var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, Column } from "../OfflineAdapter";
import UserDAO from "./../basics/UserDAO";
let LPUserDAO = class LPUserDAO extends UserDAO {
    constructor() {
        super();
        this.moduleName = "MyLearningPlatform";
        this.modelName = "LPUser";
    }
};
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], LPUserDAO.prototype, "allSupervisorsHref", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], LPUserDAO.prototype, "defaultLanguage", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], LPUserDAO.prototype, "failedLoginAttempts", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], LPUserDAO.prototype, "generalScore", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], LPUserDAO.prototype, "hr3Id", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], LPUserDAO.prototype, "hr3Synced", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], LPUserDAO.prototype, "initialPassword", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], LPUserDAO.prototype, "isActive", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], LPUserDAO.prototype, "isAdmin", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], LPUserDAO.prototype, "isBlocked", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], LPUserDAO.prototype, "isInitialPW", void 0);
__decorate([
    Column("simple-json", { nullable: true }),
    __metadata("design:type", Array)
], LPUserDAO.prototype, "learnings", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], LPUserDAO.prototype, "learningsHref", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], LPUserDAO.prototype, "nameSearchField", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], LPUserDAO.prototype, "nextDueDate", void 0);
__decorate([
    Column("simple-json", { nullable: true }),
    __metadata("design:type", Array)
], LPUserDAO.prototype, "position", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], LPUserDAO.prototype, "teamHref", void 0);
__decorate([
    Column("simple-json", { nullable: true }),
    __metadata("design:type", Array)
], LPUserDAO.prototype, "teamIds", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], LPUserDAO.prototype, "userMail", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], LPUserDAO.prototype, "userMailPrivate", void 0);
LPUserDAO = __decorate([
    Entity("MyLearningPlatform_LPUser"),
    __metadata("design:paramtypes", [])
], LPUserDAO);
export default LPUserDAO;
