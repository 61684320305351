import { makeStyles, Theme } from '@material-ui/core';

export const useStylesBase = makeStyles((theme: Theme) => ({
  dialogIcon: {
    marginBottom: -4,
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  dialogContent: {
    padding: theme.spacing(3),
    minWidth: '100%',
  },
  dialogDivider: {
    margin: theme.spacing(3, -3),
  },
  dialogSwitchControl: {
    marginLeft: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogSubmit: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}), { index: 999 });
