import React from 'react';
import { makeStyles, Theme, CircularProgress, Backdrop, BackdropProps } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: 10000,
    color: '#fff',
  },
}));

export default (props: BackdropProps) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} {...props}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
