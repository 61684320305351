import React from 'react';
import { Button, makeStyles, Theme } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    minWidth: 180,
    padding: theme.spacing(1, 2),
    borderRadius: 10,
  },
  buttonIcon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: 10,
  },
  success: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
  },
  error: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));

export interface SelectionButtonProps {
  label: string;
  selected: boolean;
  selectedColor?: 'success' | 'error';
  onSelect: () => void;
}

const SelectionButton = (props: SelectionButtonProps) => {
  const { label, selected, selectedColor, onSelect } = props;
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      color={selected && Boolean(selectedColor) === false ? 'primary' : 'default'}
      endIcon={selected && <CheckCircleIcon color="inherit" className={classes.buttonIcon} />}
      onClick={onSelect}
      className={clsx(classes.button, selected && selectedColor && classes[selectedColor])}
    >
      {label}
    </Button>
  );
};

export default SelectionButton;
