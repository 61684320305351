var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Datastore from "./../Datastore";
import AbstractClientDataModel from "./../AbstractClientDataModel";
export default class Question extends AbstractClientDataModel {
    static getQuestions(query, withReferencedHrefs = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadListFromServerWithClass(Question, query, withReferencedHrefs, usePersistentStorage);
        });
    }
    static getQuestionsCount(query, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadCountFromServer(Question, undefined, query, usePersistentStorage);
        });
    }
    static staticGetModuleName() {
        return "MyLearningPlatform";
    }
    static staticGetModelName() {
        return "Question";
    }
    get customMetaForClass() {
        return "";
    }
    static removeAllFromStorage() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorageByClass(Question);
        });
    }
    static deleteAllFromStorage(query) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorage(query, Question);
        });
    }
    static getQuestionsFromPersistentStorage(whereWithPlaceholders = "", whereValues = [], orderBy = "", limit, offset) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadFromPersistentStorage(Question, whereWithPlaceholders, whereValues, orderBy, limit, offset);
        });
    }
    static getQuestionsCountFromPersistentStorage(whereWithPlaceholders = "", whereValues = []) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.getCountFromPersistentStorage(Question, whereWithPlaceholders, whereValues);
        });
    }
    constructor() {
        super();
        this.dao = new QuestionDAO();
    }
    get modelName() { return "Question"; }
    get moduleName() { return "MyLearningPlatform"; }
    get isAnswerTrue() {
        return this.dao.isAnswerTrue !== null ? this.dao.isAnswerTrue : undefined;
    }
    set isAnswerTrue(_isAnswerTrue) {
        this.dao.isAnswerTrue = _isAnswerTrue;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get orderNumber() {
        return this.dao.orderNumber !== null ? this.dao.orderNumber : undefined;
    }
    set orderNumber(_orderNumber) {
        this.dao.orderNumber = _orderNumber;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get textKey() {
        return this.dao.textKey !== null ? this.dao.textKey : undefined;
    }
    set textKey(_textKey) {
        this.dao.textKey = _textKey;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
}
import QuestionDAO from "./QuestionDAO";
