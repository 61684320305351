export default class SdkInfo {
    static get AUTHSERVICE_URL() { return "https://dfh-prod.easy01.proactcloud.de/auth"; }
    static get AUTHSERVICE_REALMNAME() { return "MyLP"; }
    static getModuleInfo() {
        return this._moduleInfo;
    }
    static getVersion(moduleName) {
        return this._moduleVersions[moduleName];
    }
}
SdkInfo._moduleInfo = [{ "name": "Localisation", "version": "4.1.0.yambas331" }, { "name": "MyLearningPlatform", "version": "2.6.1" }, { "name": "CSV", "version": "1.0.0" }, { "name": "PDFGenerator", "version": "1.0.0" }, { "name": "HR3", "version": "1.2.4" }, { "name": "MaintenanceModule", "version": "1.0.0" }, { "name": "Webhosting", "version": "1.0.0" }, { "name": "Basics", "version": "1.0.0" }, { "name": "SMTP", "version": "4.3.0" }];
SdkInfo._moduleVersions = SdkInfo._moduleInfo.reduce((result, moduleInfo) => {
    result[moduleInfo.name] = moduleInfo.version;
    return result;
}, {});
