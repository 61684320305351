import React, { useEffect, useState } from 'react';
import { makeStyles, Typography, Paper, Grid, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import { useHistory, useLocation } from 'react-router-dom';
import AppHeader from '../../components/AppHeader/AppHeader';
import TrainingList from '../../components/Dashboard/TrainingList';
import LearnProgress from '../../sdk/com/apiomat/frontend/mylearningplatform/LearnProgress';
import GetAppIcon from '@material-ui/icons/GetApp';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LanguageSelector from '../../components/Dialogs/LanguageSelector';
import { notificationActions } from '../../store/notification';
import { sortTrainingsBySuccessDate, getAvailableLanguages } from '../../utils/trainings.utils';
import { onDownloadFileByLink, onDownloadAll } from '../../utils/file.utils';
import BottomAppBar from '../../components/AppHeader/BottomAppBar';
import { DiamondIcon } from '../../components/Icons/Icons';
import BasicContainer from '../../components/Container/BasicContainer';
import { learnContentsActions } from '../../store/learnContents';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
  },
  tableTitle: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const { learnContentsMap, loading } = useSelector((state: AppState) => state.learnContents);
  const { user } = useSelector((state: AppState) => state.auth);

  const [activeTraining, setActiveTraining] = useState<LearnProgress>(null);

  useEffect(() => {
    dispatch(learnContentsActions.loadLearnContents());
  }, [dispatch]);

  const allItems = user.learnings
    .filter(training => Boolean(training.successDate))
    .slice()
    .sort(sortTrainingsBySuccessDate);

  const goToTraining = (item: LearnProgress) => {
    const langs = getAvailableLanguages(learnContentsMap.get(item.learnContentId));
    if (langs.length === 0) {
      dispatch(notificationActions.showError(t('errors.no-learn-content-available')));
    } else if (langs.length === 1) {
      navigateToTraining(item, langs[0]);
    } else if (langs.includes(user.defaultLanguage)) {
      navigateToTraining(item, user.defaultLanguage);
    } else {
      setActiveTraining(item);
    }
  };

  const onDownload = async (item) => {
    try {
      await onDownloadFileByLink(item, 'certificate');
    } catch (e) {
      dispatch(notificationActions.showError(e));
    }
  };

  const navigateToTraining = (training: LearnProgress, lang: string) => {
    history.push(`/user/training/${training.ID}/${lang}?backRoute=${location.pathname}`);
  };

  return (
    <>
      <AppHeader tab="user/certificates" />
      <BasicContainer>
        {loading === 'pending' ? (
          <LoadingIndicator />
        ) : (
          <>
            <Paper elevation={0} className={classes.paper}>
              <Grid container justify="space-around" alignItems="center" spacing={2}>
                <Grid item container xs={6} sm={1} direction="column" alignItems="center" spacing={1}>
                  <Grid item>
                    <CheckCircleIcon />
                  </Grid>
                  <Grid item>
                    <Typography variant="h5">{allItems.length}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="textSecondary" align="center">
                      {t('dashboard.closed-learn-contents')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={6} sm={2} direction="column" alignItems="center" spacing={1}>
                  <Grid item>
                    <DiamondIcon />
                  </Grid>
                  <Grid item>
                    <Typography variant="h5">{user.generalScore || 0}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="textSecondary" align="center">
                      {t('dashboard.collected-learning-points')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Grid container alignItems="center" justify="space-between" className={classes.tableTitle}>
              <Grid item>
                <Typography variant="body2">{t('app-header.my-certificates')}</Typography>
              </Grid>
              {allItems.length !== 0 ? (
                (
                <Grid item>
                   <Button color="primary" startIcon={<GetAppIcon />} onClick={item => onDownloadAll(user)}>
                    {t('dashboard.download-all')} ({allItems.length})
                  </Button>
                  </Grid>
              )
              ) : null}
            </Grid>
            <TrainingList
              items={allItems}
              learnContentsMap={learnContentsMap}
              onItemClick={goToTraining}
              onDownload={onDownload}
              showNoResultsMessage={true}
            />
          </>
        )}
      </BasicContainer>
      <BottomAppBar tab="user/certificates" />
      <LanguageSelector
        open={Boolean(activeTraining) === true}
        onClose={() => setActiveTraining(null)}
        onSave={lang => navigateToTraining(activeTraining, lang)}
        availableLanguages={getAvailableLanguages(learnContentsMap.get(activeTraining?.learnContentId))}
      />
    </>
  );
};
