import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, call, all, takeLatest, select } from 'redux-saga/effects';
import { notificationActions } from './notification';
import { AppState } from './index';
import LearnProgress from '../sdk/com/apiomat/frontend/mylearningplatform/LearnProgress';
import { ScormEventTypes } from '../enums/ScormEventTypes';
import { push } from 'connected-react-router';
import i18n from 'i18next';
import { LearnContentTypes } from '../enums/LearnContentTypes';
import { onDownloadFileByLink } from '../utils/file.utils';
import LPUser from '../sdk/com/apiomat/frontend/mylearningplatform/LPUser';
import LearnContent from '../sdk/com/apiomat/frontend/mylearningplatform/LearnContent';
import { getResourceLink } from '../utils/learnContents.utils';
import { ApiRequestState } from '../models/api-request-state';

/** STATE */
export interface TrainingsState {
  currentTraining: LearnProgress | null;
  currentLearnContent: LearnContent | null;
  loading: ApiRequestState;
  loadingPost: ApiRequestState;
}

const initialState: TrainingsState = {
  currentTraining: null,
  currentLearnContent: null,
  loading: 'idle',
  loadingPost: 'idle',
};

/** TYPES */
export interface LoadTrainingProps {
  id: string;
  lang: string;
}

export interface LoadTrainingSuccessProps {
  training: LearnProgress;
  learnContent: LearnContent;
}

export interface UpdatePdfTrainingProps {
  training: LearnProgress;
  downloadCertificate?: boolean;
}

/** SLICE */
const trainingsSlice = createSlice({
  name: 'trainings',
  initialState,
  reducers: {
    loadCurrentTraining: (state, _action: PayloadAction<LoadTrainingProps>) => {
      state.loading = 'pending';
    },
    loadCurrentTrainingSuccess: (state, action: PayloadAction<LoadTrainingSuccessProps>) => {
      state.loading = 'succeeded';
      state.currentTraining = action.payload.training;
      state.currentLearnContent = action.payload.learnContent;
    },
    loadCurrentTrainingFailure: state => {
      state.loading = 'failed';
      state.currentTraining = null;
      state.currentLearnContent = null;
    },
    updateCurrentScormTraining: (_state, _action: PayloadAction<ScormEventTypes>) => {
      /** No changes to avoid rerender of iframe with training */
    },
    updateCurrentPdfTraining: (_state, _actions: PayloadAction<LearnProgress>) => {
      /** No changes for background saving */
    },
    approveCurrentTraining: (state, _action: PayloadAction<boolean>) => {
      state.loadingPost = 'pending';
    },
    approveCurrentTrainingSuccess: state => {
      state.loadingPost = 'succeeded';
    },
    approveCurrentTrainingFailure: state => {
      state.loadingPost = 'failed';
    },
  },
});

export const trainingsActions = trainingsSlice.actions;
export const trainingsReducer = trainingsSlice.reducer;

/** SAGAS */
function* onLoadCurrentTraining(action: PayloadAction<LoadTrainingProps>) {
  const { id, lang } = action.payload;
  const user: LPUser = yield select((state: AppState) => state.auth.user);

  try {
    const training = user.learnings.find(learning => learning.ID === id);

    if (!training) {
      throw new Error(i18n.t('errors.no-entry-found'));
    }

    const rows: LearnContent[] = yield call(() => LearnContent.getLearnContents(`id==id(${training.learnContentId})`));
    const learnContent = rows[0];

    if (Boolean(learnContent) === false) {
      throw new Error(i18n.t('errors.no-learn-content-available'));
    }

    const foundContent = learnContent.localizedLearnContents.find(content => content.language === lang);
    if (!foundContent) {
      throw new Error(i18n.t('errors.training-has-not-selected-language'));
    }

    if (learnContent.learnContentType === LearnContentTypes.pdf) {
      /** Update last access date */
      if (Boolean(training.successDate) === false) {
        training.lastAccess = new Date();
        // TODO: hot fix for issue [https://jira.apiomat.com/browse/AOM-6011]
        (training as any).setDataToParentLocal(training);
        yield call(() => training.save());
      }
    }

    /** Get link to pdf or scorm */
    const link = yield call(() => getResourceLink(foundContent));
    foundContent['dao']['resourceTokenURL'] = link;

    yield put(trainingsActions.loadCurrentTrainingSuccess({ training, learnContent }));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(trainingsActions.loadCurrentTrainingFailure());
  }
}

function* onUpdateCurrentScormTraining(action: PayloadAction<ScormEventTypes>) {
  const eventType = action.payload;
  const data = (window as any).API_1484_11.cmi;
  const currentTraining: LearnProgress = yield select((state: AppState) => state.trainings.currentTraining);

  if (Boolean(currentTraining) === false) {
    return;
  }

  try {
    switch (eventType) {
      case ScormEventTypes.terminate:
        yield put(push('/'));
        break;

      case ScormEventTypes.initialize:
        currentTraining.lastAccess = new Date();
        // TODO: hot fix for issue [https://jira.apiomat.com/browse/AOM-6011]
        (currentTraining as any).setDataToParentLocal(currentTraining);
        yield call(() => currentTraining.save());
        break;

      case ScormEventTypes.commit:
        /** check if completed course */
        if (data['cmi.success_status'] !== 'passed' && data.hasOwnProperty('cmi.score.scaled')) {
          currentTraining.scormProgress = (parseFloat(data['cmi.score.scaled']) || 0) * 100;
          yield call(() => currentTraining.save());
        }
        break;

      default:
        break;
    }
  } catch (error) {
    yield put(notificationActions.showError(error));
  }
}

function* onUpdateCurrentPdfTraining(action: PayloadAction<LearnProgress>) {
  const training = action.payload;

  if (Boolean(training) === false) {
    return;
  }

  try {
    yield call(() => training.save());
  } catch (error) {
    yield put(notificationActions.showError(error));
  }
}

function* onApproveCurrentTraining(action: PayloadAction<boolean>) {
  const training: LearnProgress = yield select((state: AppState) => state.trainings.currentTraining);
  const learnContent: LearnContent = yield select((state: AppState) => state.trainings.currentLearnContent);
  const downloadCertificate = action.payload;

  try {
    if (learnContent?.learnContentType === LearnContentTypes.pdf) {
      training.attemptsCount = (training.attemptsCount || 0) + 1;
    } else {
      training.scormProgress = 100;
    }
    training.successDate = new Date();
    training.disclaimerApproved = 1;

    yield call(() => training.save());

    /** reload training for getting certificate */
    yield call(() => training.load());
    if (downloadCertificate && training.certificateURL) {
      yield call(() => onDownloadFileByLink(training, 'certificate'));
    }

    yield put(notificationActions.showSuccessMessage(i18n.t('training.success.success-message')));
    yield put(push('/user/learn-contents'));
    yield put(trainingsActions.approveCurrentTrainingSuccess());
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(trainingsActions.approveCurrentTrainingFailure());
  }
}

export function* trainingsSaga() {
  yield all([
    takeLatest(trainingsActions.loadCurrentTraining, onLoadCurrentTraining),
    takeLatest(trainingsActions.updateCurrentScormTraining, onUpdateCurrentScormTraining),
    takeLatest(trainingsActions.updateCurrentPdfTraining, onUpdateCurrentPdfTraining),
    takeLatest(trainingsActions.approveCurrentTraining, onApproveCurrentTraining),
  ]);
}
