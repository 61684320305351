import { Button, Container, Divider, Grid, IconButton, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import { authActions } from '../../store/auth';
import AppLogo from '../../assets/img/app_logo.svg';
import { useHistory } from 'react-router-dom';
import Imprint from "../Imprint/Imprint";

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  appLogo: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(6),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
    },
    width: 230,
  },
  title: {
    padding: theme.spacing(3),
  },
  description: {
    padding: theme.spacing(3),
    paddingBottom: 0,
  },
  card: {
    marginTop: theme.spacing(6),
    maxWidth: 350,
  },
  form: {
    padding: theme.spacing(3),
    paddingTop: 0,
  },
  submit: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    textTransform: 'none',
  },
  button: {
    padding: 0,
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [userName, setUserName] = useState<string>('');

  const onResetPassword = (event) => {
    event.preventDefault();
    dispatch(authActions.resetPassword(userName));
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <img src={AppLogo} className={classes.appLogo} alt="App Logo" />
      <Typography>{t('login.app-slogan')}</Typography>
      <Paper elevation={0} className={classes.card}>
        <Grid container direction="row" justify="space-between" alignItems="center" className={classes.title}>
          <Grid item>
            <Typography component="span" variant="h5">
              {t('forgot-password.title')}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton size="small" onClick={() => history.push('/login')}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography component="p" variant="body2" className={classes.description}>
              {t('forgot-password.description')}
            </Typography>
          </Grid>
          <form className={classes.form} onSubmit={onResetPassword}>
            <TextField
              name="userName"
              label={t('login.email')}
              variant="outlined"
              margin="normal"
              fullWidth
              onChange={e => setUserName(e.target.value)}
            ></TextField>
            <Button variant="contained" color="primary" fullWidth type="submit" className={classes.submit}>
              {t('forgot-password.reset-password')}
            </Button>
          </form>
        </Grid>
      </Paper>
      <Imprint />
    </Container>
  );
};

export default ForgotPassword;
