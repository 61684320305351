var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import AOMCacheStrategy from "./../AOMCacheStrategy";
import AOMRequestError from "./../AOMRequestError";
import Datastore from "./../Datastore";
import AOMStatus from "./../Status";
import AbstractClientDataModel from "./../AbstractClientDataModel";
export default class LearnContent extends AbstractClientDataModel {
    static getLearnContents(query, withReferencedHrefs = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadListFromServerWithClass(LearnContent, query, withReferencedHrefs, usePersistentStorage);
        });
    }
    static getLearnContentsCount(query, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadCountFromServer(LearnContent, undefined, query, usePersistentStorage);
        });
    }
    static staticGetModuleName() {
        return "MyLearningPlatform";
    }
    static staticGetModelName() {
        return "LearnContent";
    }
    get customMetaForClass() {
        return "";
    }
    static removeAllFromStorage() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorageByClass(LearnContent);
        });
    }
    static deleteAllFromStorage(query) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorage(query, LearnContent);
        });
    }
    static getLearnContentsFromPersistentStorage(whereWithPlaceholders = "", whereValues = [], orderBy = "", limit, offset) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadFromPersistentStorage(LearnContent, whereWithPlaceholders, whereValues, orderBy, limit, offset);
        });
    }
    static getLearnContentsCountFromPersistentStorage(whereWithPlaceholders = "", whereValues = []) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.getCountFromPersistentStorage(LearnContent, whereWithPlaceholders, whereValues);
        });
    }
    constructor() {
        super();
        this.dao = new LearnContentDAO();
        this.hashmap.localizedLearnContents = [];
        this.hashmap.questions = [];
    }
    get modelName() { return "LearnContent"; }
    get moduleName() { return "MyLearningPlatform"; }
    get archivingDate() {
        const retDate = this.dao.archivingDate;
        return (typeof retDate !== "undefined") ? new Date(retDate) : undefined;
    }
    set archivingDate(_archivingDate) {
        if (_archivingDate instanceof Date) {
            this.dao.archivingDate = _archivingDate.getTime();
        }
        else {
            this.dao.archivingDate = undefined;
        }
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get creator() {
        return this.dao.creator !== null ? this.dao.creator : undefined;
    }
    set creator(_creator) {
        this.dao.creator = _creator;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get descriptionKey() {
        return this.dao.descriptionKey !== null ? this.dao.descriptionKey : undefined;
    }
    set descriptionKey(_descriptionKey) {
        this.dao.descriptionKey = _descriptionKey;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get downloadable() {
        return this.dao.downloadable !== null ? this.dao.downloadable : undefined;
    }
    set downloadable(_downloadable) {
        this.dao.downloadable = _downloadable;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get dueDate() {
        const retDate = this.dao.dueDate;
        return (typeof retDate !== "undefined") ? new Date(retDate) : undefined;
    }
    set dueDate(_dueDate) {
        if (_dueDate instanceof Date) {
            this.dao.dueDate = _dueDate.getTime();
        }
        else {
            this.dao.dueDate = undefined;
        }
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get headlineKey() {
        return this.dao.headlineKey !== null ? this.dao.headlineKey : undefined;
    }
    set headlineKey(_headlineKey) {
        this.dao.headlineKey = _headlineKey;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get isArchived() {
        return this.dao.isArchived !== null ? this.dao.isArchived : undefined;
    }
    set isArchived(_isArchived) {
        this.dao.isArchived = _isArchived;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get learnContentType() {
        return this.dao.learnContentType !== null ? this.dao.learnContentType : undefined;
    }
    set learnContentType(_learnContentType) {
        this.dao.learnContentType = _learnContentType;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get lives() {
        return this.dao.lives !== null ? this.dao.lives : undefined;
    }
    set lives(_lives) {
        this.dao.lives = _lives;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get localizedLearnContents() {
        if (this.hashmap.localizedLearnContents.length === 0) {
            const data = this.getDataOrKeyFromParent();
            if (typeof data !== "undefined" && this.dao.localizedLearnContents instanceof Array && this.dao.localizedLearnContents.length > 0) {
                const objects = [];
                this.dao.localizedLearnContents.forEach((element) => {
                    const el = new LocalizedLearnContent().fromJson(element);
                    el.parent = this;
                    el.parentAttrName = "localizedLearnContents";
                    el.parentAttrKey = objects.push(el) - 1;
                });
                this.hashmap.localizedLearnContents = objects;
            }
        }
        return this.hashmap.localizedLearnContents;
    }
    loadLocalizedLearnContents(withRefHref = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.load(undefined, usePersistentStorage);
                this.hashmap.localizedLearnContents = [];
                return this.localizedLearnContents;
            }
            catch (error) {
                if (error.statusCode === 204 || error.statusCode === AOMStatus.ID_NOT_FOUND_OFFLINE
                    || error.statusCode === AOMStatus.ID_NOT_FOUND) {
                    this.hashmap.localizedLearnContents = undefined;
                }
                return Promise.reject(error);
            }
        });
    }
    loadLocalizedLearnContentsAndRefHref(usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.loadLocalizedLearnContents(true, usePersistentStorage);
        });
    }
    postLocalizedLearnContents(_embData, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (_embData instanceof LocalizedLearnContent === false) {
                return Promise.reject(new AOMRequestError(AOMStatus.WRONG_REF_TYPE, 201));
            }
            if (typeof _embData.href !== "undefined" || _embData.parent !== null) {
                return Promise.reject(new AOMRequestError(AOMStatus.EMBEDDED_POST_EXISTING_OBJECT, 201));
            }
            if (typeof this.dao.localizedLearnContents === "undefined" || this.dao.localizedLearnContents === null) {
                this.dao.localizedLearnContents = [];
            }
            if (typeof _embData.ID === "undefined" || _embData.ID === null) {
                _embData["dao"].embeddedCollectionId = Date.now().toString() + (Math.floor(Math.random() * 9000) + 1000).toString();
            }
            const parentAttrKey = this.dao.localizedLearnContents.push(_embData.toJson()) - 1;
            this.hashmap.localizedLearnContents = [];
            _embData.parentAttrKey = parentAttrKey;
            _embData.parentAttrName = "localizedLearnContents";
            _embData.parent = this;
            const ret = yield this.setDataToParent(_embData, usePersistentStorage);
            const data = _embData.getDataOrKeyFromParent();
            this.dao.localizedLearnContents[parentAttrKey] = data;
            _embData = _embData.fromJson(data);
            return ret;
        });
    }
    removeLocalizedLearnContents(_embData, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (_embData instanceof LocalizedLearnContent === false) {
                return Promise.reject(new AOMRequestError(AOMStatus.WRONG_REF_TYPE, 204));
            }
            if (_embData.parent === this) {
                return yield _embData.delete(usePersistentStorage);
            }
            else if (_embData.parent !== null && _embData.parent.ID === this.ID) {
                const ret = yield _embData.delete(usePersistentStorage);
                yield this.load(undefined, usePersistentStorage);
                return ret;
            }
            else {
                return Promise.reject(new AOMRequestError(AOMStatus.NOT_EMBEDDED_CHILD, 204));
            }
        });
    }
    getLocalizedLearnContentsCount(usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.load(undefined, usePersistentStorage);
            return this.localizedLearnContents.length;
        });
    }
    get questions() {
        if (this.hashmap.questions.length === 0) {
            const data = this.getDataOrKeyFromParent();
            if (typeof data !== "undefined" && this.dao.questions instanceof Array && this.dao.questions.length > 0) {
                const objects = [];
                this.dao.questions.forEach((element) => {
                    const el = new Question().fromJson(element);
                    el.parent = this;
                    el.parentAttrName = "questions";
                    el.parentAttrKey = objects.push(el) - 1;
                });
                this.hashmap.questions = objects;
            }
        }
        return this.hashmap.questions;
    }
    loadQuestions(withRefHref = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.load(undefined, usePersistentStorage);
                this.hashmap.questions = [];
                return this.questions;
            }
            catch (error) {
                if (error.statusCode === 204 || error.statusCode === AOMStatus.ID_NOT_FOUND_OFFLINE
                    || error.statusCode === AOMStatus.ID_NOT_FOUND) {
                    this.hashmap.questions = undefined;
                }
                return Promise.reject(error);
            }
        });
    }
    loadQuestionsAndRefHref(usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.loadQuestions(true, usePersistentStorage);
        });
    }
    postQuestions(_embData, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (_embData instanceof Question === false) {
                return Promise.reject(new AOMRequestError(AOMStatus.WRONG_REF_TYPE, 201));
            }
            if (typeof _embData.href !== "undefined" || _embData.parent !== null) {
                return Promise.reject(new AOMRequestError(AOMStatus.EMBEDDED_POST_EXISTING_OBJECT, 201));
            }
            if (typeof this.dao.questions === "undefined" || this.dao.questions === null) {
                this.dao.questions = [];
            }
            if (typeof _embData.ID === "undefined" || _embData.ID === null) {
                _embData["dao"].embeddedCollectionId = Date.now().toString() + (Math.floor(Math.random() * 9000) + 1000).toString();
            }
            const parentAttrKey = this.dao.questions.push(_embData.toJson()) - 1;
            this.hashmap.questions = [];
            _embData.parentAttrKey = parentAttrKey;
            _embData.parentAttrName = "questions";
            _embData.parent = this;
            const ret = yield this.setDataToParent(_embData, usePersistentStorage);
            const data = _embData.getDataOrKeyFromParent();
            this.dao.questions[parentAttrKey] = data;
            _embData = _embData.fromJson(data);
            return ret;
        });
    }
    removeQuestions(_embData, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (_embData instanceof Question === false) {
                return Promise.reject(new AOMRequestError(AOMStatus.WRONG_REF_TYPE, 204));
            }
            if (_embData.parent === this) {
                return yield _embData.delete(usePersistentStorage);
            }
            else if (_embData.parent !== null && _embData.parent.ID === this.ID) {
                const ret = yield _embData.delete(usePersistentStorage);
                yield this.load(undefined, usePersistentStorage);
                return ret;
            }
            else {
                return Promise.reject(new AOMRequestError(AOMStatus.NOT_EMBEDDED_CHILD, 204));
            }
        });
    }
    getQuestionsCount(usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.load(undefined, usePersistentStorage);
            return this.questions.length;
        });
    }
    get scores() {
        return this.dao.scores !== null ? this.dao.scores : undefined;
    }
    set scores(_scores) {
        this.dao.scores = _scores;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get subTopicKeys() {
        return this.dao.subTopicKeys !== null ? this.dao.subTopicKeys : undefined;
    }
    set subTopicKeys(_subTopicKeys) {
        this.dao.subTopicKeys = _subTopicKeys;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get thumbnailUrlResource() {
        let url;
        this.refreshEmbedded();
        url = this.dao.thumbnailURL !== null ? this.dao.thumbnailURL : undefined;
        if (url) {
            url += ".img";
            return url;
        }
        else {
            return undefined;
        }
    }
    get thumbnailURL() {
        let url = this.thumbnailUrlResource;
        if (url) {
            url += "?apiKey=" + User.AOMAPIKEY + "&system=" + User.AOMSYS;
        }
        return url;
    }
    getThumbnailTokenURL(validity, oneTime) {
        return __awaiter(this, void 0, void 0, function* () {
            let url = this.dao.thumbnailURL;
            if (!url || typeof validity === "undefined" || typeof oneTime === "undefined") {
                return undefined;
            }
            url += ".key?apiKey=" + User.AOMAPIKEY + "&system=" + User.AOMSYS;
            return yield Datastore.Instance.getStaticDataTokenUrl(url, validity, oneTime);
        });
    }
    loadThumbnail(usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            const resUrl = this.thumbnailURL;
            if (typeof resUrl === "undefined") {
                return Promise.reject(new AOMRequestError(AOMStatus.ATTACHED_HREF_MISSING, 200));
            }
            return yield Datastore.Instance.loadResource(resUrl, usePersistentStorage);
        });
    }
    postThumbnail(_data, name = "", format = "", usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof _data === "undefined" || _data === null) {
                return Promise.reject(new AOMRequestError(AOMStatus.BAD_RESOURCE, 200));
            }
            if ((typeof this.href === "undefined" || this.href === "") && this.parent === null) {
                return Promise.reject(new AOMRequestError(AOMStatus.HREF_NOT_FOUND, 200));
            }
            let href;
            let response;
            const options = this.parent === null ? this.getOptionsForStaticData("thumbnail") : {};
            try {
                response = yield Datastore.Instance.postStaticDataOnServer(_data, name, format, false, usePersistentStorage, options);
                href = response.href;
            }
            catch (error) {
                return Promise.reject(error);
            }
            this.dao.thumbnailURL = href;
            if (this.href && Datastore.Instance.getCachingStrategy() !== AOMCacheStrategy.NETWORK_ONLY) {
                yield this.saveToStorage(usePersistentStorage);
            }
            if (this.parent !== null) {
                this.setDataToParentLocal(this);
                try {
                    yield this.save(false, usePersistentStorage);
                }
                catch (error) {
                    yield this.deleteThumbnail(usePersistentStorage);
                }
            }
            if (typeof response !== "undefined" && response.offline === true) {
                yield this.save(false, usePersistentStorage);
            }
            try {
                yield this.load(undefined, usePersistentStorage);
            }
            catch (error) {
                return Promise.reject(error);
            }
        });
    }
    deleteThumbnail(usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            const href = this.dao.thumbnailURL;
            if (href === null || href === undefined || href === "") {
                return Promise.reject(new AOMRequestError(AOMStatus.ATTACHED_HREF_MISSING, 200));
            }
            try {
                const response = yield Datastore.Instance.deleteOnServer(href, this, false, usePersistentStorage, true);
                this.dao.thumbnailURL = null;
                if (this.href && Datastore.Instance.getCachingStrategy() !== AOMCacheStrategy.NETWORK_ONLY) {
                    yield this.saveToStorage(usePersistentStorage);
                }
                if (typeof response !== "undefined" && response.offline === true) {
                    yield this.save(false, usePersistentStorage);
                }
                if (this.parent !== null) {
                    delete this.dao.thumbnailURL;
                    this.setDataToParentLocal(this);
                    yield this.save(false, usePersistentStorage);
                }
                yield this.load(undefined, usePersistentStorage);
            }
            catch (error) {
                return Promise.reject(error);
            }
        });
    }
}
import LearnContentDAO from "./LearnContentDAO";
import User from "./../basics/User";
import Question from "./../mylearningplatform/Question";
import LocalizedLearnContent from "./../mylearningplatform/LocalizedLearnContent";
