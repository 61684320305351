import React from 'react';
import { makeStyles, Typography, Dialog, DialogTitle, Divider, Button, DialogContent, DialogActions, Theme, Grid } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { useTranslation } from 'react-i18next';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { useStylesBase } from './BaseDailogStyles';

const useStyles = makeStyles((theme: Theme) => ({
  lives: {
    marginTop: -3,
    fontSize: 18,
    color: theme.palette.primary.main,
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
  livesIcon: {
    marginRight: theme.spacing(1),
    marginTop: 2,
  },
}));

export interface ApproveSuccessTrainingProps {
  open: boolean;
  onClose: () => void;
}

const FailedTraining = (props: ApproveSuccessTrainingProps) => {
  const { open, onClose } = props;

  const classes = useStyles();
  const classesBase = useStylesBase();
  const { t } = useTranslation();

  return (
    <Dialog maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle className={classesBase.dialogContent}>
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={3}>
          <Grid item>
            <Typography component="span" variant="h5">
              <CancelIcon className={classesBase.dialogIcon} />
              {t('training.failed.title')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="span" variant="subtitle2" className={classes.lives}>
              <FavoriteIcon className={classes.livesIcon} /> 0
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent className={classesBase.dialogContent}>
        <Typography component="p" variant="body2">
          {t('training.failed.description')}
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" color="primary" fullWidth className={classesBase.dialogSubmit} onClick={onClose}>
          {t('training.failed.done')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FailedTraining;
