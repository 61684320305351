import { ListItem, ListItemAvatar, ListItemText, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(theme => ({
  icon: {
    width: 54,
    height: 54,
    borderRadius: 10,
    marginRight: theme.spacing(2),
  }
}))

interface NoResultsMessageProps {
  imageSrc: any
  title: string
  description: string
}

export const NoResultsListItem: React.FC<NoResultsMessageProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <ListItem>
      <ListItemAvatar>
        <img alt="all done icon" className={classes.icon} src={props.imageSrc} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography component="span" variant="subtitle2" color="primary">
            {t(props.title)}
          </Typography>
        }
        secondary={<Typography variant="subtitle2">{t(props.description)}</Typography>}
      />
    </ListItem>
  )
}