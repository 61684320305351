import React from 'react';
import { Typography, makeStyles, Theme, Button } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    color: theme.palette.common.white,
    fontSize: 11,
    padding: theme.spacing(0, 0.5),
    height: 16,
    minWidth: 16,
    borderRadius: 8,
    marginLeft: theme.spacing(1),
  },
  notSelectedButton: {
    backgroundColor: theme.palette.common.white,
    borderWidth: 0,
  },
  errorWrapper: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  warningWrapper: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
  },
  defaultWrapper: {
    backgroundColor: '#E6E6E6',
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  default: {
    backgroundColor: theme.palette.text.primary,
  },
}));

export interface ButtonWithBadgeProps {
  title: string;
  selected: boolean;
  number?: number;
  color?: 'error' | 'warning' | 'default';
  onClick: () => void;
}

const ButtonWithBadge = (props: ButtonWithBadgeProps) => {
  const { title, selected, number, color = 'default', onClick } = props;
  const classes = useStyles();

  const displayBadge = typeof number === 'number' && !Number.isNaN(number)

  return (
    <Button
      fullWidth
      variant="outlined"
      className={clsx(selected ? classes[`${color}Wrapper`] : classes.notSelectedButton)}
      onClick={onClick}
    >
      {title}
      {displayBadge ? (
        <Typography component="span" className={clsx(classes.badge, classes[color])}>
          {number}
        </Typography>
      ): null}
    </Button>
  );
};

export default ButtonWithBadge;
