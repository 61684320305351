var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import AOMCacheStrategy from "./../AOMCacheStrategy";
import AOMRequestError from "./../AOMRequestError";
import Datastore from "./../Datastore";
import AOMStatus from "./../Status";
import AbstractClientDataModel from "./../AbstractClientDataModel";
export default class LPResource extends AbstractClientDataModel {
    static getLPResources(query, withReferencedHrefs = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadListFromServerWithClass(LPResource, query, withReferencedHrefs, usePersistentStorage);
        });
    }
    static getLPResourcesCount(query, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadCountFromServer(LPResource, undefined, query, usePersistentStorage);
        });
    }
    static staticGetModuleName() {
        return "MyLearningPlatform";
    }
    static staticGetModelName() {
        return "LPResource";
    }
    get customMetaForClass() {
        return "";
    }
    static removeAllFromStorage() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorageByClass(LPResource);
        });
    }
    static deleteAllFromStorage(query) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorage(query, LPResource);
        });
    }
    static getLPResourcesFromPersistentStorage(whereWithPlaceholders = "", whereValues = [], orderBy = "", limit, offset) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadFromPersistentStorage(LPResource, whereWithPlaceholders, whereValues, orderBy, limit, offset);
        });
    }
    static getLPResourcesCountFromPersistentStorage(whereWithPlaceholders = "", whereValues = []) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.getCountFromPersistentStorage(LPResource, whereWithPlaceholders, whereValues);
        });
    }
    constructor() {
        super();
        this.dao = new LPResourceDAO();
    }
    get modelName() { return "LPResource"; }
    get moduleName() { return "MyLearningPlatform"; }
    get contentType() {
        return this.dao.contentType !== null ? this.dao.contentType : undefined;
    }
    set contentType(_contentType) {
        this.dao.contentType = _contentType;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get fileContentUrlResource() {
        let url;
        this.refreshEmbedded();
        url = this.dao.fileContentURL !== null ? this.dao.fileContentURL : undefined;
        if (url) {
            url += ".img";
            return url;
        }
        else {
            return undefined;
        }
    }
    get fileContentURL() {
        let url = this.fileContentUrlResource;
        if (url) {
            url += "?apiKey=" + User.AOMAPIKEY + "&system=" + User.AOMSYS;
        }
        return url;
    }
    getFileContentTokenURL(validity, oneTime) {
        return __awaiter(this, void 0, void 0, function* () {
            let url = this.dao.fileContentURL;
            if (!url || typeof validity === "undefined" || typeof oneTime === "undefined") {
                return undefined;
            }
            url += ".key?apiKey=" + User.AOMAPIKEY + "&system=" + User.AOMSYS;
            return yield Datastore.Instance.getStaticDataTokenUrl(url, validity, oneTime);
        });
    }
    loadFileContent(usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            const resUrl = this.fileContentURL;
            if (typeof resUrl === "undefined") {
                return Promise.reject(new AOMRequestError(AOMStatus.ATTACHED_HREF_MISSING, 200));
            }
            return yield Datastore.Instance.loadResource(resUrl, usePersistentStorage);
        });
    }
    postFileContent(_data, name = "", format = "", usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof _data === "undefined" || _data === null) {
                return Promise.reject(new AOMRequestError(AOMStatus.BAD_RESOURCE, 200));
            }
            if ((typeof this.href === "undefined" || this.href === "") && this.parent === null) {
                return Promise.reject(new AOMRequestError(AOMStatus.HREF_NOT_FOUND, 200));
            }
            let href;
            let response;
            const options = this.parent === null ? this.getOptionsForStaticData("fileContent") : {};
            try {
                response = yield Datastore.Instance.postStaticDataOnServer(_data, name, format, false, usePersistentStorage, options);
                href = response.href;
            }
            catch (error) {
                return Promise.reject(error);
            }
            this.dao.fileContentURL = href;
            if (this.href && Datastore.Instance.getCachingStrategy() !== AOMCacheStrategy.NETWORK_ONLY) {
                yield this.saveToStorage(usePersistentStorage);
            }
            if (this.parent !== null) {
                this.setDataToParentLocal(this);
                try {
                    yield this.save(false, usePersistentStorage);
                }
                catch (error) {
                    yield this.deleteFileContent(usePersistentStorage);
                }
            }
            if (typeof response !== "undefined" && response.offline === true) {
                yield this.save(false, usePersistentStorage);
            }
            try {
                yield this.load(undefined, usePersistentStorage);
            }
            catch (error) {
                return Promise.reject(error);
            }
        });
    }
    deleteFileContent(usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            const href = this.dao.fileContentURL;
            if (href === null || href === undefined || href === "") {
                return Promise.reject(new AOMRequestError(AOMStatus.ATTACHED_HREF_MISSING, 200));
            }
            try {
                const response = yield Datastore.Instance.deleteOnServer(href, this, false, usePersistentStorage, true);
                this.dao.fileContentURL = null;
                if (this.href && Datastore.Instance.getCachingStrategy() !== AOMCacheStrategy.NETWORK_ONLY) {
                    yield this.saveToStorage(usePersistentStorage);
                }
                if (typeof response !== "undefined" && response.offline === true) {
                    yield this.save(false, usePersistentStorage);
                }
                if (this.parent !== null) {
                    delete this.dao.fileContentURL;
                    this.setDataToParentLocal(this);
                    yield this.save(false, usePersistentStorage);
                }
                yield this.load(undefined, usePersistentStorage);
            }
            catch (error) {
                return Promise.reject(error);
            }
        });
    }
    get fileName() {
        return this.dao.fileName !== null ? this.dao.fileName : undefined;
    }
    set fileName(_fileName) {
        this.dao.fileName = _fileName;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get fileType() {
        return this.dao.fileType !== null ? this.dao.fileType : undefined;
    }
    set fileType(_fileType) {
        this.dao.fileType = _fileType;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get isArchived() {
        return this.dao.isArchived !== null ? this.dao.isArchived : undefined;
    }
    set isArchived(_isArchived) {
        this.dao.isArchived = _isArchived;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get markedForDeletion() {
        return this.dao.markedForDeletion !== null ? this.dao.markedForDeletion : undefined;
    }
    set markedForDeletion(_markedForDeletion) {
        this.dao.markedForDeletion = _markedForDeletion;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get webhostingPath() {
        return this.dao.webhostingPath !== null ? this.dao.webhostingPath : undefined;
    }
    set webhostingPath(_webhostingPath) {
        this.dao.webhostingPath = _webhostingPath;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
}
import LPResourceDAO from "./LPResourceDAO";
import User from "./../basics/User";
