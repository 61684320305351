var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import AbstractStorage from "./AbstractStorage";
export default class MemoryStorage extends AbstractStorage {
    constructor() {
        super();
        this._collectionStorage = {};
        this._collectionInfo = {};
        this._objectStorage = {};
        this._eTagStorage = {};
        this._collectionStorage = {};
        this._objectStorage = {};
    }
    static get Instance() {
        return this._instance || (this._instance = new this());
    }
    addOrUpdateCollection(href, responseData, useCollectionMerging = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if (responseData instanceof Array) {
                this._collectionStorage[href] = this.updateIdArray(responseData, href, useCollectionMerging);
                responseData.forEach((el) => {
                    this.addOrUpdateObject(el);
                });
                this.saveLastUpdateOfCollection(href);
            }
            else {
                this.addOrUpdateObject(responseData, href);
                if (typeof responseData.id !== "undefined") {
                    if (this._collectionStorage[href] instanceof Array) {
                        this._collectionStorage[href].push(responseData.id);
                    }
                    else {
                        this._collectionStorage[href] = responseData.id;
                    }
                }
            }
        });
    }
    addOrUpdateObject(object, href) {
        if ((typeof object.id === "undefined" || object.id === null || object.id === "")
            && typeof object.foreignId !== "undefined") {
            object.id = object.foreignId;
        }
        if (href !== undefined && href.endsWith("/models/me")) {
            if ((this._objectStorage.hasOwnProperty(href) && this._objectStorage[href].lastModifiedAt <=
                object.lastModifiedAt) || !this._objectStorage.hasOwnProperty(href)) {
                this._objectStorage[href] = object;
            }
        }
        else if (object.id !== undefined) {
            if ((this._objectStorage.hasOwnProperty(object.id) && this._objectStorage[object.id].lastModifiedAt <=
                object.lastModifiedAt) || (this._objectStorage.hasOwnProperty(object.id)
                && typeof this._objectStorage[object.id].lastModifiedAt === "undefined") || this._objectStorage.hasOwnProperty(object.id) === false) {
                this._objectStorage[object.id] = object;
            }
        }
        else if (href && (href.indexOf("images/") > -1 || href.indexOf("files/") > -1)) {
            const key = this.getIdFromHref(href);
            this._objectStorage[key] = object;
        }
    }
    getCollection(href) {
        return this._collectionStorage[href];
    }
    getCollectionObjects(href, clazz) {
        return __awaiter(this, void 0, void 0, function* () {
            const collection = this.getCollection(href);
            if (href.endsWith("/models/me")) {
                if (collection === null) {
                    return undefined;
                }
                return this.getObject(collection);
            }
            if (typeof collection === "string") {
                return this.getObject(collection);
            }
            else if (!collection) {
                let id = this.getIdFromHref(href);
                if (id.indexOf(".img") > -1) {
                    id = id.substring(0, id.lastIndexOf(".img"));
                }
                return this.getObject(id);
            }
            const objectArray = new Array();
            for (let i = 0; i < collection.length; i++) {
                if (this._objectStorage[collection[i]]) {
                    objectArray.push(this._objectStorage[collection[i]]);
                }
            }
            return objectArray;
        });
    }
    getObject(id, href, clazz, isCollection) {
        return this._objectStorage[id];
    }
    removeCollection(href) {
        return __awaiter(this, void 0, void 0, function* () {
            delete this._collectionStorage[href];
        });
    }
    removeObjectByHref(href, clazz) {
        return __awaiter(this, void 0, void 0, function* () {
            const potentialHref = this.getPotentialHref(href);
            const id = this.getIdFromHref(href);
            const splits = potentialHref.split("/");
            let isReference = false;
            for (let i = splits.length - 1; i > 2; i--) {
                if ((splits[i].slice(0, 1).match(/[0-9]/) !== null) && splits[i].indexOf(".") === -1) {
                    isReference = true;
                    break;
                }
            }
            if (potentialHref in this._collectionStorage && isReference) {
                yield this.removeIdFromCollection(potentialHref, id);
                yield this.removeObject(id);
            }
            else if (id in this._objectStorage && !isReference) {
                yield this.removeObject(id);
            }
        });
    }
    removeObjectById(id) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.removeObject(id);
        });
    }
    getCollectionStorage() {
        return this._collectionStorage;
    }
    getObjectStorage() {
        return this._objectStorage;
    }
    removeAllObjects(clazz) {
        return __awaiter(this, void 0, void 0, function* () {
            const type = clazz.staticGetModuleName() + "$" + clazz.staticGetModelName();
            const clazzObjectHrefs = Object.keys(this._objectStorage)
                .map(k => this._objectStorage[k])
                .filter(obj => Datastore.getCleanTypeFromTypeString(obj["@type"]) === type)
                .map(obj => obj.href);
            for (const href of clazzObjectHrefs) {
                yield this.removeObjectByHref(href, clazz);
            }
        });
    }
    clearStorage() {
        return __awaiter(this, void 0, void 0, function* () {
            this.clearCollections();
            this.clearObjects();
        });
    }
    clearCollections() {
        this._collectionStorage = {};
    }
    clearObjects() {
        this._objectStorage = {};
    }
    updateIdArray(collection, href, useCollectionMerging = false) {
        let idArray = [];
        if (useCollectionMerging) {
            idArray = this._collectionStorage[href];
            if (typeof idArray === "undefined") {
                idArray = [];
            }
        }
        collection.forEach((element) => {
            if (element.id !== undefined && idArray.some((el) => el === element.id) === false) {
                idArray.push(element.id);
            }
            else if (typeof element.id === "undefined" && typeof element.foreignId !== "undefined" && idArray.some((el) => el === element.foreignId) === false) {
                idArray.push(element.foreignId);
            }
        });
        return idArray;
    }
    removeObject(id) {
        return __awaiter(this, void 0, void 0, function* () {
            delete this._objectStorage[id];
            for (const key in this._collectionStorage) {
                yield this.removeIdFromCollection(key, id);
            }
        });
    }
    removeIdFromCollection(href, id) {
        return __awaiter(this, void 0, void 0, function* () {
            for (const collhref of Object.keys(this._collectionStorage)) {
                if (collhref.indexOf(href) > -1) {
                    if (this._collectionStorage[collhref] instanceof Array) {
                        const index = this._collectionStorage[collhref].indexOf(id);
                        if (index !== undefined && index > -1) {
                            this._collectionStorage[collhref].splice(index, 1);
                        }
                    }
                    else {
                        if (this._collectionStorage[collhref] === id) {
                            delete this._collectionStorage[collhref];
                        }
                    }
                }
            }
        });
    }
    getETag(url) {
        return __awaiter(this, void 0, void 0, function* () {
            return this._eTagStorage[url];
        });
    }
    saveETag(url, eTag) {
        return __awaiter(this, void 0, void 0, function* () {
            this._eTagStorage[url] = eTag;
        });
    }
    getLastUpdateFromCollection(href) {
        return __awaiter(this, void 0, void 0, function* () {
            let lastUpdate = null;
            if (typeof this._collectionInfo[href] !== "undefined") {
                lastUpdate = this._collectionInfo[href].lastUpdate;
            }
            return lastUpdate;
        });
    }
    saveLastUpdateOfCollection(href) {
        this._collectionInfo[href] = {
            lastUpdate: new Date().getTime(),
        };
    }
}
import Datastore from "../Datastore";
