import { useLayoutEffect, useState } from "react";

export const useInfiniteScroll = () => {
  const calculateInnerHeightScrollThreshold = () => {
    if (window.innerHeight < 1200) {
      return 0.8;
    } else if (window.innerHeight < 1400) {
      return 0.7;
    } else if (window.innerHeight < 2000) {
      return 0.6;
    } else if (window.innerHeight < 2400) {
      return 0.5;
    } else if (window.innerHeight < 2800) {
      return 0.4;
    } else {
      return 0.3;
    }
  };

  const [scrollThreshold, setScrollThreshold] = useState(calculateInnerHeightScrollThreshold());
  const [innerHeight, setInnerHeight] = useState(0);
  useLayoutEffect(() => {
    function updateInnerHeight() {
      setScrollThreshold(calculateInnerHeightScrollThreshold());
      setInnerHeight(window.innerHeight);
    }

    window.addEventListener('resize', updateInnerHeight);
    updateInnerHeight();
    return () => window.removeEventListener('resize', updateInnerHeight);
  }, []);

  return {innerHeight, scrollThreshold};
}
