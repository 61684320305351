import React from 'react';
import { makeStyles, Theme, Typography, ListItemAvatar, Avatar, Hidden } from '@material-ui/core';
import { LearnContentTypes } from '../../enums/LearnContentTypes';
import clsx from 'clsx';
import TrainingLabel from '../Dashboard/TrainingLabel';

const useStyles = makeStyles((theme: Theme) => ({
  avatarWrapper: {
    position: 'relative',
  },
  avatar: {
    width: 54,
    height: 54,
    backgroundColor: theme.palette.background.default,
    borderRadius: 10,
    marginRight: theme.spacing(2),
  },
  labelAvatar: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    height: 13,
    left: 0,
    top: 41,
    right: theme.spacing(2),
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelAvatarText: {
    color: theme.palette.common.white,
    fontSize: 11,
  },
  archievedAvatarImage: {
    opacity: 0.3,
  },
}));

export interface LearnContentAvatarProps {
  thumbnailURL: string;
  defaultThumbnailUrl?: string;
  dueDate: Date;
  isArchived?: boolean;
  learnContentType: LearnContentTypes;
}

const LearnContentAvatar = (props: LearnContentAvatarProps) => {
  const { thumbnailURL, defaultThumbnailUrl, learnContentType, isArchived, dueDate } = props;
  const classes = useStyles();

  return (
    <ListItemAvatar className={classes.avatarWrapper}>
      <>
        <Avatar
          className={clsx(classes.avatar, isArchived && classes.archievedAvatarImage)}
          src={thumbnailURL || defaultThumbnailUrl}
        ></Avatar>
        {Boolean(learnContentType) && (
          <div className={classes.labelAvatar}>
            <Typography className={classes.labelAvatarText}>{learnContentType}</Typography>
          </div>
        )}
        <Hidden mdUp>
          <TrainingLabel dueDate={dueDate} isSmall />
        </Hidden>
      </>
    </ListItemAvatar>
  );
};

export default LearnContentAvatar;
