var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Datastore from "./../Datastore";
import AbstractClientDataModel from "./../AbstractClientDataModel";
export default class MaintenanceInterval extends AbstractClientDataModel {
    static getMaintenanceIntervals(query, withReferencedHrefs = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadListFromServerWithClass(MaintenanceInterval, query, withReferencedHrefs, usePersistentStorage);
        });
    }
    static getMaintenanceIntervalsCount(query, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadCountFromServer(MaintenanceInterval, undefined, query, usePersistentStorage);
        });
    }
    static staticGetModuleName() {
        return "MaintenanceModule";
    }
    static staticGetModelName() {
        return "MaintenanceInterval";
    }
    get customMetaForClass() {
        return "";
    }
    static removeAllFromStorage() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorageByClass(MaintenanceInterval);
        });
    }
    static deleteAllFromStorage(query) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorage(query, MaintenanceInterval);
        });
    }
    static getMaintenanceIntervalsFromPersistentStorage(whereWithPlaceholders = "", whereValues = [], orderBy = "", limit, offset) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadFromPersistentStorage(MaintenanceInterval, whereWithPlaceholders, whereValues, orderBy, limit, offset);
        });
    }
    static getMaintenanceIntervalsCountFromPersistentStorage(whereWithPlaceholders = "", whereValues = []) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.getCountFromPersistentStorage(MaintenanceInterval, whereWithPlaceholders, whereValues);
        });
    }
    constructor() {
        super();
        this.dao = new MaintenanceIntervalDAO();
    }
    get modelName() { return "MaintenanceInterval"; }
    get moduleName() { return "MaintenanceModule"; }
    get displayStartDate() {
        const retDate = this.dao.displayStartDate;
        return (typeof retDate !== "undefined") ? new Date(retDate) : undefined;
    }
    set displayStartDate(_displayStartDate) {
        if (_displayStartDate instanceof Date) {
            this.dao.displayStartDate = _displayStartDate.getTime();
        }
        else {
            this.dao.displayStartDate = undefined;
        }
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get displayText() {
        return this.dao.displayText !== null ? this.dao.displayText : undefined;
    }
    set displayText(_displayText) {
        this.dao.displayText = _displayText;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get endDate() {
        const retDate = this.dao.endDate;
        return (typeof retDate !== "undefined") ? new Date(retDate) : undefined;
    }
    set endDate(_endDate) {
        if (_endDate instanceof Date) {
            this.dao.endDate = _endDate.getTime();
        }
        else {
            this.dao.endDate = undefined;
        }
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get startDate() {
        const retDate = this.dao.startDate;
        return (typeof retDate !== "undefined") ? new Date(retDate) : undefined;
    }
    set startDate(_startDate) {
        if (_startDate instanceof Date) {
            this.dao.startDate = _startDate.getTime();
        }
        else {
            this.dao.startDate = undefined;
        }
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
}
import MaintenanceIntervalDAO from "./MaintenanceIntervalDAO";
