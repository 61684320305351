import React from 'react';
import { makeStyles, Typography, ListItemText, Hidden, Theme } from '@material-ui/core';
import LearnContent from '../../sdk/com/apiomat/frontend/mylearningplatform/LearnContent';
import TrainingLabel from '../Dashboard/TrainingLabel';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  primaryTitle: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(-1),
    },
  },
  text: {
    wordBreak: 'break-word',
  },
}));

export interface LearnContentTitleProps {
  item: LearnContent;
}

const LearnContentTitle = (props: LearnContentTitleProps) => {
  const { item } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ListItemText
      primary={
        <div className={classes.primaryTitle}>
          <Typography component="span" variant="subtitle2" color="primary" className={classes.text}>
            {t(item.headlineKey)}
          </Typography>
          <Hidden smDown>
            <TrainingLabel dueDate={item.dueDate} />
          </Hidden>
        </div>
      }
      secondary={
        <Typography variant="subtitle2" className={classes.text}>
          {t(item.descriptionKey)}
        </Typography>
      }
    />
  );
};

export default LearnContentTitle;
