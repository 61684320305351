import React, { useState } from 'react';
import {
  Typography,
  Dialog,
  DialogTitle,
  Divider,
  Button,
  DialogContent,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStylesBase } from './BaseDailogStyles';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
  },
}));

export interface LearnContentReassignDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (value: ReassignLearnContentType) => void;
}

export type ReassignLearnContentType = 'assign' | 'assignToUnfinishedUsers' | 'doNotAssign';

const LearnContentReassignDialog = (props: LearnContentReassignDialogProps) => {
  const { open, onClose, onSave } = props;

  const classes = useStylesBase();
  const customClasses = useStyles();
  const { t } = useTranslation();

  const [radioValue, setRadioValue] = useState<ReassignLearnContentType>(null);

  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle className={classes.dialogContent}>
        <Typography component="span" variant="h5">
          <AssignmentIndIcon className={classes.dialogIcon} />
          {t('admin.reassign-dialog.title')}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <Typography component="p" variant="body2">
          {t('admin.reassign-dialog.description')}
        </Typography>
        <RadioGroup row value={radioValue} onChange={(_event, value) => setRadioValue(value as ReassignLearnContentType)}>
          <FormControlLabel
            value="doNotAssign"
            className={customClasses.formControl}
            control={<Radio />}
            label={<Typography variant="body2">{t('admin.reassign-dialog.no-assignment')}</Typography>}
          />
          <FormControlLabel
            value="assign"
            className={customClasses.formControl}
            control={<Radio />}
            label={<Typography variant="body2">{t('admin.reassign-dialog.all-users')}</Typography>}
          />
          <FormControlLabel
            value="assignToUnfinishedUsers"
            className={customClasses.formControl}
            control={<Radio />}
            label={<Typography variant="body2">{t('admin.reassign-dialog.not-completed-users')}</Typography>}
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.dialogSubmit}
          disabled={radioValue == null}
          onClick={() => onSave(radioValue)}
        >
          {t('admin.reassign-dialog.done')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LearnContentReassignDialog;
