import React, { useEffect } from 'react';
import { makeStyles, Theme, Container } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { AppState } from '../../store';
import { trainingsActions } from '../../store/trainings';
import { ScormEventTypes } from '../../enums/ScormEventTypes';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ApproveSuccessTraining from '../../components/Dialogs/ApproveSuccessTraining';
import TrainingCertificateLoaderDialog from '../../components/Dialogs/TrainingCertificateLoaderDialog';
import AppHeader from '../../components/AppHeader/AppHeader';
import TrainingHeader from '../../components/Dashboard/TrainingHeader';
import HideOnScroll from '../../components/AppHeader/HideOnScroll';
import BottomAppBar from '../../components/AppHeader/BottomAppBar';
import BasicContainer from '../../components/Container/BasicContainer';
import { useQuery } from '../../utils/router.utils';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  iframe: {
    width: '100%',
    minHeight: 662,
    height: 'calc(100vh - 8px)',
    borderRadius: 10,
    borderWidth: 0,
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { lang } = useParams<{ lang: string }>();
  const query = useQuery();

  const { currentTraining, currentLearnContent, loadingPost } = useSelector((state: AppState) => state.trainings);
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const scormApiObjects = [(window as any).API_1484_11, (window as any).API]

  useEffect(() => {
    const sendScormStatus = (eventType: ScormEventTypes, apiObject: any) => {
      const data = apiObject.cmi;

      switch (eventType) {
        case ScormEventTypes.initialize:
          dispatch(trainingsActions.updateCurrentScormTraining(eventType));
          break;

        case ScormEventTypes.commit:
          /** 
           * SCORM 2004 sets cmi.completion_status and  cmi.success_status on completion
           * SCORM 1.2 sets cmi.core.lesson_status on completion
          */
          const isCourseCompleted = (data['cmi.completion_status'] === 'completed' && data['cmi.success_status'] === 'passed') || data['cmi.core.lesson_status'] === 'completed';
          if (isCourseCompleted) {
            setShowSuccessDialog(true);
          } else {
            dispatch(trainingsActions.updateCurrentScormTraining(eventType));
          }
          break;

        default:
          break;
      }
    };

    if (Boolean(currentTraining.successDate)) {
      scormApiObjects.forEach(apiObject => apiObject._preInitialize(() => {}));
    } else {
      scormApiObjects.forEach(apiObject => apiObject._preInitialize((event) => sendScormStatus(event, apiObject)));
    }
  }, [currentTraining, dispatch, history]);

  const onApproveSuccessTraining = (isDownloadChecked: boolean) => {
    dispatch(trainingsActions.approveCurrentTraining(isDownloadChecked));
  };

  const localizedLearnContent = (currentLearnContent?.localizedLearnContents || []).find(el => el.language === lang);
  const src = localizedLearnContent['dao']['resourceTokenURL'];

  const tab = currentTraining.successDate ? 'user/certificates' : 'user/learn-contents';

  return (
    <>
      <HideOnScroll>
        <AppHeader tab={tab} >
          <Container maxWidth="md" className={classes.container}>
            <TrainingHeader
              learnContent={currentLearnContent}
              isReadingMode={true}
              isFullScreen={isFullScreen}
              setIsFullScreen={setIsFullScreen}
              backRoute={query.get('backRoute')}
            />
          </Container>
        </AppHeader>
      </HideOnScroll>
      <BasicContainer maxWidth={isFullScreen ? 'xl' : 'md'}>
        <iframe title={t(currentLearnContent?.headlineKey)} src={src} className={classes.iframe}></iframe>
      </BasicContainer>
      <BottomAppBar tab={tab} />
      <ApproveSuccessTraining open={showSuccessDialog} onApprove={onApproveSuccessTraining} />
      <TrainingCertificateLoaderDialog open={loadingPost === 'pending'} />
    </>
  );
};
