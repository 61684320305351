import React, { useState, useEffect } from 'react';
import { makeStyles, Typography, Dialog, DialogTitle, Divider, DialogContent, Theme, Grid, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LearnContent from '../../sdk/com/apiomat/frontend/mylearningplatform/LearnContent';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LanguageIcon from '@material-ui/icons/Language';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { LearnContentTypes } from '../../enums/LearnContentTypes';
import moment from 'moment';
import { useStylesBase } from './BaseDailogStyles';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { notificationActions } from '../../store/notification';
import { DiamondIcon } from '../Icons/Icons';
import LPUser from '../../sdk/com/apiomat/frontend/mylearningplatform/LPUser';
import { LPScoreKey } from '../../enums/LPScoreKey';
import { getScore } from '../../utils/trainings.utils'
import { AppState } from '../../store';

const useStyles = makeStyles((theme: Theme) => ({
  headline: {
    marginBottom: theme.spacing(1),
  },
  lineContent: {
    margin: theme.spacing(2, 0),
  },
  smallIcon: {
    color: '#A8B3B3',
    fontSize: theme.typography.body2.fontSize,
    marginRight: theme.spacing(1),
  },
  contentPadding: {
    padding: theme.spacing(1)
  }
}));

export interface LearnContentInfoDialogProps {
  open: boolean;
  item: LearnContent;
  editing?: boolean;
  onClose: () => void;
  onEdit?: () => void;
  onArchive?: () => void;
  onDelete?: (assigned: boolean) => void;
}

const LearnContentInfoDialog = (props: LearnContentInfoDialogProps) => {
  const { open, item, editing, onClose, onEdit, onArchive, onDelete } = props;

  const classes = useStyles();
  const classesBase = useStylesBase();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isDeletionAdmin } = useSelector((state: AppState) => state.auth);

  const [loading, setLoading] = useState<boolean>(false);
  const [finishedTrainingsCount, setFinishedTraingsCount] = useState<number>(0);

  const loadFinishedTrainings = async () => {
    setLoading(true);
    try {
      const users = await LPUser.getLPUsers(`learnings.learnContentId=="${item.ID}"`);
      const filteredUsers = users.filter(user =>
        user.learnings.find(learning => learning.learnContentId === item.ID && Boolean(learning.successDate))
      );
      setFinishedTraingsCount(filteredUsers.length);
    } catch (error) {
      dispatch(notificationActions.showError(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Boolean(item) && open && editing) {
      loadFinishedTrainings();
    }
  }, [item, open, editing]);

  const score = getScore(item, LPScoreKey.BASIC);

  const isTrainingFinished = () => finishedTrainingsCount !== 0;
  const showDeleteButton = () => !isTrainingFinished() || isDeletionAdmin;

  return (
    <Dialog maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle className={classesBase.dialogContent}>
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={3}>
          <Grid item>
            <Typography component="span" variant="h5">
              <InfoOutlinedIcon className={classesBase.dialogIcon} />
              {t('admin.learn-content-info-dialog.title')}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent className={classesBase.dialogContent}>
        <Typography component="p" variant="subtitle1" color="primary" className={classes.headline}>
          {t(item?.headlineKey)}
        </Typography>
        <Typography component="p" variant="body2">
          {t(item?.descriptionKey)}
        </Typography>
        <div className={classes.contentPadding}>
          <Grid container className={classes.lineContent}>
            <Grid item xs={6} container alignItems="center">
              <DiamondIcon className={classes.smallIcon} />
              <Typography variant="body2">{score} LP</Typography>
            </Grid>
            <Grid item xs={6} container alignItems="center">
              <LanguageIcon className={classes.smallIcon} />
              <Typography variant="body2" component="span">
                {item?.localizedLearnContents
                  .map(el => el.language)
                  .join(', ')
                  .toUpperCase()}
              </Typography>
            </Grid>
          </Grid>
          {item?.learnContentType === LearnContentTypes.pdf && (
            <Grid container className={classes.lineContent}>
              <Grid item xs={6} container alignItems="center">
                <CheckCircleIcon className={classes.smallIcon} />
                <Typography variant="body2" component="span">
                  {t('admin.questions', { count: item?.questions?.length })}
                </Typography>
              </Grid>
              <Grid item xs={6} container alignItems="center">
                <FavoriteIcon className={classes.smallIcon} />
                <Typography variant="body2" component="span">
                  {t('admin.lives', { count: item?.lives })}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container className={classes.lineContent}>
            <Grid item xs={6}>
              <Typography component="p" variant="body2">
                {t('admin.learn-content-info-dialog.create-date')}:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography component="p" variant="body2">
                {moment(item?.createdAt).format('DD.MM.yyyy')}
              </Typography>
            </Grid>
          </Grid>
          {Boolean(item?.dueDate) && (
            <Grid container className={classes.lineContent}>
              <Grid item xs={6}>
                <Typography component="p" variant="body2">
                  {t('admin.learn-content-info-dialog.due-date')}:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component="p" variant="body2">
                  {moment(item.dueDate).format('DD.MM.yyyy')}
                </Typography>
              </Grid>
            </Grid>
          )}
        </div>
        {editing && loading && <LoadingIndicator />}
        {editing && loading === false && (
          <Grid container spacing={2} direction="column">
            {item?.isArchived !== 1 && (
              <Grid item>
                <Button fullWidth size="large" variant="contained" color="primary" onClick={onEdit}>
                  {t('admin.learn-content-info-dialog.edit')}
                </Button>
              </Grid>
            )}
            {item?.isArchived !== 1 && (
              <Grid item>
                <Button fullWidth size="large" variant="contained" color="primary" onClick={onArchive}>
                  {t('admin.learn-content-info-dialog.archive')}
                </Button>
              </Grid>
            )}
            {showDeleteButton() && (
              <Grid item>
                <Button fullWidth size="large" variant="contained" color="primary" onClick={() => onDelete(isTrainingFinished())}>
                  {t('admin.learn-content-info-dialog.delete')}
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LearnContentInfoDialog;
