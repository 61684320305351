var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, PrimaryColumn, Column } from "../OfflineAdapter";
let AbstractClientDataModelDAO = class AbstractClientDataModelDAO {
    constructor() {
    }
};
__decorate([
    PrimaryColumn(),
    __metadata("design:type", String)
], AbstractClientDataModelDAO.prototype, "href", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], AbstractClientDataModelDAO.prototype, "id", void 0);
__decorate([
    Column("simple-json", { nullable: true }),
    __metadata("design:type", Array)
], AbstractClientDataModelDAO.prototype, "referencedHrefs", void 0);
__decorate([
    Column("simple-json", { nullable: true }),
    __metadata("design:type", Array)
], AbstractClientDataModelDAO.prototype, "allowedRolesGrant", void 0);
__decorate([
    Column("simple-json", { nullable: true }),
    __metadata("design:type", Array)
], AbstractClientDataModelDAO.prototype, "allowedRolesWrite", void 0);
__decorate([
    Column("simple-json", { nullable: true }),
    __metadata("design:type", Array)
], AbstractClientDataModelDAO.prototype, "allowedRolesRead", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], AbstractClientDataModelDAO.prototype, "foreignId", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Boolean)
], AbstractClientDataModelDAO.prototype, "restrictResourceAccess", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], AbstractClientDataModelDAO.prototype, "createdAt", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], AbstractClientDataModelDAO.prototype, "lastModifiedAt", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], AbstractClientDataModelDAO.prototype, "applicationName", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], AbstractClientDataModelDAO.prototype, "modelName", void 0);
AbstractClientDataModelDAO = __decorate([
    Entity("AbstractClientDataModel"),
    __metadata("design:paramtypes", [])
], AbstractClientDataModelDAO);
export default AbstractClientDataModelDAO;
