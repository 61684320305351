import React from 'react';
import { makeStyles, Theme, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  progress: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.progress}>
      <CircularProgress />
    </div>
  );
};
