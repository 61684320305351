var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export default class AOMNetworkHandler {
    static checkForConnection() {
        return __awaiter(this, void 0, void 0, function* () {
            const baseUrl = User.AOMBASEURL.substring(0, User.AOMBASEURL.indexOf("yambas/rest/") + 12);
            try {
                const response = yield axios.request({
                    url: baseUrl,
                    method: "GET",
                    headers: { Accept: "*/*" },
                    transformResponse: (r) => {
                        return r;
                    },
                    validateStatus: (status) => {
                        return true;
                    },
                    timeout: Datastore.Instance.requestTimeout
                });
                if (response.data.indexOf("Yambas REST") !== -1) {
                    this.isConnected = true;
                }
                else {
                    this.isConnected = false;
                }
            }
            catch (error) {
                this.isConnected = false;
            }
            return this.isConnected;
        });
    }
    static get isConnected() {
        if (this.forceOffline === true) {
            return false;
        }
        return this._isConnected;
    }
    static set isConnected(isConnected) {
        const oldConnectionStatus = this._isConnected;
        this._isConnected = isConnected;
        this.changeConnState(isConnected, oldConnectionStatus);
    }
    static set forceOffline(forceOffline) {
        const oldConnectionStatus = this.isConnected;
        this._forceOffline = forceOffline;
        this.changeConnState(forceOffline === false, oldConnectionStatus);
    }
    static get forceOffline() {
        return this._forceOffline;
    }
    static get timeout() {
        return this._timeout;
    }
    static clearTimeout() {
        clearTimeout(this.timeout);
    }
    static changeConnState(isConnected, oldConnectionStatus) {
        if (oldConnectionStatus === false && isConnected === true) {
            this.backOnlineCallback();
            this.clearTimeout();
            this._timeout = null;
            if (Datastore.isInstantiated() && Datastore.Instance.isOfflineHandlerInitialized()) {
                Datastore.Instance.OfflineHandler.sendTasks();
            }
        }
        if (isConnected === false) {
            this._timeout = setTimeout(() => {
                AOMNetworkHandler.checkForConnection();
            }, this.checkInterval);
        }
    }
}
AOMNetworkHandler.checkInterval = 2000;
AOMNetworkHandler.backOnlineCallback = () => {
};
AOMNetworkHandler._forceOffline = false;
AOMNetworkHandler._isConnected = true;
AOMNetworkHandler._timeout = null;
import axios from "axios";
import Datastore from "../Datastore";
import User from "./../basics/User";
