import User from './sdk/com/apiomat/frontend/basics/User';

/* Import this file as the first thing before bootstrapping the application */

export const configureSdkFromConfig = () => {
  const configKeys = ['AOMBASEURL', 'AOMSYS', 'AOMAPIKEY'];

  const config = window['AOMCONFIG'];
  if (config) {
    for (const key of configKeys) {

      if (config.hasOwnProperty(key)) {
        const value = config[key].trim();

        Object.defineProperty(User, key, {
          get(): any {
            return value;
          },
        });
      }
    }
  }
};

/* We need to do this here, to ensure all requests by the sdk use the updated config */
configureSdkFromConfig()