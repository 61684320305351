import AbstractClientDataModel from '../sdk/com/apiomat/frontend/AbstractClientDataModel';
import User from '../sdk/com/apiomat/frontend/basics/User';
import LPUser from '../sdk/com/apiomat/frontend/mylearningplatform/LPUser';
import SdkInfo from '../sdk/com/apiomat/frontend/SdkInfo';

export const getReferencedHrefId = (obj: AbstractClientDataModel, attribute: string): string | null => {
  return obj?.referencedHrefs?.[attribute]?.[0]?.split('/').pop();
};

export const createCustomRestUrl = (suffix: string) => {
  const moduleName = LPUser.staticGetModuleName();
  return `${User.AOMBASEURL.replace('apps', `modules/${moduleName}/v/${SdkInfo.getVersion(moduleName)}`)}/spec` + suffix;
};
