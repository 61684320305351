export default class StorageFactory {
    static ChooseStorageImpl(usePersistentStorage = false, offlineHandlerExists = false) {
        if (usePersistentStorage) {
            if (offlineHandlerExists === false) {
                throw new Error("No offlinehandler is set. Please initialize the AOMOfflineHandler.");
            }
            return PersistentStorage.Instance;
        }
        if (offlineHandlerExists === false) {
            return MemoryStorage.Instance;
        }
        else {
            return MemoryElsePersistentStorage.Instance;
        }
    }
}
import MemoryStorage from "./MemoryStorage";
import MemoryElsePersistentStorage from "./MemoryElsePersistentStorage";
import PersistentStorage from "./PersistentStorage";
