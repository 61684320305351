import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import AppHeader from '../../components/AppHeader/AppHeader';
import { learnContentsActions } from '../../store/learnContents';
import LearnContent from '../../sdk/com/apiomat/frontend/mylearningplatform/LearnContent';
import LearnContentList from '../../components/Admin/LearnContentList';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import LearnContentInfoDialog from '../../components/Dialogs/LearnContentInfoDialog';
import LoadingIndicatorFullScreen from '../../components/LoadingIndicator/LoadingIndicatorFullScreen';
import ApproveBaseDialog from '../../components/Dialogs/ApproveBaseDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import ArchiveIcon from '@material-ui/icons/Archive';
import EditIcon from '@material-ui/icons/Edit';
import BottomAppBar from '../../components/AppHeader/BottomAppBar';
import BasicContainer from '../../components/Container/BasicContainer';

const useStyles = makeStyles(theme => ({
  tableHeader: {
    padding: theme.spacing(1),
  },
  tableTitle: {
    padding: theme.spacing(1),
  },
  addButton: {
    margin: theme.spacing(2, 0, 4),
  },
  searchInput: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ': {
      borderColor: theme.palette.primary.main,
    },
  },
  selectedSearch: {
    '& .MuiInputLabel-outlined': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const { learnContents, loading, loadingPost } = useSelector((state: AppState) => state.learnContents);

  const [filterActiveItems, setFilterActiveItems] = useState<boolean>(true);
  const [filterString, setFilterString] = useState<string>('');
  const [activeItem, setActiveItem] = useState<LearnContent>(null);
  const [showInfoDialog, setShowInfoDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showDeleteAssignedDialog, setShowDeleteAssignedDialog] = useState<boolean>(false);
  const [showArchiveDialog, setShowArchiveDialog] = useState<boolean>(false);
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);

  useEffect(() => {
    dispatch(learnContentsActions.loadLearnContents());
  }, [dispatch]);

  const onDeleteActiveItem = () => {
    setShowDeleteDialog(false);
    setShowDeleteAssignedDialog(false);
    dispatch(learnContentsActions.deleteLearnContent(activeItem));
  };

  const onArchiveActiveItem = () => {
    setShowArchiveDialog(false);
    dispatch(learnContentsActions.archiveLearnContent(activeItem));
  };

  const onEditActiveItem = () => {
    setShowEditDialog(false);
    history.push(`/admin/learn-content-creation/${activeItem.ID}`);
  };

  const learnContentFilter = (learnContent: LearnContent): boolean => {
    if (filterActiveItems && learnContent.isArchived === 1) {
      return false;
    }

    if (Boolean(filterString) === false) {
      return true;
    }

    return (
      t(learnContent.headlineKey)
        .toLowerCase()
        .includes(filterString.toLowerCase()) ||
      t(learnContent.descriptionKey)
        .toLowerCase()
        .includes(filterString.toLowerCase())
    );
  };

  const learnContentSort = (a: LearnContent, b: LearnContent): number => {
    return t(a.headlineKey).toLowerCase() > t(b.headlineKey).toLowerCase() ? 1 : -1;
  };

  const filteredLearnCoontents = learnContents
    .slice()
    .filter(learnContentFilter)
    .sort(learnContentSort);

  return (
    <>
      <AppHeader tab="admin/learn-content-management" />
      <BasicContainer>
        {loading === 'pending' ? (
          <LoadingIndicator />
        ) : (
          <>
            <TextField
              name="filter"
              label={t('admin.filter')}
              variant="outlined"
              margin="normal"
              className={clsx(classes.searchInput, filterString.length > 0 && classes.selectedSearch)}
              fullWidth
              value={filterString}
              onChange={e => setFilterString(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="clean filter" onClick={() => setFilterString('')}>
                      {Boolean(filterString) ? <CloseIcon /> : <SearchIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            ></TextField>
            <Button
              component={Link}
              to="/admin/learn-content-creation"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              className={classes.addButton}
            >
              {t('admin.add-learn-content')}
            </Button>
            <Grid container justify="space-between" alignItems="center" className={classes.tableHeader}>
              <Grid item>
                <Typography variant="body2" className={classes.tableTitle}>
                  {t('admin.all-learn-contents')}
                </Typography>
              </Grid>
              <Grid item>
                <RadioGroup row value={filterActiveItems} onChange={(_event, value) => setFilterActiveItems(value === 'true')}>
                  <FormControlLabel value={true} control={<Radio />} label={t('admin.show-active-learn-contents')} />
                  <FormControlLabel value={false} control={<Radio />} label={t('admin.show-all-learn-contents')} />
                </RadioGroup>
              </Grid>
            </Grid>
            <LearnContentList
              items={filteredLearnCoontents}
              onItemClick={item => {
                setActiveItem(item);
                setShowInfoDialog(true);
              }}
            />
          </>
        )}
      </BasicContainer>
      <BottomAppBar tab="admin/learn-content-management" />
      <LearnContentInfoDialog
        open={showInfoDialog}
        editing
        onClose={() => setShowInfoDialog(false)}
        item={activeItem}
        onEdit={() => {
          setShowInfoDialog(false);
          setShowEditDialog(true);
        }}
        onArchive={() => {
          setShowInfoDialog(false);
          setShowArchiveDialog(true);
        }}
        onDelete={(assigned) => {
          setShowInfoDialog(false);
          assigned ? setShowDeleteAssignedDialog(true) : setShowDeleteDialog(true);
        }}
      />
      <ApproveBaseDialog
        open={showDeleteDialog}
        title="admin.learn-content-delete-dialog.title"
        descriptions={['admin.learn-content-delete-dialog.description1', 'admin.learn-content-delete-dialog.description2']}
        toggleTitle="admin.learn-content-delete-dialog.toggle-label"
        buttonTitle="admin.learn-content-delete-dialog.delete-now"
        Icon={DeleteIcon}
        onClose={() => setShowDeleteDialog(false)}
        onButtonClick={onDeleteActiveItem}
      />
      <ApproveBaseDialog
        open={showDeleteAssignedDialog}
        title="admin.learn-content-delete-assigned-dialog.title"
        descriptions={['admin.learn-content-delete-assigned-dialog.description1', 'admin.learn-content-delete-assigned-dialog.description2']}
        toggleTitle="admin.learn-content-delete-assigned-dialog.toggle-label"
        buttonTitle="admin.learn-content-delete-assigned-dialog.delete-now"
        Icon={DeleteIcon}
        onClose={() => setShowDeleteAssignedDialog(false)}
        onButtonClick={onDeleteActiveItem}
      />
      <ApproveBaseDialog
        open={showArchiveDialog}
        title="admin.learn-content-archive-dialog.title"
        descriptions={[
          'admin.learn-content-archive-dialog.description1',
          'admin.learn-content-archive-dialog.description2',
          'admin.learn-content-archive-dialog.description3',
        ]}
        toggleTitle="admin.learn-content-archive-dialog.toggle-label"
        buttonTitle="admin.learn-content-archive-dialog.archive-now"
        Icon={ArchiveIcon}
        onClose={() => setShowArchiveDialog(false)}
        onButtonClick={onArchiveActiveItem}
      />
      <ApproveBaseDialog
        open={showEditDialog}
        title="admin.learn-content-edit-dialog.title"
        descriptions={['admin.learn-content-edit-dialog.description1', 'admin.learn-content-edit-dialog.description2']}
        buttonTitle="admin.learn-content-edit-dialog.edit-now"
        Icon={EditIcon}
        onClose={() => setShowEditDialog(false)}
        onButtonClick={onEditActiveItem}
      />
      <LoadingIndicatorFullScreen open={loadingPost === 'pending'} />
    </>
  );
};
