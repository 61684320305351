import React from 'react';
import { makeStyles, Theme, Hidden, BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/index';
import { AppBar } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import GroupIcon from '@material-ui/icons/Group';
import { MedalIcon } from '../Icons/Icons';
import ViewListIcon from '@material-ui/icons/ViewList';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    left: 0,
    right: 0,
    boxShadow: theme.shadows[4],
  },
}));

export interface BottomAppBarProps {
  tab:
    | 'user/learn-contents'
    | 'user/certificates'
    | 'manager/team'
    | 'profile'
    | 'admin/learn-content-management'
    | 'admin/learn-content-assignment';
}

const BottomAppBar = (props: BottomAppBarProps) => {
  const { tab } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const { isManager, isAdmin, isAdminAreaNavigation } = useSelector((state: AppState) => state.auth);

  const setTab = (newTab: string) => {
    history.push(`/${newTab}`);
  };

  const userTabs = [
    <BottomNavigationAction
      key="learn-content"
      icon={<ListIcon />}
      label={t('app-header.my-learn-contents_short')}
      value="user/learn-contents"
    />,
    <BottomNavigationAction
      key="certificates"
      icon={<MedalIcon />}
      label={t('app-header.my-certificates_short')}
      value="user/certificates"
    />,
    isManager && <BottomNavigationAction key="team" icon={<GroupIcon />} label={t('app-header.my-team_short')} value="manager/team" />,
  ];

  const adminTabs = [
    isAdmin && (
      <BottomNavigationAction
        key="management"
        icon={<ViewListIcon />}
        label={t('app-header.learn-content-management_short')}
        value="admin/learn-content-management"
      />
    ),
    <BottomNavigationAction
      key="assignment"
      icon={<GroupAddIcon />}
      label={t('app-header.learn-content-assignment_short')}
      value="admin/learn-content-assignment"
    />,
  ];

  return (
    <Hidden mdUp>
      <AppBar component="footer" position="fixed" className={classes.appBar}>
        <BottomNavigation value={tab} onChange={(_event, newValue) => setTab(newValue)} showLabels>
          {isAdminAreaNavigation ? adminTabs : userTabs}
        </BottomNavigation>
      </AppBar>
    </Hidden>
  );
};

export default BottomAppBar;
