import { Button, Grid, Hidden, IconButton, ListItem, makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import GroupIcon from '@material-ui/icons/Group';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LearnContent from '../../sdk/com/apiomat/frontend/mylearningplatform/LearnContent';
import LPUser from '../../sdk/com/apiomat/frontend/mylearningplatform/LPUser';
import { getPostion, getTeam, getUserName, isUserAssigned } from '../../utils/users.utils';
import TrainingLabel from '../Dashboard/TrainingLabel';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    padding: theme.spacing(2),
  },
  icon: {
    fontSize: theme.typography.body2.fontSize,
    marginRight: theme.spacing(1),
    display: 'block',
    marginTop: 3,
  },
  completedMessage: {
    cursor: 'auto',
    color: theme.palette.success.main,
  },
}));

export interface UserAssignmentListItemProps {
  item: LPUser;
  learnContent: LearnContent;
  divider: boolean;
  allowChanges?: boolean;
  onItemDelete: () => void;
  onItemEditDueDate: (originalDate?: Date) => void;
}

const UserAssignmentListItem = (props: UserAssignmentListItemProps) => {
  const { item, learnContent, divider, onItemDelete, onItemEditDueDate, allowChanges = true } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const learning = item.learnings.filter(learning => learning.learnContentId === learnContent.ID)[0];

  const learnContentCompleted = !!learning?.successDate;
  const dueDate = learning?.dueDate;

  const isAssigned = isUserAssigned(item, learnContent.ID);

  return (
    <ListItem divider={divider} className={classes.item}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5}>
          <Typography component="span" variant="subtitle2">
            {getUserName(item)}
          </Typography>
          {!learnContentCompleted && isAssigned ? (
            <TrainingLabel onClick={() => onItemEditDueDate(dueDate)} dueDate={dueDate} isSmall={isSmall} editable={allowChanges} />
          ) : null}
        </Grid>
        <Grid item container xs={5} md={3} spacing={1} direction="column">
          {getTeam(item) && (
            <Grid item container alignItems="flex-start" wrap="nowrap">
              <GroupIcon className={classes.icon} />
              <Typography variant="body2" component="span">
                {getTeam(item)}
              </Typography>
            </Grid>
          )}
          {getPostion(item) && (
            <Grid item container alignItems="flex-start" wrap="nowrap">
              <RecentActorsIcon className={classes.icon} />
              <Typography variant="body2" component="span">
                {getPostion(item)}
              </Typography>
            </Grid>
          )}
        </Grid>
        {isAssigned ? (
          <Grid item xs={2} md={4} container justify="flex-end">
            {!learnContentCompleted ? (
              <>
                <Hidden mdUp>
                  <IconButton color="primary" disabled={!allowChanges} onClick={() => onItemDelete()}>
                    <CloseIcon />
                  </IconButton>
                </Hidden>
                <Hidden smDown>
                  <Button color="primary" startIcon={<CloseIcon />} disabled={!allowChanges} onClick={() => onItemDelete()}>
                    {t('admin.assignment.delete-assignment')}
                  </Button>
                </Hidden>
              </>
            ) : (
              <>
                <Hidden mdUp>
                  <IconButton className={classes.completedMessage} color="inherit">
                    <CheckIcon />
                  </IconButton>
                </Hidden>
                <Hidden smDown>
                  <Button className={classes.completedMessage} color="inherit" startIcon={<CheckIcon />}>
                    {t('admin.assignment.completed')}
                  </Button>
                </Hidden>
              </>
            )}
          </Grid>
        ) : null}
      </Grid>
    </ListItem>
  );
};

export default UserAssignmentListItem;
