import React, { useEffect } from 'react';
import { Typography, makeStyles, Theme, Paper, Grid, Divider, Slider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LearnContentData } from '../../../store/learnContents';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { LearnContentTypes } from '../../../enums/LearnContentTypes';
import { DiamondIcon } from '../../Icons/Icons';
import SelectionButton from '../../Buttons/SelectionButton';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  textIcon: {
    marginRight: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(3, -3),
  },
  dateInput: {
    minWidth: 300,
  },
}));

export interface LPLivesDueDateStepProps {
  learnContent: LearnContentData;
  setLearnContent: (learnContent: LearnContentData) => void;
}

const LPLivesDueDateStep = (props: LPLivesDueDateStepProps) => {
  const { learnContent, setLearnContent } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    if (typeof learnContent.lp !== 'number') {
      setLearnContent({ ...learnContent, lp: 50 });
    }
    if (Boolean(learnContent.lives) === false) {
      setLearnContent({ ...learnContent, lives: 3 });
    }
  });

  return (
    <Paper elevation={0} className={classes.paper}>
      <Typography component="h2" variant="subtitle2" color="primary">
        {t('admin.create-learn-content.lp-lives-due-date.define-lp')}
      </Typography>
      <Typography component="p" variant="body1" className={classes.description}>
        {t('admin.create-learn-content.lp-lives-due-date.define-lp-description')}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={6} container justify="center">
          <Slider
            value={learnContent.lp}
            onChange={(_event, lp: number) => setLearnContent({ ...learnContent, lp })}
            step={10}
            min={0}
            defaultValue={50}
            max={100}
          />
        </Grid>
        <Grid item xs={6} container alignItems="center">
          <DiamondIcon color="primary" className={classes.textIcon} />
          <Typography>{learnContent.lp} LP</Typography>
        </Grid>
      </Grid>
      {learnContent.learnContentType === LearnContentTypes.pdf && (
        <>
          <Divider className={classes.divider} />
          <Typography component="h2" variant="subtitle2" color="primary">
            {t('admin.create-learn-content.lp-lives-due-date.define-lives')}
          </Typography>
          <Typography component="p" variant="body1" className={classes.description}>
            {t('admin.create-learn-content.lp-lives-due-date.define-lives-description')}
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={6} container justify="center">
              <Slider
                value={learnContent.lives}
                onChange={(_event, lives: number) => setLearnContent({ ...learnContent, lives })}
                step={1}
                defaultValue={3}
                min={1}
                max={5}
              />
            </Grid>
            <Grid item xs={6} container alignItems="center">
              <FavoriteIcon color="primary" className={classes.textIcon} />
              <Typography>{learnContent.lives}</Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Typography component="h2" variant="subtitle2" color="primary">
            {t('admin.create-learn-content.lp-lives-due-date.define-downloadable')}
          </Typography>
          <Typography component="p" variant="body1" className={classes.description}>
            {t('admin.create-learn-content.lp-lives-due-date.define-downloadable-description')}
          </Typography>
          <Grid container spacing={2} justify="flex-start">
            <Grid item>
              <SelectionButton
                label={t('admin.create-learn-content.lp-lives-due-date.yes')}
                selected={learnContent.downloadable}
                onSelect={() => setLearnContent({ ...learnContent, downloadable: true })}
              />
            </Grid>
            <Grid item>
              <SelectionButton
                label={t('admin.create-learn-content.lp-lives-due-date.no')}
                selected={!learnContent.downloadable}
                onSelect={() => setLearnContent({ ...learnContent, downloadable: false })}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Paper>
  );
};

export default LPLivesDueDateStep;
