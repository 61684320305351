import React, { useEffect } from 'react';
import { makeStyles, Theme, Container } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { AppState } from '../../store';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ApproveSuccessTraining from '../../components/Dialogs/ApproveSuccessTraining';
import TrainingCertificateLoaderDialog from '../../components/Dialogs/TrainingCertificateLoaderDialog';
import TrainingQuestion from '../../components/Dashboard/TrainingQuestion';
import FailedTraining from '../../components/Dialogs/FailedTraining';
import { trainingsActions } from '../../store/trainings';
import PdfViewer from '../../components/Dashboard/PdfViewer';
import PdfViewerHeader from '../../components/Dashboard/PdfViewerHeader';
import TrainingQuestionHeader from '../../components/Dashboard/TrainingQuestionHeader';
import AppHeader from '../../components/AppHeader/AppHeader';
import HideOnScroll from '../../components/AppHeader/HideOnScroll';
import TrainingHeader from '../../components/Dashboard/TrainingHeader';
import BottomAppBar from '../../components/AppHeader/BottomAppBar';
import { useQuery } from '../../utils/router.utils';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { i18n } = useTranslation();
  const { lang } = useParams<{ lang: string }>();
  const query = useQuery();

  useEffect(() => {
    i18n.loadLanguages([lang]);
  }, [i18n, lang]);

  const { currentTraining, currentLearnContent, loadingPost } = useSelector((state: AppState) => state.trainings);

  const [readingMode, setReadingMode] = useState<boolean>(true);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(
    currentTraining.successDate ? 0 : currentTraining.questionProgress || 0
  );
  const [currentLives, setCurrentLives] = useState<number>(currentTraining.liveCount || currentLearnContent?.lives);
  const [showApproveDialog, setShowApproveDialog] = useState<boolean>(false);
  const [showFailedDialog, setShowFailedDialog] = useState<boolean>(false);

  const questions = currentLearnContent?.questions || [];
  const hasNoQuestions = questions.length === 0;

  const currentQuestion = questions.find(el => el.orderNumber === currentQuestionIndex);
  const localizedLearnContent = (currentLearnContent?.localizedLearnContents || []).find(el => el.language === lang);

  const progress = (currentQuestionIndex / (questions.length || 1)) * 100;

  const onAnswer = (isAnswerCorrect: boolean) => {
    if (currentTraining.successDate) {
      return;
    }

    if (currentQuestionIndex === 0) {
      currentTraining.liveCount = currentLives;
      dispatch(trainingsActions.updateCurrentPdfTraining(currentTraining));
    }

    if (isAnswerCorrect === false) {
      setCurrentLives(currentLives - 1);
      currentTraining.liveCount = currentTraining.liveCount - 1;

      if (currentLives === 1) {
        setShowFailedDialog(true);
        /** Reset values to start training again */
        currentTraining.attemptsCount = (currentTraining.attemptsCount || 0) + 1;
        currentTraining.questionProgress = 0;
        currentTraining.liveCount = currentLearnContent?.lives;
      }
      dispatch(trainingsActions.updateCurrentPdfTraining(currentTraining));
    }
  };

  const onNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);

    if (currentQuestionIndex + 1 === questions.length) {
      if (currentTraining.successDate) {
        history.push(query.get('backRoute'));
        return;
      }

      if (currentLives > 0) {
        setShowApproveDialog(true);
      }
    } else {
      if (currentTraining.successDate) {
        return;
      }

      currentTraining.questionProgress = currentQuestionIndex + 1;
      dispatch(trainingsActions.updateCurrentPdfTraining(currentTraining));
    }
  };

  const onApproveSuccessTraining = (isDownloadChecked: boolean) => {
    dispatch(trainingsActions.approveCurrentTraining(isDownloadChecked));
  };

  const tab = currentTraining.successDate ? 'user/certificates' : 'user/learn-contents';
  const pdfUrl = localizedLearnContent['dao']['resourceTokenURL'];

  return (
    <>
      <HideOnScroll>
        <AppHeader tab={tab}>
          <Container maxWidth="md" className={classes.container}>
            <TrainingHeader
              learnContent={currentLearnContent}
              isReadingMode={readingMode}
              isFullScreen={isFullScreen}
              setIsFullScreen={setIsFullScreen}
              backRoute={query.get('backRoute')}
            />
            {readingMode ? (
              <PdfViewerHeader
                isDownloadable={Boolean(currentLearnContent.downloadable)}
                hasNoQuestions={hasNoQuestions}
                hasSuccessDate={Boolean(currentTraining.successDate)}
                src={pdfUrl}
                onStart={() => {
                  if (hasNoQuestions) {
                    setShowApproveDialog(true);
                  } else {
                    setReadingMode(false);
                  }
                }}
              />
            ) : (
              <TrainingQuestionHeader
                currentTraining={currentTraining}
                currentLives={currentLives}
                progress={progress}
                setReadingMode={setReadingMode}
              />
            )}
          </Container>
        </AppHeader>
      </HideOnScroll>
      {readingMode ? (
        <PdfViewer src={pdfUrl} isFullScreen={isFullScreen} />
      ) : (
        <TrainingQuestion question={currentQuestion} lang={lang} onAnswer={onAnswer} onNextQuestion={onNextQuestion} />
      )}
      <BottomAppBar tab={tab} />
      <ApproveSuccessTraining open={showApproveDialog} onApprove={onApproveSuccessTraining} />
      <FailedTraining open={showFailedDialog} onClose={() => history.push('/')} />
      <TrainingCertificateLoaderDialog open={loadingPost === 'pending'} />
    </>
  );
};
