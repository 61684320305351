import React from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const ForgotPasswordButton = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <Button variant="text" color="primary" onClick={() => history.push('/forgot-password')}>
        {t('forgot-password.forgot-password')}
      </Button>
    </>
  );
};

export default ForgotPasswordButton;
