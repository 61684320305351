import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Datastore from '../sdk/com/apiomat/frontend/Datastore';
import Translation from '../sdk/com/apiomat/frontend/localisation/Translation';
import I18nBackend from './i18n-backend';
import moment from 'moment';

import 'moment/locale/de';
import 'moment/locale/pl';
import 'moment/locale/cs';
import 'moment/locale/ru';
import { Language } from '../enums/Language';

export const languages = [Language.DE, Language.PL, Language.CS, Language.RU];
export const fallbackLanguage = Language.DE;

const getLangFromAom = async (language: string): Promise<any[]> => {
  const translates = await Translation.getTranslations(`language=="${language}"`);
  return (translates || []).map(item => item.wording);
};

const initBackend = () => {
  Datastore.configureAsGuest();
};

const backend = new I18nBackend(null, {
  resources: {
    [Language.DE]: { translation: {} },
    [Language.PL]: { translation: {} },
    [Language.CS]: { translation: {} },
    [Language.RU]: { translation: {} },
  },
  getLangFromBackend: getLangFromAom,
  initBackend,
});

/** add local translations */
i18n
  .use(backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: {
      de: [Language.PL, Language.CS, Language.RU],
      pl: [Language.DE, Language.CS, Language.RU],
      cs: [Language.DE, Language.PL, Language.RU],
      ru: [Language.DE, Language.PL, Language.CS],
      default: [Language.DE, Language.PL, Language.CS, Language.RU],
    },
    whitelist: languages,
    debug: false,
    nsSeparator: false,
    keySeparator: false,
    saveMissing: true,
    react: {
      wait: true,
      bindI18nStore: 'added'
    } as any,
  });

i18n.on('languageChanged', lang => moment.locale(lang));
i18n.on('missingKey', (lngs, namespace, key, res) => {
  if (key) {
    i18n.reloadResources()
  }
})

export default i18n;
