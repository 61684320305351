import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { authReducer, authSaga, authTransform } from './auth';
import { notificationReducer } from './notification';
import { maintenanceReducer, maintenanceSaga } from './maintenance';
import { appConfigReducer, appConfigSaga } from './appConfig';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { trainingsReducer, trainingsSaga } from './trainings';
import { assignmentSaga, assignmentReducer } from './assignment';
import { learnContentsReducer, learnContentsSaga } from './learnContents';
import { teamOverviewSaga, teamOverviewReducer } from './team';
import { history } from './history';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth'],
  blacklist: ['trainings'],
  transforms: [authTransform],
};

const rootReducer = combineReducers({
  auth: authReducer,
  learnContents: learnContentsReducer,
  assignment: assignmentReducer,
  trainings: trainingsReducer,
  notifiaction: notificationReducer,
  teamOverview: teamOverviewReducer,
  maintenance: maintenanceReducer,
  appConfig: appConfigReducer,
  router: connectRouter(history),
});

const rootSaga = function* root() {
  yield all([
    fork(trainingsSaga),
    fork(authSaga),
    fork(assignmentSaga),
    fork(learnContentsSaga),
    fork(teamOverviewSaga),
    fork(maintenanceSaga),
    fork(appConfigSaga),
  ]);
};

export type AppState = ReturnType<typeof rootReducer>;

export default () => {
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = configureStore({
    reducer: persistedReducer,
    middleware: [sagaMiddleware, routerMiddleware(history)],
  });
  sagaMiddleware.run(rootSaga);
  const persistor = persistStore(store);
  return { store, persistor };
};
