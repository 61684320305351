import React, { useEffect } from 'react';
import { LinearProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from '../../store';
import { trainingsActions } from '../../store/trainings';
import { LearnContentTypes } from '../../enums/LearnContentTypes';
import ScormTraining from './ScormTraining';
import PdfTraining from './PdfTraining';

interface TrainingRouteParams {
  id: string;
  lang: string;
}

export default () => {
  const dispatch = useDispatch();
  const { id, lang } = useParams() as TrainingRouteParams;

  const { currentLearnContent, loading } = useSelector((state: AppState) => state.trainings);

  useEffect(() => {
    dispatch(trainingsActions.loadCurrentTraining({ id, lang }));
  }, [dispatch, id, lang]);

  if (loading === 'pending') {
    return <LinearProgress />;
  }

  if (Boolean(id) === false || Boolean(currentLearnContent) === false) {
    return null;
  }

  return currentLearnContent.learnContentType === LearnContentTypes.scorm ? <ScormTraining /> : <PdfTraining />;
};
