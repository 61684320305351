import React from 'react';
import { makeStyles, ListItem, Theme, Typography, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import LPUser from '../../sdk/com/apiomat/frontend/mylearningplatform/LPUser';
import GroupIcon from '@material-ui/icons/Group';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { getUserName, getPostion, getTeam } from '../../utils/users.utils';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fontSize: theme.typography.body2.fontSize,
    marginRight: theme.spacing(1),
    display: 'block',
    marginTop: 3,
  },
  item: {
    padding: theme.spacing(2),
  },
}));

export interface UserSerachItemProps {
  item: LPUser;
  onItemClick?: (item: LPUser) => void;
}

const UserSearchItem = (props: UserSerachItemProps) => {
  const { item } = props;

  const classes = useStyles();
  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ListItem divider={true} className={classes.item} component="div">
      <Grid container spacing={isSmall ? 1 : 2} alignItems="center">
        <Grid item xs={12} sm={4}>
          <Typography component="span" variant="subtitle2">
            {getUserName(item)}
          </Typography>
        </Grid>
        {(getTeam(item) || getPostion(item)) && (
          <Grid item container xs={12} sm={8} spacing={isSmall ? 1 : 2}>
            <Grid item container xs={12} sm={5} alignItems="flex-start" wrap="nowrap">
              {getTeam(item) && <GroupIcon className={classes.icon} />}
              <Typography variant="body2" component="span">
                {getTeam(item)}
              </Typography>
            </Grid>
            {getPostion(item) && (
              <Grid item container xs={12} sm={7} alignItems="flex-start" wrap="nowrap">
                <RecentActorsIcon className={classes.icon} />
                <Typography variant="body2" component="span">
                  {getPostion(item)}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </ListItem>
  );
};

export default UserSearchItem;
