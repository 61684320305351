import React from 'react';
import ReactDOM from 'react-dom';
import DialogPrompt from '../components/Dialogs/DialogPrompt';
import { createHashHistory as createHistory } from 'history'

const getUserConfirmation = (message: string, callback: (ok: boolean) => void) => {
  ReactDOM.render(<DialogPrompt message={message} callback={callback} />, document.getElementById('prompt'));
};

export const history = createHistory({ basename: '', getUserConfirmation });

