import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  makeStyles,
  Typography,
  Grid,
  TextField,
  Chip,
  useTheme,
  useMediaQuery,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  LinearProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { AppState } from '../../store';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import AppHeader from '../../components/AppHeader/AppHeader';
import ButtonWithBadge from '../../components/Dashboard/ButtonWithBadge';
import UserList from '../../components/Dashboard/UserList';
import LPUser from '../../sdk/com/apiomat/frontend/mylearningplatform/LPUser';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LearnContent from '../../sdk/com/apiomat/frontend/mylearningplatform/LearnContent';
import clsx from 'clsx';
import LearnContentSearchItem from '../../components/Admin/LearnContentSearchItem';
import BottomAppBar from '../../components/AppHeader/BottomAppBar';
import BasicContainer from '../../components/Container/BasicContainer';
import { learnContentsActions } from '../../store/learnContents';
import UserSelectionAutocomplete from '../../components/Admin/UserSelectionAutocomplete';
import { teamOverviewActions, LearnContentCompletedFilterType } from '../../store/team';
import { TeamOverviewCategory } from '../../enums/TeamOverviewCategory';
import TeamSelectionAutocomplete from '../../components/Admin/TeamSelectionAutocomplete';
import LPTeam from '../../sdk/com/apiomat/frontend/mylearningplatform/LPTeam';
import GetAppIcon from '@material-ui/icons/GetApp';
import { onDownloadCSV } from '../../utils/file.utils';
import { notificationActions } from '../../store/notification';
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
  },
  tableTitle: {
    padding: theme.spacing(1),
  },
  tabs: {
    marginBottom: theme.spacing(3),
  },
  search: {
    margin: theme.spacing(1, 0, 4),
  },
  list: {
    margin: theme.spacing(0, 0, 0, 3),
  },
  searchInput: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ': {
      borderColor: theme.palette.primary.main,
    },
  },
  selectedSearch: {
    '& .MuiInputLabel-outlined': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  tableHeader: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1),
  },
  downloadButton: {
    marginLeft: '1em',
  },
}));

type TeamListShowType = 'by-user' | 'by-user-group';

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const { learnContentsMap, learnContents, loading } = useSelector((state: AppState) => state.learnContents);

  const {
    users,
    loadingUsersStatus,
    userCounts,
    learnContentFilters,
    userNameFilters,
    activeCategory,
    teams,
    teamFilters,
    userQuery,
    selectedUserCount,
    learnContentCompletedFilter,
    autocompleteLearnContentCompletedFilter,
  } = useSelector((state: AppState) => state.teamOverview);

  const [autocompleteList, setAutocompleteList] = useState([]);
  const [autocompleteInputValue, setAutocompleteInputValue] = useState('');
  const [userCache, setUserCache] = useState<{ [userName: string]: LPUser }>({});
  const [teamCache, setTeamCache] = useState<{ [teamId: string]: LPTeam }>({});
  const [teamListShow, setTeamListShow] = useState<TeamListShowType>('by-user');
  const [isDownloading, setIsDownloading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const [userList, setUserList] = useState<HTMLUListElement>(null);
  const userListRef = useCallback(node => {
    setUserList(node)
  }, []);

  const { innerHeight, scrollThreshold } = useInfiniteScroll();

  useEffect(() =>
    () => dispatch(teamOverviewActions.clearAllFilters())
  , [dispatch]);

  useEffect(() => {
    dispatch(learnContentsActions.loadLearnContents());
    setIsInitialized(true);
  }, [dispatch]);

  useEffect(() => {
    loadUserCounts();
  }, [dispatch, learnContents, users]);

  useEffect(() => {
    dispatch(teamOverviewActions.setLearnContents(learnContents));

    if (isInitialized) {
      dispatch(teamOverviewActions.loadUsers());
    }
  }, [dispatch, learnContents, activeCategory, learnContentCompletedFilter]);

  useEffect(() => {
    dispatch(teamOverviewActions.loadTeams());
  }, [dispatch]);

  useEffect(() => {
    if (isInitialized) {
      const filteredAutocompleteList = learnContents.filter(item =>
        autocompleteLearnContentCompletedFilter === 'open' ? item.isArchived !== 1 : learnContents);
      dispatch(teamOverviewActions.setLearnContentFilters(
          learnContentFilters.filter(id => filteredAutocompleteList.findIndex(item => item.ID === id) !== -1)));
      setAutocompleteList(filteredAutocompleteList);
    }
  }, [dispatch, learnContents, autocompleteLearnContentCompletedFilter]);

  /* Load more users if whole user list element is visible */
  useEffect(() => {
    if (loadingUsersStatus[activeCategory] !== 'pending' && !isUsersLoadingCompleted() && isWholeElementVisible(userList)) {
      dispatch(teamOverviewActions.loadUsers());
    }
  }, [userList, teamListShow, loadingUsersStatus, users, innerHeight, dispatch]);

  const isWholeElementVisible = (element: HTMLElement): boolean => {
    const elementBottom = element?.getBoundingClientRect()?.bottom;
    return elementBottom <= window.innerHeight;
  }

  const isUsersLoadingCompleted = () => {
    return ['completed', 'failed'].includes(loadingUsersStatus[activeCategory]);
  }

  const handleUserSelectionChange = (selectedUsers: LPUser[] = []) => {
    setUserCache(selectedUsers.reduce((acc, user) => ({ ...acc, [user.userName]: user }), {}));
    dispatch(teamOverviewActions.setUserNameFilters(selectedUsers.map(user => user.userName)));
  };

  const handleTeamSelectionChange = (selectedTeams: LPTeam[] = []) => {
    setTeamCache(selectedTeams.reduce((acc, team) => ({ ...acc, [team.ID]: team }), {}));
    dispatch(teamOverviewActions.setTeamFilters(selectedTeams.map(team => team.ID)));
  };

  const handleFilterTypeChange = (value: TeamListShowType) => {
    setTeamListShow(value);
    dispatch(teamOverviewActions.clearUserAndTeamFilters());
  };

  const handleLearnContentCompletedFilterChange = (value: LearnContentCompletedFilterType) => {
    dispatch(teamOverviewActions.setLearnContentCompletedFilter(value));
  };

  const handleAutocompleteLearnContentCompletedFilterChange = (value: LearnContentCompletedFilterType) => {
    dispatch((teamOverviewActions.setAutocompleteLearnContentCompletedFilter(value)));
  };

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDownloadCSV = async () => {
    setIsDownloading(true);
    try {
      await onDownloadCSV(userQuery);
    } catch (err) {
      dispatch(notificationActions.showError(t('team.download-error')));
    } finally {
      setIsDownloading(false);
    }
  };

  const loadUserCounts = useMemo(() =>
    debounce(() =>
      dispatch(teamOverviewActions.loadUserCounts())
    , 3000)
  , [dispatch]);

  return (
    <>
      <AppHeader tab="manager/team" />
      <BasicContainer>
        {!isInitialized || loading === 'pending' ? (
          <LoadingIndicator />
        ) : (
          <>
            <Grid container justify="space-between" alignItems="center" className={classes.tableHeader}>
              <Grid item>
                <Typography variant="body2">{t('team.filter-by')}</Typography>
              </Grid>
              <Grid container item justify="space-between">
                <Grid item>
                  <RadioGroup
                      row
                      value={autocompleteLearnContentCompletedFilter}
                      onChange={(_event, value) => handleAutocompleteLearnContentCompletedFilterChange(value as LearnContentCompletedFilterType)}
                  >
                    <FormControlLabel value="all" control={<Radio />} label={t('team.all')} />
                    <FormControlLabel value="open" control={<Radio />} label={t('team.active')} />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>
            <Autocomplete
              multiple
              options={autocompleteList}
              className={classes.search}
              getOptionLabel={option => t(option.headlineKey)}
              value={learnContentFilters.map(contentId => learnContentsMap.get(contentId))}
              onChange={(_event, value: LearnContent[]) => {
                dispatch(teamOverviewActions.setLearnContentFilters(value.map(content => content.ID)));
                setAutocompleteInputValue('');
              }}
              filterSelectedOptions
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => <Chip label={t(option.headlineKey)} color="primary" {...getTagProps({ index })} />)
              }
              renderOption={option => <LearnContentSearchItem item={option} />}
              renderInput={params => (
                <TextField
                  {...params}
                  className={clsx(classes.searchInput, learnContentFilters.length > 0 && classes.selectedSearch)}
                  variant="outlined"
                  label={t('team.search-training')}
                />
              )}
              inputValue={autocompleteInputValue}
              onInputChange={(event, value, reason) => {
                if (reason === 'input') {
                  setAutocompleteInputValue(value);
                }
              }}
            />
            <Grid container justify="space-between" alignItems="center" className={classes.tableHeader}>
              <Grid item>
                <Typography variant="body2">{t('team.filter-by')}</Typography>
              </Grid>

              <Grid container item justify="space-between">
                <Grid item>
                  <RadioGroup
                    row
                    value={learnContentCompletedFilter}
                    onChange={(_event, value) => handleLearnContentCompletedFilterChange(value as LearnContentCompletedFilterType)}
                  >
                    <FormControlLabel value="all" control={<Radio />} label={t('team.all')} />
                    <FormControlLabel value="open" control={<Radio />} label={t('team.open')} />
                  </RadioGroup>
                </Grid>

                <Grid item>
                  <RadioGroup row value={teamListShow} onChange={(_event, value) => handleFilterTypeChange(value as TeamListShowType)}>
                    <FormControlLabel value="by-user" control={<Radio />} label={t('team.by-user')} />
                    <FormControlLabel value="by-user-group" control={<Radio />} label={t('team.by-user-group')} />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>
            {teamListShow === 'by-user-group' ? (
              <TeamSelectionAutocomplete
                selectedTeams={teamFilters.map(teamId => teamCache[teamId])}
                setSelectedTeams={handleTeamSelectionChange}
              />
            ) : (
              <UserSelectionAutocomplete
                selectedUsers={userNameFilters.map(userName => userCache[userName])}
                setSelectedUsers={handleUserSelectionChange}
              />
            )}
            <Typography variant="body2" className={classes.tableTitle}>
              {t('team.remaining-processing-time')}
            </Typography>
            <Grid container spacing={isSmall ? 1 : 3} className={classes.tabs}>
              <Grid item xs={12} sm={6} md={4}>
                <ButtonWithBadge
                  selected={activeCategory === TeamOverviewCategory.OVERDUE}
                  title={t('team.overdue', { count: userCounts.overdue })}
                  color="error"
                  onClick={() => dispatch(teamOverviewActions.setActiveCategory(TeamOverviewCategory.OVERDUE))}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ButtonWithBadge
                  selected={activeCategory === TeamOverviewCategory.ALMOST_OVERDUE}
                  title={t('team.less-than-14-days', { count: userCounts.almostOverdue })}
                  color="warning"
                  onClick={() => dispatch(teamOverviewActions.setActiveCategory(TeamOverviewCategory.ALMOST_OVERDUE))}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ButtonWithBadge
                  selected={activeCategory === TeamOverviewCategory.ALL}
                  title={t('team.show-all', { count: userCounts.all })}
                  color="default"
                  onClick={() => dispatch(teamOverviewActions.setActiveCategory(TeamOverviewCategory.ALL))}
                />
              </Grid>
            </Grid>
            <Typography variant="body2" className={classes.tableTitle}>
              {t('team.selected-users-count', { count: selectedUserCount })}
              <Button
                className={classes.downloadButton}
                color="secondary"
                startIcon={<GetAppIcon />}
                onClick={handleDownloadCSV}
                size="small"
                disabled={isDownloading}
              >
                {t('team.download-csv')}
              </Button>
              {isDownloading && <LinearProgress />}
            </Typography>
            <UserList
              forwardedRef={userListRef}
              teams={teams}
              users={users[activeCategory]}
              learnContentsMap={learnContentsMap}
              scrollThreshold={scrollThreshold}
              onNext={() => dispatch(teamOverviewActions.loadUsers())}
              completed={isUsersLoadingCompleted()}
            />
          </>
        )}
      </BasicContainer>
      <BottomAppBar tab="manager/team" />
    </>
  );
};
