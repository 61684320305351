import React, { useEffect } from 'react';
import { Typography, makeStyles, Theme, Paper, Grid, Divider, Button, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LearnContentData, QuestionData, TranslatedString } from '../../../store/learnContents';
import SelectionButton from '../../Buttons/SelectionButton';
import TranslatedStringForm from './TranslatedStringForm';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(3, -3),
  },
  addMoreButton: {
    padding: theme.spacing(1, 3),
    margin: theme.spacing(3, 0),
  },
  deleteButton: {
    marginBottom: theme.spacing(1),
  },
}));

export interface ExaminationStepProps {
  learnContent: LearnContentData;
  setLearnContent: (learnContent: LearnContentData) => void;
}

const ExaminationStep = (props: ExaminationStepProps) => {
  const { learnContent, setLearnContent } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const updateQuestion = (i: number, title: TranslatedString, value: boolean) => {
    const questions = [
      ...learnContent.questions.filter(q => q.id !== learnContent.questions[i]?.id),
      {
        ...learnContent.questions[i],
        title,
        value
      }
    ];

    setLearnContent({ ...learnContent, questions });
  };

  const addQuestion = () => {
    setLearnContent({ ...learnContent, questions: [...learnContent.questions, { id: uuid() } as QuestionData] });
  };

  const deleteQuestion = (id: string) => {
    setLearnContent({ ...learnContent, questions: learnContent.questions.filter(el => el.id !== id) });
  };

  useEffect(() => {
    /* Set defaults if this is a preexisting learn content, that had no questions defined */
    if (!learnContent.questionsDefined) {
      setLearnContent({
        ...learnContent,
        questionsDefined: true,
        questions: [{ id: uuid() } as QuestionData]
      });
    }
  })

  return (
    <Grid container direction="column" spacing={3}>
      {learnContent.questionsDefined && (
        <>
          {learnContent.questions.map((question, i) => (
            <Grid item key={question.id}>
              <Paper elevation={0} className={classes.paper}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography component="h2" variant="subtitle2" color="primary" className={classes.description}>
                      {t('admin.create-learn-content.examination.question')} {i + 1}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {learnContent.questions.length > 1 && (
                      <IconButton onClick={() => deleteQuestion(question.id)} className={classes.deleteButton}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
                <TranslatedStringForm
                  langs={learnContent.langs}
                  placeholderKey="admin.create-learn-content.examination.question-placeholder"
                  translatedString={question.title || {}}
                  setTranslatedString={title => updateQuestion(i, title, question.value)}
                />
                <Divider className={classes.divider} />
                <Typography component="p" variant="body1" className={classes.description}>
                  {t('admin.create-learn-content.examination.answer')}
                </Typography>
                <Grid container spacing={2} justify="center">
                  <Grid item>
                    <SelectionButton
                      label={t('admin.create-learn-content.examination.yes')}
                      selected={question.value}
                      selectedColor="success"
                      onSelect={() => updateQuestion(i, question.title, true)}
                    />
                  </Grid>
                  <Grid item>
                    <SelectionButton
                      label={t('admin.create-learn-content.examination.no')}
                      selected={question.value === false}
                      selectedColor="error"
                      onSelect={() => updateQuestion(i, question.title, false)}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
          <Grid container justify="center">
            <Button color="primary" variant="contained" startIcon={<AddIcon />} className={classes.addMoreButton} onClick={addQuestion}>
              {t('admin.create-learn-content.examination.add-more-questions')}
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ExaminationStep;
