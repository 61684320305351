var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import AOMRequestError from "./../AOMRequestError";
import Datastore from "./../Datastore";
import AOMStatus from "./../Status";
import AbstractClientDataModel from "./../AbstractClientDataModel";
export default class LPTeam extends AbstractClientDataModel {
    static getLPTeams(query, withReferencedHrefs = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadListFromServerWithClass(LPTeam, query, withReferencedHrefs, usePersistentStorage);
        });
    }
    static getLPTeamsCount(query, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadCountFromServer(LPTeam, undefined, query, usePersistentStorage);
        });
    }
    static staticGetModuleName() {
        return "MyLearningPlatform";
    }
    static staticGetModelName() {
        return "LPTeam";
    }
    get customMetaForClass() {
        return "";
    }
    static removeAllFromStorage() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorageByClass(LPTeam);
        });
    }
    static deleteAllFromStorage(query) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorage(query, LPTeam);
        });
    }
    static getLPTeamsFromPersistentStorage(whereWithPlaceholders = "", whereValues = [], orderBy = "", limit, offset) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadFromPersistentStorage(LPTeam, whereWithPlaceholders, whereValues, orderBy, limit, offset);
        });
    }
    static getLPTeamsCountFromPersistentStorage(whereWithPlaceholders = "", whereValues = []) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.getCountFromPersistentStorage(LPTeam, whereWithPlaceholders, whereValues);
        });
    }
    constructor() {
        super();
        this.dao = new LPTeamDAO();
    }
    get modelName() { return "LPTeam"; }
    get moduleName() { return "MyLearningPlatform"; }
    get assignedLearnContents() {
        return this.dao.assignedLearnContents !== null ? this.dao.assignedLearnContents : undefined;
    }
    set assignedLearnContents(_assignedLearnContents) {
        this.dao.assignedLearnContents = _assignedLearnContents;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get name() {
        return this.dao.name !== null ? this.dao.name : undefined;
    }
    set name(_name) {
        this.dao.name = _name;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get superTeam() {
        return this.hashmap.superTeam !== null ? this.hashmap.superTeam : undefined;
    }
    loadSuperTeam(withRefHref = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            let refUrl = this.dao.superTeamHref;
            if (typeof refUrl === "undefined" || refUrl === null) {
                return Promise.reject(new AOMRequestError(AOMStatus.ATTACHED_HREF_MISSING, 200));
            }
            try {
                this.hashmap.superTeam = new LPTeam();
                const res = yield Datastore.Instance.loadFromServer(refUrl, this.hashmap.superTeam, withRefHref, usePersistentStorage, LPTeam, "", false);
                if (typeof res.responseText !== "undefined" && (Datastore.getCleanTypeFromTypeString(res.responseText["@type"])
                    !== Datastore.getCleanTypeFromTypeString(this.hashmap.superTeam["@type"])
                    || (res.responseText.modelName + "$" + res.responseText.moduleName) !==
                        Datastore.getCleanTypeFromTypeString(this.hashmap.superTeam["@type"]))) {
                    const clazz = yield Datastore.Instance.getClassFromJSON(res.responseText);
                    this.hashmap.superTeam = new clazz();
                    this.hashmap.superTeam.fromJson(res.responseText);
                }
                return this.hashmap.superTeam;
            }
            catch (error) {
                if (error.statusCode === 204 || error.statusCode === AOMStatus.ID_NOT_FOUND_OFFLINE
                    || error.statusCode === AOMStatus.ID_NOT_FOUND) {
                    this.hashmap.superTeam = undefined;
                }
                return Promise.reject(error);
            }
        });
    }
    loadSuperTeamAndRefHref(usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.loadSuperTeam(true, usePersistentStorage);
        });
    }
    postSuperTeam(_refData, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.parent !== null) {
                return Promise.reject(new AOMRequestError(AOMStatus.EMBEDDED_REF, 201));
            }
            if (typeof _refData.href === "undefined") {
                return Promise.reject(new AOMRequestError(AOMStatus.SAVE_REFERENECE_BEFORE_REFERENCING, 201));
            }
            try {
                let href = this.href + "/superTeam";
                if (typeof this.dao.superTeamHref !== "undefined"
                    && this.dao.superTeamHref !== null) {
                    href = this.dao.superTeamHref;
                }
                const { returnedHref: refHref, offline } = yield Datastore.Instance.postOnServer(_refData, href, true, usePersistentStorage, false, "superTeam");
                if (offline === true) {
                    this.dao.superTeamHref = href;
                    yield this.save(false, usePersistentStorage);
                }
                if (refHref) {
                    this.hashmap.superTeam = _refData;
                    return refHref;
                }
            }
            catch (error) {
                return Promise.reject(error);
            }
        });
    }
    removeSuperTeam(_refData, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (_refData === null || typeof _refData === "undefined" || typeof _refData.href === "undefined") {
                return Promise.reject(new AOMRequestError(AOMStatus.HREF_NOT_FOUND, 204));
            }
            const id = _refData.href.substring(_refData.href.lastIndexOf("/") + 1);
            const deleteHref = this.dao.superTeamHref + "/" + id;
            try {
                const response = yield Datastore.Instance.deleteOnServer(deleteHref, _refData, true, usePersistentStorage);
                this.hashmap.superTeam = undefined;
                if (typeof response !== "undefined" && response.offline === true) {
                    yield this.save(false, usePersistentStorage);
                }
                return response;
            }
            catch (error) {
                return Promise.reject(error);
            }
        });
    }
    get usersCount() {
        return this.dao.usersCount !== null ? this.dao.usersCount : undefined;
    }
    set usersCount(_usersCount) {
        this.dao.usersCount = _usersCount;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
}
import LPTeamDAO from "./LPTeamDAO";
