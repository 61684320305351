import { makeStyles, Theme, TextField, CircularProgress, Chip, List, Typography, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { useTranslation } from 'react-i18next';
import PositionSearchItem from './PositionSearchItem';
import LPUser from '../../sdk/com/apiomat/frontend/mylearningplatform/LPUser';
import { useDispatch } from 'react-redux';
import { notificationActions } from '../../store/notification';

const SEARCH_LIMIT = 10;

const useStyles = makeStyles((theme: Theme) => ({
  search: {
    margin: theme.spacing(1, 0, 2),
  },
  searchInput: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ': {
      borderColor: theme.palette.primary.main,
    },
  },
  selectedSearch: {
    '& .MuiInputLabel-outlined': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  moreUsersLabel: {
    margin: theme.spacing(1),
    textAlign: 'center',
    fontStyle: 'italic',
  },
  icon: {
    fontSize: theme.typography.body2.fontSize,
    marginRight: theme.spacing(1),
  },
}));

export interface PositionSelectionAutocompleteProps {
  allPositions: string[];
  selectedPositions: string[];
  setSelectedPositions?: (positions: string[]) => void;
}

const PositionSelectionAutocomplete = (props: PositionSelectionAutocompleteProps) => {
  const { selectedPositions, setSelectedPositions, allPositions } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<string[]>([]);

  const onLoadPositions = async (filter?: string) => {
    setLoading(true);
    try {
      const options = allPositions
        .filter(it => (!filter || it.toLowerCase().includes(filter.toLowerCase())))
        .filter(it => !selectedPositions?.includes(it))
        .slice(0, 10);

      setOptions(options);
    } catch (error) {
      dispatch(notificationActions.showError(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open === false) {
      setOptions([]);
    } else {
      onLoadPositions();
    }
  }, [open]);

  const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;

    const items = React.Children.toArray(children);

    return (
      <div ref={ref} {...other}>
        <List role="listbox">{children}</List>
        {items.length === SEARCH_LIMIT && (
          <Typography className={classes.moreUsersLabel}>{t('admin.assignment.search-groups-more-label')}</Typography>
        )}
      </div>
    );
  });

  return (
    <Autocomplete
      multiple
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={(_event, value, reason) => {
        if (reason === 'clear') {
          setOpen(false);
        } else {
          onLoadPositions(value);
        }
      }}
      filterOptions={() => options}
      ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      options={options}
      noOptionsText={t('errors.no-entry-found')}
      className={classes.search}
      loading={loading}
      value={selectedPositions}
      onChange={(_event, value: string[]) => setSelectedPositions(value)}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={
              <Grid alignItems="center" container>
                <RecentActorsIcon className={classes.icon} />
                <Typography>{option}</Typography>
              </Grid>
            }
            color="primary"
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={option => <PositionSearchItem item={option} />}
      renderInput={params => (
        <TextField
          {...params}
          className={clsx(classes.searchInput, selectedPositions.length > 0 && classes.selectedSearch)}
          variant="outlined"
          label={t('admin.assignment.search-positions')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default PositionSelectionAutocomplete;
