import React, { useState } from 'react';
import {
  Container,
  makeStyles,
  Theme,
  Tabs,
  Tab,
  IconButton,
  Button,
  Hidden,
  Grid,
  Menu,
  MenuItem,
  Typography,
  useTheme,
  useMediaQuery,
  AppBar,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AppLogo from '../../assets/img/app_logo.svg';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/auth';
import { maintenanceActions } from "../../store/maintenance";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { AppState } from '../../store/index';
import FaceIcon from '@material-ui/icons/Face';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { CrownIcon, DiamondIcon } from '../Icons/Icons';
import MaintenanceNotifier from '../Notifier/MaintenanceNotifier';
import BasicContainer from '../Container/BasicContainer';
import IdleTimerContainer from '../Timer/IdleTimerContainer';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey.A100
  },
  container: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0, 2),
    minHeight: 64,
  },
  tabs: {
    marginTop: -64,
  },
  appLogo: {
    height: 35,
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
  },
  profileIcon: {
    marginRight: theme.spacing(1),
  },
  menuIcon: {
    marginRight: theme.spacing(2),
  },
  lpButton: {
    whiteSpace: 'nowrap',
    color: theme.palette.primary.main,
  },
  flexNoWrap: {
    flexWrap: 'nowrap',
  },
  maintenanceNotifier: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

export interface AppHeaderProps {
  tab:
    | 'user/learn-contents'
    | 'user/certificates'
    | 'manager/team'
    | 'profile'
    | 'admin/learn-content-management'
    | 'admin/learn-content-assignment';
}

const AppHeader: React.FC<AppHeaderProps> = React.forwardRef(function(inProps, ref: React.Ref<HTMLDivElement>) {
  const props = { ...inProps };
  const { tab } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { user, isManager, isAdmin, isAdminAreaNavigation } = useSelector((state: AppState) => state.auth);
  const { maintenance } = useSelector((state: AppState) => state.maintenance);

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const setTab = (newTab: string) => {
    setAnchorEl(null);
    history.push(`/${newTab}`);
  };

  const userTabs = [
    <Tab key="user/learn-contents" label={t('app-header.my-learn-contents')} value="user/learn-contents" />,
    <Tab key="user/certificates" label={t('app-header.my-certificates')} value="user/certificates" />,
    isManager && <Tab key="manager/team" label={t('app-header.my-team')} value="manager/team" />,
  ];

  const adminTabs = [
    isAdmin && (
      <Tab key="admin/learn-content-management" label={t('app-header.learn-content-management')} value="admin/learn-content-management" />
    ),
    <Tab key="admin/learn-content-assignment" label={t('app-header.learn-content-assignment')} value="admin/learn-content-assignment" />,
  ];

  const tabs = (
    <Tabs value={tab} onChange={(_event, newValue) => setTab(newValue)} textColor="primary" indicatorColor="primary">
      {isAdminAreaNavigation ? adminTabs : userTabs}
    </Tabs>
  );

  const logout = () => {
    dispatch(maintenanceActions.resetMaintenance());
    dispatch(authActions.logout());
  };

  return (
    <div ref={ref}>
      <AppBar position="sticky" color="transparent" className={classes.header}>
        <Grid container className={classes.container} alignItems="center" justify="space-between">
          <Grid item>
            <img src={AppLogo} className={classes.appLogo} alt="App Logo" />
          </Grid>
          <Grid item>
            <Hidden smDown lgUp>
              {tabs}
            </Hidden>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={isSmall ? 0 : 2} className={classes.flexNoWrap}>
              <Grid item>
                <Button startIcon={<DiamondIcon color="primary" />} className={classes.lpButton}>
                  {user?.generalScore || 0} LP
                </Button>
              </Grid>
              <Grid item>
                <Hidden xsDown>
                  <Tabs
                    value={tab === 'profile' ? tab : false}
                    onChange={(event, newValue) => (isAdmin ? setAnchorEl(event.currentTarget) : setTab(newValue))}
                    textColor="primary"
                    indicatorColor="primary"

                  >
                    <Tab
                      label={
                        <div className={classes.profile}>
                          {isAdminAreaNavigation ? (
                            <CrownIcon color="primary" className={classes.profileIcon} />
                          ) : (
                            <FaceIcon color="primary" className={classes.profileIcon} />
                          )}
                          {user?.firstName}
                        </div>
                      }
                      value="profile"
                    />
                  </Tabs>
                </Hidden>
                <Hidden smUp>
                  <IconButton onClick={event => (isAdmin ? setAnchorEl(event.currentTarget) : setTab('profile'))}>
                    {isAdminAreaNavigation ? <CrownIcon color="primary" /> : <FaceIcon color="primary" />}
                  </IconButton>
                </Hidden>
                <Menu
                  anchorEl={anchorEl}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem onClick={() => setTab('profile')}>
                    <AccountCircleIcon fontSize="small" color="primary" className={classes.menuIcon} />
                    <Typography variant="inherit">{t('app-header.my-profile')}</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => setTab(isAdminAreaNavigation ? 'user/learn-contents' : 'admin')}>
                    {isAdminAreaNavigation ? (
                      <FaceIcon fontSize="small" color="primary" className={classes.menuIcon} />
                    ) : (
                      <CrownIcon fontSize="small" color="primary" className={classes.menuIcon} />
                    )}
                    <Typography variant="inherit">{isAdminAreaNavigation ? t('app-header.user') : t('app-header.admin')}</Typography>
                  </MenuItem>
                </Menu>
              </Grid>
              <Grid container item alignItems="center">
                <IconButton aria-label="logout" onClick={logout}>
                  <PowerSettingsNewIcon color="primary" />
                </IconButton>
                <IdleTimerContainer></IdleTimerContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Hidden mdDown>
          <Container maxWidth="md" className={classes.tabs}>
            {tabs}
          </Container>
        </Hidden>
      </AppBar>
      {maintenance !== null ? (
        <BasicContainer className={classes.maintenanceNotifier}>
          <MaintenanceNotifier />
        </BasicContainer>
      ) : null}
      {props.children}
    </div>
  );
});

export default AppHeader;
