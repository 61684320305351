var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createConnection, canUseOfflineHandlingCheck } from "../OfflineAdapter";
import AbstractStorage from "./AbstractStorage";
import CollectionInfoDAO from "./CollectionInfoDAO";
import CollectionMappingDAO from "./CollectionMappingDAO";
import Datastore from "../Datastore";
import ETagDAO from "./ETagDAO";
import ModuleInfoDAO from "./ModuleInfoDAO";
import SdkInfo from "./../SdkInfo";
import StaticDataDAO from "./StaticDataDAO";
import TaskBinaryDAO from "./TaskBinaryDAO";
import TaskInfoDAO from "./TaskInfoDAO";
import TaskObjectDAO from "./TaskObjectDAO";
import TasksIdHrefMapDAO from "./TasksIdHrefMapDAO";
export default class PersistentStorage extends AbstractStorage {
    constructor() {
        super();
        this._connection = null;
        this._defaultDaoClasses = [
            CollectionMappingDAO,
            CollectionInfoDAO,
            ETagDAO,
            StaticDataDAO,
            TaskInfoDAO,
            TaskObjectDAO,
            TaskBinaryDAO,
            TasksIdHrefMapDAO,
            ModuleInfoDAO,
        ];
        this._taskRelevantDAOs = [
            TaskInfoDAO,
            TaskObjectDAO,
            TaskBinaryDAO,
            TasksIdHrefMapDAO,
        ];
        this._dbRequestSpacing = 100;
        canUseOfflineHandlingCheck();
    }
    static get Instance() {
        return this._instance || (this._instance = new this());
    }
    get connection() {
        if (!this._connection) {
            throw Error("Connection for persistent Storage is not initialized.");
        }
        return this._connection;
    }
    initNewConnection(config) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof config !== "undefined") {
                if (typeof config.entities === "undefined") {
                    config.entities = this._defaultDaoClasses;
                }
                else if (config.entities instanceof Array) {
                    for (const daoClass of this._defaultDaoClasses) {
                        if (config.entities.includes(daoClass) === false) {
                            config.entities.push(daoClass);
                        }
                    }
                }
            }
            const dbCfg = typeof config !== "undefined" && config !== null ? config :
                {
                    type: "sqljs",
                    location: "apiomat.db",
                    autoSave: true,
                    entities: this._defaultDaoClasses,
                    logging: false,
                };
            this._connection = yield createConnection(dbCfg);
            yield this.connection.synchronize();
            this._collectionMappingRep = this.connection.getRepository("CollectionMapping");
            this._staticDataRep = this.connection.getRepository("StaticData");
            this._taskInfoRep = this.connection.getRepository("TaskInfo");
            this._taskObjectRep = this.connection.getRepository("TaskObject");
            this._taskBinaryRep = this.connection.getRepository("TaskBinary");
            this._tasksIdHrefMapRep = this.connection.getRepository("TasksIdHrefMap");
            this._eTagRep = this.connection.getRepository("ETag");
            this._collectionInfoRep = this.connection.getRepository("CollectionInfo");
            this._moduleInfoRep = this.connection.getRepository("ModuleInfo");
            yield this.checkModuleVersions();
        });
    }
    closeConnection() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this._connection) {
                yield this.connection.close();
            }
        });
    }
    addOrUpdateCollection(href, responseData, useCollectionMerging = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if (responseData instanceof Array) {
                yield this.saveCollectionIds(href, responseData, useCollectionMerging);
                for (const el of responseData) {
                    yield this.addOrUpdateObject(el);
                }
                yield this.saveLastUpdateOfCollection(href);
            }
            else {
                yield this.addOrUpdateObject(responseData, href);
                if (typeof responseData.id !== "undefined") {
                    yield this.saveCollectionIds(href, [responseData]);
                }
            }
        });
    }
    getCollectionObjects(href, clazz) {
        return __awaiter(this, void 0, void 0, function* () {
            const collectionIdDAOs = yield this.getCollection(href);
            if (href.endsWith("/models/me")) {
                if (collectionIdDAOs.length < 1) {
                    return undefined;
                }
                return yield this.getObject(collectionIdDAOs[0].id, href, clazz);
            }
            if (collectionIdDAOs.length === 1) {
                clazz = yield this.getClassFromType(collectionIdDAOs[0].type);
                return this.getObject(collectionIdDAOs[0].id, href, clazz);
            }
            else if (href.indexOf(".img") > -1) {
                const id = this.getIdFromHref(href);
                return yield this.getStaticData(id);
            }
            else if (collectionIdDAOs.length === 0) {
                const id = this.getIdFromHref(href);
                return this.getObject(id, href, clazz);
            }
            const objectArray = [];
            for (const idDAO of collectionIdDAOs) {
                clazz = yield this.getClassFromType(idDAO.type);
                const tmpDAO = yield this.getObject(idDAO.id, href, clazz, true);
                const tmpObj = this.getObjFromDAO(tmpDAO);
                objectArray.push(tmpObj);
            }
            return objectArray;
        });
    }
    getCollection(href) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this._collectionMappingRep.find({
                order: {
                    position: "ASC",
                },
                where: { href: href },
            });
        });
    }
    removeCollection(href) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._collectionMappingRep.delete({ href: href });
        });
    }
    getObject(id, href, clazz, isCollection = false) {
        return __awaiter(this, void 0, void 0, function* () {
            let entity = "";
            if (clazz) {
                entity = this.getDAOEntityName(clazz);
            }
            else if (href) {
                entity = this.getDAOEntityNameFromHref(href, isCollection);
            }
            try {
                const repo = this.connection.getRepository(entity);
                return yield repo.findOne({ id: id });
            }
            catch (error) {
                return false;
            }
        });
    }
    getStaticData(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const staticDataDAO = yield this._staticDataRep.findOne(id);
            if (typeof staticDataDAO !== "undefined") {
                return staticDataDAO.data;
            }
            else {
                return undefined;
            }
        });
    }
    removeObjectByHref(href, clazz) {
        return __awaiter(this, void 0, void 0, function* () {
            const potentialHref = this.getPotentialHref(href);
            const id = this.getIdFromHref(href);
            const isReference = this.isReferenceHref(href);
            if (clazz) {
                if (isReference) {
                    yield this.removeIdFromCollection(potentialHref, id);
                }
                yield this.removeObject(id, clazz);
            }
            else {
                yield this.removeStaticData(id);
            }
        });
    }
    query(clazz, whereWithPlaceholders = "", whereValues = [], orderBy = "", limit, offset, getCount = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const entity = this.getDAOEntityName(clazz);
            const repo = this.connection.getRepository(entity);
            let completeQuery = "SELECT ";
            if (getCount) {
                completeQuery += "COUNT(*)";
            }
            else {
                completeQuery += "*";
            }
            completeQuery += " FROM " + clazz.staticGetModuleName() + "_" + clazz.staticGetModelName();
            if (whereWithPlaceholders !== "") {
                completeQuery += " WHERE " + whereWithPlaceholders;
            }
            if (orderBy !== "") {
                const regExOrder = /[^a-zA-Z0-9\s,`]/gim;
                if (regExOrder.test(orderBy)) {
                    throw new Error("The orderBy clause contains forbidden symbols. Only alphanumeric symbols, space, comma and ` are allowed");
                }
                completeQuery += " ORDER BY " + orderBy;
            }
            const regExInt = /^\d+$/;
            if (typeof limit !== "undefined") {
                if (regExInt.test(limit.toString())) {
                    completeQuery += " LIMIT " + limit.toString();
                }
                else {
                    throw new Error("Limit must be integer");
                }
            }
            if (typeof offset !== "undefined") {
                if (regExInt.test(offset.toString())) {
                    completeQuery += " OFFSET " + offset.toString();
                }
                else {
                    throw new Error("Offset must be integer");
                }
            }
            const data = yield repo.query(completeQuery, whereValues);
            if (getCount) {
                return data[0]["COUNT(*)"];
            }
            const instances = yield Reflect.apply(Reflect.get(Datastore.Instance, "createClassInstancesFromJSON"), undefined, [data, clazz]);
            if (instances instanceof Array) {
                return instances;
            }
            else if (instances instanceof Object) {
                return [instances];
            }
            else {
                return undefined;
            }
        });
    }
    getCollectionCountFromPersistentStorage(clazz, refUrl, whereWithPlaceholders = "", whereValues = []) {
        return __awaiter(this, void 0, void 0, function* () {
            const entity = this.getDAOEntityName(clazz);
            const repo = this.connection.getRepository(entity);
            const collMappingTableName = CollectionMappingDAO.name.replace("DAO", "");
            const queryIds = "SELECT id FROM " + collMappingTableName + " WHERE href LIKE $1";
            const dataIds = yield repo.query(queryIds, [refUrl]);
            const ids = [];
            for (const id of dataIds) {
                ids.push("'" + id.id + "'");
            }
            const where = "id in (" + ids.join(",") + ")";
            if (whereWithPlaceholders != "") {
                whereWithPlaceholders = where + " AND (" + whereWithPlaceholders + ")";
            }
            else {
                whereWithPlaceholders = where;
            }
            return yield this.query(clazz, whereWithPlaceholders, whereValues, undefined, undefined, undefined, true);
        });
    }
    removeAllObjects(clazz) {
        return __awaiter(this, void 0, void 0, function* () {
            const entity = this.getDAOEntityName(clazz);
            const repo = this.connection.getRepository(entity);
            const daos = yield repo.find();
            const ids = daos.map((el) => el.id);
            for (let i = 0; i < ids.length; i += this._dbRequestSpacing) {
                const idsToDel = ids.slice(i, i + this._dbRequestSpacing);
                const query = "DELETE FROM CollectionMapping WHERE id IN (" + idsToDel.map((id) => '"' + id + '"').join(",") + ")";
                yield this._collectionMappingRep.query(query);
            }
            yield repo.clear();
        });
    }
    clearStorage(withTaskData = true) {
        return __awaiter(this, void 0, void 0, function* () {
            const tables = yield this.connection.query("SELECT name FROM sqlite_master WHERE type='table'");
            const deletePromises = [];
            for (const table of tables) {
                let deleteData = true;
                if (withTaskData === false) {
                    deleteData = false === this._taskRelevantDAOs.some((daoClass) => {
                        return daoClass.name.replace("DAO", "") === table.name;
                    });
                }
                if (deleteData) {
                    deletePromises.push(this.connection.query("DELETE FROM " + table.name));
                }
            }
            yield Promise.all(deletePromises);
        });
    }
    dropDatabase() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.connection.dropDatabase();
        });
    }
    removeIdFromCollection(href, id) {
        return __awaiter(this, void 0, void 0, function* () {
            const mappings = yield this._collectionMappingRep.find({ id: id });
            const collHref = href;
            for (const mapping of mappings) {
                if (mapping.href.indexOf(collHref) > -1) {
                    href = mapping.href;
                    yield this._collectionMappingRep.delete({ href: href, id: id });
                }
            }
        });
    }
    getAllTaskInfos() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this._taskInfoRep.find();
        });
    }
    storeTaskInfo(task) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._taskInfoRep.save(task);
        });
    }
    removeTaskFromTaskInfoList(fileKey) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._taskInfoRep.delete({ fileKey: fileKey });
        });
    }
    deleteAllTasks() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._taskInfoRep.clear();
        });
    }
    getTaskIdHrefMap() {
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield this._tasksIdHrefMapRep.find();
            const map = {};
            for (const idToHref of data) {
                map[idToHref.localId] = idToHref.href;
            }
            return map;
        });
    }
    storeIdToHref(localId, href) {
        return __awaiter(this, void 0, void 0, function* () {
            const idToHref = new TasksIdHrefMapDAO();
            idToHref.localId = localId;
            idToHref.href = href;
            yield this._tasksIdHrefMapRep.save(idToHref);
        });
    }
    deleteTaskIdHrefMap() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._tasksIdHrefMapRep.clear();
        });
    }
    storeTaskBinary(fileKey, content) {
        return __awaiter(this, void 0, void 0, function* () {
            const taskBinary = new TaskBinaryDAO();
            taskBinary.fileKey = fileKey;
            taskBinary.data = content;
            yield this._taskBinaryRep.save(taskBinary);
        });
    }
    getStoredTaskBinary(fileKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const taskBinary = yield this._taskBinaryRep.findOne(fileKey);
            if (taskBinary !== null && typeof taskBinary !== "undefined") {
                return taskBinary.data;
            }
            else {
                return null;
            }
        });
    }
    removeTaskBinary(fileKey) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._taskBinaryRep.delete({ fileKey: fileKey });
        });
    }
    deleteTaskBinaries() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._taskBinaryRep.clear();
        });
    }
    storeTaskObject(fileKey, json) {
        return __awaiter(this, void 0, void 0, function* () {
            const taskObject = new TaskBinaryDAO();
            taskObject.fileKey = fileKey;
            taskObject.model = json;
            yield this._taskObjectRep.save(taskObject);
        });
    }
    getStoredModelFromTaskObject(fileKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const taskObject = yield this._taskObjectRep.findOne(fileKey);
            if (taskObject !== null && typeof taskObject !== "undefined") {
                return taskObject.model;
            }
            else {
                return null;
            }
        });
    }
    removeTaskObject(fileKey) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._taskObjectRep.delete({ fileKey: fileKey });
        });
    }
    deleteTaskObjects() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._taskObjectRep.clear();
        });
    }
    getETag(url) {
        return __awaiter(this, void 0, void 0, function* () {
            const eTagDao = yield this._eTagRep.findOne(url);
            if (eTagDao !== null && typeof eTagDao !== "undefined") {
                return eTagDao.eTag;
            }
            else {
                return undefined;
            }
        });
    }
    saveETag(url, eTag) {
        return __awaiter(this, void 0, void 0, function* () {
            const dao = new ETagDAO();
            dao.url = url;
            dao.eTag = eTag;
            yield this._eTagRep.save(dao);
        });
    }
    getLastUpdateFromCollection(href) {
        return __awaiter(this, void 0, void 0, function* () {
            let lastUpdate = null;
            const collInfo = yield this._collectionInfoRep.findOne(href);
            if (collInfo !== null && typeof collInfo !== "undefined") {
                lastUpdate = collInfo.lastUpdate;
            }
            return lastUpdate;
        });
    }
    addOrUpdateObject(object, href) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof object.id === "undefined" || object.id === null || object.id === "")
                && typeof object.foreignId !== "undefined") {
                object.id = object.foreignId;
            }
            if (typeof href !== "undefined" && href.endsWith("/models/me")) {
                const idDaos = yield this.getCollection(href);
                if (idDaos.length < 1) {
                    yield this.saveObject(object);
                }
                else {
                    const clazz = yield this.getClassFromType(Datastore.getCleanTypeFromTypeString(object["@type"]));
                    const dao = yield this.getObject(object.id, object.href, clazz);
                    if (typeof dao === "undefined" || dao.lastModifiedAt === null || dao.lastModifiedAt <= object.lastModifiedAt) {
                        yield this.saveObject(object);
                    }
                }
            }
            else if (object.id !== undefined) {
                const idDaos = yield this.getCollection(href);
                if (idDaos.length < 1) {
                    yield this.saveObject(object);
                }
                else {
                    const dao = yield this.getObjectByIdAndType(object.id, Datastore.getCleanTypeFromTypeString(object["@type"]));
                    if (typeof dao === "undefined" || dao.lastModifiedAt === null || dao.lastModifiedAt <= object.lastModifiedAt) {
                        yield this.saveObject(object);
                    }
                }
            }
            else if (href && (href.indexOf("images/") > -1 || href.indexOf("files/") > -1)) {
                const key = this.getIdFromHref(href);
                yield this.saveStaticDataObj(key, object);
            }
        });
    }
    updateIdArray(collection, href) {
        return [];
    }
    removeObject(id, clazz) {
        return __awaiter(this, void 0, void 0, function* () {
            const entity = this.getDAOEntityName(clazz);
            const repo = this.connection.getRepository(entity);
            const del = yield repo.delete({ id: id });
            yield this._collectionMappingRep.delete({ id: id });
        });
    }
    saveCollectionIds(href, objs, useCollectionMerging = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if (useCollectionMerging === false) {
                yield this.removeCollection(href);
            }
            const daos = [];
            if (objs instanceof Array) {
                objs.forEach((obj, i) => {
                    const mappingDAO = new CollectionMappingDAO();
                    mappingDAO.href = href;
                    if (typeof obj.id !== "undefined") {
                        mappingDAO.id = obj.id;
                    }
                    else if (typeof obj.foreignId !== "undefined") {
                        mappingDAO.id = obj.foreignId;
                    }
                    mappingDAO.position = i;
                    mappingDAO.type = typeof obj["@type"] !== "undefined" ?
                        Datastore.getCleanTypeFromTypeString(obj["@type"]) : obj.moduleName + "$" + obj.modelName;
                    daos.push(mappingDAO);
                });
            }
            else {
                const mappingDAO = new CollectionMappingDAO();
                mappingDAO.href = href;
                mappingDAO.id = objs.id;
                mappingDAO.position = 0;
                mappingDAO.type = typeof objs["@type"] !== "undefined" ?
                    Datastore.getCleanTypeFromTypeString(objs["@type"]) : objs.moduleName + "$" + objs.modelName;
                daos.push(mappingDAO);
            }
            for (let i = 0; i < daos.length; i += this._dbRequestSpacing) {
                const daosToSave = daos.slice(i, i + this._dbRequestSpacing);
                yield this._collectionMappingRep.save(daosToSave);
            }
        });
    }
    saveObject(object) {
        return __awaiter(this, void 0, void 0, function* () {
            const dao = yield this.getDAOFromObject(object);
            let entity;
            if (typeof object["@type"] !== "undefined") {
                entity = this.getDAOEntityNameFromType(Datastore.getCleanTypeFromTypeString(object["@type"]));
            }
            else {
                entity = this.getDAOEntityNameFromType(object.moduleName + "$" + object.modelName);
            }
            const repo = this.connection.getRepository(entity);
            yield repo.save(dao);
        });
    }
    getObjectByIdAndType(id, type) {
        return __awaiter(this, void 0, void 0, function* () {
            const entity = this.getDAOEntityNameFromType(type);
            const repo = this.connection.getRepository(entity);
            const data = yield repo.find({ id: id });
            return data[0];
        });
    }
    getDAOEntityNameFromHref(href, isCollection = false) {
        try {
            return this.getDAOEntityNameFromType(Datastore.getTypeFromUrl(href, isCollection));
        }
        catch (error) {
            throw error;
        }
    }
    getDAOEntityName(clazz) {
        return this.getDAOEntityNameFromType(clazz.staticGetModuleName() + "$" + clazz.staticGetModelName());
    }
    getDAOEntityNameFromType(type) {
        if (!type) {
            throw Error(typeNotDynamicallyFoundError);
        }
        const [moduleName, className] = type.split("$");
        return moduleName + "_" + className;
    }
    getDAOClassFromType(type) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!type) {
                throw Error(typeNotDynamicallyFoundError);
            }
            const [moduleName, className] = type.split("$");
            const { default: daoClazz } = yield import("../" + moduleName.toLowerCase() + "/" + className + "DAO");
            return daoClazz;
        });
    }
    getClassFromType(type) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!type) {
                throw Error(typeNotDynamicallyFoundError);
            }
            const [moduleName, className] = type.split("$");
            const { default: clazz } = yield import("../" + moduleName.toLowerCase() + "/" + className);
            return clazz;
        });
    }
    saveStaticDataObj(id, object) {
        return __awaiter(this, void 0, void 0, function* () {
            const staticDataDAO = new StaticDataDAO();
            staticDataDAO.id = id;
            staticDataDAO.data = object;
            yield this._staticDataRep.save(staticDataDAO);
        });
    }
    removeStaticData(id) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._staticDataRep.delete({ id: id });
        });
    }
    getDAOFromObject(obj) {
        return __awaiter(this, void 0, void 0, function* () {
            let daoClazz;
            if (typeof obj["@type"] !== "undefined") {
                daoClazz = yield this.getDAOClassFromType(Datastore.getCleanTypeFromTypeString(obj["@type"]));
            }
            else if (typeof obj.moduleName !== "undefined" && typeof obj.modelName !== "undefined") {
                daoClazz = yield this.getDAOClassFromType(obj.moduleName + "$" + obj.modelName);
            }
            const dao = new daoClazz();
            if (typeof obj !== "object") {
                obj = JSON.parse(obj);
            }
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    dao[key] = obj[key];
                }
            }
            return dao;
        });
    }
    getObjFromDAO(dao) {
        const obj = {};
        for (const key in dao) {
            if (dao.hasOwnProperty(key)) {
                obj[key] = dao[key];
            }
        }
        return obj;
    }
    isReferenceHref(href) {
        const potentialHref = this.getPotentialHref(href);
        const id = this.getIdFromHref(href);
        const splits = potentialHref.split("/");
        let isReference = false;
        for (let i = splits.length - 1; i > 2; i--) {
            if ((splits[i].slice(0, 1).match(/[0-9]/) !== null) && splits[i].indexOf(".") === -1) {
                isReference = true;
                break;
            }
        }
        return isReference;
    }
    saveLastUpdateOfCollection(href) {
        return __awaiter(this, void 0, void 0, function* () {
            if (href) {
                const collInfo = new CollectionInfoDAO();
                collInfo.url = href;
                collInfo.lastUpdate = new Date().getTime();
                yield this._collectionInfoRep.save(collInfo);
            }
        });
    }
    checkModuleVersions() {
        return __awaiter(this, void 0, void 0, function* () {
            let clearStorage = false;
            const moduleInfoDAOs = yield this._moduleInfoRep.find();
            if (moduleInfoDAOs.length === 0) {
                clearStorage = true;
            }
            else {
                clearStorage = moduleInfoDAOs.some((infoDAO) => {
                    return SdkInfo.getModuleInfo().some((info) => info.name === infoDAO.moduleName && info.version !== infoDAO.version);
                });
            }
            if (clearStorage) {
                yield this.clearStorage(false);
            }
            yield this.refreshModuleInfo();
        });
    }
    refreshModuleInfo() {
        return __awaiter(this, void 0, void 0, function* () {
            for (const module of SdkInfo.getModuleInfo()) {
                const moduleInfoDAO = new ModuleInfoDAO();
                moduleInfoDAO.moduleName = module.name;
                moduleInfoDAO.version = module.version;
                yield this._moduleInfoRep.save(moduleInfoDAO);
            }
        });
    }
}
const typeNotDynamicallyFoundError = "Type of class cannot be dynamically found.";
