var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import AOMCacheStrategy from "./../AOMCacheStrategy";
import AOMRequestError from "./../AOMRequestError";
import Datastore from "./../Datastore";
import AOMStatus from "./../Status";
import AbstractClientDataModel from "./../AbstractClientDataModel";
export default class LearnProgress extends AbstractClientDataModel {
    static getLearnProgresss(query, withReferencedHrefs = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadListFromServerWithClass(LearnProgress, query, withReferencedHrefs, usePersistentStorage);
        });
    }
    static getLearnProgresssCount(query, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadCountFromServer(LearnProgress, undefined, query, usePersistentStorage);
        });
    }
    static staticGetModuleName() {
        return "MyLearningPlatform";
    }
    static staticGetModelName() {
        return "LearnProgress";
    }
    get customMetaForClass() {
        return "";
    }
    static removeAllFromStorage() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorageByClass(LearnProgress);
        });
    }
    static deleteAllFromStorage(query) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorage(query, LearnProgress);
        });
    }
    static getLearnProgresssFromPersistentStorage(whereWithPlaceholders = "", whereValues = [], orderBy = "", limit, offset) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadFromPersistentStorage(LearnProgress, whereWithPlaceholders, whereValues, orderBy, limit, offset);
        });
    }
    static getLearnProgresssCountFromPersistentStorage(whereWithPlaceholders = "", whereValues = []) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.getCountFromPersistentStorage(LearnProgress, whereWithPlaceholders, whereValues);
        });
    }
    constructor() {
        super();
        this.dao = new LearnProgressDAO();
    }
    get modelName() { return "LearnProgress"; }
    get moduleName() { return "MyLearningPlatform"; }
    get achievedScoreKeys() {
        return this.dao.achievedScoreKeys !== null ? this.dao.achievedScoreKeys : undefined;
    }
    set achievedScoreKeys(_achievedScoreKeys) {
        this.dao.achievedScoreKeys = _achievedScoreKeys;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get attemptsCount() {
        return this.dao.attemptsCount !== null ? this.dao.attemptsCount : undefined;
    }
    set attemptsCount(_attemptsCount) {
        this.dao.attemptsCount = _attemptsCount;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get certificateUrlResource() {
        let url;
        this.refreshEmbedded();
        url = this.dao.certificateURL !== null ? this.dao.certificateURL : undefined;
        if (url) {
            url += ".img";
            return url;
        }
        else {
            return undefined;
        }
    }
    get certificateURL() {
        let url = this.certificateUrlResource;
        if (url) {
            url += "?apiKey=" + User.AOMAPIKEY + "&system=" + User.AOMSYS;
        }
        return url;
    }
    getCertificateTokenURL(validity, oneTime) {
        return __awaiter(this, void 0, void 0, function* () {
            let url = this.dao.certificateURL;
            if (!url || typeof validity === "undefined" || typeof oneTime === "undefined") {
                return undefined;
            }
            url += ".key?apiKey=" + User.AOMAPIKEY + "&system=" + User.AOMSYS;
            return yield Datastore.Instance.getStaticDataTokenUrl(url, validity, oneTime);
        });
    }
    loadCertificate(usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            const resUrl = this.certificateURL;
            if (typeof resUrl === "undefined") {
                return Promise.reject(new AOMRequestError(AOMStatus.ATTACHED_HREF_MISSING, 200));
            }
            return yield Datastore.Instance.loadResource(resUrl, usePersistentStorage);
        });
    }
    postCertificate(_data, name = "", format = "", usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof _data === "undefined" || _data === null) {
                return Promise.reject(new AOMRequestError(AOMStatus.BAD_RESOURCE, 200));
            }
            if ((typeof this.href === "undefined" || this.href === "") && this.parent === null) {
                return Promise.reject(new AOMRequestError(AOMStatus.HREF_NOT_FOUND, 200));
            }
            let href;
            let response;
            const options = this.parent === null ? this.getOptionsForStaticData("certificate") : {};
            try {
                response = yield Datastore.Instance.postStaticDataOnServer(_data, name, format, false, usePersistentStorage, options);
                href = response.href;
            }
            catch (error) {
                return Promise.reject(error);
            }
            this.dao.certificateURL = href;
            if (this.href && Datastore.Instance.getCachingStrategy() !== AOMCacheStrategy.NETWORK_ONLY) {
                yield this.saveToStorage(usePersistentStorage);
            }
            if (this.parent !== null) {
                this.setDataToParentLocal(this);
                try {
                    yield this.save(false, usePersistentStorage);
                }
                catch (error) {
                    yield this.deleteCertificate(usePersistentStorage);
                }
            }
            if (typeof response !== "undefined" && response.offline === true) {
                yield this.save(false, usePersistentStorage);
            }
            try {
                yield this.load(undefined, usePersistentStorage);
            }
            catch (error) {
                return Promise.reject(error);
            }
        });
    }
    deleteCertificate(usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            const href = this.dao.certificateURL;
            if (href === null || href === undefined || href === "") {
                return Promise.reject(new AOMRequestError(AOMStatus.ATTACHED_HREF_MISSING, 200));
            }
            try {
                const response = yield Datastore.Instance.deleteOnServer(href, this, false, usePersistentStorage, true);
                this.dao.certificateURL = null;
                if (this.href && Datastore.Instance.getCachingStrategy() !== AOMCacheStrategy.NETWORK_ONLY) {
                    yield this.saveToStorage(usePersistentStorage);
                }
                if (typeof response !== "undefined" && response.offline === true) {
                    yield this.save(false, usePersistentStorage);
                }
                if (this.parent !== null) {
                    delete this.dao.certificateURL;
                    this.setDataToParentLocal(this);
                    yield this.save(false, usePersistentStorage);
                }
                yield this.load(undefined, usePersistentStorage);
            }
            catch (error) {
                return Promise.reject(error);
            }
        });
    }
    get disclaimerApproved() {
        return this.dao.disclaimerApproved !== null ? this.dao.disclaimerApproved : undefined;
    }
    set disclaimerApproved(_disclaimerApproved) {
        this.dao.disclaimerApproved = _disclaimerApproved;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get dueDate() {
        const retDate = this.dao.dueDate;
        return (typeof retDate !== "undefined") ? new Date(retDate) : undefined;
    }
    set dueDate(_dueDate) {
        if (_dueDate instanceof Date) {
            this.dao.dueDate = _dueDate.getTime();
        }
        else {
            this.dao.dueDate = undefined;
        }
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get lastAccess() {
        const retDate = this.dao.lastAccess;
        return (typeof retDate !== "undefined") ? new Date(retDate) : undefined;
    }
    set lastAccess(_lastAccess) {
        if (_lastAccess instanceof Date) {
            this.dao.lastAccess = _lastAccess.getTime();
        }
        else {
            this.dao.lastAccess = undefined;
        }
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get learnContentId() {
        return this.dao.learnContentId !== null ? this.dao.learnContentId : undefined;
    }
    set learnContentId(_learnContentId) {
        this.dao.learnContentId = _learnContentId;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get liveCount() {
        return this.dao.liveCount !== null ? this.dao.liveCount : undefined;
    }
    set liveCount(_liveCount) {
        this.dao.liveCount = _liveCount;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get mailSent() {
        return this.dao.mailSent !== null ? this.dao.mailSent : undefined;
    }
    set mailSent(_mailSent) {
        this.dao.mailSent = _mailSent;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get questionProgress() {
        return this.dao.questionProgress !== null ? this.dao.questionProgress : undefined;
    }
    set questionProgress(_questionProgress) {
        this.dao.questionProgress = _questionProgress;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get result() {
        return this.dao.result !== null ? this.dao.result : undefined;
    }
    set result(_result) {
        this.dao.result = _result;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get scormProgress() {
        return this.dao.scormProgress !== null ? this.dao.scormProgress : undefined;
    }
    set scormProgress(_scormProgress) {
        this.dao.scormProgress = _scormProgress;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get successDate() {
        const retDate = this.dao.successDate;
        return (typeof retDate !== "undefined") ? new Date(retDate) : undefined;
    }
    set successDate(_successDate) {
        if (_successDate instanceof Date) {
            this.dao.successDate = _successDate.getTime();
        }
        else {
            this.dao.successDate = undefined;
        }
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
}
import LearnProgressDAO from "./LearnProgressDAO";
import User from "./../basics/User";
