import LearnProgress from '../sdk/com/apiomat/frontend/mylearningplatform/LearnProgress';
import moment from 'moment';
import i18n from './i18n';
import LearnContent from '../sdk/com/apiomat/frontend/mylearningplatform/LearnContent';
import { LPScoreKey } from '../enums/LPScoreKey';
import { Moment } from 'moment';

export const getRestDays = (dueDate: Date): number => {
  /** to put the trainings without due date to the bottom of list */
  if (Boolean(dueDate) === false) {
    return 10000;
  }

  return moment(dueDate)
    .startOf('day')
    .diff(moment().startOf('day'), 'days');
};

export const dateDaysFromNow = (numberOfDays: number): Moment => {
  return moment().add(numberOfDays, 'days').startOf('day')
}

export const sortLearnContentsByRestDays = (a: LearnContent, b: LearnContent): number => {
  const firstSort = getRestDays(a?.dueDate) - getRestDays(b?.dueDate);
  const secondSort = a?.headlineKey === b?.headlineKey ? 0 : i18n.t(a?.headlineKey) < i18n.t(b?.headlineKey) ? -1 : 1;
  return firstSort * 10 + secondSort;
};

export const sortTrainingsByLastAccess = (a: LearnProgress, b: LearnProgress): number => {
  return b.lastAccess.getTime() - a.lastAccess.getTime();
};

export const sortTrainingsBySuccessDate = (a: LearnProgress, b: LearnProgress): number => {
  return b.successDate.getTime() - a.successDate.getTime();
};

export const getAvailableLanguages = (learnContent: LearnContent): string[] => {
  return learnContent?.localizedLearnContents?.map(el => el.language) || [];
};

export const getScore = (learnContent: LearnContent, key: LPScoreKey): number => {
  return Number(learnContent?.scores && learnContent.scores[key]) || 0;
}
