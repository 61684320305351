import { all, call, put, takeLatest } from 'redux-saga/effects';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import AppConfig from '../sdk/com/apiomat/frontend/mylearningplatform/AppConfig';
import { notificationActions } from './notification';
import { ApiRequestState } from '../models/api-request-state';

/** STATE */
export interface ConfigState {
  loading: ApiRequestState;
  appConfig: AppConfig;
}

const initialState: ConfigState = {
  loading: 'idle',
  appConfig: null,
};

/** SLICE */
const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    loadAppConfig: state => {
      state.loading = 'pending';
    },
    loadAppConfigSuccess: (state, action: PayloadAction<AppConfig>) => {
      state.appConfig = action.payload;
      state.loading = 'succeeded';
    },
    loadAppConfigFailure: state => {
      state.loading = 'failed';
      state.appConfig = null;
    },
  },
});

export const appConfigActions = appConfigSlice.actions;
export const appConfigReducer = appConfigSlice.reducer;

/** SAGAS */
function* onLoadAppConfig() {
  try {
    const query = 'limit 1';
    const appConfigs: AppConfig[] = yield call(() => AppConfig.getAppConfigs(query));

    if (appConfigs.length === 0) {
      throw new Error('Empty List');
    }
    const currentAppConfig = appConfigs[0];
    yield put(appConfigActions.loadAppConfigSuccess(currentAppConfig));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(appConfigActions.loadAppConfigFailure());
  }
}

export function* appConfigSaga() {
  yield all([takeLatest(appConfigActions.loadAppConfig, onLoadAppConfig)]);
}
