import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import BasicContainer from '../Container/BasicContainer';

import { Document, Page, pdfjs } from 'react-pdf';
import './PdfViewer.scss';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme: Theme) => ({
  pdfPage: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

export interface PdfViewerProps {
  src: string;
  isFullScreen: boolean;
}

const PdfViewer = (props: PdfViewerProps) => {
  const { src, isFullScreen } = props;

  const classes = useStyles();

  const [numPages, setNumPages] = useState<number>(0);

  const onLoadSuccess = (pdf: pdfjs.PDFDocumentProxy) => {
    setNumPages(pdf.numPages);
  };

  return (
    <BasicContainer maxWidth={isFullScreen ? 'xl' : 'md'}>
      <Document file={src} onLoadSuccess={onLoadSuccess} loading={<LoadingIndicator />}>
        {Array.from(new Array(numPages), (_el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} className={classes.pdfPage} renderTextLayer={false} scale={4} />
        ))}
      </Document>
    </BasicContainer>
  );
};

export default PdfViewer;
