var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export default class AOMOfflineHandler {
    constructor(config) {
        this.tasks = [];
        this.mapIdToHref = {};
        this.mapIdToAcdm = {};
        this.usedLocalIDs = [];
        this.embeddedLocalIDs = [];
        this.embeddedReplaceIDs = {};
        this._initialized = false;
        this.dbConfig = config;
    }
    get initialized() {
        return this._initialized;
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PersistentStorage.Instance.initNewConnection(this.dbConfig);
            this.tasks = yield PersistentStorage.Instance.getAllTaskInfos();
            this.mapIdToHref = yield PersistentStorage.Instance.getTaskIdHrefMap();
            this.usedLocalIDs = this.getUsedLocalIdsFromTasklist(this.tasks);
            this._initialized = true;
            this.sendTasks();
        });
    }
    createNewLocalId() {
        let trials = 0;
        let localID = Math.floor((Math.random() * 100000000) + 1);
        while (this.usedLocalIDs.indexOf(localID) !== -1 && trials < AOMOfflineHandler.createLocalIdMaxTrials + 1) {
            trials++;
            localID = Math.floor((Math.random() * 100000000) + 1);
        }
        if (trials >= AOMOfflineHandler.createLocalIdMaxTrials) {
            throw new Error(AOMStatus.getReasonPhrase(AOMStatus.TOO_MANY_LOCALIDS));
        }
        this.usedLocalIDs.push(localID);
        return localID.toString();
    }
    writeTaskInfoToStorage(task) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PersistentStorage.Instance.storeTaskInfo(task);
        });
    }
    writeHrefMapToStorageSingle(id, href) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PersistentStorage.Instance.storeIdToHref(id, href);
        });
    }
    clearStorage() {
        return __awaiter(this, void 0, void 0, function* () {
            this.tasks = [];
            this.mapIdToHref = {};
            this.usedLocalIDs = [];
            this.mapIdToAcdm = {};
            yield PersistentStorage.Instance.deleteAllTasks();
            yield PersistentStorage.Instance.deleteTaskIdHrefMap();
            AOMOfflineHandler.isSendTasksRunning = false;
        });
    }
    addTask(httpMethod, url, clazz, _args, isImage, isRef, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            const { isByteData = false, parentHref, dataModel, isStaticDataDelete, isRefCollection, } = _args;
            if (isByteData) {
                return yield this.addBinaryTask(httpMethod, url, _args, isImage, isRef, usePersistentStorage);
            }
            usePersistentStorage = Datastore.Instance.getUsePersistentStorageForClass(clazz, url, usePersistentStorage);
            let returnedUri = url;
            let localId = null;
            if (httpMethod === "POST") {
                localId = this.createNewLocalId();
                if (typeof parentHref !== "undefined" && url.substring(url.lastIndexOf("/") + 1).indexOf(parentHref) === -1) {
                    returnedUri += "/" + parentHref;
                }
                returnedUri += "/" + localId;
                this.mapIdToAcdm[localId] = dataModel;
                yield this.saveObjectToStorage(returnedUri, dataModel, true, isRef, isRefCollection, usePersistentStorage);
            }
            else if (httpMethod === "PUT") {
                const id = url.substring(url.lastIndexOf("/") + 1);
                this.mapIdToAcdm[id] = dataModel;
                yield this.saveObjectToStorage(returnedUri, dataModel, false, isRef, isRefCollection, usePersistentStorage);
            }
            else if (httpMethod === "DELETE") {
                const removeObjClazz = isStaticDataDelete ? undefined : clazz;
                if (isRef) {
                    const delUrl = url.substring(0, url.lastIndexOf("/"));
                    const id = url.substring(url.lastIndexOf("/") + 1);
                    yield StorageFactory.ChooseStorageImpl(usePersistentStorage, true).removeIdFromCollection(delUrl, id);
                }
                else {
                    yield StorageFactory.ChooseStorageImpl(usePersistentStorage, true).removeObjectByHref(url, removeObjClazz);
                }
            }
            const fileKey = this.generateFileKey(httpMethod, localId);
            yield PersistentStorage.Instance.storeTaskObject(fileKey, dataModel.toJson());
            const type = isStaticDataDelete === true ? null : dataModel.type;
            const info = new TaskInfoDAO({
                fileKey, httpMethod, url, type, localId,
                isImage: false, isRef, refName: parentHref, usePersistentStorage, isRefCollection,
            });
            this.tasks.push(info);
            yield this.writeTaskInfoToStorage(info);
            return {
                _httpMethod: httpMethod,
                returnedHref: returnedUri || undefined,
                responseText: returnedUri,
                offline: true,
            };
        });
    }
    addBinaryTask(httpMethod, url, _args, isImage, isRef, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data: content } = _args;
            if (content != null && content.byteLength <= Datastore.Instance.maxOfflineFileBytes) {
                let returnedUri = url;
                let localId = null;
                usePersistentStorage = Datastore.Instance.getUsePersistentStorageForClass(undefined, url, usePersistentStorage);
                if (httpMethod === "POST") {
                    localId = this.createNewLocalId();
                    if (returnedUri[returnedUri.length - 1] !== "/") {
                        returnedUri += "/";
                    }
                    returnedUri += localId;
                    yield StorageFactory.ChooseStorageImpl(usePersistentStorage, true).addOrUpdateCollection(returnedUri, content);
                }
                else if (httpMethod === "PUT") {
                    yield StorageFactory.ChooseStorageImpl(usePersistentStorage, true).addOrUpdateCollection(returnedUri, content);
                }
                try {
                    const fileKey = this.generateFileKey(httpMethod, localId);
                    if (httpMethod !== "DELETE") {
                        yield PersistentStorage.Instance.storeTaskBinary(fileKey, content);
                    }
                    const info = new TaskInfoDAO({
                        fileKey, httpMethod, url, localId, isImage,
                        isRef, usePersistentStorage,
                    });
                    this.tasks.push(info);
                    yield this.writeTaskInfoToStorage(info);
                }
                catch (e) {
                    console.log("Error occurred while adding offline task", e);
                }
                return {
                    _httpMethod: httpMethod,
                    returnedHref: returnedUri || undefined,
                    responseText: returnedUri,
                    offline: true,
                };
            }
            else {
                throw new Error(AOMStatus.getReasonPhrase(AOMStatus.MAX_FILE_SIZE_OFFLINE_EXCEEDED));
            }
        });
    }
    saveObjectToStorage(url, dataModel, injectHref, isRef = false, isRefCollection = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            const clazz = yield Datastore.getClassFromDataModel(dataModel);
            usePersistentStorage = Datastore.Instance.getUsePersistentStorageForClass(clazz, url, usePersistentStorage);
            const storage = StorageFactory.ChooseStorageImpl(usePersistentStorage, true);
            try {
                if (injectHref && isRef === false) {
                    this.setHrefToAcdm(url, dataModel);
                }
            }
            catch (e) {
                return false;
            }
            dataModel.offline = true;
            if (isRef) {
                const mappingHref = url.substring(0, url.lastIndexOf("/"));
                let dataToSave = dataModel.toJson();
                if (isRefCollection && dataToSave instanceof Array === false) {
                    dataToSave = [dataToSave];
                }
                if (isRefCollection === false) {
                    yield storage.removeCollection(mappingHref);
                }
                return yield storage.addOrUpdateCollection(mappingHref, dataToSave, true);
            }
            else {
                return yield storage.addOrUpdateCollection(url, dataModel.toJson());
            }
        });
    }
    generateFileKey(httpMethod, localId) {
        return httpMethod.toLocaleLowerCase() + "_" + new Date().getTime() +
            "_" + (localId != null ? localId : this.createNewLocalId());
    }
    sendTasks() {
        return __awaiter(this, void 0, void 0, function* () {
            if (AOMOfflineHandler.isSendTasksRunning === true) {
                return Promise.resolve();
            }
            AOMOfflineHandler.isSendTasksRunning = true;
            this.embeddedReplaceIDs = {};
            this.embeddedLocalIDs = [];
            while (AOMNetworkHandler.isConnected === true && this.tasks.length > 0) {
                const task = this.tasks[0];
                if (task == null) {
                    continue;
                }
                task.url = this.getPossibleNewHref(task);
                try {
                    const isStaticData = task.type === null;
                    let tmpModel = null;
                    let staticData = null;
                    if (isStaticData) {
                        if (task.httpMethod !== "DELETE") {
                            staticData = yield PersistentStorage.Instance.getStoredTaskBinary(task.fileKey);
                            if (staticData == null) {
                                throw new Error(AOMStatus.getReasonPhrase(AOMStatus.CANT_WRITE_IN_CACHE));
                            }
                            yield PersistentStorage.Instance.removeTaskBinary(task.fileKey);
                        }
                        yield this.sendStaticDataToServer(task, staticData);
                    }
                    else {
                        const json = yield PersistentStorage.Instance.getStoredModelFromTaskObject(task.fileKey);
                        if (json == null) {
                            throw new Error(AOMStatus.getReasonPhrase(AOMStatus.CANT_WRITE_IN_CACHE));
                        }
                        tmpModel = yield AbstractClientDataModel.fromJsonObject(json);
                        if (tmpModel) {
                            yield PersistentStorage.Instance.removeTaskObject(task.fileKey);
                            yield this.sendModelToServer(task, tmpModel);
                        }
                    }
                }
                catch (e) {
                    console.log("Can't update class instance: ", e);
                }
                finally {
                    const task2 = this.tasks.shift();
                    if (task2) {
                        delete this.mapIdToAcdm[task2.localId];
                    }
                    yield PersistentStorage.Instance.removeTaskFromTaskInfoList(task.fileKey);
                    if (task != null && task2 != null && task.fileKey != null && task2.fileKey != null && task2.fileKey !== task.fileKey) {
                        console.log("dequeued task was not equal to peeked task");
                    }
                }
            }
            AOMOfflineHandler.isSendTasksRunning = false;
        });
    }
    sendStaticDataToServer(task, data) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (task.httpMethod === "POST") {
                    const realDataUrl = this.getRealDataUrlForPost(task.url);
                    const response = yield Datastore.Instance.postStaticDataOnServerWithHref(data, "", "", realDataUrl, task.isImage, task.usePersistentStorage);
                    const href = response.href;
                    if (typeof href === "undefined" || href === "") {
                        throw new Error(AOMStatus.getReasonPhrase(AOMStatus.HREF_NOT_FOUND));
                    }
                    this.mapIdToHref[task.localId] = href;
                    yield this.writeHrefMapToStorageSingle(task.localId, href);
                    const realModel = yield this.updateRealModelAfterPost(task.localId, href, task.usePersistentStorage);
                    if (realModel != null) {
                        yield this.updateStorageAfterPost(task.localId, href, realModel, task.usePersistentStorage);
                    }
                }
                else if (task.httpMethod === "DELETE") {
                    yield Datastore.Instance.deleteOnServer(task.url, task.isRef, task.usePersistentStorage);
                }
            }
            catch (e) {
                console.log("Can't delete or save static data: ", e);
            }
        });
    }
    sendModelToServer(task, tmpModel) {
        return __awaiter(this, void 0, void 0, function* () {
            if (task.httpMethod === "POST") {
                yield this.sendModelToServerPOST(task, tmpModel);
            }
            else if (task.httpMethod === "PUT") {
                yield this.sendModelToServerPUT(task, tmpModel);
            }
            else if (task.httpMethod === "DELETE") {
                yield this.sendModelToServerDELETE(task, tmpModel);
            }
        });
    }
    sendModelToServerDELETE(task, tmpModel) {
        return __awaiter(this, void 0, void 0, function* () {
            let _href = this.getHref(tmpModel);
            if (task.isRef) {
                _href = task.url;
            }
            if (_href !== null && _href.length > 0) {
                try {
                    yield Datastore.Instance.deleteOnServer(_href, tmpModel, task.isRef, task.usePersistentStorage);
                }
                catch (e) {
                    console.log("Can't delete class instance: ", e);
                }
            }
        });
    }
    replaceEmbeddedProp(dao, replaceURL = true, parentID = "") {
        for (const key in dao) {
            if (dao[key] instanceof Array) {
                for (const key2 in dao[key]) {
                    if (dao[key].hasOwnProperty(key2) && typeof dao[key][key2] === "object"
                        && dao[key][key2] !== null && typeof dao[key][key2]["@type"] !== "undefined") {
                        this.replaceEmbeddedProp(dao[key][key2], replaceURL, parentID);
                    }
                }
            }
            else if (typeof dao[key] === "object" && dao[key] !== null && typeof dao[key]["@type"] !== "undefined") {
                this.replaceEmbeddedProp(dao[key], replaceURL, parentID);
            }
            else if (replaceURL && typeof dao[key] !== "undefined" && typeof dao[key] !== "object" && key.endsWith("URL")) {
                const realHref = this.getHrefForLocalHref(dao[key]);
                if (realHref !== null && typeof realHref !== "undefined") {
                    dao[key] = realHref;
                }
            }
            else if (replaceURL == false && typeof dao[key] !== "undefined" && key === "embeddedCollectionId"
                && typeof this.embeddedReplaceIDs[parentID] !== "undefined" && typeof dao.id === "undefined"
                && typeof this.embeddedReplaceIDs[parentID][dao[key]] !== "undefined") {
                dao.id = this.embeddedReplaceIDs[parentID][dao[key]];
                delete dao[key];
            }
        }
    }
    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }
    getAllEmbeddedIds(dao, retKey, ret = []) {
        for (const key in dao) {
            if (dao[key] instanceof Array) {
                for (const key2 in dao[key]) {
                    if (dao[key].hasOwnProperty(key2) && typeof dao[key][key2] === "object"
                        && dao[key][key2] !== null && typeof dao[key][key2]["@type"] !== "undefined") {
                        ret = ret.concat(this.getAllEmbeddedIds(dao[key][key2], retKey, ret)).filter(this.onlyUnique);
                        ;
                    }
                }
            }
            else if (typeof dao[key] === "object" && dao[key] !== null && typeof dao[key]["@type"] !== "undefined") {
                ret = ret.concat(this.getAllEmbeddedIds(dao[key], retKey, ret)).filter(this.onlyUnique);
            }
            else if (key === retKey) {
                ret = ret.concat([dao[key]]).filter(this.onlyUnique);
            }
        }
        return ret;
    }
    sendModelToServerPUT(task, tmpModel) {
        return __awaiter(this, void 0, void 0, function* () {
            tmpModel.offline = true;
            const href = this.getHref(tmpModel);
            if (href === null || href.length === 0) {
                throw new Error(AOMStatus.getReasonPhrase(AOMStatus.HREF_NOT_FOUND));
            }
            try {
                const cleanModel = yield this.cleanNullValuesFromDAO(tmpModel);
                let embeddedIds = [];
                let ids = [];
                const cleanHasEmbeddedObjects = cleanModel.hasEmbeddedObjects();
                if (typeof cleanModel !== "undefined" && cleanModel !== null && cleanHasEmbeddedObjects) {
                    const dao = Reflect.get(cleanModel, "dao");
                    if (Object.keys(this.mapIdToHref).length > 0) {
                        this.replaceEmbeddedProp(dao);
                    }
                    if (cleanModel.ID && typeof this.embeddedReplaceIDs[cleanModel.ID] === "undefined") {
                        this.embeddedReplaceIDs[cleanModel.ID] = {};
                    }
                    this.replaceEmbeddedProp(dao, false, cleanModel.ID);
                    ids = this.getAllEmbeddedIds(dao, "id");
                    embeddedIds = this.getAllEmbeddedIds(dao, "embeddedCollectionId");
                }
                yield Datastore.Instance.updateOnServer(href, cleanModel.toJsonString(true), task.UsePersistentStorage);
                let realId = tmpModel.ID ? this.mapIdToHref[tmpModel.ID] : undefined;
                if (typeof realId !== "undefined" && realId !== "") {
                    realId = realId.substring(realId.lastIndexOf("/") + 1);
                }
                else {
                    realId = tmpModel.ID;
                }
                const realModel = yield this.updateRealModelAfterPut(realId, tmpModel, href, task.usePersistentStorage);
                if (realModel) {
                    yield this.updateStorageAfterPUT(task, realModel);
                }
                if (typeof realModel !== "undefined" && realModel !== null && (cleanHasEmbeddedObjects || realModel.hasEmbeddedObjects())) {
                    const clazz = yield Datastore.getClassFromDataModel(realModel);
                    yield StorageFactory.ChooseStorageImpl(task.usePersistentStorage, true).removeObjectByHref(realModel.href, clazz);
                    yield realModel.load(undefined, task.usePersistentStorage);
                    const loadedIds = this.getAllEmbeddedIds(Reflect.get(realModel, "dao"), "id");
                    if (loadedIds.length > ids.length) {
                        const diff = AOMOfflineHandler.arrayDiff(loadedIds, ids);
                        const diffEmbedded = AOMOfflineHandler.arrayDiff(embeddedIds, this.embeddedLocalIDs);
                        if (diffEmbedded.length && diff.length && cleanModel.ID) {
                            this.embeddedReplaceIDs[cleanModel.ID][diffEmbedded[0]] = diff[0];
                        }
                    }
                }
            }
            catch (e) {
                console.log("Can't update class instance: ", e);
            }
        });
    }
    static arrayDiff(a1, a2) {
        return a1.filter((i) => a2.indexOf(i) < 0);
    }
    sendModelToServerPOST(task, tmpModel) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                let url = task.url;
                if (task.refName != null && task.refName.length > 0) {
                    const parentID = this.getParentIdFromRefUrl(url);
                    this.getHref(tmpModel);
                    let parentHref = this.mapIdToHref[parentID];
                    if (typeof parentHref === "undefined" || parentHref === "") {
                        parentHref = task.url;
                    }
                    if (parentHref.substring(parentHref.lastIndexOf("/") + 1).indexOf(task.refName) === -1) {
                        url = parentHref + "/" + task.refName;
                    }
                }
                if (url === null) {
                    throw new Error(AOMStatus.getReasonPhrase(AOMStatus.HREF_NOT_FOUND));
                }
                if (task.isRef === false) {
                    this.setHrefToAcdm(undefined, tmpModel);
                }
                else {
                    if (tmpModel.offline) {
                        if (tmpModel.ID) {
                            const realHref = this.mapIdToHref[tmpModel.ID];
                            const realId = realHref.substring(realHref.lastIndexOf("/") + 1);
                            tmpModel = this.mapIdToAcdm[realId];
                        }
                    }
                    else {
                        tmpModel = this.mapIdToAcdm[task.localId];
                    }
                }
                tmpModel = yield this.cleanNullValuesFromDAO(tmpModel);
                const { returnedHref: href } = yield Datastore.Instance.postOnServer(tmpModel, url, task.isRef, task.usePersistentStorage);
                if (typeof href === "undefined" || href.length === 0) {
                    throw new Error(AOMStatus.getReasonPhrase(AOMStatus.HREF_NOT_FOUND));
                }
                this.mapIdToHref[task.localId] = href;
                yield this.writeHrefMapToStorageSingle(task.localId, href);
                const realModel = yield this.updateRealModelAfterPost(task.localId, href, task.usePersistentStorage);
                if (realModel !== null) {
                    yield this.updateStorageAfterPost(task.localId, href, realModel, task.usePersistentStorage);
                    if (task.isRef) {
                        const storage = StorageFactory.ChooseStorageImpl(task.usePersistentStorage, true);
                        const acdmData = realModel.toJson();
                        const dataToSave = task.isRefCollection ? [acdmData] : acdmData;
                        yield storage.addOrUpdateCollection(task.url, dataToSave, true);
                    }
                }
                if (realModel) {
                    yield realModel.load(undefined, task.usePersistentStorage);
                }
            }
            catch (e) {
                console.log("Can't save class instance: ", e);
            }
        });
    }
    updateRealModelAfterPut(_realId, tmpModel, _href, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            const acdm = yield this.getModelFromRealModelMapOrStorage(_realId, _href, usePersistentStorage, false);
            if (acdm === null || typeof acdm === "undefined") {
                return null;
            }
            this.injectDataUrl(acdm, tmpModel);
            return acdm;
        });
    }
    updateStorageAfterPUT(task, acdm) {
        return __awaiter(this, void 0, void 0, function* () {
            const storage = StorageFactory.ChooseStorageImpl(task.usePersistentStorage, true);
            yield storage.addOrUpdateCollection(acdm.href, acdm.toJson());
        });
    }
    getModelFromRealModelMapOrStorage(id, _href, usePersistentStorage, removeFromMap = true) {
        return __awaiter(this, void 0, void 0, function* () {
            let model = null;
            if (this.mapIdToAcdm.hasOwnProperty(id) !== false) {
                model = this.mapIdToAcdm[id];
                if (removeFromMap) {
                    delete this.mapIdToAcdm[id];
                }
            }
            else {
                const storage = StorageFactory.ChooseStorageImpl(usePersistentStorage, true);
                try {
                    const tmpObj = yield storage.getObject(id, _href);
                    model = yield AbstractClientDataModel.fromJsonObject(tmpObj);
                }
                catch (error) {
                    model = null;
                }
            }
            return model;
        });
    }
    getHref(tmpModel) {
        let result = tmpModel.href;
        if (tmpModel.offline === false) {
            return result;
        }
        this.injectDataUrl(tmpModel);
        const id = tmpModel.ID;
        if (id === null || typeof id === "undefined" || id.length === 0) {
            console.log("No local ID found");
            return result;
        }
        const tempHref = this.mapIdToHref[id];
        if (typeof tempHref !== "undefined" && result.length > 0) {
            result = tempHref;
        }
        this.setHrefToAcdm(result, tmpModel);
        return result;
    }
    injectDataUrl(model, tmpModel) {
        try {
            const acdm = typeof tmpModel !== "undefined" ? tmpModel : model;
            const daoCopy = Reflect.get(acdm, "dao");
            for (const key in daoCopy) {
                if (typeof daoCopy[key] === "undefined" || key.endsWith("URL") === false) {
                    continue;
                }
                const realHref = this.getHrefForLocalHref(daoCopy[key]);
                if ((typeof tmpModel !== "undefined" && daoCopy[key] === null)) {
                    this.setPropertyInDaoOnAcdm(model, key, daoCopy[key]);
                }
                else if (realHref !== null && typeof realHref !== "undefined") {
                    this.setPropertyInDaoOnAcdm(model, key, realHref);
                }
            }
        }
        catch (e) {
            console.log("injecting data url failed: ", e);
        }
    }
    getHrefForLocalHref(_localHref) {
        if (_localHref === null) {
            return null;
        }
        const isUpdatedHref = Object.keys(this.mapIdToHref)
            .map((key) => this.mapIdToHref[key]).some((href) => href === _localHref);
        if (isUpdatedHref) {
            return _localHref;
        }
        const id = _localHref.substring(_localHref.lastIndexOf("/") + 1);
        return this.mapIdToHref[id];
    }
    getRealDataUrlForPost(url) {
        const cleanUrl = url.endsWith("/") ? url.substring(0, url.length - 1) : url;
        const urlPathSegments = cleanUrl.split("/");
        const localObjectId = urlPathSegments[urlPathSegments.length - 2];
        if (this.mapIdToHref.hasOwnProperty(localObjectId) === false) {
            return url;
        }
        let realHref = this.mapIdToHref[localObjectId];
        realHref = realHref.endsWith("/") ? realHref.substring(0, realHref.length - 1) : realHref;
        const realId = realHref.substring(realHref.lastIndexOf("/") + 1);
        urlPathSegments[urlPathSegments.length - 2] = realId;
        return urlPathSegments.join("/") + "/";
    }
    updateRealModelAfterPost(_localId, _href, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            const model = yield this.getModelFromRealModelMapOrStorage(_localId, _href, usePersistentStorage, true);
            if (model === null || model.offline === false) {
                return model;
            }
            const oldHref = model.href;
            this.setHrefToAcdm(_href, model);
            this.updateStaticDataUrls(model, oldHref, _href);
            model.offline = false;
            if (model.ID) {
                this.mapIdToAcdm[model.ID] = model;
            }
            return model;
        });
    }
    setHrefToAcdm(_href, model) {
        const daoCopy = Reflect.get(model, "dao");
        daoCopy.href = _href;
        daoCopy.id = undefined;
        Reflect.set(model, "dao", daoCopy);
    }
    updateStaticDataUrls(acdm, oldHref, newHref) {
        oldHref = oldHref.endsWith("/") ? oldHref.substring(0, oldHref.length - 1) : oldHref;
        const localObjectId = oldHref.substring(oldHref.lastIndexOf("/") + 1);
        const realObjectId = newHref.substring(newHref.lastIndexOf("/") + 1);
        const daoCopy = Reflect.get(acdm, "dao");
        for (const key in daoCopy) {
            if (daoCopy[key] !== null && typeof daoCopy[key] !== "undefined" && (key.endsWith("URL") === true
                || key.endsWith("Href") === true)) {
                daoCopy[key] = daoCopy[key].replace(localObjectId, realObjectId);
            }
        }
        Reflect.set(acdm, "dao", daoCopy);
    }
    updateStorageAfterPost(_localId, _newHref, acdm, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            const oldHref = _newHref.replace(_newHref.substring(_newHref.lastIndexOf("/") + 1), _localId);
            const storage = StorageFactory.ChooseStorageImpl(usePersistentStorage, true);
            const clazz = yield Datastore.getClassFromDataModel(acdm);
            let loadedObj = null;
            const id = acdm.ID ? acdm.ID : _newHref.substring(_newHref.lastIndexOf("/") + 1);
            const href = acdm.href ? acdm.href : _newHref;
            try {
                loadedObj = yield storage.getObject(id, href, clazz, false);
            }
            catch (error) {
                return;
            }
            yield storage.removeObjectByHref(oldHref, clazz);
            yield storage.addOrUpdateCollection(href, acdm.toJson());
        });
    }
    getUsedLocalIdsFromTasklist(list) {
        return list.map((task) => task.localId);
    }
    getParentIdFromRefUrl(url) {
        const urlWithoutRefName = url.substring(0, url.lastIndexOf("/"));
        const id = urlWithoutRefName.substring(urlWithoutRefName.lastIndexOf("/") + 1);
        return id;
    }
    getPossibleNewHref(task) {
        let url = task.url;
        if (task.isRef) {
            const possibleOldRefId = task.url.substring(task.url.lastIndexOf("/") + 1);
            const possibleNewRefHref = this.mapIdToHref[possibleOldRefId];
            if (typeof possibleNewRefHref !== "undefined") {
                const possibleNewRefId = possibleNewRefHref.substring(possibleNewRefHref.lastIndexOf("/") + 1);
                url = url.replace(possibleOldRefId, possibleNewRefId);
                const possibleOldParentId = this.getParentIdFromRefUrl(task.url.substring(0, task.url.lastIndexOf("/")));
                const possibleNewParentHref = this.mapIdToHref[possibleOldParentId];
                if (typeof possibleNewParentHref !== "undefined") {
                    const possibleNewParentId = possibleNewParentHref.substring(possibleNewParentHref.lastIndexOf("/") + 1);
                    url = url.replace(possibleOldParentId, possibleNewParentId);
                }
            }
        }
        else {
            const possibleOldId = task.url.substring(task.url.lastIndexOf("/") + 1);
            const possibleNewHref = this.mapIdToHref[possibleOldId];
            if (typeof possibleNewHref !== "undefined") {
                url = possibleNewHref;
            }
        }
        return url;
    }
    setPropertyInDaoOnAcdm(acdm, property, value) {
        const daoCopy = Reflect.get(acdm, "dao");
        daoCopy[property] = value;
        Reflect.set(acdm, "dao", daoCopy);
    }
    cleanNullValuesFromDAO(tmpModel) {
        return __awaiter(this, void 0, void 0, function* () {
            const cleanModel = yield AbstractClientDataModel.fromJsonObject(tmpModel.toJson());
            const dao = Reflect.get(cleanModel, "dao");
            for (const key in dao) {
                if (dao[key] === null) {
                    delete dao[key];
                }
            }
            Reflect.set(cleanModel, "dao", dao);
            return cleanModel;
        });
    }
}
AOMOfflineHandler.isSendTasksRunning = false;
AOMOfflineHandler.createLocalIdMaxTrials = 4;
import AbstractClientDataModel from "../AbstractClientDataModel";
import AOMNetworkHandler from "./AOMNetworkHandler";
import AOMStatus from "../Status";
import Datastore from "../Datastore";
import PersistentStorage from "./PersistentStorage";
import StorageFactory from "./StorageFactory";
import TaskInfoDAO from "./TaskInfoDAO";
