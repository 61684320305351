import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/index';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { maintenanceActions } from '../../store/maintenance';

const useStyles = makeStyles((theme: Theme) => ({
  info: {
    padding: 16,
    color: theme.palette.warning.main,
    backgroundColor: theme.palette.warning.light,
    borderRadius: 10,
  },
  text: {
    paddingLeft: 35,
    paddingBottom: 10,
  },
  button: { fontWeight: 'bold', color: theme.palette.warning.main, marginLeft: 28, padding: 6 },
  title: { display: 'flex', fontWeight: 'bold', paddingTop: 8, paddingBottom: 15 },
  icon: { marginRight: 10 },
}));

const MaintenanceNotifier = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { maintenance, showMaintenance } = useSelector((state: AppState) => state.maintenance);
  const { t } = useTranslation();

  const hideMaintenance = () => {
    dispatch(maintenanceActions.hideMaintenanceInfo());
  };

  const showMaintenanceInfo = () => {
    dispatch(maintenanceActions.showMaintenanceInfo());
  };

  const formatDate = (date: Date): string => {
    return moment(date).format('dddd DD. MMMM HH:mm');
  };

  return (
    <div className={classes.info}>
      <Typography variant="subtitle2" className={classes.title}>
        <InfoIcon className={classes.icon} />
        {t('maintenance.title')}
      </Typography>
      {showMaintenance ? (
        <>
          <Typography variant="body2" className={classes.text}>
            {maintenance['displayText'] && maintenance['displayText'].trim().length !== 0 ?
              maintenance['displayText'] : `${t('maintenance.interval', {
                startDate: formatDate(maintenance['startDate']),
                endDate: formatDate(maintenance['endDate']),
              })} ${t('maintenance.text')}`}</Typography>
          <Button className={classes.button} onClick={hideMaintenance}>
            {t('maintenance.hide-state')}
          </Button>
        </>
      ) : (
        <Button className={classes.button} onClick={showMaintenanceInfo}>
          {t('maintenance.show-state')}
        </Button>
      )}
    </div>
  );
};

export default MaintenanceNotifier;
