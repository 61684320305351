import { createCustomRestUrl } from './aom.utils';
import LPUser from '../sdk/com/apiomat/frontend/mylearningplatform/LPUser';
import LearnProgress from '../sdk/com/apiomat/frontend/mylearningplatform/LearnProgress';
import LPTeam from '../sdk/com/apiomat/frontend/mylearningplatform/LPTeam';
import axios from 'axios';
import Datastore from '../sdk/com/apiomat/frontend/Datastore';
import LearnContent from '../sdk/com/apiomat/frontend/mylearningplatform/LearnContent';

interface AssignContentRequestBody {
  contentId: string;
  teamId?: string;
  userIds?: string[];
  positions?: string[];
  dueDate?: number;
  updateExisting?: boolean;
}

export const getAssignedUsersCountForLearnContent = (team: LPTeam, learnContentId: string): number | null => {
  const countEntry = team.assignedLearnContents && team.assignedLearnContents[learnContentId];

  let count = null;
  if (countEntry) {
    try {
      count = Number(countEntry.split('_')[1]);
    } catch {}
  }

  return count;
};

export const assignUser = async (user: LPUser, learnContentId: string): Promise<void> => {
  const learnProgress = new LearnProgress();
  learnProgress.learnContentId = learnContentId;
  await user.postLearnings(learnProgress);
};

export const deassignUser = async (user: LPUser, learnContentId: string): Promise<void> => {
  const trainings: LearnProgress[] = user.learnings.filter(learning => learning.learnContentId === learnContentId);
  await Promise.all(trainings.map(el => user.removeLearnings(el)));
};

const getAssignContentCustomUrl = () => {
  return createCustomRestUrl('/assignContent');
};

const getTeamAssignmentCustomRestUrl = (teamId: string, learnContentId: string) => {
  return createCustomRestUrl(`/teamContent/${teamId}/${learnContentId}`);
};

export const fetchAllPositionsForLearnContentId = async (learnContentId: string): Promise<string[]> => {
  const url = createCustomRestUrl(`/positions/${learnContentId}`);

  const response = await axios.get(url, { headers: Datastore.Instance.getHeader(false, {}) });

  return response.data.positions;
};

export const fetchAllPositions = async (): Promise<string[]> => {
  const url = createCustomRestUrl(`/positions`);

  const response = await axios.get(url, { headers: Datastore.Instance.getHeader(false, {}) });

  return response.data.positions;  
}

export const bulkAssignUsers = async (learnContentId: string, userIds: string[], dueDate?: Date, updateExisting?: boolean) => {
  const body: AssignContentRequestBody = {
    contentId: learnContentId,
    userIds,
    dueDate: dueDate && dueDate.getTime(),
    updateExisting,
  };

  return await axios.post(getAssignContentCustomUrl(), body, { headers: Datastore.Instance.getHeader(false, {}) });
};

export const assignTeam = async (teamId: string, learnContentId: string, dueDate?: Date, updateExisting?: boolean): Promise<void> => {
  const body: AssignContentRequestBody = {
    contentId: learnContentId,
    teamId,
    dueDate: dueDate && dueDate.getTime(),
    updateExisting,
  };

  return await axios.post(getAssignContentCustomUrl(), body, { headers: Datastore.Instance.getHeader(false, {}) });
};

export const deassignTeam = async (teamId: string, learnContentId: string): Promise<void> => {
  return await axios.delete(getTeamAssignmentCustomRestUrl(teamId, learnContentId), { headers: Datastore.Instance.getHeader(false, {}) });
};

const getAssignPositionsCustomUrl = () => {
  return createCustomRestUrl(`/assignPositions`);
};

const getDeassignPositionCustomUrl = (position: string, learnContentId: string) => {
  return createCustomRestUrl(`/positionContent/${position}/${learnContentId}`);
};

export const assignPositions = async (learnContentId: string, positions: string[], dueDate?: Date, updateExisting?: boolean) => {
  const body: AssignContentRequestBody = {
    contentId: learnContentId,
    positions,
    dueDate: dueDate && dueDate.getTime(),
    updateExisting,
  };

  return await axios.post(getAssignPositionsCustomUrl(), body, { headers: Datastore.Instance.getHeader(false, {}) });
};

export const deassignPosition = async (position: string, learnContentId: string): Promise<void> => {
  return await axios.delete(getDeassignPositionCustomUrl(position, learnContentId), { headers: Datastore.Instance.getHeader(false, {}) });
};

export const updateLearnContent = async (learnContentId: string, assignmentType: string, newLearnContent: LearnContent) => {
  const url = createCustomRestUrl(`/editLearnContent/${learnContentId}?status=${assignmentType}`);

  const body = newLearnContent.toJson();

  return await axios.post(url, body, { headers: Datastore.Instance.getHeader(false, {}) });
};

export const updateNewLearnContentCount = async (oldLearnContentId: string, newLearnContentId: string) => {
  const url = createCustomRestUrl(`/team/learnings/update/count/${oldLearnContentId}/${newLearnContentId}`);
  
  return await axios.put(url, null, { headers: Datastore.Instance.getHeader(false, {}) });
};
