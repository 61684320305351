import React, { useState } from 'react';
import { Typography, Dialog, DialogTitle, Divider, Button, DialogContent, DialogActions, FormControlLabel } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useTranslation } from 'react-i18next';
import { IOSSwitch } from '../Switch/IOSSwitch';
import { useStylesBase } from './BaseDailogStyles';

export interface ApproveSuccessTrainingProps {
  open: boolean;
  onApprove: (isDownloadChecked: boolean) => void;
}

const ApproveSuccessTraining = (props: ApproveSuccessTrainingProps) => {
  const { open, onApprove } = props;

  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [isDownloadChecked, setIsDownloadChecked] = useState<boolean>(false);

  const classes = useStylesBase();
  const { t } = useTranslation();

  return (
    <Dialog maxWidth="xs" open={open}>
      <DialogTitle className={classes.dialogContent}>
        <Typography component="span" variant="h5">
          <CheckCircleIcon className={classes.dialogIcon} />
          {t('training.success.title')}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <Typography component="p" variant="body2">
          {t('training.success.description')}
        </Typography>
        <Divider className={classes.dialogDivider} />
        <FormControlLabel
          className={classes.dialogSwitchControl}
          control={<IOSSwitch checked={isApproved} onChange={() => setIsApproved(!isApproved)} />}
          label={
            <Typography component="label" variant="body2">
              {t('training.success.approve')}
            </Typography>
          }
          labelPlacement="start"
        />
        <Divider className={classes.dialogDivider} />
        <FormControlLabel
          className={classes.dialogSwitchControl}
          control={<IOSSwitch checked={isDownloadChecked} onChange={() => setIsDownloadChecked(!isDownloadChecked)} />}
          label={
            <Typography component="label" variant="body2">
              {t('training.success.download-certificate')}
            </Typography>
          }
          labelPlacement="start"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.dialogSubmit}
          disabled={!isApproved}
          onClick={() => onApprove(isDownloadChecked)}
        >
          {t('training.success.done')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveSuccessTraining;
