var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Datastore from "./../Datastore";
import AbstractClientDataModel from "./../AbstractClientDataModel";
export default class AppConfig extends AbstractClientDataModel {
    static getAppConfigs(query, withReferencedHrefs = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadListFromServerWithClass(AppConfig, query, withReferencedHrefs, usePersistentStorage);
        });
    }
    static getAppConfigsCount(query, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadCountFromServer(AppConfig, undefined, query, usePersistentStorage);
        });
    }
    static staticGetModuleName() {
        return "MyLearningPlatform";
    }
    static staticGetModelName() {
        return "AppConfig";
    }
    get customMetaForClass() {
        return "";
    }
    static removeAllFromStorage() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorageByClass(AppConfig);
        });
    }
    static deleteAllFromStorage(query) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorage(query, AppConfig);
        });
    }
    static getAppConfigsFromPersistentStorage(whereWithPlaceholders = "", whereValues = [], orderBy = "", limit, offset) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadFromPersistentStorage(AppConfig, whereWithPlaceholders, whereValues, orderBy, limit, offset);
        });
    }
    static getAppConfigsCountFromPersistentStorage(whereWithPlaceholders = "", whereValues = []) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.getCountFromPersistentStorage(AppConfig, whereWithPlaceholders, whereValues);
        });
    }
    constructor() {
        super();
        this.dao = new AppConfigDAO();
    }
    get modelName() { return "AppConfig"; }
    get moduleName() { return "MyLearningPlatform"; }
    get maximumLoginAttempts() {
        return this.dao.maximumLoginAttempts !== null ? this.dao.maximumLoginAttempts : undefined;
    }
    set maximumLoginAttempts(_maximumLoginAttempts) {
        this.dao.maximumLoginAttempts = _maximumLoginAttempts;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get minutesUntilLogout() {
        return this.dao.minutesUntilLogout !== null ? this.dao.minutesUntilLogout : undefined;
    }
    set minutesUntilLogout(_minutesUntilLogout) {
        this.dao.minutesUntilLogout = _minutesUntilLogout;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
}
import AppConfigDAO from "./AppConfigDAO";
