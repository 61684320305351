import React from 'react';
import { makeStyles, Theme, Grid, TextField, InputAdornment } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TranslatedString } from '../../../store/learnContents';

const useStyles = makeStyles((theme: Theme) => ({
  languageImage: {
    width: 24,
    height: 24,
    borderRadius: '50%',
  },
}));

export interface TranslatedStringFormProps {
  langs: string[];
  placeholderKey: string;
  translatedString: TranslatedString;
  setTranslatedString: (translatedString: TranslatedString) => void;
  maxLength?: number;
}

const TranslatedStringForm = (props: TranslatedStringFormProps) => {
  const { langs, placeholderKey, translatedString, setTranslatedString, maxLength } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container direction="column" spacing={2}>
      {langs.map(lang => (
        <Grid item key={lang}>
          <TextField
            variant="outlined"
            fullWidth
            value={translatedString[lang]}
            onChange={event => setTranslatedString({ ...translatedString, [lang]: event.target.value })}
            label={t(placeholderKey, { lng: lang })}
            helperText={maxLength && `${translatedString[lang] ? translatedString[lang].length : 0}/${maxLength}`}
            inputProps={maxLength && { maxLength }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    className={classes.languageImage}
                    src={require(`../../../assets/img/langs/${lang}.svg`)}
                    alt={t(`languages.${lang}`)}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default TranslatedStringForm;
