import React from 'react';
import { Typography, makeStyles, Theme, Paper, Grid, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LearnContentData } from '../../../store/learnContents';
import { LearnContentTypes } from '../../../enums/LearnContentTypes';
import TranslatedFileForm from './TranslatedFileForm';
import SelectionButton from '../../Buttons/SelectionButton';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(3, -3),
  },
}));

export interface UploadLearningMaterialsStepProps {
  learnContent: LearnContentData;
  setLearnContent: (learnContent: LearnContentData) => void;
}

const UploadLearningMaterialsStep = (props: UploadLearningMaterialsStepProps) => {
  const { learnContent, setLearnContent } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper elevation={0} className={classes.paper}>
      <Typography component="h2" variant="subtitle2" color="primary">
        {t('admin.create-learn-content.upload-learning-materials.content-type-title')}
      </Typography>
      <Typography component="p" variant="body1" className={classes.description}>
        {t('admin.create-learn-content.upload-learning-materials.content-type-description')}
      </Typography>
      <Grid container spacing={2} justify="center">
        <Grid item>
          <SelectionButton
            label={t('admin.create-learn-content.upload-learning-materials.content-type-pdf')}
            selected={learnContent.learnContentType === LearnContentTypes.pdf}
            onSelect={() => setLearnContent({ ...learnContent, learnContentType: LearnContentTypes.pdf, files: {} })}
          />
        </Grid>
        <Grid item>
          <SelectionButton
            label={t('admin.create-learn-content.upload-learning-materials.content-type-scorm')}
            selected={learnContent.learnContentType === LearnContentTypes.scorm}
            onSelect={() => setLearnContent({ ...learnContent, learnContentType: LearnContentTypes.scorm, files: {} })}
          />
        </Grid>
      </Grid>
      {Boolean(learnContent.learnContentType) && (
        <>
          <Divider className={classes.divider} />
          <Typography component="h2" variant="subtitle2" color="primary">
            {t('admin.create-learn-content.upload-learning-materials.upload-title')}
          </Typography>
          <Typography component="p" variant="body1" className={classes.description}>
            {t(`admin.create-learn-content.upload-learning-materials.upload-description-${learnContent.learnContentType.toLowerCase()}`)}
          </Typography>
          <TranslatedFileForm
            langs={learnContent.langs || ['de', 'pl']}
            fileTypesAccept={learnContent.learnContentType === LearnContentTypes.scorm ? '.zip' : '.pdf'}
            placeholderKey={`admin.create-learn-content.upload-learning-materials.upload-placeholder-${learnContent.learnContentType}`}
            translatedFile={learnContent.files || {}}
            setTranslatedFile={files => setLearnContent({ ...learnContent, files })}
          />
        </>
      )}
    </Paper>
  );
};

export default UploadLearningMaterialsStep;
