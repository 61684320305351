import React from 'react';
import { makeStyles, ListItem, Theme, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import LearnContent from '../../sdk/com/apiomat/frontend/mylearningplatform/LearnContent';
import LearnContentAvatar from './LearnContentAvatar';
import LearnContentTitle from './LearnContentTitle';
import LearnContentInfo from './LearnContentInfo';
import AppLogoIcon from '../../assets/img/app_logo_icon.svg';
import { LearnContentTypes } from '../../enums/LearnContentTypes';
import { getFileLink } from '../../utils/file.utils';

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    padding: theme.spacing(2),
  },
}));

export interface LearnContentSearchItemProps {
  item: LearnContent;
  onItemClick?: (item: LearnContent) => void;
}

const LearnContentSearchItem = (props: LearnContentSearchItemProps) => {
  const { item } = props;

  const classes = useStyles();
  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ListItem divider={true} className={classes.itemWrapper} component="div">
      <Grid container spacing={2} direction="row" wrap="nowrap" alignItems={isSmall ? 'flex-start' : 'center'}>
        <Grid item container direction="row" wrap="nowrap" xs alignItems="flex-start">
          <Grid item>
            <LearnContentAvatar
              thumbnailURL={item?.thumbnailURL ? getFileLink(item, 'thumbnail') : AppLogoIcon}
              defaultThumbnailUrl={AppLogoIcon}
              learnContentType={item.learnContentType as LearnContentTypes}
              dueDate={item.dueDate}
            />
          </Grid>
          <Grid item xs>
            <LearnContentTitle item={item} />
          </Grid>
        </Grid>
        <Grid item xs={3} md={2}>
          <LearnContentInfo item={item} />
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default LearnContentSearchItem;
