import React, { ChangeEvent } from 'react';
import { Typography, makeStyles, Theme, Paper, Grid, IconButton, Avatar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PublishIcon from '@material-ui/icons/Publish';
import { LearnContentData, FileData } from '../../../store/learnContents';
import TranslatedStringForm from './TranslatedStringForm';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  uploadButton: {
    width: 100,
    height: 100,
    borderRadius: 20,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.main,
  },
  uploadButtonImage: {
    position: 'absolute',
    width: 100,
    height: 100,
    borderRadius: 20,
    opacity: 0.5,
  },
}));

export interface TitleDescriptionThumbnailStepProps {
  learnContent: LearnContentData;
  setLearnContent: (learnContent: LearnContentData) => void;
}

const TitleDescriptionThumbnailStep = (props: TitleDescriptionThumbnailStepProps) => {
  const { learnContent, setLearnContent } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const reader = new FileReader();

  const uploadThumbnail = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (event.target.files !== undefined) {
      reader.onloadend = () => {
        if (event.target.files !== undefined) {
          const thumbnail = {
            name: event.target.files[0].name,
            data: reader.result,
            imageUrl: URL.createObjectURL(event.target.files[0]),
          } as FileData;
          setLearnContent({ ...learnContent, thumbnail });
        }
      };
      if (event.target.files.length !== 0) {
        reader.readAsArrayBuffer(event.target.files[0]);
      }
    }
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Paper elevation={0} className={classes.paper}>
          <Typography component="h2" variant="subtitle2" color="primary">
            {t('admin.create-learn-content.title-description-thumbnail.add-headline')}
          </Typography>
          <Typography component="p" variant="body1" className={classes.description}>
            {t('admin.create-learn-content.title-description-thumbnail.add-headline-hint')}
          </Typography>
          <TranslatedStringForm
            langs={learnContent.langs}
            placeholderKey="admin.create-learn-content.title-description-thumbnail.add-headline-placeholder"
            translatedString={learnContent.header || {}}
            setTranslatedString={header => setLearnContent({ ...learnContent, header })}
            maxLength={100}
          />
        </Paper>
      </Grid>

      <Grid item>
        <Paper elevation={0} className={classes.paper}>
          <Typography component="h2" variant="subtitle2" color="primary">
            {t('admin.create-learn-content.title-description-thumbnail.add-description')}
          </Typography>
          <Typography component="p" variant="body1" className={classes.description}>
            {t('admin.create-learn-content.title-description-thumbnail.add-description-hint')}
          </Typography>
          <TranslatedStringForm
            langs={learnContent.langs}
            placeholderKey="admin.create-learn-content.title-description-thumbnail.add-description-placeholder"
            translatedString={learnContent.description || {}}
            setTranslatedString={description => setLearnContent({ ...learnContent, description })}
          />
        </Paper>
      </Grid>

      <Grid item>
        <Paper elevation={0} className={classes.paper}>
          <Typography component="h2" variant="subtitle2" color="primary">
            {t('admin.create-learn-content.title-description-thumbnail.add-thumbnail')}
          </Typography>
          <Typography component="p" variant="body1" className={classes.description}>
            {t('admin.create-learn-content.title-description-thumbnail.add-thumbnail-hint')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              {learnContent.thumbnail?.imageUrl && <Avatar src={learnContent.thumbnail.imageUrl} className={classes.uploadButtonImage} />}
              <input accept=".png, .jpg, .jpeg" style={{ display: 'none' }} id="icon-button-file" type="file" onChange={uploadThumbnail} />
              <label htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload thumbnail" component="span" className={classes.uploadButton}>
                  <PublishIcon />
                </IconButton>
              </label>
            </Grid>
            <Grid item>
              <Typography component="p" variant="body1">
                {t('admin.create-learn-content.title-description-thumbnail.thumbnail-formats')}
              </Typography>
              <Typography component="p" variant="body1">
                {t('admin.create-learn-content.title-description-thumbnail.thumbnail-sizes')}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TitleDescriptionThumbnailStep;
