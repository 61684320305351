import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DfhLogo from '../../assets/img/dfh_logo.svg';

const useStyles = makeStyles(theme => ({
  dfhLogo: {
    marginBottom: theme.spacing(0),
    width: 110,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
    },
  },
  button: {
    padding: 4,
    fontWeight: 'bold',
    fontSize: 'smaller'
  }
}));


const Imprint = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const url = 'https://dfh-gruppe.de/kontakt/impressum/';

  return (
    <>
      <img src={DfhLogo} className={classes.dfhLogo} alt="DFH Logo" />
      <Button variant="text"
              color="primary"
              className={classes.button}
              onClick={() => window.open(url, '_blank', 'noopener noreferrer')}>
        {t('login.imprint')}
      </Button>
    </>
  );
};

export default Imprint;
