import React, { useState } from 'react';
import {
  makeStyles,
  Theme,
  Typography,
  Paper,
  TextField,
  Grid,
  Button,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store';
import { useTranslation } from 'react-i18next';
import AppHeader from '../components/AppHeader/AppHeader';
import { languages } from '../utils/i18n';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { authActions } from '../store/auth';
import { getPostion } from '../utils/users.utils';
import BasicContainer from '../components/Container/BasicContainer';
import BottomAppBar from '../components/AppHeader/BottomAppBar';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  label: {
    textTransform: 'uppercase',
    marginTop: theme.spacing(2),
  },
  emailDescription: {
    marginTop: theme.spacing(1),
  },
  languageDescription: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  languageButton: {
    padding: theme.spacing(1.5, 1.5, 0.5),
    borderRadius: 10,
  },
  languageImage: {
    width: 24,
    height: 24,
    borderRadius: '50%',
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const { user } = useSelector((state: AppState) => state.auth);

  const [lang, setLang] = useState<string>(user?.defaultLanguage);

  const onUpdateLang = (newLang: string) => {
    setLang(newLang);
    i18n.changeLanguage(newLang);
    user.defaultLanguage = newLang;
    dispatch(authActions.updateUser(user));
  };

  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <AppHeader tab="profile" />
      <BasicContainer>
        <Paper elevation={0} className={classes.paper}>
          <Grid container spacing={isSmall ? 2 : 4}>
            <Grid item xs={12} sm={6}>
              <Typography component="label" variant="subtitle2" color="primary" className={classes.label}>
                {t('profile.username')}
              </Typography>
              <TextField name="userName" value={user?.userName} variant="outlined" margin="normal" fullWidth disabled></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography component="label" variant="subtitle2" color="primary" className={classes.label}>
                {t('profile.position')}
              </Typography>
              <TextField name="position" value={getPostion(user)} variant="outlined" margin="normal" fullWidth disabled></TextField>
            </Grid>
          </Grid>

          {user?.userMail && (
            <>
              <Typography component="p" variant="subtitle2" color="primary" className={classes.label}>
                {t('profile.email')}
              </Typography>
              <Typography component="p" variant="body1" className={classes.emailDescription}>
                {t('profile.email-description')}
              </Typography>
              <Grid container spacing={isSmall ? 0 : 4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    data-testid="email"
                    name="email"
                    value={user?.userMail || 'email'}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CheckCircleIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                </Grid>
              </Grid>
            </>
          )}

          <Typography component="p" variant="subtitle2" color="primary" className={classes.label}>
            {t('profile.language-settings')}
          </Typography>
          <Typography component="p" variant="body1" className={classes.languageDescription}>
            {t('profile.language-settings-description')}
          </Typography>
          <Grid container spacing={1}>
            {languages.map(el => (
              <Grid item xs={12} sm={6} md={3} key={el}>
                <Button
                  className={classes.languageButton}
                  variant="outlined"
                  color={lang === el ? 'primary' : 'default'}
                  fullWidth
                  onClick={() => onUpdateLang(el)}
                >
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item xs>
                      <Grid container spacing={1}>
                        <Grid item>
                          <img
                            className={classes.languageImage}
                            src={require(`../assets/img/langs/${el}.svg`)}
                            alt={t(`languages.${el}`)}
                          />
                        </Grid>
                        <Grid item>
                          <Typography component="span" color={lang === el ? 'primary' : 'initial'} variant="subtitle2">
                            {t(`languages.${el}`)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>{lang === el && <CheckCircleIcon color="primary" />}</Grid>
                  </Grid>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </BasicContainer>
      <BottomAppBar tab="profile" />
    </>
  );
};
