var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export default class AbstractClientDataModel {
    constructor() {
        this.hashmap = {};
        this.ObjectState = {
            TRANSIENT: "TRANSIENT",
            DELETING: "DELETING",
            DELETED: "DELETED",
            PERSISTING: "PERSISTING",
            PERSISTED: "PERSISTED",
            LOCAL_PERSISTED: "LOCAL_PERSISTED",
            LOCAL_DELETED: "LOCAL_DELETED",
        };
        this.parent = null;
        this.currentState = this.ObjectState.LOCAL_PERSISTED;
    }
    hasEmbeddedObjects() {
        if (this !== null) {
            for (const key of Object.keys(this.dao)) {
                if (this[key] && this[key].parent !== null && typeof this[key].parent !== "undefined") {
                    return true;
                }
                if (this[key] instanceof Array) {
                    for (const i in this[key]) {
                        if (this[key][i] && this[key][i].parent !== null && typeof this[key][i].parent !== "undefined") {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }
    get parentAttrKey() {
        if (typeof this._parentAttrKey === "undefined") {
            return this._parentAttrKey;
        }
        else {
            const ret = this.getDataOrKeyFromParent(true);
            if (isNaN(ret)) {
                return this._parentAttrKey;
            }
            else {
                return ret;
            }
        }
    }
    set parentAttrKey(key) {
        this._parentAttrKey = key;
    }
    get currentState() {
        return this._currentState;
    }
    set currentState(currentState) {
        this._currentState = currentState;
    }
    get offline() {
        return this._offline;
    }
    set offline(offline) {
        this._offline = offline;
    }
    get href() {
        return this.dao.href;
    }
    get ID() {
        this.refreshEmbedded(true);
        if (typeof this.dao.id !== "undefined") {
            return this.dao.id;
        }
        else if (typeof this.href === "undefined") {
            return undefined;
        }
        const id = this.href.substring(this.href.lastIndexOf("/") + 1);
        this.dao.id = id;
        return id;
    }
    get type() {
        return this.moduleName + "$" + SdkInfo.getVersion(this.moduleName) + "$" + this.modelName;
    }
    get referencedHrefs() {
        return typeof this.dao.referencedHrefs !== "undefined" ? this.dao.referencedHrefs : {};
    }
    get allowedRolesGrant() {
        return typeof this.dao.allowedRolesGrant !== "undefined" ? this.dao.allowedRolesGrant : [];
    }
    set allowedRolesGrant(allowedRolesGrant) {
        this.dao.allowedRolesGrant = allowedRolesGrant;
    }
    get allowedRolesWrite() {
        return typeof this.dao.allowedRolesWrite !== "undefined" ? this.dao.allowedRolesWrite : [];
    }
    set allowedRolesWrite(allowedRolesWrite) {
        this.dao.allowedRolesWrite = allowedRolesWrite;
    }
    get allowedRolesRead() {
        return typeof this.dao.allowedRolesRead !== "undefined" ? this.dao.allowedRolesRead : [];
    }
    set allowedRolesRead(allowedRolesRead) {
        this.dao.allowedRolesRead = allowedRolesRead;
    }
    get foreignId() {
        return this.dao.foreignId;
    }
    set foreignId(foreignId) {
        this.dao.foreignId = foreignId;
    }
    get restrictResourceAccess() {
        return this.dao.restrictResourceAccess;
    }
    set restrictResourceAccess(restrictResourceAccess) {
        this.dao.restrictResourceAccess = restrictResourceAccess;
    }
    get createdAt() {
        const retDate = this.dao.createdAt;
        return (typeof retDate !== "undefined") ? new Date(retDate) : undefined;
    }
    get lastModifiedAt() {
        const retDate = this.dao.lastModifiedAt;
        return (typeof retDate !== "undefined") ? new Date(retDate) : undefined;
    }
    get applicationName() {
        return this.dao.applicationName;
    }
    refreshEmbedded(checkID = false) {
        if (typeof this.dao.id === "undefined" && this.parent !== null) {
            let p = this.parent;
            while (p.parent !== null) {
                p = p.parent;
            }
            if (this.currentState === this.ObjectState.LOCAL_PERSISTED
                && p.currentState == p.ObjectState.PERSISTED) {
                if (checkID && typeof this.dao.embeddedCollectionId !== "undefined" && Datastore.Instance.offlineHandlerExists()
                    && typeof Datastore.Instance.OfflineHandler.embeddedReplaceIDs[p.ID] !== "undefined"
                    && typeof Datastore.Instance.OfflineHandler.embeddedReplaceIDs[p.ID][this.dao.embeddedCollectionId] !== "undefined") {
                    this.dao.id = Datastore.Instance.OfflineHandler.embeddedReplaceIDs[p.ID][this.dao.embeddedCollectionId];
                }
                const data = this.getDataOrKeyFromParent();
                if (typeof data === "object" && data !== null && typeof data.id !== "undefined") {
                    this.fromJson(data);
                }
            }
        }
    }
    static fromJsonObject(json) {
        return __awaiter(this, void 0, void 0, function* () {
            const clazz = yield Datastore.Instance.getClassFromJSON(json);
            const tmp = new clazz();
            tmp.fromJson(json);
            return tmp;
        });
    }
    toJson(cleanEmbeddedCollectionIds) {
        if (this.href) {
            this.dao.id = this.ID;
        }
        this.dao["@type"] = this.type;
        try {
            delete this.dao.allowedRolesReadFromClass;
            delete this.dao.allowedRolesWriteFromClass;
            delete this.dao.allowedRolesCreateFromClass;
            delete this.dao.allowedRolesGrantFromClass;
            delete this.dao.restrictResourceAccessFromClass;
            delete this.dao.allowedRolesReadFromObject;
            delete this.dao.allowedRolesWriteFromObject;
            delete this.dao.allowedRolesGrantFromObject;
            delete this.dao.restrictResourceAccessFromObject;
            delete this.dao.modelName;
        }
        catch (error) {
        }
        if (cleanEmbeddedCollectionIds) {
            const clone = JSON.parse(JSON.stringify(this.dao));
            return AbstractClientDataModel.cleanEmbeddedCollectionIds(clone);
        }
        else {
            const obj = {};
            for (const key in this.dao) {
                if (this.dao.hasOwnProperty(key)) {
                    obj[key] = this.dao[key];
                }
            }
            return obj;
        }
    }
    toJsonString(cleanEmbeddedCollectionIds) {
        return JSON.stringify(this.toJson(cleanEmbeddedCollectionIds));
    }
    fromJson(json) {
        for (const key in this.dao) {
            if (this.dao.hasOwnProperty(key)) {
                this.dao[key] = undefined;
            }
        }
        if (typeof json !== "object") {
            json = JSON.parse(json);
        }
        for (const key in json) {
            if (json.hasOwnProperty(key)) {
                this.dao[key] = json[key];
            }
        }
        if (typeof this.dao.id !== "undefined") {
            this.currentState = this.ObjectState.PERSISTED;
        }
        return this;
    }
    static cleanEmbeddedCollectionIds(obj) {
        for (const i in obj) {
            if (obj[i] instanceof Array) {
                for (let index = 0; index < obj[i].length; index++) {
                    obj[i][index] = AbstractClientDataModel.cleanEmbeddedCollectionIds(obj[i][index]);
                }
            }
            else if (typeof obj[i] === "object" && obj[i] !== null) {
                obj[i] = AbstractClientDataModel.cleanEmbeddedCollectionIds(obj[i]);
            }
            else if (i === "embeddedCollectionId") {
                delete obj[i];
            }
        }
        return obj;
    }
    save(loadAfterwards = true, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            let isLoaded;
            let objectToUpdate = this;
            const illegalState = this.isIllegalState();
            if (illegalState) {
            }
            else {
                this.currentState = this.ObjectState.PERSISTING;
                if (!this.href && this.parent === null) {
                    try {
                        res = yield Datastore.Instance.postOnServer(this, undefined, false, usePersistentStorage);
                    }
                    catch (error) {
                        this.currentState = this.ObjectState.LOCAL_PERSISTED;
                        throw error;
                    }
                    this.currentState = this.ObjectState.LOCAL_PERSISTED;
                }
                else {
                    if (this.parent !== null) {
                        let p = this.parent;
                        while (p.parent !== null) {
                            p = p.parent;
                        }
                        p.currentState = this.ObjectState.PERSISTING;
                        res = yield Datastore.Instance.updateOnServer(p.href, p.toJsonString(true), usePersistentStorage, p);
                        p.currentState = this.ObjectState.LOCAL_PERSISTED;
                        objectToUpdate = p;
                        p.saveToStorage(usePersistentStorage, true);
                    }
                    else {
                        res = yield Datastore.Instance.updateOnServer(this.href, this.toJsonString(true), usePersistentStorage, this);
                    }
                    this.currentState = this.ObjectState.LOCAL_PERSISTED;
                }
                if (res && loadAfterwards) {
                    isLoaded = yield objectToUpdate.loadAfterSave(res.returnedHref || objectToUpdate.href, usePersistentStorage);
                    if (Datastore.Instance.wasLoadedFromStorage(objectToUpdate.ID) === true) {
                        objectToUpdate.currentState = objectToUpdate.ObjectState.LOCAL_PERSISTED;
                    }
                    else {
                        objectToUpdate.currentState = objectToUpdate.ObjectState.PERSISTED;
                    }
                }
                return res;
            }
        });
    }
    delete(usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            const illegalState = this.isIllegalState();
            return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                if (this.currentState === this.ObjectState.DELETED) {
                    resolve();
                }
                if (illegalState) {
                    let error;
                    error = "Object is in persisting or deleting process. Please try again later";
                    if (this.currentState === this.ObjectState.PERSISTING) {
                        error = new AOMRequestError(AOMStatus.IN_PERSISTING_PROCESS, 204, "Object is in persisting process. Please try again later");
                    }
                    else if (this.currentState === this.ObjectState.DELETING) {
                        error = new AOMRequestError(AOMStatus.IN_DELETING_PROCESS, 204, "Object is in deleting process. Please try again later");
                    }
                    reject(error);
                }
                else {
                    if (this.parent !== null) {
                        const data = this.getDataOrKeyFromParent();
                        if (typeof data === "undefined") {
                            this.currentState = this.ObjectState.DELETED;
                            resolve();
                        }
                        if (typeof this.parentAttrKey !== "undefined") {
                            this.parent.dao[this.parentAttrName].splice(this.parentAttrKey, 1);
                            this.parent.hashmap[this.parentAttrName] = [];
                        }
                        else {
                            this.parent.dao[this.parentAttrName] = null;
                            this.parent.hashmap[this.parentAttrName] = undefined;
                        }
                    }
                    let response;
                    try {
                        if (this.parent === null) {
                            this.currentState = this.ObjectState.DELETING;
                            response = yield Datastore.Instance.deleteOnServer(undefined, this, false, usePersistentStorage);
                        }
                        else if (this.parent.parent === null) {
                            response = yield this.parent.save(true, usePersistentStorage);
                        }
                        else {
                            response = yield this.setDataToParent(this.parent, usePersistentStorage);
                        }
                        this.currentState = this.ObjectState.DELETED;
                        resolve(response);
                    }
                    catch (error) {
                        reject(error);
                    }
                }
            }));
        });
    }
    deleteFromStorage() {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.href === undefined) {
                throw new Error(AOMStatus.getReasonPhrase(AOMStatus.HREF_NOT_FOUND));
            }
            if (Datastore.Instance.offlineHandlerExists() === true) {
                const clazz = yield Datastore.getClassFromDataModel(this);
                yield PersistentStorage.Instance.removeObjectByHref(this.href, clazz);
            }
            yield MemoryStorage.Instance.removeObjectByHref(this.href);
        });
    }
    isIllegalState() {
        let result = false;
        if (Datastore.Instance.getCheckObjectState()) {
            result = this.currentState === this.ObjectState.PERSISTING
                || this.currentState === this.ObjectState.DELETING;
        }
        return result;
    }
    load(href, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (href) {
                const illegalState = this.isIllegalState();
                if (illegalState) {
                    let error;
                    if (this.currentState === this.ObjectState.PERSISTING) {
                        error = new AOMRequestError(AOMStatus.IN_PERSISTING_PROCESS, 201, "Object is in persisting process. Please try again later");
                    }
                    else if (this.currentState === this.ObjectState.DELETING) {
                        error = new AOMRequestError(AOMStatus.IN_DELETING_PROCESS, 201, "Object is in deleting process. Please try again later");
                    }
                    else {
                        error = "Object is in persisting or deleting process. Please try again later";
                    }
                    return new Promise((resolve, reject) => {
                        reject(error);
                    });
                }
                else {
                    return new Promise((resolve, reject) => {
                        Datastore.Instance.loadFromServer(href || this.href, this, false, usePersistentStorage, undefined, undefined, false)
                            .then((res) => {
                            resolve(res);
                        })
                            .catch((err) => {
                            reject(err);
                        });
                    });
                }
            }
            else if (this.parent === null) {
                return yield this.load(this.href, usePersistentStorage);
            }
            else {
                const result = yield this.parent.load(undefined, usePersistentStorage);
                const data = this.getDataOrKeyFromParent();
                if (typeof data !== "undefined") {
                    this.fromJson(data);
                }
                return result;
            }
        });
    }
    loadWithHref(href, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.load(href, usePersistentStorage);
        });
    }
    saveToStorage(usePersistentStorage, cleanEmbeddedCollectionIds = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const clazz = yield Datastore.getClassFromDataModel(this);
            const _usePersistentStorage = Datastore.Instance.getUsePersistentStorageForClass(clazz, this.href, usePersistentStorage);
            yield StorageFactory.ChooseStorageImpl(_usePersistentStorage, Datastore.Instance.
                offlineHandlerExists()).addOrUpdateCollection(this.href, this.toJson(cleanEmbeddedCollectionIds));
        });
    }
    getOptionsForStaticData(refName) {
        const options = {};
        if (typeof this.href !== "undefined") {
            options.moduleName = this.moduleName;
            options.dataModelName = this.modelName;
            options.dataModelId = this.ID;
            options.refName = refName;
        }
        return options;
    }
    static getEmbeddedCollectionId(obj) {
        if (typeof obj.dao.id !== "undefined" && obj.dao.id !== null) {
            return obj.dao.id;
        }
        else {
            return obj.dao.embeddedCollectionId;
        }
    }
    getDataOrKeyFromParent(getKey = false) {
        let p = this.parent;
        const ids = [AbstractClientDataModel.getEmbeddedCollectionId(this)];
        const els = [this];
        let retKey;
        let data = this.dao;
        let hasParentAttrName = true;
        while (p !== null) {
            if (p.parent !== null && typeof p.parentAttrName === "undefined") {
                hasParentAttrName = false;
            }
            ids.push(AbstractClientDataModel.getEmbeddedCollectionId(p));
            els.push(p);
            data = p.dao;
            p = p.parent;
        }
        if (getKey || (ids.length > 1 && hasParentAttrName === false)) {
            for (let i = ids.length - 2; i >= 0; i--) {
                const id = ids[i];
                loop: for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (data[key] instanceof Array) {
                            for (const k in data[key]) {
                                if (typeof data[key][k] === "object") {
                                    if (typeof data[key][k] === "object" && typeof data[key][k]["@type"] === "string") {
                                        if (typeof data[key][k].id === "string" && data[key][k].id === id ||
                                            typeof data[key][k].id === "undefined" && data[key][k].embeddedCollectionId === id) {
                                            data = data[key][k];
                                            if (i === 0) {
                                                retKey = k;
                                            }
                                            break loop;
                                        }
                                    }
                                }
                            }
                        }
                        else if (typeof data[key] === "object" && data[key] !== null && typeof data[key]["@type"] === "string") {
                            if (typeof data[key].id === "string" && data[key].id === id ||
                                typeof data[key].id === "undefined" && data[key].embeddedCollectionId === id) {
                                data = data[key];
                                break loop;
                            }
                        }
                    }
                }
            }
        }
        else {
            for (let i = els.length - 2; i >= 0; i--) {
                if (typeof data !== "undefined") {
                    const el = els[i];
                    if (typeof el.parentAttrKey !== "undefined") {
                        data = data[el.parentAttrName][el.parentAttrKey];
                    }
                    else {
                        data = data[el.parentAttrName];
                    }
                }
            }
        }
        if (getKey) {
            return +retKey;
        }
        return data;
    }
    setDataToParent(embData, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            embData.parent.dao = embData.parent.getDataOrKeyFromParent();
            if (typeof embData.parentAttrKey !== "undefined") {
                if (typeof embData.parent.dao[embData.parentAttrName] === "undefined") {
                    embData.parent.dao[embData.parentAttrName] = [];
                }
                embData.parent.dao[embData.parentAttrName][embData.parentAttrKey] = embData.toJson();
            }
            else {
                embData.parent.dao[embData.parentAttrName] = embData.toJson();
            }
            if (embData.parent.parent !== null) {
                return yield embData.parent.parent.setDataToParent(embData.parent, usePersistentStorage);
            }
            else {
                return yield embData.parent.save(true, usePersistentStorage);
            }
        });
    }
    setDataToParentLocal(embData) {
        return __awaiter(this, void 0, void 0, function* () {
            embData.parent.dao = embData.parent.getDataOrKeyFromParent();
            if (typeof embData.parentAttrKey !== "undefined") {
                if (typeof embData.parent.dao[embData.parentAttrName] === "undefined") {
                    embData.parent.dao[embData.parentAttrName] = [];
                }
                embData.parent.dao[embData.parentAttrName][embData.parentAttrKey] = embData.toJson();
                embData.parent.hashmap[embData.parentAttrName] = [];
            }
            else {
                embData.parent.dao[embData.parentAttrName] = embData.toJson();
                embData.parent.hashmap[embData.parentAttrName] = undefined;
            }
            if (embData.parent.parent !== null) {
                embData.parent.parent.setDataToParentLocal(embData.parent);
            }
        });
    }
    loadAfterSave(href, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            try {
                res = yield this.load(href, usePersistentStorage);
                const str = JSON.stringify(res.responseText);
                this.fromJson(JSON.parse(str));
                return true;
            }
            catch (err) {
                return false;
            }
        });
    }
}
import AOMRequestError from "./AOMRequestError";
import AOMStatus from "./Status";
import Datastore from "./Datastore";
import MemoryStorage from "./offline/MemoryStorage";
import PersistentStorage from "./offline/PersistentStorage";
import SdkInfo from "./SdkInfo";
import StorageFactory from "./offline/StorageFactory";
