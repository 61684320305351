import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, RouteProps, useLocation } from 'react-router-dom';
import Login from '../containers/Login';
import NotFoundPage from '../containers/NotFoundPage';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store/index';
import LearnContents from '../containers/Dashboard/LearnContents';
import Training from '../containers/Dashboard/Training';
import Certificates from '../containers/Dashboard/Certificates';
import Profile from '../containers/Profile';
import Team from '../containers/Dashboard/Team';
import LearnContentManagement from '../containers/Admin/LearnContentManagement';
import LearnContentCreation from '../containers/Admin/LearnContentCreation';
import LearnContentAssignment from '../containers/Admin/LearnContentAssignment';
import { authActions } from '../store/auth';
import ForgotPassword from '../components/ForgotPassword/ForgotPassword';

type PrivateRouteProps = RouteProps & {
  isAuthenticated: boolean;
  hasPermissions?: boolean;
};

const AppRouter = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const { isAuthenticated, isAdmin, isGlobalAdmin, isManager } = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    if (location.pathname?.includes('admin')) {
      dispatch(authActions.setIsAdminAreaNavigation(true));
    } else if (location.pathname?.includes('user') || location.pathname?.includes('manager')) {
      dispatch(authActions.setIsAdminAreaNavigation(false));
    }

    setIsInitialized(true);
  }, [dispatch, location]);

  const PrivateRoute = ({ isAuthenticated, hasPermissions, ...rest }: PrivateRouteProps) => {
    if (isAuthenticated === false) {
      if (location.pathname !== '/login') {
        dispatch(authActions.setRedirectAfterAuth(location.pathname));
      }
      return <Redirect to="/login" />;
    }
    if (hasPermissions === false) {
      // TODO: page with no permissions message
      return <Route component={NotFoundPage} />;
    }
    return <Route {...rest} />;
  };

  return (
    <>
      {
        isInitialized &&
        <Switch>
          <Route exact path="/" render={() => <Redirect to={isAuthenticated ? '/user/learn-contents' : '/login'} />} />
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <PrivateRoute path="/profile" component={Profile} isAuthenticated={isAuthenticated} />
          <PrivateRoute path="/user/learn-contents" component={LearnContents} isAuthenticated={isAuthenticated} />
          <PrivateRoute path="/user/certificates" component={Certificates} isAuthenticated={isAuthenticated} />
          <PrivateRoute path="/user/training/:id/:lang" component={Training} isAuthenticated={isAuthenticated} />
          <PrivateRoute path="/manager/team" component={Team} isAuthenticated={isAuthenticated} hasPermissions={isManager} />
          <Route
            exact
            path="/admin"
            render={() => <Redirect to={isGlobalAdmin ? '/admin/learn-content-management' : '/admin/learn-content-assignment'} />}
          />
          <PrivateRoute
            path="/admin/learn-content-management"
            component={LearnContentManagement}
            isAuthenticated={isAuthenticated}
            hasPermissions={isAdmin}
          />
          <PrivateRoute
            exact
            path="/admin/learn-content-creation"
            component={LearnContentCreation}
            isAuthenticated={isAuthenticated}
            hasPermissions={isAdmin}
          />
          <PrivateRoute
            exact
            path="/admin/learn-content-creation/:id"
            component={LearnContentCreation}
            isAuthenticated={isAuthenticated}
            hasPermissions={isAdmin}
          />
          <PrivateRoute
            path="/admin/learn-content-assignment"
            component={LearnContentAssignment}
            isAuthenticated={isAuthenticated}
            hasPermissions={isAdmin}
          />
          <Route component={NotFoundPage} />
        </Switch>
      }
    </>
  );
};

export default AppRouter;
