import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const MedalIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 22">
      <path
        d="M20,2H4V4L9.81,8.36a7,7,0,1,0,4.38,0L20,4V2M14.94,19.5,12,17.78,9.06,19.5l.78-3.33L7.25,13.93l3.41-.29L12,10.5l1.34,3.14,3.41.29-2.59,2.24Z"
        transform="translate(-4 -2)"
      />
    </SvgIcon>
  );
};

export const CrownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 17">
      <path d="M5,16,3,5l5.5,7L12,5l3.5,7L21,5,19,16H5m14,3a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V18H19Z" transform="translate(-3 -5)" />
    </SvgIcon>
  );
};

export const DiamondIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        d="M16,9h3l-5,7M10,9h4l-2,8M5,9H8l2,7M15,4h2l2,3H16M11,4h2l1,3H10M7,4H9L8,7H5M6,2,2,8,12,22,22,8,18,2Z"
        transform="translate(-2 -2)"
      />
    </SvgIcon>
  );
};
