import React from 'react';
import { SnackbarProvider } from 'notistack';
import { makeStyles, Theme } from '@material-ui/core';
import Notifier from './Notifier';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  variantSuccess: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
    backgroundColor: theme.palette.success.light,
  },
  variantError: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.light,
  },
  variantInfo: {
    borderColor: theme.palette.info.main,
    color: theme.palette.info.main,
    backgroundColor: theme.palette.info.light,
  },
  variantWarning: {
    borderColor: theme.palette.warning.main,
    color: theme.palette.warning.main,
    backgroundColor: theme.palette.warning.light,
  },
}));

const NotistackProvider = ({ children }) => {
  const { icon, ...classes } = useStyles();
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      iconVariant={{
        success: <CheckCircleIcon className={icon} />,
        error: <CancelIcon className={icon} />,
        warning: <WarningIcon className={icon} />,
        info: <InfoIcon className={icon} />,
      }}
      classes={classes}
    >
      <>
        <Notifier />
        {children}
      </>
    </SnackbarProvider>
  );
};

export default NotistackProvider;
