import React, { useState, useEffect, Ref } from 'react';
import {
  makeStyles,
  Paper,
  List,
  ListItem,
  Theme,
  ListItemAvatar,
  ListItemText,
  Typography,
  Avatar,
  Button,
  IconButton,
  Hidden,
  Grid,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LPUser from '../../sdk/com/apiomat/frontend/mylearningplatform/LPUser';
import AppLogoIcon from '../../assets/img/app_logo_icon.svg';
import TrainingLabel from './TrainingLabel';
import LearnProgress from '../../sdk/com/apiomat/frontend/mylearningplatform/LearnProgress';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';
import clsx from 'clsx';
import { sortLearnContentsByRestDays, sortTrainingsBySuccessDate } from '../../utils/trainings.utils';
import { hasOverdueTrainings, hasExpiringTrainings, getUserName } from '../../utils/users.utils';
import CheckIcon from '@material-ui/icons/Check';
import { onDownloadFileByLink } from '../../utils/file.utils';
import LearnContent from '../../sdk/com/apiomat/frontend/mylearningplatform/LearnContent';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import NoResultsIcon from '../../assets/img/team_no_results_icon.png';
import GroupIcon from '@material-ui/icons/Group';
import LPTeam from '../../sdk/com/apiomat/frontend/mylearningplatform/LPTeam';

const useStyles = makeStyles((theme: Theme) => ({
  infiniteScrollContainer: {
    overflow: 'hidden !important',
  },
  item: {
    padding: theme.spacing(2),
  },
  itemTraining: {
    padding: theme.spacing(2),
    alignItems: 'flex-start',
  },
  secondList: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
  },
  primaryTitle: {
    display: 'flex',
  },
  avatarWrapper: {
    position: 'relative',
  },
  avatar: {
    width: 54,
    height: 54,
    backgroundColor: theme.palette.background.default,
    borderRadius: 10,
    marginRight: theme.spacing(2),
  },
  userAvatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
  },
  successAvatar: {
    color: theme.palette.success.main,
    backgroundColor: theme.palette.success.light,
  },
  warningAvatar: {
    color: theme.palette.warning.main,
    backgroundColor: theme.palette.warning.light,
  },
  errorAvatar: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.light,
  },
  noResultsIcon: {
    width: 54,
    height: 54,
    borderRadius: 10,
    marginRight: theme.spacing(2),
  },
  icon: {
    fontSize: theme.typography.body2.fontSize,
    marginRight: theme.spacing(1),
    display: 'block',
    marginTop: 3,
  },
}));

export interface UserListProps {
  forwardedRef: Ref<HTMLUListElement>;
  users: LPUser[];
  teams?: LPTeam[];
  learnContentsMap: Map<string, LearnContent>;
  scrollThreshold: number;
  onNext?: () => void;
  completed?: boolean;
}

const UserList = (props: UserListProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { users, learnContentsMap } = props;

  const [activeUser, setActiveUser] = useState<LPUser>(null);
  const [showClosedTrainings, setShowClosedTrainings] = useState<boolean>(false);

  const activeTrainigs = activeUser?.learnings
    .filter(el => Boolean(el.successDate) === false)
    .sort((a, b) => sortLearnContentsByRestDays(learnContentsMap.get(a.learnContentId), learnContentsMap.get(b.learnContentId)));
  const closedTrainings = activeUser?.learnings.filter(el => Boolean(el.successDate)).sort(sortTrainingsBySuccessDate);

  const onUserClick = (user: LPUser) => {
    setActiveUser(activeUser !== user ? user : null);
    setShowClosedTrainings(false);
  };

  const getUserAvatarClass = (user: LPUser): string => {
    if (hasOverdueTrainings(user)) {
      return classes.errorAvatar;
    }
    if (hasExpiringTrainings(user)) {
      return classes.warningAvatar;
    }
    return classes.successAvatar;
  };

  const getOpenTrainingsCount = (user: LPUser): number => {
    return user.learnings.filter(el => Boolean(el.successDate) === false).length;
  };

  const getTrainingListItem = (training: LearnProgress, learnContent: LearnContent) => {
    return (
      <ListItem key={training.ID} className={classes.itemTraining}>
        <ListItemAvatar className={classes.avatarWrapper}>
          <>
            <Avatar className={classes.avatar} src={learnContent?.thumbnailURL || AppLogoIcon} />
            <Hidden mdUp>
              <TrainingLabel item={training} dueDate={training.dueDate} background="white" isSmall />
            </Hidden>
          </>
        </ListItemAvatar>
        <Grid container direction="row" wrap="nowrap" alignItems="center">
          <Grid item xs>
            <ListItemText
              primary={
                <div className={classes.primaryTitle}>
                  <Typography component="span" variant="subtitle2" color="primary">
                    {t(learnContent?.headlineKey)}
                  </Typography>
                  <Hidden smDown>
                    <TrainingLabel item={training} dueDate={training.dueDate} background="white" />
                  </Hidden>
                </div>
              }
              secondary={<Typography variant="subtitle2">{t(learnContent?.descriptionKey)}</Typography>}
            />
          </Grid>
          <Grid item>
            {Boolean(training.successDate) && (
              <>
                <Hidden mdUp>
                  <IconButton color="primary" onClick={() => onDownloadFileByLink(training, 'certificate')}>
                    <GetAppIcon />
                  </IconButton>
                </Hidden>
                <Hidden smDown>
                  <Button color="primary" startIcon={<GetAppIcon />} onClick={() => onDownloadFileByLink(training, 'certificate')}>
                    {t('dashboard.download')}
                  </Button>
                </Hidden>
              </>
            )}
          </Grid>
        </Grid>
      </ListItem>
    );
  };

  const getTrainingsList = (items: LearnProgress[]) => {
    return (
      <List disablePadding>{items.map(training => getTrainingListItem(training, learnContentsMap.get(training.learnContentId)))}</List>
    );
  };

  const getTeam = (user: LPUser): string => {
    if (!props.teams) {
      return ''
    }

    return user.teamIds
      ?.map(teamId => props.teams.find(team => team.ID === teamId))
      ?.filter(team => !!team)
      ?.map(team => team.name)
      ?.join(', ')
      || ''
  }

  return (
    <>
      <InfiniteScroll
        dataLength={users.length}
        next={props.onNext}
        hasMore={!props.completed}
        scrollThreshold={props.scrollThreshold}
        loader={<LoadingIndicator />}
        className={classes.infiniteScrollContainer}
      >
        <Paper elevation={0}>
          <List ref={props.forwardedRef} disablePadding>
            {users.map((user, i) => (
              <>
                <ListItem divider={i !== users.length - 1} key={user.ID} className={classes.item} button onClick={() => onUserClick(user)}>
                  <ListItemAvatar className={classes.avatarWrapper}>
                    <div className={clsx(classes.avatar, classes.userAvatar, getUserAvatarClass(user))}>
                      {getOpenTrainingsCount(user) === 0 ? (
                        <CheckIcon />
                      ) : (
                        <Typography component="span" variant="h6">
                          {getOpenTrainingsCount(user)}
                        </Typography>
                      )}
                    </div>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography component="span" variant="subtitle2">
                            {getUserName(user)}
                          </Typography>
                        </Grid>
                        {(getTeam(user) && (
                          <Grid item container xs={12} sm={8} spacing={2}>
                            <Grid item container xs={12} sm={8} alignItems="flex-start" wrap="nowrap">
                              {getTeam(user) && <GroupIcon className={classes.icon} />}
                              <Typography variant="body2" component="span">
                                {getTeam(user)}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    }
                  />
                </ListItem>
                {activeUser === user && activeUser?.learnings.length > 0 && (
                  <div className={classes.secondList}>
                    {getTrainingsList(activeTrainigs)}
                    {closedTrainings.length > 0 && (
                      <Button
                        color="primary"
                        startIcon={showClosedTrainings ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        onClick={() => setShowClosedTrainings(!showClosedTrainings)}
                      >
                        {t(showClosedTrainings ? 'team.hide-closed-learn-content' : 'team.show-closed-learn-content', {
                          count: closedTrainings.length,
                        })}
                      </Button>
                    )}
                    {showClosedTrainings && getTrainingsList(closedTrainings)}
                  </div>
                )}
              </>
            ))}
          </List>
        </Paper>
      </InfiniteScroll>
      {users.length === 0 && props.completed && (
        <ListItem>
          <ListItemAvatar>
            <img alt="all done icon" className={classes.noResultsIcon} src={NoResultsIcon} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography component="span" variant="subtitle2" color="primary">
                {t('team.no-results-title')}
              </Typography>
            }
            secondary={<Typography variant="subtitle2">{t('team.no-results-description')}</Typography>}
          />
        </ListItem>
      )}
    </>
  );
};

export default UserList;
