import React from 'react';
import { makeStyles, ListItem, Theme, Typography, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import RecentActorsIcon from '@material-ui/icons/RecentActors';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fontSize: theme.typography.body2.fontSize,
    marginRight: theme.spacing(1),
    display: 'block',
    marginTop: 3,
  },
  item: {
    padding: theme.spacing(2),
  },
}));

export interface PositionSearchItemProps {
  item: string;
  onItemClick?: (position: string) => void;
}

const PositionSearchItem = (props: PositionSearchItemProps) => {
  const { item } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ListItem divider={true} className={classes.item} component="div">
      <Grid container spacing={isSmall ? 1 : 2} alignItems="center">
        <Grid item xs={12} sm={4} container>
          <RecentActorsIcon className={classes.icon} />
          <Typography component="span" variant="subtitle2">
            {item}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default PositionSearchItem;
