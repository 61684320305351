var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Datastore from "./../Datastore";
import AbstractClientDataModel from "./../AbstractClientDataModel";
export default class PasswordResetRequest extends AbstractClientDataModel {
    static getPasswordResetRequests(query, withReferencedHrefs = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadListFromServerWithClass(PasswordResetRequest, query, withReferencedHrefs, usePersistentStorage);
        });
    }
    static getPasswordResetRequestsCount(query, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadCountFromServer(PasswordResetRequest, undefined, query, usePersistentStorage);
        });
    }
    static staticGetModuleName() {
        return "MyLearningPlatform";
    }
    static staticGetModelName() {
        return "PasswordResetRequest";
    }
    get customMetaForClass() {
        return "";
    }
    static removeAllFromStorage() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorageByClass(PasswordResetRequest);
        });
    }
    static deleteAllFromStorage(query) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorage(query, PasswordResetRequest);
        });
    }
    static getPasswordResetRequestsFromPersistentStorage(whereWithPlaceholders = "", whereValues = [], orderBy = "", limit, offset) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadFromPersistentStorage(PasswordResetRequest, whereWithPlaceholders, whereValues, orderBy, limit, offset);
        });
    }
    static getPasswordResetRequestsCountFromPersistentStorage(whereWithPlaceholders = "", whereValues = []) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.getCountFromPersistentStorage(PasswordResetRequest, whereWithPlaceholders, whereValues);
        });
    }
    constructor() {
        super();
        this.dao = new PasswordResetRequestDAO();
    }
    get modelName() { return "PasswordResetRequest"; }
    get moduleName() { return "MyLearningPlatform"; }
    get initiatedOn() {
        const retDate = this.dao.initiatedOn;
        return (typeof retDate !== "undefined") ? new Date(retDate) : undefined;
    }
    set initiatedOn(_initiatedOn) {
        if (_initiatedOn instanceof Date) {
            this.dao.initiatedOn = _initiatedOn.getTime();
        }
        else {
            this.dao.initiatedOn = undefined;
        }
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get passwordResetToken() {
        return this.dao.passwordResetToken !== null ? this.dao.passwordResetToken : undefined;
    }
    set passwordResetToken(_passwordResetToken) {
        this.dao.passwordResetToken = _passwordResetToken;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get userMail() {
        return this.dao.userMail !== null ? this.dao.userMail : undefined;
    }
    set userMail(_userMail) {
        this.dao.userMail = _userMail;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get userName() {
        return this.dao.userName !== null ? this.dao.userName : undefined;
    }
    set userName(_userName) {
        this.dao.userName = _userName;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
}
import PasswordResetRequestDAO from "./PasswordResetRequestDAO";
