import { NoResultsListItem } from '../../components/NoResults/NoResultsListItem';
import React, { useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TrainingList from '../../components/Dashboard/TrainingList';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import { useHistory, useLocation } from 'react-router-dom';
import LearnProgress from '../../sdk/com/apiomat/frontend/mylearningplatform/LearnProgress';
import { useState } from 'react';
import LanguageSelector from '../../components/Dialogs/LanguageSelector';
import AppHeader from '../../components/AppHeader/AppHeader';
import { notificationActions } from '../../store/notification';
import { sortLearnContentsByRestDays, sortTrainingsByLastAccess, getAvailableLanguages } from '../../utils/trainings.utils';
import BottomAppBar from '../../components/AppHeader/BottomAppBar';
import BasicContainer from '../../components/Container/BasicContainer';
import { learnContentsActions } from '../../store/learnContents';

import AllDoneIcon from '../../assets/img/all_done_icon.png';

const useStyles = makeStyles(theme => ({
  nav: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  progress: {
    margin: theme.spacing(5, 0),
  },
  progressInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
  },
  progressLine: {
    height: 10,
    borderRadius: 10,
  },
  bar: {
    borderRadius: 10,
  },
  tableTitle: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
    },
  },
  doneIcon: {
    width: 54,
    height: 54,
    borderRadius: 10,
    marginRight: theme.spacing(2),
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const { learnContentsMap, loading } = useSelector((state: AppState) => state.learnContents);
  const { user, isAuthenticated } = useSelector((state: AppState) => state.auth);

  const [activeTraining, setActiveTraining] = useState<LearnProgress>(null);

  useEffect(() => {
    if (loading !== 'pending') {
      dispatch(learnContentsActions.loadLearnContents());
    }
  }, []);

  const goToTraining = (item: LearnProgress) => {
    const langs = getAvailableLanguages(learnContentsMap.get(item.learnContentId));
    if (langs.length === 0) {
      dispatch(notificationActions.showError(t('errors.no-learn-content-available')));
    } else if (langs.length === 1) {
      navigateToTraining(item, langs[0]);
    } else if (langs.includes(user.defaultLanguage)) {
      navigateToTraining(item, user.defaultLanguage);
    } else {
      setActiveTraining(item);
    }
  };

  const navigateToTraining = (training: LearnProgress, lang: string) => {
    history.push(`/user/training/${training.ID}/${lang}?backRoute=${location.pathname}`);
  };

  const recentItems = user.learnings
    .filter(
      training =>
        learnContentsMap.get(training.learnContentId)?.isArchived !== 1 &&
        Boolean(training.successDate) === false &&
        Boolean(training.lastAccess)
    )
    .sort(sortTrainingsByLastAccess)
    .slice(0, 2);

  const allItems = user.learnings
    .filter(training => learnContentsMap.get(training.learnContentId)?.isArchived !== 1 && Boolean(training.successDate) === false)
    .slice()
    .sort((a, b) => sortLearnContentsByRestDays(learnContentsMap.get(a.learnContentId), learnContentsMap.get(b.learnContentId)));

  return (
    <>
      <AppHeader tab="user/learn-contents" />
      <BasicContainer>
        {loading === 'pending' ? (
          <LoadingIndicator />
        ) : (
          <>
            {recentItems.length > 0 && (
              <>
                <Typography variant="body2" className={classes.tableTitle}>
                  {t('dashboard.recently-worked-on')}
                </Typography>
                <TrainingList items={recentItems} learnContentsMap={learnContentsMap} onItemClick={goToTraining} />
              </>
            )}
            <Typography variant="body2" className={classes.tableTitle}>
              {t('dashboard.all-trainings')}
            </Typography>
            {allItems.length === 0 && (
              <NoResultsListItem imageSrc={AllDoneIcon} title="dashboard.all-done" description="dashboard.all-done-description" />
            )}
            <TrainingList items={allItems} learnContentsMap={learnContentsMap} onItemClick={goToTraining} />
          </>
        )}
      </BasicContainer>
      <BottomAppBar tab="user/learn-contents" />
      <LanguageSelector
        open={Boolean(activeTraining) === true}
        onClose={() => setActiveTraining(null)}
        onSave={lang => navigateToTraining(activeTraining, lang)}
        availableLanguages={getAvailableLanguages(learnContentsMap.get(activeTraining?.learnContentId))}
      />
    </>
  );
};
