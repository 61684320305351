var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, Column } from "../OfflineAdapter";
import AbstractClientDataModelDAO from "./../offline/AbstractClientDataModelDAO";
let MaintenanceIntervalDAO = class MaintenanceIntervalDAO extends AbstractClientDataModelDAO {
    constructor() {
        super();
        this.moduleName = "MaintenanceModule";
        this.modelName = "MaintenanceInterval";
    }
};
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], MaintenanceIntervalDAO.prototype, "displayStartDate", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], MaintenanceIntervalDAO.prototype, "displayText", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], MaintenanceIntervalDAO.prototype, "endDate", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], MaintenanceIntervalDAO.prototype, "startDate", void 0);
MaintenanceIntervalDAO = __decorate([
    Entity("MaintenanceModule_MaintenanceInterval"),
    __metadata("design:paramtypes", [])
], MaintenanceIntervalDAO);
export default MaintenanceIntervalDAO;
