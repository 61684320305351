var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Datastore from "./../Datastore";
import AbstractClientDataModel from "./../AbstractClientDataModel";
export default class LocalizedLearnContent extends AbstractClientDataModel {
    static getLocalizedLearnContents(query, withReferencedHrefs = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadListFromServerWithClass(LocalizedLearnContent, query, withReferencedHrefs, usePersistentStorage);
        });
    }
    static getLocalizedLearnContentsCount(query, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadCountFromServer(LocalizedLearnContent, undefined, query, usePersistentStorage);
        });
    }
    static staticGetModuleName() {
        return "MyLearningPlatform";
    }
    static staticGetModelName() {
        return "LocalizedLearnContent";
    }
    get customMetaForClass() {
        return "";
    }
    static removeAllFromStorage() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorageByClass(LocalizedLearnContent);
        });
    }
    static deleteAllFromStorage(query) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorage(query, LocalizedLearnContent);
        });
    }
    static getLocalizedLearnContentsFromPersistentStorage(whereWithPlaceholders = "", whereValues = [], orderBy = "", limit, offset) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadFromPersistentStorage(LocalizedLearnContent, whereWithPlaceholders, whereValues, orderBy, limit, offset);
        });
    }
    static getLocalizedLearnContentsCountFromPersistentStorage(whereWithPlaceholders = "", whereValues = []) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.getCountFromPersistentStorage(LocalizedLearnContent, whereWithPlaceholders, whereValues);
        });
    }
    constructor() {
        super();
        this.dao = new LocalizedLearnContentDAO();
    }
    get modelName() { return "LocalizedLearnContent"; }
    get moduleName() { return "MyLearningPlatform"; }
    get isArchived() {
        return this.dao.isArchived !== null ? this.dao.isArchived : undefined;
    }
    set isArchived(_isArchived) {
        this.dao.isArchived = _isArchived;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get language() {
        return this.dao.language !== null ? this.dao.language : undefined;
    }
    set language(_language) {
        this.dao.language = _language;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get learnContentType() {
        return this.dao.learnContentType !== null ? this.dao.learnContentType : undefined;
    }
    set learnContentType(_learnContentType) {
        this.dao.learnContentType = _learnContentType;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get resourceId() {
        return this.dao.resourceId !== null ? this.dao.resourceId : undefined;
    }
    set resourceId(_resourceId) {
        this.dao.resourceId = _resourceId;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get translationId() {
        return this.dao.translationId !== null ? this.dao.translationId : undefined;
    }
    set translationId(_translationId) {
        this.dao.translationId = _translationId;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
}
import LocalizedLearnContentDAO from "./LocalizedLearnContentDAO";
