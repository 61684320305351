var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, Column } from "../OfflineAdapter";
import AbstractClientDataModelDAO from "./../offline/AbstractClientDataModelDAO";
let LPUserFilterDAO = class LPUserFilterDAO extends AbstractClientDataModelDAO {
    constructor() {
        super();
        this.moduleName = "MyLearningPlatform";
        this.modelName = "LPUserFilter";
    }
};
__decorate([
    Column("simple-json", { nullable: true }),
    __metadata("design:type", Array)
], LPUserFilterDAO.prototype, "users", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], LPUserFilterDAO.prototype, "usersHref", void 0);
LPUserFilterDAO = __decorate([
    Entity("MyLearningPlatform_LPUserFilter"),
    __metadata("design:paramtypes", [])
], LPUserFilterDAO);
export default LPUserFilterDAO;
