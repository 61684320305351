import React from 'react';
import { Typography, makeStyles, Theme, Button, Paper, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { languages } from '../../../utils/i18n';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { LearnContentData } from '../../../store/learnContents';
import { Language } from '../../../enums/Language';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  languageDescription: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  languageButton: {
    padding: theme.spacing(1.5, 1.5, 0.5),
    borderRadius: 10,
  },
  languageImage: {
    width: 24,
    height: 24,
    borderRadius: '50%',
  },
}));

export interface LanguageSelectionStepProps {
  learnContent: LearnContentData;
  mandatoryLanguages?: Language[];
  setLearnContent: (learnContent: LearnContentData) => void;
}

const LanguageSelectionStep: React.FC<LanguageSelectionStepProps> = ({ mandatoryLanguages = [], ...props }) => {
  const { learnContent, setLearnContent } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  let { langs: selectedLanguages = [] } = learnContent;
  
  for (const lang of mandatoryLanguages) {
    if (!selectedLanguages.includes(lang)) {
      selectedLanguages = [...selectedLanguages, lang];
    }
  }

  const toggleLang = (lang: Language) => {
    if (mandatoryLanguages.includes(lang)) {
      return
    }

    const newLangs = selectedLanguages.includes(lang) ? selectedLanguages.filter(el => el !== lang) : [...selectedLanguages, lang];
    setLearnContent({
      ...learnContent,
      langs: newLangs,
    });
  };

  return (
    <Paper elevation={0} className={classes.paper}>
      <Typography component="p" variant="body1" className={classes.languageDescription}>
        {t('admin.create-learn-content.language-select-step.description')}
      </Typography>
      <Grid container spacing={1}>
        {languages.map(el => (
          <Grid item xs={12} sm={6} md={3} key={el}>
            <Button
              className={classes.languageButton}
              variant="outlined"
              color={selectedLanguages.includes(el) ? 'primary' : 'default'}
              fullWidth
              onClick={() => toggleLang(el)}
            >
              <Grid container justify="space-between" alignItems="center">
                <Grid item xs>
                  <Grid container spacing={1}>
                    <Grid item>
                      <img
                        className={classes.languageImage}
                        src={require(`../../../assets/img/langs/${el}.svg`)}
                        alt={t(`languages.${el}`)}
                      />
                    </Grid>
                    <Grid item>
                      <Typography component="span" color={selectedLanguages.includes(el) ? 'primary' : 'initial'} variant="subtitle2">
                        {t(`languages.${el}`)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>{selectedLanguages.includes(el) && <CheckCircleIcon color="primary" />}</Grid>
              </Grid>
            </Button>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default LanguageSelectionStep;
