export default class AOMRequestError {
    get message() {
        return this._message;
    }
    get name() {
        return this._name;
    }
    get expectedCodes() {
        return this._expectedCodes;
    }
    get statusCode() {
        return this._statusCode;
    }
    get extraInformations() {
        return this._extraInformations;
    }
    set extraInformations(info) {
        this._extraInformations = info;
    }
    constructor(_statusCode, _expectedCodes = 0, _message = "") {
        this._statusCode = _statusCode;
        try {
            const errorJson = JSON.parse(_message);
            if (errorJson.status) {
                this._statusCode = errorJson.status;
                this._message = errorJson.message || AOMStatus.getReasonPhrase(errorJson.status);
            }
            else {
                throw new Error("No readable error JSON");
            }
        }
        catch (e) {
            this._message = _message || AOMStatus.getReasonPhrase(_statusCode);
            this._statusCode = _statusCode;
        }
        this._name = "ApiomatRequestError";
        this._expectedCodes = _expectedCodes;
        this._extraInformations = {};
    }
}
import AOMStatus from "./Status";
