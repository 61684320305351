import { createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme();

const corporateDesignDFHPalette = {
  black: '#000000',
  grauDFH: '#636766',
  grauton1: '#6e7478',
  grauton2: '#8e979c',
  grauton3: '#b0b5b5',
  grauton4: '#c8c8ca',
  grunNachhaltig: '#c2ccb1',
  background: '#f2f2f2',
  backgroundDark: '#e6e6e6',
  white: '#ffffff',
}

export const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: corporateDesignDFHPalette.grauton2,
      light: corporateDesignDFHPalette.grauton4,
    },
    secondary: {
      main: corporateDesignDFHPalette.grauton2,
    },
    background: {
      default: corporateDesignDFHPalette.background,
    },
    text: {
      primary: corporateDesignDFHPalette.grauDFH,
      secondary: corporateDesignDFHPalette.grauton2
    },
    success: {
      main: '#24823f',
      light: '#edf7f1',
    },
    warning: {
      main: '#ff7f1a',
      light: '#f7ebe1',
    },
    error: {
      main: '#E20005',
      light: '#F8E4E2',
    },
    info: {
      main: '#0c95d1',
      light: '#e1f0f7',
    },
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    h5: {
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 600,
    },
    subtitle2: {
      fontWeight: 600,
    },
  },
  overrides: {
    MuiTypography: {
      colorPrimary: {
        color: corporateDesignDFHPalette.grauDFH,
      }
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
        backgroundColor: '#F2F2F2 !important',
      },
    },
    MuiTabs: {
      indicator: {
        height: 4,
        backgroundColor: corporateDesignDFHPalette.grunNachhaltig,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontWeight: 600,
        padding: 20,
        minWidth: '0 !important',
        maxWidth: '280px',
        maxHeight: '64px',
        '&.MuiTab-textColorPrimary.Mui-selected': {
          color: corporateDesignDFHPalette.grauDFH,
        }
      },
    },
    MuiFormLabel: {
      root: {
        color: corporateDesignDFHPalette.grauDFH,
        '& .Mui-focused': {
          color: corporateDesignDFHPalette.grauDFH,
        }
      }
    },
    MuiChip: {
      colorPrimary: {
        color: corporateDesignDFHPalette.white,
      },
      label: {
        fontWeight: 600,
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontWeight: 600,
      },
      containedPrimary: {
        color: corporateDesignDFHPalette.white,
      },
      text: {
        color: corporateDesignDFHPalette.grauDFH,
      },
      textPrimary: {
        color: corporateDesignDFHPalette.grauDFH,
      },
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: corporateDesignDFHPalette.grunNachhaltig,
      }
    },
    MuiIconButton: {
      colorPrimary: {
        color: corporateDesignDFHPalette.grunNachhaltig,
      }
    },
    MuiListItem: {
      root: {
        '& h6.MuiTypography-subtitle2': {
          color: corporateDesignDFHPalette.grauton2,
        }
      }
    },
    MuiRadio: {
      root: {
        color: corporateDesignDFHPalette.grauDFH,
        '&.MuiRadio-colorSecondary.Mui-checked': {
          color: corporateDesignDFHPalette.grauDFH,
        }
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      },
    },
    MuiDivider: {
      root: {
        height: 2,
        backgroundColor: corporateDesignDFHPalette.background,
      },
    },
    MuiLinearProgress: {
      barColorPrimary: {
        backgroundColor: corporateDesignDFHPalette.grunNachhaltig,
      },
      colorPrimary: {
        backgroundColor: corporateDesignDFHPalette.backgroundDark,
      },
    },
    MuiDialog: {
      paperWidthXs: {
        [defaultTheme.breakpoints.down('xs')]: {
          minWidth: '90%',
        },
        [defaultTheme.breakpoints.up('sm')]: {
          minWidth: 350,
        },
        maxWidth: 350,
      },
      paperWidthSm: {
        [defaultTheme.breakpoints.down('xs')]: {
          minWidth: '90%',
        },
        [defaultTheme.breakpoints.up('sm')]: {
          minWidth: 420,
        },
        maxWidth: 420,
      },
    },
    MuiDialogContent: {
      root: {
        padding: '24px'
      }
    },    
    MuiSnackbarContent: {
      root: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: '10px !important',
        boxShadow: 'none',
      },
    },
    MuiSlider: {
      root: {
        height: 10,
      },
      rail: {
        height: 10,
        borderRadius: 5,
        backgroundColor: corporateDesignDFHPalette.backgroundDark,
      },
      track: {
        height: 10,
        borderRadius: 5,
      },
      thumb: {
        width: 20,
        height: 20,
      },
    },
  },
});
