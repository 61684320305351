import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppHeader from '../../components/AppHeader/AppHeader';
import LearnContentCreationHeader from '../../components/Admin/LearnContentCreationHeader';
import LanguageSelectionStep from '../../components/Admin/CreationSteps/LanguageSelectionStep';
import { learnContentsActions, LearnContentData, TranslatedString, TranslatedFile } from '../../store/learnContents';
import TitleDescriptionThumbnailStep from '../../components/Admin/CreationSteps/TitleDescriptionThumbnailStep';
import UploadLearningMaterialsStep from '../../components/Admin/CreationSteps/UploadLearningMaterialsStep';
import ExaminationStep from '../../components/Admin/CreationSteps/ExaminationStep';
import { LearnContentTypes } from '../../enums/LearnContentTypes';
import LPLivesDueDateStep from '../../components/Admin/CreationSteps/LPLivesDueDateStep';
import TopicStep from '../../components/Admin/CreationSteps/TopicStep';
import { AppState } from '../../store';
import LoadingIndicatorFullScreen from '../../components/LoadingIndicator/LoadingIndicatorFullScreen';
import { useParams } from 'react-router-dom';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import LearnContentReassignDialog, { ReassignLearnContentType } from '../../components/Dialogs/LearnContentReassignDialog';
import BottomAppBar from '../../components/AppHeader/BottomAppBar';
import BasicContainer from '../../components/Container/BasicContainer';
import { Prompt } from 'react-router-dom';
import { Language } from '../../enums/Language';

const useStyles = makeStyles(theme => ({
  bottomNav: {
    marginTop: theme.spacing(6),
  },
  bottomNavButton: {
    padding: theme.spacing(1.5),
    minWidth: 200,
    boxShadow: 'none',
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const defaultLearnContent = {
    langs: [Language.DE],
    header: {} as TranslatedString,
    description: {} as TranslatedString,
    files: {} as TranslatedFile,
    questions: [],
    dueDate: null,
    topics: [],
    downloadable: false,
  } as LearnContentData;

  const { currentLearnContent, currentLearnContentData, loadingPost, loadingCurrentLearnContent } = useSelector(
    (state: AppState) => state.learnContents
  );

  const [learnContent, setLearnContent] = useState<LearnContentData>(defaultLearnContent);
  const [step, setStep] = useState<number>(0);
  const [showAssignDialog, setShowAssignDialog] = useState<boolean>(false);

  useEffect(() => {
    if (Boolean(id)) {
      dispatch(learnContentsActions.loadCurrentLearnContent(id));
    } else {
      setLearnContent(defaultLearnContent);
    }
  }, []);

  useEffect(() => {
    if (Boolean(id) && Boolean(currentLearnContentData)) {
      setLearnContent(currentLearnContentData);
    }
  }, [currentLearnContentData]);

  const allSteps = [
    {
      title: 'language-select-step',
      content: <LanguageSelectionStep mandatoryLanguages={[Language.DE]} learnContent={learnContent} setLearnContent={setLearnContent} />,
    },
    {
      title: 'title-description-thumbnail',
      content: <TitleDescriptionThumbnailStep learnContent={learnContent} setLearnContent={setLearnContent} />,
    },
    {
      title: 'upload-learning-materials',
      content: <UploadLearningMaterialsStep learnContent={learnContent} setLearnContent={setLearnContent} />,
    },
    {
      title: 'examination',
      content: <ExaminationStep learnContent={learnContent} setLearnContent={setLearnContent} />,
    },
    {
      title: 'lp-lives-due-date',
      content: <LPLivesDueDateStep learnContent={learnContent} setLearnContent={setLearnContent} />,
    },
    {
      title: 'topics',
      content: <TopicStep learnContent={learnContent} setLearnContent={setLearnContent} />,
    },
  ];

  const steps = learnContent.learnContentType !== LearnContentTypes.scorm ? allSteps : allSteps.filter((el, i) => i !== 3);

  const nextStep = () => {
    if (step === steps.length - 1) {
      if (Boolean(id)) {
        setShowAssignDialog(true);
      } else {
        dispatch(learnContentsActions.createLearnContent(learnContent));
      }
    } else {
      setStep(step + 1);
    }
  };

  const onReassign = (assignmentType: ReassignLearnContentType) => {
    setShowAssignDialog(false);
    dispatch(learnContentsActions.updateLearnContent({ oldLearnContent: currentLearnContent, learnContent, assignmentType }));
  };

  const validateStep = (): boolean => {
    switch (steps[step].title) {
      case 'language-select-step':
        return learnContent.langs?.length > 0;

      case 'title-description-thumbnail':
        const emptyFields = learnContent.langs?.find(
          lang => Boolean(learnContent.header?.[lang]) === false || Boolean(learnContent.description?.[lang]) === false
        );
        return Boolean(emptyFields) === false;

      case 'upload-learning-materials':
        const emptyFiles = learnContent.langs?.find(lang => Boolean(learnContent.files?.[lang]) === false);
        return Boolean(learnContent.learnContentType) && Boolean(emptyFiles) === false;

      case 'examination':
        if (learnContent.questionsDefined == null) {
          return false;
        }
        const emptyQuestions = learnContent.questions.find(
          question => question.value == null || learnContent.langs?.find(lang => Boolean(question.title?.[lang]) === false)
        );
        return Boolean(emptyQuestions) === false;

      case 'topics':
        if (learnContent.topicsDefined == null) {
          return false;
        }
        const emptyTopics = learnContent.topics.find(topic => learnContent.langs?.find(lang => Boolean(topic.title?.[lang]) === false));
        return Boolean(emptyTopics) === false;

      default:
        return true;
    }
  };

  const isValidStep = validateStep();
  const isSaved = steps[step].title === 'topics' && isValidStep && showAssignDialog === false && loadingPost === 'succeeded';

  return (
    <>
      <AppHeader tab="admin/learn-content-management" />
      <BasicContainer>
        {loadingCurrentLearnContent === 'pending' ? (
          <LoadingIndicator />
        ) : (
          <>
            <LearnContentCreationHeader stepHeader={steps[step].title} step={step} stepsNumber={steps.length} learnContent={learnContent} />
            {steps[step].content}
            <Grid container justify="space-between" className={classes.bottomNav} spacing={2}>
              <Grid item>
                {step !== 0 && (
                  <Button variant="contained" className={classes.bottomNavButton} onClick={() => setStep(step - 1)}>
                    {t('admin.create-learn-content.back')}
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.bottomNavButton}
                  onClick={nextStep}
                  key={`${!isValidStep}`}
                  disabled={!isValidStep}
                >
                  {t(step === steps.length - 1 ? 'admin.create-learn-content.done' : 'admin.create-learn-content.next')}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </BasicContainer>
      <BottomAppBar tab="admin/learn-content-management" />
      <LearnContentReassignDialog open={showAssignDialog} onClose={null} onSave={onReassign} />
      <LoadingIndicatorFullScreen open={loadingPost === 'pending'} />
      <Prompt message={t('admin.create-learn-content.prompt-message')} when={isSaved === false} />
    </>
  );
};
