import React, { useState, useEffect } from 'react';
import {
  Typography,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  FormControlLabel,
  DialogActions,
  Button,
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import LPUser from '../../sdk/com/apiomat/frontend/mylearningplatform/LPUser';
import EventIcon from '@material-ui/icons/Event';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Moment } from 'moment';
import { useStylesBase } from './BaseDailogStyles';
import { AssignDueDateContext } from '../../models/assign-due-date-context';
import { IOSSwitch } from '../Switch/IOSSwitch';
import CloseIcon from '@material-ui/icons/Close';

export interface CustomDueDateDialogProps {
  open: boolean;
  item?: LPUser;
  context: AssignDueDateContext;
  onClose: () => void;
  onSave: (dueDate: Date, context?: any) => void;
}

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    position: 'absolute',
    right: 0
  }
}))

const CustomDueDateDialog = (props: CustomDueDateDialogProps) => {
  const { open, item, context, onClose, onSave } = props;
  const classes = {...useStyles(), ...useStylesBase()};
  const { t } = useTranslation();

  const [dueDate, setDueDate] = useState<Moment>(null);
  const [updateExisting, setUpdateExisting] = useState<boolean>(false);

  const endOfDay = (moment: Moment): Moment => {
    return moment.add(23, 'hours').add(59, 'minutes');
  };
  
    useEffect(() => {
      setDueDate(Boolean(context?.originalDate) ? moment(context.originalDate) : null);
      setUpdateExisting(context?.updateExisting);
    }, [item, context]);

  return (
    <Dialog maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle className={classes.dialogContent}>
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={3}>
          <Grid item>
            <Typography component="span" variant="h5">
              <EventIcon className={classes.dialogIcon} />
              {t('admin.assignment.due-date-dialog.title')}
            </Typography>
          </Grid>
          <Grid item className={classes.closeBtn}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <Typography component="p" variant="body2">
          {t('admin.assignment.due-date-dialog.define-due-date')}
        </Typography>
        <KeyboardDatePicker
          inputVariant="outlined"
          clearable
          disablePast
          fullWidth
          margin="normal"
          value={dueDate}
          placeholder={t('admin.assignment.due-date-dialog.define-due-date-placeholder')}
          format="DD.MM.yyyy"
          onChange={value => setDueDate(endOfDay(value))}
        />
        {context?.allowUpdateExistingChange ? (
          <>
            <Divider className={classes.dialogDivider} />
            <FormControlLabel
              className={classes.dialogSwitchControl}
              control={
                <IOSSwitch
                  checked={updateExisting}
                  onChange={() => {
                    setUpdateExisting(!updateExisting);
                  }}
                />
              }
              label={
                <Typography component="label" variant="body2">
                  {t('admin.assignment.due-date-dialog.force-overwrite-of-existing-due-date')}
                </Typography>
              }
              labelPlacement="start"
            />
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.dialogSubmit}
          disabled={Boolean(dueDate) === false}
          onClick={() => onSave(dueDate.toDate(), { ...context, updateExisting })}
        >
          {t('admin.assignment.due-date-dialog.assign-selection')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDueDateDialog;
