import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/auth';
import { AppState } from '../../store/index';

export const toCountdown = milliseconds => {
  const leadingZeroes = num => {
    if (num < 10) {
      return `0${num}`;
    } else {
      return num;
    }
  };
  if (isNaN(milliseconds) || typeof milliseconds !== 'number') {
    return '...';
  } else {
           let temp;
           temp = milliseconds / 1000;
           let minutes = Math.floor(temp / 60);
           temp = temp % 60;
           let seconds = Math.floor(temp);
           return `${leadingZeroes(minutes)}:${leadingZeroes(seconds)}`;
         } 
};

const IdleTimerContainer = () => {
  const { appConfig } = useSelector((state: AppState) => state.appConfig);
  const dispatch = useDispatch();

  const DEFAULT_MAX_IDLE_TIME = 5 * 60 * 1000;  
  const timeUntilLogout = appConfig?.minutesUntilLogout * 60 * 1000 || DEFAULT_MAX_IDLE_TIME;

  const { getRemainingTime } = useIdleTimer({
    timeout: timeUntilLogout,
    onIdle: () => dispatch(authActions.logout()),
   });

  const [remainingTime, setRemainingTime] = useState(getRemainingTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(getRemainingTime());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

   return <>{toCountdown(remainingTime)}</>;
   };

export default IdleTimerContainer;
