import { updateLearnContent, updateNewLearnContentCount } from './../utils/assignment.utils';
import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, call, all, takeLatest } from 'redux-saga/effects';
import { notificationActions } from './notification';
import LearnContent from '../sdk/com/apiomat/frontend/mylearningplatform/LearnContent';
import { getLearnContentData, createLearnContent, archiveLearnContent } from '../utils/learnContents.utils';
import { deassignUser } from '../utils/assignment.utils';
import { LearnContentTypes } from '../enums/LearnContentTypes';
import { Moment } from 'moment';
import { ReassignLearnContentType } from '../components/Dialogs/LearnContentReassignDialog';
import { push } from 'connected-react-router';
import LPUser from '../sdk/com/apiomat/frontend/mylearningplatform/LPUser';
import Translation from '../sdk/com/apiomat/frontend/localisation/Translation';
import i18n from 'i18next';
import { ApiRequestState } from '../models/api-request-state';
import { authActions } from "./auth";

/** STATE */
export interface LearnContentsState {
  learnContents: LearnContent[];
  learnContentsMap: Map<string, LearnContent>;
  currentLearnContent: LearnContent;
  currentLearnContentData: LearnContentData;
  loading: ApiRequestState;
  loadingCurrentLearnContent: ApiRequestState;
  loadingPost: ApiRequestState;
}

const initialState: LearnContentsState = {
  learnContents: [],
  learnContentsMap: new Map<string, LearnContent>(),
  currentLearnContent: null,
  currentLearnContentData: null,
  loading: 'idle',
  loadingCurrentLearnContent: 'idle',
  loadingPost: 'idle',
};

/** TYPES */

export interface FileData {
  name: string;
  data: ArrayBuffer;
  imageUrl: string;
}

export interface TranslatedFile {
  de?: FileData;
  pl?: FileData;
  cs?: FileData;
  ru?: FileData;
}

export interface TranslatedString {
  de?: string;
  pl?: string;
  cs?: string;
  ru?: string;
}

export interface QuestionData {
  id: string;
  title: TranslatedString;
  value: boolean;
}

export interface TopicData {
  id: string;
  title: TranslatedString;
}

export interface LearnContentData {
  langs: string[];
  header: TranslatedString;
  description: TranslatedString;
  thumbnail: FileData;
  learnContentType: LearnContentTypes;
  files: TranslatedFile;
  questionsDefined: boolean;
  questions: QuestionData[];
  lp: number;
  lives: number;
  dueDate?: Moment;
  topicsDefined: boolean;
  topics: TopicData[];
  downloadable: boolean;
}

export interface LoadCurrentLearnContent {
  learnContent: LearnContent;
  learnContentData: LearnContentData;
}

export interface UpdateLearnContentData {
  oldLearnContent: LearnContent;
  learnContent: LearnContentData;
  assignmentType: ReassignLearnContentType;
}

/** SLICE */
const learnContentsSlice = createSlice({
  name: 'trainings',
  initialState,
  reducers: {
    loadLearnContents: state => {
      state.loading = 'pending';
    },
    loadLearnContentsSuccess: (state, action: PayloadAction<LearnContent[]>) => {
      const learnContents = action.payload;
      state.loading = 'succeeded';
      state.learnContents = learnContents;
      state.learnContentsMap = new Map(learnContents.map(learnContent => [learnContent.ID, learnContent]));
    },
    loadLearnContentsFailure: state => {
      state.loading = 'failed';
    },
    loadCurrentLearnContent: (state, _action: PayloadAction<string>) => {
      state.loadingCurrentLearnContent = 'pending';
      state.currentLearnContent = null;
      state.currentLearnContentData = null;
    },
    loadCurrentLearnContentSuccess: (state, action: PayloadAction<LoadCurrentLearnContent>) => {
      state.loadingCurrentLearnContent = 'succeeded';
      state.currentLearnContent = action.payload.learnContent;
      state.currentLearnContentData = action.payload.learnContentData;
    },
    loadCurrentLearnContentFailure: state => {
      state.loadingCurrentLearnContent = 'failed';
    },
    createLearnContent: (state, _action: PayloadAction<LearnContentData>) => {
      state.loadingPost = 'pending';
    },
    createLearnContentSuccess: state => {
      state.loadingPost = 'succeeded';
    },
    createLearnContentFailure: state => {
      state.loadingPost = 'failed';
    },
    updateLearnContent: (state, _action: PayloadAction<UpdateLearnContentData>) => {
      state.loadingPost = 'pending';
    },
    updateLearnContentSuccess: state => {
      state.loadingPost = 'succeeded';
    },
    updateLearnContentFailure: state => {
      state.loadingPost = 'failed';
    },
    archiveLearnContent: (state, _action: PayloadAction<LearnContent>) => {
      state.loadingPost = 'pending';
    },
    archiveLearnContentSuccess: state => {
      state.loadingPost = 'succeeded';
    },
    archiveLearnContentFailure: state => {
      state.loadingPost = 'failed';
    },
    deleteLearnContent: (state, _action: PayloadAction<LearnContent>) => {
      state.loadingPost = 'pending';
    },
    deleteLearnContentSuccess: state => {
      state.loadingPost = 'succeeded';
    },
    deleteLearnContentFailure: state => {
      state.loadingPost = 'failed';
    },
  },
});

export const learnContentsActions = learnContentsSlice.actions;
export const learnContentsReducer = learnContentsSlice.reducer;

/*
 * For some reason the sagas get invoked twice for a single dispatched action.
 * Therefore we track duplicate invocations by comparing the actions.
 *
 * TODO: This should rather be solved by proper configuration of redux-saga.
 */
const currentActions = {};
const preventDuplicateInvocation = (action: Action): boolean => currentActions[action.type] === action;

/** SAGAS */
function* onLoadLearnContents() {
  try {
    const learnContents: LearnContent[] = yield call(() => LearnContent.getLearnContents());
    yield put(learnContentsActions.loadLearnContentsSuccess(learnContents));
  } catch (error) {
    /*
     * Because we may receive an 'Authorization failed' error if no learnContents are assigned yet,
     * we have to assume it as a success
     */
    if (error && [204, 840].includes(error.statusCode)) {
      yield put(learnContentsActions.loadLearnContentsSuccess([]));
    } else {
      yield put(notificationActions.showError(error));
      yield put(learnContentsActions.loadLearnContentsFailure());
    }
  }
}

function* onLoadCurrentLearnContent(action: PayloadAction<string>) {
  const id = action.payload;

  try {
    const rows: LearnContent[] = yield call(() => LearnContent.getLearnContents(`id==id(${id})`));
    const learnContent = rows[0];
    const learnContentData = yield call(() => getLearnContentData(learnContent));

    yield put(learnContentsActions.loadCurrentLearnContentSuccess({ learnContent, learnContentData }));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(learnContentsActions.loadCurrentLearnContentFailure());
  }
}

function* onCreateLearnContent(action: PayloadAction<LearnContentData>) {
  if (preventDuplicateInvocation(action)) {
    return;
  } else {
    currentActions[action.type] = action;
  }

  const learnContentData = action.payload;

  try {
    yield call(() => createLearnContent(learnContentData));

    yield put(learnContentsActions.createLearnContentSuccess());
    yield put(push('/admin/learn-content-management'));
  } catch (error) {
    if (error.statusCode === 400) {
      yield put(notificationActions.showError(i18n.t('admin.create-learn-content.error')));
    } else {
      notificationActions.showError(error);
    }
    yield put(learnContentsActions.createLearnContentFailure());
  }
}

function* onUpdateLearnContent(action: PayloadAction<UpdateLearnContentData>) {
  if (preventDuplicateInvocation(action)) {
    return;
  } else {
    currentActions[action.type] = action;
  }

  const { oldLearnContent, learnContent, assignmentType } = action.payload;

  try {
    const newLearnContent = yield call(() => createLearnContent(learnContent));
    yield call(() => updateLearnContent(oldLearnContent.ID, assignmentType, newLearnContent));
    yield call(() => updateNewLearnContentCount(oldLearnContent.ID, newLearnContent.ID));

    yield put(learnContentsActions.updateLearnContentSuccess());
    yield put(authActions.reloadUser());
    yield put(push('/admin/learn-content-management'));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(learnContentsActions.updateLearnContentFailure());
  }
}

function* onDeleteLearnContent(action: PayloadAction<LearnContent>) {
  const learnContent = action.payload;

  try {
    /** delete learn progresses */
    const users: LPUser[] = yield call(() => LPUser.getLPUsers(`learnings.learnContentId=="${learnContent.ID}"`));
    yield all(users.map(user => deassignUser(user, learnContent.ID)));

    /* delete translations */
    yield all(
      learnContent.localizedLearnContents.map(async localLearnContent => {
        if (Boolean(localLearnContent.translationId) === false) {
          return;
        }
        const rows = await Translation.getTranslations(`id==id(${localLearnContent.translationId})`);
        if (rows[0]) {
          return rows[0].delete();
        }
      })
    );

    /** delete learn content */
    yield call(() => learnContent.delete());

    yield put(learnContentsActions.deleteLearnContentSuccess());
    yield put(learnContentsActions.loadLearnContents());
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(learnContentsActions.deleteLearnContentFailure());
  }
}

function* onArchiveLearnContent(action: PayloadAction<LearnContent>) {
  const learnContent = action.payload;

  try {
    yield call(() => archiveLearnContent(learnContent));
    yield put(learnContentsActions.deleteLearnContentSuccess());
    yield put(learnContentsActions.loadLearnContents());
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(learnContentsActions.deleteLearnContentFailure());
  }
}

export function* learnContentsSaga() {
  yield all([
    takeLatest(learnContentsActions.loadLearnContents, onLoadLearnContents),
    takeLatest(learnContentsActions.loadCurrentLearnContent, onLoadCurrentLearnContent),
    takeLatest(learnContentsActions.createLearnContent, onCreateLearnContent),
    takeLatest(learnContentsActions.updateLearnContent, onUpdateLearnContent),
    takeLatest(learnContentsActions.archiveLearnContent, onArchiveLearnContent),
    takeLatest(learnContentsActions.deleteLearnContent, onDeleteLearnContent),
  ]);
}
