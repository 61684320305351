import React, { useState } from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import LearnProgress from '../../sdk/com/apiomat/frontend/mylearningplatform/LearnProgress';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { getRestDays } from '../../utils/trainings.utils';
import EditIcon from '@material-ui/icons/Edit';
import AvTimerIcon from '@material-ui/icons/AvTimer';

const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 5,
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
      padding: '2px 10px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
      maxWidth: 54,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 11,
      padding: 2,
      textAlign: 'center',
    },
  },
  defaultBackground: {
    backgroundColor: theme.palette.background.default,
  },
  whiteBackground: {
    backgroundColor: theme.palette.common.white,
  },
  error: {
    color: theme.palette.error.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  button: {
    cursor: 'pointer',
  },
}));

export interface TrainingLabelProps {
  item?: LearnProgress;
  isSmall?: boolean;
  dueDate: Date;
  background?: 'white' | 'default';
  editable?: boolean;
  onClick?: () => void;
}

const TrainingLabel = (props: TrainingLabelProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { item, isSmall, dueDate, background, editable, onClick } = props;

  const [isOnHover, setIsOnHover] = useState<boolean>(false);

  if (!editable && Boolean(item) === false && Boolean(dueDate) === false) {
    return null;
  }

  if (!editable && Boolean(item) && Boolean(item.successDate) === false && Boolean(dueDate) === false) {
    return null;
  }

  let className: string;
  let title: string;
  let showSmallIcon = false;
  const restDays = getRestDays(dueDate);

  const shortLabel = isSmall ? '-short' : '';

  if (editable && Boolean(dueDate) === false) {
    className = null;
    title = t(`dashboard.no-due-date${shortLabel}`);
  } else if (Boolean(item) && Boolean(item.successDate)) {
    className = null;
    title = t(`dashboard.closed-on${shortLabel}`, { date: moment(item.successDate).format('DD.MM.YY') });
  } else if (restDays < 0) {
    className = classes.error;
    title = t(`dashboard.overdue-from${shortLabel}`, { date: moment(dueDate).format('DD.MM.YY') });
  } else if (restDays <= 14) {
    title = t(`dashboard.x-more-days${shortLabel}`, { count: restDays });
    className = classes.warning;
    showSmallIcon = isSmall;
  } else {
    title = t(`dashboard.due-to${shortLabel}`, { date: moment(dueDate).format('DD.MM.YY') });
    className = classes.success;
  }

  if (isOnHover) {
    className = classes.defaultBackground;
  }

  return (
    <Typography
      component="span"
      variant="subtitle2"
      className={clsx(
        classes.badge,
        background === 'white' ? classes.whiteBackground : classes.defaultBackground,
        className,
        isOnHover && classes.button
      )}
      onMouseEnter={() => setIsOnHover(editable)}
      onMouseLeave={() => setIsOnHover(false)}
      onClick={onClick}
    >
      {isOnHover && <EditIcon fontSize="inherit" className={classes.icon} />}
      {showSmallIcon && <AvTimerIcon fontSize="inherit" className={classes.icon} />}
      {title}
    </Typography>
  );
};

export default TrainingLabel;
