export default class AOMStatus {
    constructor() {
    }
    static getReasonPhrase(statusCode) {
        let phrase;
        switch (statusCode) {
            case this.SCRIPT_ERROR:
                phrase = "Script error!";
                break;
            case this.APPLICATION_NOT_ACTIVE:
                phrase = "Application is deactivated!";
                break;
            case this.BAD_IMAGE:
                phrase = "Image format seems to be corrupted!";
                break;
            case this.BAD_ID:
                phrase = "ID format is wrong!";
                break;
            case this.CONCURRENT_ACCESS:
                phrase = "Concurrent access forbidden!";
                break;
            case this.APPLICATION_SANDBOX:
                phrase = "Application is in sandbox mode!";
                break;
            case this.MODEL_NOT_DEPLOYED:
                phrase = "Class is not deployed!";
                break;
            case this.WRONG_REF_TYPE:
                phrase = "Wrong reference type!";
                break;
            case this.ATTRIBUTE_NOT_SET:
                phrase = "Attribute not set!";
                break;
            case this.OPERATION_NOT_POSSIBLE:
                phrase = "CRUD operation not possible on this class!";
                break;
            case this.APPLICATION_NAME_MISMATCH:
                phrase = "Application name does not match the one defined in the class!";
                break;
            case this.WRONG_AUTH_HEADER:
                phrase = "Wrong authentication header format, must be 'username:password'";
                break;
            case this.MODEL_STILL_USED:
                phrase = "Class is still used by other attributes, scripts or subclasses!'";
                break;
            case this.COLLECTION_NOT_ALLOWED:
                phrase = "Collection is not supported for this class type!";
                break;
            case this.FB_NO_VALID_MEMBER:
                phrase = "Request send from no valid member";
                break;
            case this.FB_NO_OAUTH_TOKEN:
                phrase = "Requesting member has no oAuth token, please authenticate! See http://doc.apiomat.com";
                break;
            case this.FB_POST_ID_MISSING:
                phrase = "Facebook post id has to be set!";
                break;
            case this.RESTORE_NO_DUMPS_FOUND:
                phrase = "No dumps for app on this date exist!";
                break;
            case this.TW_NO_VALID_MEMBER:
                phrase = "Request send from no valid member";
                break;
            case this.TW_NO_OAUTH_TOKEN:
                phrase = "Requesting member has no oAuth token, please authenticate! See http://doc.apiomat.com";
                break;
            case this.IMEXPORT_WRONG_ENCODING:
                phrase = "Wrong Encoding";
                break;
            case this.IMEXPORT_WRONG_CONTENT:
                phrase = "Wrong Filecontent";
                break;
            case this.PUSH_PAYLOAD_EXCEEDED:
                phrase = "Payload size exceeded!";
                break;
            case this.PUSH_ERROR:
                phrase = "Error in push request!";
                break;
            case this.BAD_EMAIL:
                phrase = "eMail format is wrong!";
                break;
            case this.BAD_PROMOTIONCODE_DISCOUNT:
                phrase = "Discount value is wrong!";
                break;
            case this.BAD_PROMOTIONCODE_CODE:
                phrase = "Code is invalid";
                break;
            case this.PLAN_PRICE:
                phrase = "Plan price must be >= 0!";
                break;
            case this.PLAN_NO_SYSTEMS:
                phrase = "Plan must have at least one system!";
                break;
            case this.SCRIPT_TIME_ERROR:
                phrase = "Script was interrupted, execution took too long.";
                break;
            case this.INVALID_NAME:
                phrase = "Name must start with a letter, followed only by letters or numbers.";
                break;
            case this.ATTRIBUTE_IN_SUPERCLASS:
                phrase = "Attribute is already defined in superclass.";
                break;
            case this.JSON_TYPE_ERROR:
                phrase = "The @type is not correctly defined in your JSON (must be: MODULENAME$CLASSNAME";
                break;
            case this.TBLR_NO_VALID_MEMBER:
                phrase = "Request send from no valid member";
                break;
            case this.TBLR_NO_OAUTH_TOKEN:
                phrase = "Requesting member has no oAuth token, please authenticate! See http://doc.apiomat.com";
                break;
            case this.TBLR_POST_ID_MISSING:
                phrase = "Tumblr post id has to be set!";
                break;
            case this.LOCATION_INVALID:
                phrase = "Location data is invalid (latitude or longitude missing)!";
                break;
            case this.SCRIPT_EXCEPTION:
                phrase = "Exception was raised in external code!";
                break;
            case this.BAD_ACCOUNTNAME:
                phrase = "Account name must contain only characters A-Z,a-z or 0-9!";
                break;
            case this.BAD_IMAGE_ALPHA:
                phrase = "alpha is wrong (must be a double value between 0.0 and 1.0)";
                break;
            case this.BAD_IMAGE_BGCOLOR:
                phrase = "bgcolor is wrong (must be an RGB hex value without #, like 'FF0000' for red)";
                break;
            case this.BAD_IMAGE_FORMAT:
                phrase = "format is wrong (can only be png, gif, bmp or jpg/jpeg)";
                break;
            case this.QUERY_ERROR:
                phrase = "Query could not be parsed!";
                break;
            case this.BAD_TYPE_IN_QUERY:
                phrase = "The query contains a value with the wrong type";
                break;
            case this.UNKNOWN_CLASS_IN_QUERY:
                phrase = "The definition of the class couldn't be found";
                break;
            case this.WRONG_NUM_FORMAT_IN_QUERY:
                phrase = "A number was supplied in the wrong format";
                break;
            case this.QUERY_PARSE_ERROR:
                phrase = "The query couldn't be parsed";
                break;
            case this.UNKNOWN_ATTRIBUTE_IN_QUERY:
                phrase = "An attribute that was used in the query doesn't exist in the class";
                break;
            case this.FOREIGNID_NOT_SET:
                phrase = "Foreign ID must be set to a unique value for this class!";
                break;
            case this.CLASSES_MISSING:
                phrase = "Not all classes for this module are contained in the jar! This will lead to compile errors.";
                break;
            case this.INVALID_ATTRIBUTE_TYPE:
                phrase = "Attributes type is invalid";
                break;
            case this.TOKEN_VALUE_EXISTS:
                phrase = "The token value already exists";
                break;
            case this.JSON_FORMAT_ERROR:
                phrase = "JSON could not be parsed";
                break;
            case this.MODULE_DEPLOYMENT:
                phrase = "Module is currently deploying. Please try again later.";
                break;
            case this.BAD_USERNAME:
                phrase = "User name must not contain a ':'.";
                break;
            case this.CSV_ZIP_FORMAT:
                phrase = "CSV import only accepts a single .zip file!";
                break;
            case this.VERIFICATION:
                phrase = "Verification error";
                break;
            case this.MODULE_STILL_USED:
                phrase = "Module is still used by other modules of this app!'";
                break;
            case this.CLASS_NOT_IN_MODULE:
                phrase = "Model name not found for this module!";
                break;
            case this.ORDER_TRANSACTION_TIMESTAMP_OUTDATED:
                phrase = "Transaction outdated!";
                break;
            case this.ORDER_TRANSACTION_ID_INVALID:
                phrase = "Transaction ID invalid!";
                break;
            case this.ORDER_TRANSACTION_SECRET_INVALID:
                phrase = "Transaction secret invalid!";
                break;
            case this.MANDATORY_FIELD:
                phrase = "Mandatory fields must not be empty or null!";
                break;
            case this.INVALID_PASSWD_LENGTH:
                phrase = "Password must have a length of at least 6 characters!";
                break;
            case this.BAD_PROMOTIONCODE_VALID:
                phrase = "Valid from/to of Code is null";
                break;
            case this.BAD_CLASS_NAME_SAME_AS_MODULE:
                phrase = "Class name must not be the same as the module name!";
                break;
            case this.NO_ORG_MEMBER:
                phrase = "Customer is not a member of the organization";
                break;
            case this.MODULE_CLASS_NOT_CONTAINED:
                phrase = "Module main class is not contained in the uploaded file! Probably wrong module uploaded?";
                break;
            case this.BAD_GROUP_NAME:
                phrase = "Account name must contain only characters A-Z,a-z or 0-9!";
                break;
            case this.INVISIBLE_CLASS:
                phrase = "Class is not visible to REST!";
                break;
            case this.MODULE_TYPE_NOT_ALLOWED:
                phrase = "The action is not allowed for this module type";
                break;
            case this.MAX_FILE_SIZE:
                phrase = "File is larger than maximum file size!";
                break;
            case this.BAD_RESOURCE:
                phrase = "Resource seems to be corrupted or is unexpectedly null or empty.";
                break;
            case this.BEFORE_POST_DATA_HOOK:
                phrase = "An error occured during calling the beforePostData hook method";
                break;
            case this.SAVE_DATA:
                phrase = "An error occured during saving the data (image/file)";
                break;
            case this.UPDATE_OBJECT_WITH_DATA:
                phrase = "The object couldn't be updated with the URL of the saved data (image/file)";
                break;
            case this.AFTER_POST_DATA_HOOK:
                phrase = "An error occured during calling the afterPostData hook method";
                break;
            case this.ABORT_SAVE_DATA:
                phrase = "Saving the data was aborted by beforePostData hook method";
                break;
            case this.ATTRIBUTE_NOT_FOUND:
                phrase = "The attribute doesn't exist";
                break;
            case this.SYSTEM_NOT_FOUND:
                phrase = "The system doesn't exist";
                break;
            case this.BEFORE_DELETE_DATA_HOOK:
                phrase = "An error occured during calling the beforeDeleteData hook method";
                break;
            case this.ABORT_DELETE_DATA:
                phrase = "deleting the data was aborted by beforedeleteData hook method";
                break;
            case this.UPDATE_OBJECT_WITH_DELETED_DATA:
                phrase = "The object couldn't be updated with the deleted URL of the data (image/file)";
                break;
            case this.TRANSIENT_DATA_DELETE_UNSUCCESSFUL:
                phrase = "The deletion of the data or removal of the data (image/file) reference from the object was unsuccessful in the doDeleteData() Native Module hook method";
                break;
            case this.DELETE_DATA_FAILED:
                phrase = "Deleting the data (image/file) from the database failed";
                break;
            case this.PASSWORD_POLICY_RULE_VIOLATED:
                phrase = "The password doesn't fulfill the password policies.";
                break;
            case this.PASSWORD_LOCKED:
                phrase = "Your password is locked, so you are currently not allowed to access this resource.";
                break;
            case this.NO_OBJECT_ID:
                phrase = "The object doesn't have an ID (non-transient) or foreign ID (transient) yet";
                break;
            case this.ACCOUNT_TEMPORARY_BLOCKED:
                phrase = "Your account is currently blocked due to failing login attempts.";
                break;
            case this.DATA_NOT_ATTACHED_TO_OBJECT:
                phrase = "The object does not contain data with the given ID.";
                break;
            case this.UI_BAD_ATTRIBUTE:
                phrase = "The userinterface contains a bad value.";
                break;
            case this.INVALID_ATTRIBUTE_REGEX:
                phrase = "The validation pattern is not a valid regular expression";
                break;
            case this.INVALID_ATTRIBUTE_MAX_LENGTH:
                phrase = "The max length cannot be lower than min length";
                break;
            case this.BAD_REF_DELETION:
                phrase = "The reference you're trying to delete from the object is not referenced on the object";
                break;
            case this.ENCRYPTION_INVALID_TYPE:
                phrase = "The attribute type is not supported for client-side encryption";
                break;
            case this.APPLICATION_NOT_FOUND:
                phrase = "Application was not found!";
                break;
            case this.CUSTOMER_NOT_FOUND:
                phrase = "Customer was not found!";
                break;
            case this.ID_NOT_FOUND:
                phrase = "ID was not found!";
                break;
            case this.MODEL_NOT_FOUND:
                phrase = "Class was not found! Maybe you should (re-)deploy first?";
                break;
            case this.MODULE_NOT_FOUND:
                phrase = "Module or version was not found!";
                break;
            case this.PLAN_NOT_FOUND:
                phrase = "Plan was not found!";
                break;
            case this.PROMOCODE_NOT_FOUND:
                phrase = "Promotion code not valid!";
                break;
            case this.DEMOAPP_NOT_FOUND:
                phrase = "This language has no demo content";
                break;
            case this.ORGANIZATION_NOT_FOUND:
                phrase = "Organization was not found!";
                break;
            case this.GROUP_NOT_FOUND:
                phrase = "Group was not found!";
                break;
            case this.ACCOUNT_NOT_FOUND:
                phrase = "Account was not found!";
                break;
            case this.DEFAULT_MODULE_NOT_FOUND:
                phrase = "Default module was not found for the given account";
                break;
            case this.UI_NOT_FOUND:
                phrase = "User Interface or version was not found!";
                break;
            case this.MODULE_USE_FORBIDDEN:
                phrase = "Required module is not attached to app";
                break;
            case this.PUSH_ERROR_APIKEY:
                phrase = "No API Key defined for Push service!";
                break;
            case this.PUSH_ERROR_CERTIFICATE:
                phrase = "No certificate defined for Push service!";
                break;
            case this.SAME_NAME_USED_IN_SUPERCLASS:
                phrase = "Same name is already used in a superclass.";
                break;
            case this.PAYMENT_MAX_MODULE:
                phrase = "Maximum number of used modules exceeded for this plan.";
                break;
            case this.PAYMENT_SYSTEM:
                phrase = "Selected system use is not allowed for this plan.";
                break;
            case this.PAYMENT_DOWNGRADE:
                phrase = "Up/Downgrading plans is only allowed for super admins.";
                break;
            case this.SAVE_REFERENECE_BEFORE_REFERENCING:
                phrase = "Object you are trying to reference is not on the server. Please save it first.";
                break;
            case this.PAYMENT_DB_SIZE:
                phrase = "Used database size exceeds plan";
                break;
            case this.ENDPOINT_PATH_NOT_ALLOWED:
                phrase = "Endpoint not allowed for app; please add path to your app's config.";
                break;
            case this.PAYMENT_NO_CRON:
                phrase = "Cronjobs are not allowed for this plan.";
                break;
            case this.PAYMENT_MODULE_NOT_FREE:
                phrase = "This module is not available for free plan.";
                break;
            case this.LICENSE_INVALID:
                phrase = "Your license does not allow this action.";
                break;
            case this.PAYMENT_NO_CUSTOMERROLES:
                phrase = "Customer role usage is not available for free plan.";
                break;
            case this.WHITELABEL:
                phrase = "Only available for whitelabel installations.";
                break;
            case this.WHITELABEL_NOT:
                phrase = "Not available for whitelabel installations.";
                break;
            case this.MODULE_CONFIG_NO_DOT:
                phrase = "No dot allowed in module config key.";
                break;
            case this.PLAN_FALLBACK:
                phrase = "Application cannot be activated without valid plan.";
                break;
            case this.PLAN_INACTIVE:
                phrase = "Plan is not selectable!";
                break;
            case this.ENTERPRISE:
                phrase = "Only available for enterprise installations.";
                break;
            case this.ACCOUNT_UNACCEPTED_CONTRACTS:
                phrase = "Account has unaccepted Contracts";
                break;
            case this.DELETE_MANDATORY_DEFAULT_MODULE:
                phrase = "It is not allowed to remove this default module";
                break;
            case this.ID_EXISTS:
                phrase = "ID exists!";
                break;
            case this.NAME_RESERVED:
                phrase = "Name is reserved!";
                break;
            case this.CIRCULAR_DEPENDENCY:
                phrase = "You can't set circular inheritance!";
                break;
            case this.NAME_EXISTS:
                phrase = "Name is already used!";
                break;
            case this.EMAIL_EXISTS:
                phrase = "E-mail is already used!";
                break;
            case this.CUSTOMER_IN_ORG:
                phrase = "Customer is already member of an organization";
                break;
            case this.PUSH_ERROR_PROXY:
                phrase = "Proxy configuration caused an error for Push service!";
                break;
            case this.UNAUTHORIZED:
                phrase = "Authorization failed!";
                break;
            case this.WRONG_APIKEY:
                phrase = "API Key was not correct!";
                break;
            case this.EVALANCHE_UNAUTH:
                phrase = "Authorization failed! Maybe username/password was not set for evelanche configuration?";
                break;
            case this.PW_CHANGE_W_TOKEN:
                phrase = "Not authorized to change a user's password when authenticating with a token.";
                break;
            case this.TOKEN_AUTH_ERROR:
                phrase = "The token could not be authenticated";
                break;
            case this.TOKEN_READ_ONLY:
                phrase = "The token can only be used for read requests.";
                break;
            case this.AUTHENTICATION_REJECTED:
                phrase = "Authentication with username/password was rejected by third-party-system.";
                break;
            case this.AUTHORIZATION_REJECTED:
                phrase = "Requesting user may be authenticated, but was not authorized to access (CRUD) the requested resource by custom role check method";
                break;
            case this.CRUD_ERROR:
                phrase = "Internal error during CRUD operation";
                break;
            case this.IMEXPORT_ERROR:
                phrase = "Error during im/export!";
                break;
            case this.COMPILE_ERROR:
                phrase = "Classes could not be compiled!";
                break;
            case this.REFERENCE_ERROR:
                phrase = "Error in class reference!";
                break;
            case this.PUSH_PAYLOAD_ERROR:
                phrase = "Failed to create payload!";
                break;
            case this.PUSH_SEND_ERROR:
                phrase = "Failed to send message(s)!";
                break;
            case this.PUSH_INIT_FAILED:
                phrase = "Failed to initialize push service!";
                break;
            case this.FACEBOOK_ERROR:
                phrase = "An error occured while communicating with facebook!";
                break;
            case this.FACEBOOK_OAUTH_ERROR:
                phrase = "facebook throws oAuth error! Please show login dialog again";
                break;
            case this.FACEBOOK_OAUTH_ERROR2:
                phrase = "Received OAuth2 error from Facebook";
                break;
            case this.MEMBER_NOT_FOUND:
                phrase = "Can't find member with this id/username";
                break;
            case this.WORDPRESS_FETCH_DATA_ERROR:
                phrase = "Can't fetch data for wordpress blog";
                break;
            case this.TUMBLR_OAUTH_ERROR:
                phrase = "tumblr threw oAuth error! Please show login dialog again";
                break;
            case this.TUMBLR_ERROR:
                phrase = "Error communicating with tumblr!";
                break;
            case this.EXECUTE_METHOD_ERROR_PRIMITIVE:
                phrase = "Only primitive types are allowed";
                break;
            case this.EXECUTE_METHOD_ERROR:
                phrase = "Execute method failed";
                break;
            case this.OAUTH_TOKEN_REQUEST_ERROR:
                phrase = "An error occured during requesting an ApiOmat OAuth2 token";
                break;
            case this.FINDING_RESOURCE_ERROR:
                phrase = "An error occured while trying to find the resource";
                break;
            case this.NATIVEMODULE_DEPLOY:
                phrase = "Executing onDeploy failed";
                break;
            case this.TOKEN_SEARCH_ERROR:
                phrase = "An error occured while searching for tokens";
                break;
            case this.MODULE_CONFIG_MISSING:
                phrase = "Your module seems not to be configured properly";
                break;
            case this.NATIVEMODULE_INIT:
                phrase = "Could not initialize git repository";
                break;
            case this.NATIVEMODULE_PULL:
                phrase = "Could not pull git repository";
                break;
            case this.NATIVEMODULE_PUSH:
                phrase = "Could not push git repository";
                break;
            case this.NO_DOGET_RETURN:
                phrase = "The module's doGet didn't return a model";
                break;
            case this.CUSTOMER_TWO_ORGS:
                phrase = "The customer was found in two organizations";
                break;
            case this.NATIVEMODULE_HOOKS_NOT_FOUND:
                phrase = "Annotated hook class not found";
                break;
            case this.ANALYTICS_ERROR:
                phrase = "The analytics instance couldn't process the request correctly";
                break;
            case this.EMAIL_ERROR:
                phrase = "Error during sending email";
                break;
            case this.MAX_HOOK_CALL_DEPTH_EXCEEDED:
                phrase = "The maximum call depth for hook methods was exceeded";
                break;
            case this.MALFORMED_CUSTOM_CRON_METHOD:
                phrase = "Detected malformed custom cron methods";
                break;
            case this.ANALYTICS_CONNECTION_ERROR:
                phrase = "The configured analytics instance is not available";
                break;
            case this.REF_POST:
                phrase = "Error during adding a reference to a parent object";
                break;
            case this.REF_DELETE:
                phrase = "Error during deleting a reference from a parent object";
                break;
            case this.BOUNCER_ERROR:
                phrase = "The authentication service instance couldn't process the request correctly";
                break;
            case this.HREF_NOT_FOUND:
                phrase = "Class has no HREF; please save it first!";
                break;
            case this.WRONG_URI_SYNTAX:
                phrase = "URI syntax is wrong";
                break;
            case this.WRONG_CLIENT_PROTOCOL:
                phrase = "Client protocol is wrong";
                break;
            case this.IO_EXCEPTION:
                phrase = "IOException was thrown";
                break;
            case this.UNSUPPORTED_ENCODING:
                phrase = "Encoding is not supported";
                break;
            case this.INSTANTIATE_EXCEPTION:
                phrase = "Error on class instantiation";
                break;
            case this.IN_PERSISTING_PROCESS:
                phrase = "Object is in persisting process. Please try again later";
                break;
            case this.VERIFY_SOCIALMEDIA:
                phrase = "Can't verify against social media provider";
                break;
            case this.TOO_MANY_LOCALIDS:
                phrase = "Can't create more localIDs. Please try again later";
                break;
            case this.MAX_CACHE_SIZE_REACHED:
                phrase = "The maximum cache size is reached.";
                break;
            case this.CANT_WRITE_IN_CACHE:
                phrase = "Can't persist data to cache.";
                break;
            case this.BAD_DATASTORE_CONFIG:
                phrase = "For requesting a session token without a refresh token, the Datastore must be configured with a username and password";
                break;
            case this.NO_TOKEN_RECEIVED:
                phrase = "The response didn't contain a token";
                break;
            case this.NO_NETWORK:
                phrase = "No network connection available";
                break;
            case this.ID_NOT_FOUND_OFFLINE:
                phrase = "ID was not found in offline storage";
                break;
            case this.ATTACHED_HREF_MISSING:
                phrase = "HREF of attached image / file / reference is missing";
                break;
            case this.REQUEST_TIMEOUT:
                phrase = "The request timed out during connecting or reading the response";
                break;
            case this.ASYNC_WAIT_ERROR:
                phrase = "An error occured during waiting for an async task to finish";
                break;
            case this.IN_DELETING_PROCESS:
                phrase = "Object is in deleting process. Please try again later";
                break;
            case this.SSO_REDIRECT:
                phrase = "The request was redirected to an SSO Identity Provider";
                break;
            case this.MANUAL_CONCURRENT_WRITE_FAILED:
                phrase = "Concurrent write to own concurrent data type failed";
                break;
            case this.SAVE_FAILED:
                phrase = "Load not executed because save already failed";
                break;
            case this.SSL_ERROR:
                phrase = "An error occurred during establishing a secure connection";
                break;
            case this.MAX_FILE_SIZE_OFFLINE_EXCEEDED:
                phrase = "The max file size for offline saving is exceeded";
                break;
            case this.SQL_CONSTRAINT:
                phrase = "An error occurred because of an SQL constraint (for example unique ForeignID";
                break;
            case this.UNEXPECTED_STATUS_CODE:
                phrase = "The returned status code was not expected.";
                break;
            case this.EMBEDDED_REF_SELF:
                phrase = "An object cannot embed itself";
                break;
            case this.EMBEDDED_REF:
                phrase = "You can't add a non-embedded reference to an embedded object";
                break;
            case this.EMBEDDED_POST_EXISTING_OBJECT:
                phrase = "You can't post an existing object as an embedded object";
                break;
            case this.NOT_EMBEDDED_CHILD:
                phrase = "The object is not an embedded child of the parent";
                break;
            case this.JSON_PROCESSING:
                phrase = "Problems encountered when processing (parsing, generating) JSON content";
                break;
            case this.REF_REMOVE_OFFLINE:
                phrase = "The reference you're trying to remove from storage isn't referenced";
                break;
            case this.MALICIOUS_MEMBER:
                phrase = "Malicious use of member detected!";
                break;
            default:
                phrase = "No reason found";
        }
        return phrase;
    }
    static getStatusForCode(httpCode) {
        let statusCode;
        switch (httpCode) {
            case 701:
                statusCode = this.SCRIPT_ERROR;
                break;
            case 702:
                statusCode = this.APPLICATION_NOT_ACTIVE;
                break;
            case 703:
                statusCode = this.BAD_IMAGE;
                break;
            case 704:
                statusCode = this.BAD_ID;
                break;
            case 705:
                statusCode = this.CONCURRENT_ACCESS;
                break;
            case 706:
                statusCode = this.APPLICATION_SANDBOX;
                break;
            case 707:
                statusCode = this.MODEL_NOT_DEPLOYED;
                break;
            case 709:
                statusCode = this.WRONG_REF_TYPE;
                break;
            case 710:
                statusCode = this.ATTRIBUTE_NOT_SET;
                break;
            case 711:
                statusCode = this.OPERATION_NOT_POSSIBLE;
                break;
            case 712:
                statusCode = this.APPLICATION_NAME_MISMATCH;
                break;
            case 713:
                statusCode = this.WRONG_AUTH_HEADER;
                break;
            case 714:
                statusCode = this.MODEL_STILL_USED;
                break;
            case 715:
                statusCode = this.COLLECTION_NOT_ALLOWED;
                break;
            case 716:
                statusCode = this.FB_NO_VALID_MEMBER;
                break;
            case 717:
                statusCode = this.FB_NO_OAUTH_TOKEN;
                break;
            case 718:
                statusCode = this.FB_POST_ID_MISSING;
                break;
            case 719:
                statusCode = this.RESTORE_NO_DUMPS_FOUND;
                break;
            case 720:
                statusCode = this.TW_NO_VALID_MEMBER;
                break;
            case 721:
                statusCode = this.TW_NO_OAUTH_TOKEN;
                break;
            case 722:
                statusCode = this.IMEXPORT_WRONG_ENCODING;
                break;
            case 723:
                statusCode = this.IMEXPORT_WRONG_CONTENT;
                break;
            case 724:
                statusCode = this.PUSH_PAYLOAD_EXCEEDED;
                break;
            case 725:
                statusCode = this.PUSH_ERROR;
                break;
            case 726:
                statusCode = this.BAD_EMAIL;
                break;
            case 727:
                statusCode = this.BAD_PROMOTIONCODE_DISCOUNT;
                break;
            case 728:
                statusCode = this.BAD_PROMOTIONCODE_CODE;
                break;
            case 729:
                statusCode = this.PLAN_PRICE;
                break;
            case 730:
                statusCode = this.PLAN_NO_SYSTEMS;
                break;
            case 731:
                statusCode = this.SCRIPT_TIME_ERROR;
                break;
            case 732:
                statusCode = this.INVALID_NAME;
                break;
            case 733:
                statusCode = this.ATTRIBUTE_IN_SUPERCLASS;
                break;
            case 734:
                statusCode = this.JSON_TYPE_ERROR;
                break;
            case 735:
                statusCode = this.TBLR_NO_VALID_MEMBER;
                break;
            case 736:
                statusCode = this.TBLR_NO_OAUTH_TOKEN;
                break;
            case 737:
                statusCode = this.TBLR_POST_ID_MISSING;
                break;
            case 738:
                statusCode = this.LOCATION_INVALID;
                break;
            case 739:
                statusCode = this.SCRIPT_EXCEPTION;
                break;
            case 740:
                statusCode = this.BAD_ACCOUNTNAME;
                break;
            case 746:
                statusCode = this.BAD_IMAGE_ALPHA;
                break;
            case 747:
                statusCode = this.BAD_IMAGE_BGCOLOR;
                break;
            case 748:
                statusCode = this.BAD_IMAGE_FORMAT;
                break;
            case 708:
                statusCode = this.QUERY_ERROR;
                break;
            case 741:
                statusCode = this.BAD_TYPE_IN_QUERY;
                break;
            case 742:
                statusCode = this.UNKNOWN_CLASS_IN_QUERY;
                break;
            case 743:
                statusCode = this.WRONG_NUM_FORMAT_IN_QUERY;
                break;
            case 744:
                statusCode = this.QUERY_PARSE_ERROR;
                break;
            case 745:
                statusCode = this.UNKNOWN_ATTRIBUTE_IN_QUERY;
                break;
            case 749:
                statusCode = this.FOREIGNID_NOT_SET;
                break;
            case 750:
                statusCode = this.CLASSES_MISSING;
                break;
            case 751:
                statusCode = this.INVALID_ATTRIBUTE_TYPE;
                break;
            case 752:
                statusCode = this.TOKEN_VALUE_EXISTS;
                break;
            case 753:
                statusCode = this.JSON_FORMAT_ERROR;
                break;
            case 754:
                statusCode = this.MODULE_DEPLOYMENT;
                break;
            case 755:
                statusCode = this.BAD_USERNAME;
                break;
            case 756:
                statusCode = this.CSV_ZIP_FORMAT;
                break;
            case 757:
                statusCode = this.VERIFICATION;
                break;
            case 758:
                statusCode = this.MODULE_STILL_USED;
                break;
            case 759:
                statusCode = this.CLASS_NOT_IN_MODULE;
                break;
            case 760:
                statusCode = this.ORDER_TRANSACTION_TIMESTAMP_OUTDATED;
                break;
            case 761:
                statusCode = this.ORDER_TRANSACTION_ID_INVALID;
                break;
            case 762:
                statusCode = this.ORDER_TRANSACTION_SECRET_INVALID;
                break;
            case 763:
                statusCode = this.MANDATORY_FIELD;
                break;
            case 764:
                statusCode = this.INVALID_PASSWD_LENGTH;
                break;
            case 765:
                statusCode = this.BAD_PROMOTIONCODE_VALID;
                break;
            case 766:
                statusCode = this.BAD_CLASS_NAME_SAME_AS_MODULE;
                break;
            case 767:
                statusCode = this.NO_ORG_MEMBER;
                break;
            case 768:
                statusCode = this.MODULE_CLASS_NOT_CONTAINED;
                break;
            case 769:
                statusCode = this.BAD_GROUP_NAME;
                break;
            case 770:
                statusCode = this.INVISIBLE_CLASS;
                break;
            case 771:
                statusCode = this.MODULE_TYPE_NOT_ALLOWED;
                break;
            case 772:
                statusCode = this.MAX_FILE_SIZE;
                break;
            case 773:
                statusCode = this.BAD_RESOURCE;
                break;
            case 774:
                statusCode = this.BEFORE_POST_DATA_HOOK;
                break;
            case 775:
                statusCode = this.SAVE_DATA;
                break;
            case 776:
                statusCode = this.UPDATE_OBJECT_WITH_DATA;
                break;
            case 777:
                statusCode = this.AFTER_POST_DATA_HOOK;
                break;
            case 778:
                statusCode = this.ABORT_SAVE_DATA;
                break;
            case 779:
                statusCode = this.ATTRIBUTE_NOT_FOUND;
                break;
            case 780:
                statusCode = this.SYSTEM_NOT_FOUND;
                break;
            case 781:
                statusCode = this.BEFORE_DELETE_DATA_HOOK;
                break;
            case 782:
                statusCode = this.ABORT_DELETE_DATA;
                break;
            case 783:
                statusCode = this.UPDATE_OBJECT_WITH_DELETED_DATA;
                break;
            case 784:
                statusCode = this.TRANSIENT_DATA_DELETE_UNSUCCESSFUL;
                break;
            case 785:
                statusCode = this.DELETE_DATA_FAILED;
                break;
            case 786:
                statusCode = this.PASSWORD_POLICY_RULE_VIOLATED;
                break;
            case 787:
                statusCode = this.PASSWORD_LOCKED;
                break;
            case 788:
                statusCode = this.NO_OBJECT_ID;
                break;
            case 789:
                statusCode = this.ACCOUNT_TEMPORARY_BLOCKED;
                break;
            case 790:
                statusCode = this.DATA_NOT_ATTACHED_TO_OBJECT;
                break;
            case 791:
                statusCode = this.UI_BAD_ATTRIBUTE;
                break;
            case 792:
                statusCode = this.INVALID_ATTRIBUTE_REGEX;
                break;
            case 793:
                statusCode = this.INVALID_ATTRIBUTE_MAX_LENGTH;
                break;
            case 794:
                statusCode = this.BAD_REF_DELETION;
                break;
            case 795:
                statusCode = this.ENCRYPTION_INVALID_TYPE;
                break;
            case 801:
                statusCode = this.APPLICATION_NOT_FOUND;
                break;
            case 802:
                statusCode = this.CUSTOMER_NOT_FOUND;
                break;
            case 803:
                statusCode = this.ID_NOT_FOUND;
                break;
            case 804:
                statusCode = this.MODEL_NOT_FOUND;
                break;
            case 805:
                statusCode = this.MODULE_NOT_FOUND;
                break;
            case 807:
                statusCode = this.PLAN_NOT_FOUND;
                break;
            case 808:
                statusCode = this.PROMOCODE_NOT_FOUND;
                break;
            case 809:
                statusCode = this.DEMOAPP_NOT_FOUND;
                break;
            case 810:
                statusCode = this.ORGANIZATION_NOT_FOUND;
                break;
            case 811:
                statusCode = this.GROUP_NOT_FOUND;
                break;
            case 812:
                statusCode = this.ACCOUNT_NOT_FOUND;
                break;
            case 813:
                statusCode = this.DEFAULT_MODULE_NOT_FOUND;
                break;
            case 814:
                statusCode = this.UI_NOT_FOUND;
                break;
            case 820:
                statusCode = this.MODULE_USE_FORBIDDEN;
                break;
            case 821:
                statusCode = this.PUSH_ERROR_APIKEY;
                break;
            case 822:
                statusCode = this.PUSH_ERROR_CERTIFICATE;
                break;
            case 823:
                statusCode = this.SAME_NAME_USED_IN_SUPERCLASS;
                break;
            case 824:
                statusCode = this.PAYMENT_MAX_MODULE;
                break;
            case 825:
                statusCode = this.PAYMENT_SYSTEM;
                break;
            case 826:
                statusCode = this.PAYMENT_DOWNGRADE;
                break;
            case 827:
                statusCode = this.SAVE_REFERENECE_BEFORE_REFERENCING;
                break;
            case 828:
                statusCode = this.PAYMENT_DB_SIZE;
                break;
            case 829:
                statusCode = this.ENDPOINT_PATH_NOT_ALLOWED;
                break;
            case 1820:
                statusCode = this.PAYMENT_NO_CRON;
                break;
            case 1821:
                statusCode = this.PAYMENT_MODULE_NOT_FREE;
                break;
            case 1823:
                statusCode = this.LICENSE_INVALID;
                break;
            case 1824:
                statusCode = this.PAYMENT_NO_CUSTOMERROLES;
                break;
            case 1825:
                statusCode = this.WHITELABEL;
                break;
            case 1826:
                statusCode = this.WHITELABEL_NOT;
                break;
            case 1827:
                statusCode = this.MODULE_CONFIG_NO_DOT;
                break;
            case 1828:
                statusCode = this.PLAN_FALLBACK;
                break;
            case 1829:
                statusCode = this.PLAN_INACTIVE;
                break;
            case 1830:
                statusCode = this.ENTERPRISE;
                break;
            case 1831:
                statusCode = this.ACCOUNT_UNACCEPTED_CONTRACTS;
                break;
            case 1832:
                statusCode = this.DELETE_MANDATORY_DEFAULT_MODULE;
                break;
            case 830:
                statusCode = this.ID_EXISTS;
                break;
            case 831:
                statusCode = this.NAME_RESERVED;
                break;
            case 832:
                statusCode = this.CIRCULAR_DEPENDENCY;
                break;
            case 833:
                statusCode = this.NAME_EXISTS;
                break;
            case 834:
                statusCode = this.EMAIL_EXISTS;
                break;
            case 835:
                statusCode = this.CUSTOMER_IN_ORG;
                break;
            case 836:
                statusCode = this.PUSH_ERROR_PROXY;
                break;
            case 840:
                statusCode = this.UNAUTHORIZED;
                break;
            case 841:
                statusCode = this.WRONG_APIKEY;
                break;
            case 842:
                statusCode = this.EVALANCHE_UNAUTH;
                break;
            case 843:
                statusCode = this.PW_CHANGE_W_TOKEN;
                break;
            case 844:
                statusCode = this.TOKEN_AUTH_ERROR;
                break;
            case 845:
                statusCode = this.TOKEN_READ_ONLY;
                break;
            case 846:
                statusCode = this.AUTHENTICATION_REJECTED;
                break;
            case 847:
                statusCode = this.AUTHORIZATION_REJECTED;
                break;
            case 901:
                statusCode = this.CRUD_ERROR;
                break;
            case 902:
                statusCode = this.IMEXPORT_ERROR;
                break;
            case 903:
                statusCode = this.COMPILE_ERROR;
                break;
            case 904:
                statusCode = this.REFERENCE_ERROR;
                break;
            case 905:
                statusCode = this.PUSH_PAYLOAD_ERROR;
                break;
            case 906:
                statusCode = this.PUSH_SEND_ERROR;
                break;
            case 907:
                statusCode = this.PUSH_INIT_FAILED;
                break;
            case 908:
                statusCode = this.FACEBOOK_ERROR;
                break;
            case 910:
                statusCode = this.FACEBOOK_OAUTH_ERROR;
                break;
            case 917:
                statusCode = this.FACEBOOK_OAUTH_ERROR2;
                break;
            case 911:
                statusCode = this.MEMBER_NOT_FOUND;
                break;
            case 912:
                statusCode = this.WORDPRESS_FETCH_DATA_ERROR;
                break;
            case 913:
                statusCode = this.TUMBLR_OAUTH_ERROR;
                break;
            case 914:
                statusCode = this.TUMBLR_ERROR;
                break;
            case 915:
                statusCode = this.EXECUTE_METHOD_ERROR_PRIMITIVE;
                break;
            case 916:
                statusCode = this.EXECUTE_METHOD_ERROR;
                break;
            case 918:
                statusCode = this.OAUTH_TOKEN_REQUEST_ERROR;
                break;
            case 919:
                statusCode = this.FINDING_RESOURCE_ERROR;
                break;
            case 920:
                statusCode = this.NATIVEMODULE_DEPLOY;
                break;
            case 921:
                statusCode = this.TOKEN_SEARCH_ERROR;
                break;
            case 922:
                statusCode = this.MODULE_CONFIG_MISSING;
                break;
            case 923:
                statusCode = this.NATIVEMODULE_INIT;
                break;
            case 924:
                statusCode = this.NATIVEMODULE_PULL;
                break;
            case 925:
                statusCode = this.NATIVEMODULE_PUSH;
                break;
            case 926:
                statusCode = this.NO_DOGET_RETURN;
                break;
            case 927:
                statusCode = this.CUSTOMER_TWO_ORGS;
                break;
            case 928:
                statusCode = this.NATIVEMODULE_HOOKS_NOT_FOUND;
                break;
            case 929:
                statusCode = this.ANALYTICS_ERROR;
                break;
            case 930:
                statusCode = this.EMAIL_ERROR;
                break;
            case 931:
                statusCode = this.MAX_HOOK_CALL_DEPTH_EXCEEDED;
                break;
            case 932:
                statusCode = this.MALFORMED_CUSTOM_CRON_METHOD;
                break;
            case 933:
                statusCode = this.ANALYTICS_CONNECTION_ERROR;
                break;
            case 934:
                statusCode = this.REF_POST;
                break;
            case 935:
                statusCode = this.REF_DELETE;
                break;
            case 936:
                statusCode = this.BOUNCER_ERROR;
                break;
            case 601:
                statusCode = this.HREF_NOT_FOUND;
                break;
            case 602:
                statusCode = this.WRONG_URI_SYNTAX;
                break;
            case 603:
                statusCode = this.WRONG_CLIENT_PROTOCOL;
                break;
            case 604:
                statusCode = this.IO_EXCEPTION;
                break;
            case 605:
                statusCode = this.UNSUPPORTED_ENCODING;
                break;
            case 606:
                statusCode = this.INSTANTIATE_EXCEPTION;
                break;
            case 607:
                statusCode = this.IN_PERSISTING_PROCESS;
                break;
            case 608:
                statusCode = this.VERIFY_SOCIALMEDIA;
                break;
            case 609:
                statusCode = this.TOO_MANY_LOCALIDS;
                break;
            case 610:
                statusCode = this.MAX_CACHE_SIZE_REACHED;
                break;
            case 611:
                statusCode = this.CANT_WRITE_IN_CACHE;
                break;
            case 612:
                statusCode = this.BAD_DATASTORE_CONFIG;
                break;
            case 613:
                statusCode = this.NO_TOKEN_RECEIVED;
                break;
            case 614:
                statusCode = this.NO_NETWORK;
                break;
            case 615:
                statusCode = this.ID_NOT_FOUND_OFFLINE;
                break;
            case 616:
                statusCode = this.ATTACHED_HREF_MISSING;
                break;
            case 617:
                statusCode = this.REQUEST_TIMEOUT;
                break;
            case 618:
                statusCode = this.ASYNC_WAIT_ERROR;
                break;
            case 619:
                statusCode = this.IN_DELETING_PROCESS;
                break;
            case 620:
                statusCode = this.SSO_REDIRECT;
                break;
            case 621:
                statusCode = this.MANUAL_CONCURRENT_WRITE_FAILED;
                break;
            case 622:
                statusCode = this.SAVE_FAILED;
                break;
            case 623:
                statusCode = this.SSL_ERROR;
                break;
            case 624:
                statusCode = this.MAX_FILE_SIZE_OFFLINE_EXCEEDED;
                break;
            case 625:
                statusCode = this.SQL_CONSTRAINT;
                break;
            case 626:
                statusCode = this.UNEXPECTED_STATUS_CODE;
                break;
            case 627:
                statusCode = this.EMBEDDED_REF_SELF;
                break;
            case 628:
                statusCode = this.EMBEDDED_REF;
                break;
            case 629:
                statusCode = this.EMBEDDED_POST_EXISTING_OBJECT;
                break;
            case 630:
                statusCode = this.NOT_EMBEDDED_CHILD;
                break;
            case 631:
                statusCode = this.JSON_PROCESSING;
                break;
            case 632:
                statusCode = this.REF_REMOVE_OFFLINE;
                break;
            case 950:
                statusCode = this.MALICIOUS_MEMBER;
                break;
        }
        return statusCode;
    }
}
AOMStatus.SCRIPT_ERROR = 701;
AOMStatus.APPLICATION_NOT_ACTIVE = 702;
AOMStatus.BAD_IMAGE = 703;
AOMStatus.BAD_ID = 704;
AOMStatus.CONCURRENT_ACCESS = 705;
AOMStatus.APPLICATION_SANDBOX = 706;
AOMStatus.MODEL_NOT_DEPLOYED = 707;
AOMStatus.WRONG_REF_TYPE = 709;
AOMStatus.ATTRIBUTE_NOT_SET = 710;
AOMStatus.OPERATION_NOT_POSSIBLE = 711;
AOMStatus.APPLICATION_NAME_MISMATCH = 712;
AOMStatus.WRONG_AUTH_HEADER = 713;
AOMStatus.MODEL_STILL_USED = 714;
AOMStatus.COLLECTION_NOT_ALLOWED = 715;
AOMStatus.FB_NO_VALID_MEMBER = 716;
AOMStatus.FB_NO_OAUTH_TOKEN = 717;
AOMStatus.FB_POST_ID_MISSING = 718;
AOMStatus.RESTORE_NO_DUMPS_FOUND = 719;
AOMStatus.TW_NO_VALID_MEMBER = 720;
AOMStatus.TW_NO_OAUTH_TOKEN = 721;
AOMStatus.IMEXPORT_WRONG_ENCODING = 722;
AOMStatus.IMEXPORT_WRONG_CONTENT = 723;
AOMStatus.PUSH_PAYLOAD_EXCEEDED = 724;
AOMStatus.PUSH_ERROR = 725;
AOMStatus.BAD_EMAIL = 726;
AOMStatus.BAD_PROMOTIONCODE_DISCOUNT = 727;
AOMStatus.BAD_PROMOTIONCODE_CODE = 728;
AOMStatus.PLAN_PRICE = 729;
AOMStatus.PLAN_NO_SYSTEMS = 730;
AOMStatus.SCRIPT_TIME_ERROR = 731;
AOMStatus.INVALID_NAME = 732;
AOMStatus.ATTRIBUTE_IN_SUPERCLASS = 733;
AOMStatus.JSON_TYPE_ERROR = 734;
AOMStatus.TBLR_NO_VALID_MEMBER = 735;
AOMStatus.TBLR_NO_OAUTH_TOKEN = 736;
AOMStatus.TBLR_POST_ID_MISSING = 737;
AOMStatus.LOCATION_INVALID = 738;
AOMStatus.SCRIPT_EXCEPTION = 739;
AOMStatus.BAD_ACCOUNTNAME = 740;
AOMStatus.BAD_IMAGE_ALPHA = 746;
AOMStatus.BAD_IMAGE_BGCOLOR = 747;
AOMStatus.BAD_IMAGE_FORMAT = 748;
AOMStatus.QUERY_ERROR = 708;
AOMStatus.BAD_TYPE_IN_QUERY = 741;
AOMStatus.UNKNOWN_CLASS_IN_QUERY = 742;
AOMStatus.WRONG_NUM_FORMAT_IN_QUERY = 743;
AOMStatus.QUERY_PARSE_ERROR = 744;
AOMStatus.UNKNOWN_ATTRIBUTE_IN_QUERY = 745;
AOMStatus.FOREIGNID_NOT_SET = 749;
AOMStatus.CLASSES_MISSING = 750;
AOMStatus.INVALID_ATTRIBUTE_TYPE = 751;
AOMStatus.TOKEN_VALUE_EXISTS = 752;
AOMStatus.JSON_FORMAT_ERROR = 753;
AOMStatus.MODULE_DEPLOYMENT = 754;
AOMStatus.BAD_USERNAME = 755;
AOMStatus.CSV_ZIP_FORMAT = 756;
AOMStatus.VERIFICATION = 757;
AOMStatus.MODULE_STILL_USED = 758;
AOMStatus.CLASS_NOT_IN_MODULE = 759;
AOMStatus.ORDER_TRANSACTION_TIMESTAMP_OUTDATED = 760;
AOMStatus.ORDER_TRANSACTION_ID_INVALID = 761;
AOMStatus.ORDER_TRANSACTION_SECRET_INVALID = 762;
AOMStatus.MANDATORY_FIELD = 763;
AOMStatus.INVALID_PASSWD_LENGTH = 764;
AOMStatus.BAD_PROMOTIONCODE_VALID = 765;
AOMStatus.BAD_CLASS_NAME_SAME_AS_MODULE = 766;
AOMStatus.NO_ORG_MEMBER = 767;
AOMStatus.MODULE_CLASS_NOT_CONTAINED = 768;
AOMStatus.BAD_GROUP_NAME = 769;
AOMStatus.INVISIBLE_CLASS = 770;
AOMStatus.MODULE_TYPE_NOT_ALLOWED = 771;
AOMStatus.MAX_FILE_SIZE = 772;
AOMStatus.BAD_RESOURCE = 773;
AOMStatus.BEFORE_POST_DATA_HOOK = 774;
AOMStatus.SAVE_DATA = 775;
AOMStatus.UPDATE_OBJECT_WITH_DATA = 776;
AOMStatus.AFTER_POST_DATA_HOOK = 777;
AOMStatus.ABORT_SAVE_DATA = 778;
AOMStatus.ATTRIBUTE_NOT_FOUND = 779;
AOMStatus.SYSTEM_NOT_FOUND = 780;
AOMStatus.BEFORE_DELETE_DATA_HOOK = 781;
AOMStatus.ABORT_DELETE_DATA = 782;
AOMStatus.UPDATE_OBJECT_WITH_DELETED_DATA = 783;
AOMStatus.TRANSIENT_DATA_DELETE_UNSUCCESSFUL = 784;
AOMStatus.DELETE_DATA_FAILED = 785;
AOMStatus.PASSWORD_POLICY_RULE_VIOLATED = 786;
AOMStatus.PASSWORD_LOCKED = 787;
AOMStatus.NO_OBJECT_ID = 788;
AOMStatus.ACCOUNT_TEMPORARY_BLOCKED = 789;
AOMStatus.DATA_NOT_ATTACHED_TO_OBJECT = 790;
AOMStatus.UI_BAD_ATTRIBUTE = 791;
AOMStatus.INVALID_ATTRIBUTE_REGEX = 792;
AOMStatus.INVALID_ATTRIBUTE_MAX_LENGTH = 793;
AOMStatus.BAD_REF_DELETION = 794;
AOMStatus.ENCRYPTION_INVALID_TYPE = 795;
AOMStatus.APPLICATION_NOT_FOUND = 801;
AOMStatus.CUSTOMER_NOT_FOUND = 802;
AOMStatus.ID_NOT_FOUND = 803;
AOMStatus.MODEL_NOT_FOUND = 804;
AOMStatus.MODULE_NOT_FOUND = 805;
AOMStatus.PLAN_NOT_FOUND = 807;
AOMStatus.PROMOCODE_NOT_FOUND = 808;
AOMStatus.DEMOAPP_NOT_FOUND = 809;
AOMStatus.ORGANIZATION_NOT_FOUND = 810;
AOMStatus.GROUP_NOT_FOUND = 811;
AOMStatus.ACCOUNT_NOT_FOUND = 812;
AOMStatus.DEFAULT_MODULE_NOT_FOUND = 813;
AOMStatus.UI_NOT_FOUND = 814;
AOMStatus.MODULE_USE_FORBIDDEN = 820;
AOMStatus.PUSH_ERROR_APIKEY = 821;
AOMStatus.PUSH_ERROR_CERTIFICATE = 822;
AOMStatus.SAME_NAME_USED_IN_SUPERCLASS = 823;
AOMStatus.PAYMENT_MAX_MODULE = 824;
AOMStatus.PAYMENT_SYSTEM = 825;
AOMStatus.PAYMENT_DOWNGRADE = 826;
AOMStatus.SAVE_REFERENECE_BEFORE_REFERENCING = 827;
AOMStatus.PAYMENT_DB_SIZE = 828;
AOMStatus.ENDPOINT_PATH_NOT_ALLOWED = 829;
AOMStatus.PAYMENT_NO_CRON = 1820;
AOMStatus.PAYMENT_MODULE_NOT_FREE = 1821;
AOMStatus.LICENSE_INVALID = 1823;
AOMStatus.PAYMENT_NO_CUSTOMERROLES = 1824;
AOMStatus.WHITELABEL = 1825;
AOMStatus.WHITELABEL_NOT = 1826;
AOMStatus.MODULE_CONFIG_NO_DOT = 1827;
AOMStatus.PLAN_FALLBACK = 1828;
AOMStatus.PLAN_INACTIVE = 1829;
AOMStatus.ENTERPRISE = 1830;
AOMStatus.ACCOUNT_UNACCEPTED_CONTRACTS = 1831;
AOMStatus.DELETE_MANDATORY_DEFAULT_MODULE = 1832;
AOMStatus.ID_EXISTS = 830;
AOMStatus.NAME_RESERVED = 831;
AOMStatus.CIRCULAR_DEPENDENCY = 832;
AOMStatus.NAME_EXISTS = 833;
AOMStatus.EMAIL_EXISTS = 834;
AOMStatus.CUSTOMER_IN_ORG = 835;
AOMStatus.PUSH_ERROR_PROXY = 836;
AOMStatus.UNAUTHORIZED = 840;
AOMStatus.WRONG_APIKEY = 841;
AOMStatus.EVALANCHE_UNAUTH = 842;
AOMStatus.PW_CHANGE_W_TOKEN = 843;
AOMStatus.TOKEN_AUTH_ERROR = 844;
AOMStatus.TOKEN_READ_ONLY = 845;
AOMStatus.AUTHENTICATION_REJECTED = 846;
AOMStatus.AUTHORIZATION_REJECTED = 847;
AOMStatus.CRUD_ERROR = 901;
AOMStatus.IMEXPORT_ERROR = 902;
AOMStatus.COMPILE_ERROR = 903;
AOMStatus.REFERENCE_ERROR = 904;
AOMStatus.PUSH_PAYLOAD_ERROR = 905;
AOMStatus.PUSH_SEND_ERROR = 906;
AOMStatus.PUSH_INIT_FAILED = 907;
AOMStatus.FACEBOOK_ERROR = 908;
AOMStatus.FACEBOOK_OAUTH_ERROR = 910;
AOMStatus.FACEBOOK_OAUTH_ERROR2 = 917;
AOMStatus.MEMBER_NOT_FOUND = 911;
AOMStatus.WORDPRESS_FETCH_DATA_ERROR = 912;
AOMStatus.TUMBLR_OAUTH_ERROR = 913;
AOMStatus.TUMBLR_ERROR = 914;
AOMStatus.EXECUTE_METHOD_ERROR_PRIMITIVE = 915;
AOMStatus.EXECUTE_METHOD_ERROR = 916;
AOMStatus.OAUTH_TOKEN_REQUEST_ERROR = 918;
AOMStatus.FINDING_RESOURCE_ERROR = 919;
AOMStatus.NATIVEMODULE_DEPLOY = 920;
AOMStatus.TOKEN_SEARCH_ERROR = 921;
AOMStatus.MODULE_CONFIG_MISSING = 922;
AOMStatus.NATIVEMODULE_INIT = 923;
AOMStatus.NATIVEMODULE_PULL = 924;
AOMStatus.NATIVEMODULE_PUSH = 925;
AOMStatus.NO_DOGET_RETURN = 926;
AOMStatus.CUSTOMER_TWO_ORGS = 927;
AOMStatus.NATIVEMODULE_HOOKS_NOT_FOUND = 928;
AOMStatus.ANALYTICS_ERROR = 929;
AOMStatus.EMAIL_ERROR = 930;
AOMStatus.MAX_HOOK_CALL_DEPTH_EXCEEDED = 931;
AOMStatus.MALFORMED_CUSTOM_CRON_METHOD = 932;
AOMStatus.ANALYTICS_CONNECTION_ERROR = 933;
AOMStatus.REF_POST = 934;
AOMStatus.REF_DELETE = 935;
AOMStatus.BOUNCER_ERROR = 936;
AOMStatus.HREF_NOT_FOUND = 601;
AOMStatus.WRONG_URI_SYNTAX = 602;
AOMStatus.WRONG_CLIENT_PROTOCOL = 603;
AOMStatus.IO_EXCEPTION = 604;
AOMStatus.UNSUPPORTED_ENCODING = 605;
AOMStatus.INSTANTIATE_EXCEPTION = 606;
AOMStatus.IN_PERSISTING_PROCESS = 607;
AOMStatus.VERIFY_SOCIALMEDIA = 608;
AOMStatus.TOO_MANY_LOCALIDS = 609;
AOMStatus.MAX_CACHE_SIZE_REACHED = 610;
AOMStatus.CANT_WRITE_IN_CACHE = 611;
AOMStatus.BAD_DATASTORE_CONFIG = 612;
AOMStatus.NO_TOKEN_RECEIVED = 613;
AOMStatus.NO_NETWORK = 614;
AOMStatus.ID_NOT_FOUND_OFFLINE = 615;
AOMStatus.ATTACHED_HREF_MISSING = 616;
AOMStatus.REQUEST_TIMEOUT = 617;
AOMStatus.ASYNC_WAIT_ERROR = 618;
AOMStatus.IN_DELETING_PROCESS = 619;
AOMStatus.SSO_REDIRECT = 620;
AOMStatus.MANUAL_CONCURRENT_WRITE_FAILED = 621;
AOMStatus.SAVE_FAILED = 622;
AOMStatus.SSL_ERROR = 623;
AOMStatus.MAX_FILE_SIZE_OFFLINE_EXCEEDED = 624;
AOMStatus.SQL_CONSTRAINT = 625;
AOMStatus.UNEXPECTED_STATUS_CODE = 626;
AOMStatus.EMBEDDED_REF_SELF = 627;
AOMStatus.EMBEDDED_REF = 628;
AOMStatus.EMBEDDED_POST_EXISTING_OBJECT = 629;
AOMStatus.NOT_EMBEDDED_CHILD = 630;
AOMStatus.JSON_PROCESSING = 631;
AOMStatus.REF_REMOVE_OFFLINE = 632;
AOMStatus.MALICIOUS_MEMBER = 950;
