import { createCustomRestUrl } from './aom.utils';
import axios from 'axios';
import * as FileSaver from 'file-saver';
import User from '../sdk/com/apiomat/frontend/basics/User';
import Datastore from '../sdk/com/apiomat/frontend/Datastore';
import SdkInfo from '../sdk/com/apiomat/frontend/SdkInfo';
import AbstractClientDataModel from '../sdk/com/apiomat/frontend/AbstractClientDataModel';
import LPUser from '../sdk/com/apiomat/frontend/mylearningplatform/LPUser';
import i18n from 'i18next';

export const getFileTypeName = (name: string): { fileType: string; fileName: string } => {
  const nameArr = name.split('.');
  const fileType = nameArr.pop();
  const fileName = nameArr.join('');
  return { fileType, fileName };
};

export const getFileLink = (aomObj: AbstractClientDataModel, attribute: string) => {
  const { AOMBASEURL, AOMAPIKEY, AOMSYS } = User;
  const moduleName = aomObj['moduleName'];
  const modelName = aomObj['modelName'];
  const moduleVersion = SdkInfo.getVersion(moduleName);

  const resourceUrl = aomObj['dao'][`${attribute}URL`];
  const fileId = resourceUrl.substring(resourceUrl.lastIndexOf('/') + 1);

  return `${AOMBASEURL}/data/files/${moduleName}/v/${moduleVersion}/${modelName}/${aomObj.ID}/${attribute}/${fileId}.img?apiKey=${AOMAPIKEY}&system=${AOMSYS}`;
};

export const getRestrictedFileLink = async (
  aomObj: AbstractClientDataModel,
  attribute: string,
  validity: number = 360,
  oneTime: boolean = true
): Promise<string> => {
  const { AOMBASEURL, AOMAPIKEY, AOMSYS } = User;
  const moduleName = aomObj['moduleName'];
  const modelName = aomObj['modelName'];
  const moduleVersion = SdkInfo.getVersion(moduleName);

  const resourceUrl = aomObj['dao'][`${attribute}URL`];
  const fileId = resourceUrl.substring(resourceUrl.lastIndexOf('/') + 1);

  const url = `${AOMBASEURL}/data/files/${moduleName}/v/${moduleVersion}/${modelName}/${aomObj.ID}/${attribute}/${fileId}.key?apiKey=${AOMAPIKEY}&system=${AOMSYS}`;

  const res = await axios.post(url, `validity=${validity}&oneTime=${oneTime}`, {
    headers: Datastore.Instance.getHeader(true, { contentType: 'application/x-www-form-urlencoded' }),
  });

  const tokenUrl = res.headers['location'];
  if (Boolean(tokenUrl) === false) {
    throw new Error('No location header returned');
  }
  return tokenUrl;
};

export const onDownloadFile = async (item: AbstractClientDataModel, attribute: string, validity?: number, oneTime?: boolean) => {
  const link = await getRestrictedFileLink(item, attribute, validity, oneTime);
  if (Boolean(link)) {
    window.open(link, '_blank');
  }
};

export const onDownloadFileByLink = async (item: AbstractClientDataModel, attribute: string) => {
  const link = item[`${attribute}URL`];
  if (Boolean(link)) {
    try {
      await axios.get(link, { responseType: 'blob' }).then(response => {
        const fileName = (response.headers['content-disposition'] || '').replace('attachment; filename=', '');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        FileSaver.saveAs(url, fileName);
      });
    } catch (e) {
      console.error(e);
      throw new Error(i18n.t('errors.no-window-opened'));
    }
  }
};

export const onDownloadAll = async user => {
  const employeeNo = user.foreignId;
  const url = createCustomRestUrl(`/zipCertificates/${employeeNo}`);

  return await axios.get(url, { responseType: 'blob', headers: Datastore.Instance.getHeader(false, {}) }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    FileSaver.saveAs(url, 'certificates.zip');
  });
};

export const onDownloadCSV = async (userQuery: string) => {
  const moduleName = LPUser.staticGetModuleName();
  const body = { userQuery };
  const url = `${LPUser.AOMBASEURL.replace('apps', `modules/${moduleName}/v/${SdkInfo.getVersion(moduleName)}`)}/spec/report/learnProgress`;
  return await axios.post(url, body, { responseType: 'blob', headers: Datastore.Instance.getHeader(false, {}) }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    FileSaver.saveAs(url, 'report.csv');
  });
};
