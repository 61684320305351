import React, { useState, useEffect } from 'react';
import { makeStyles, Typography, Theme, Button, Grid, ButtonBase, useTheme, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import clsx from 'clsx';
import Question from '../../sdk/com/apiomat/frontend/mylearningplatform/Question';
import BasicContainer from '../Container/BasicContainer';

const useStyles = makeStyles((theme: Theme) => ({
  questionTitle: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  button: {
    background: theme.palette.common.white,
    borderRadius: 10,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(7),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
  buttonIcon: {
    fontSize: 40,
  },
  colorYes: {
    color: theme.palette.success.main,
  },
  colorNo: {
    color: theme.palette.primary.main,
  },
  selectedButton: {
    boxShadow: '0px 0px 10px #00000029',
  },
  answerBox: {
    borderRadius: 10,
    boxShadow: '0px 0px 10px #00000029',
    padding: theme.spacing(4),
    margin: theme.spacing(4, 0),
  },
  answerBoxText: {
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
  answerBoxIcon: {
    marginRight: theme.spacing(3),
    marginTop: -1,
  },
  answerBoxButton: {
    width: 200,
  },
}));

export interface TrainingQuestionProps {
  question: Question;
  lang: string;
  onAnswer: (isAnswerCorrect: boolean) => void;
  onNextQuestion: () => void;
}

const TrainingQuestion = (props: TrainingQuestionProps) => {
  const { question, lang, onAnswer, onNextQuestion } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentAnswer, setCurrentAnswer] = useState<number>(null);

  useEffect(() => {
    setCurrentAnswer(null);
  }, [question]);

  if (Boolean(question) === false) {
    return null;
  }

  const isAnswerCorrect = currentAnswer === question.isAnswerTrue;

  const onSetAnswer = (value: number) => {
    if (currentAnswer == null) {
      onAnswer(value === question.isAnswerTrue);
    }
    setCurrentAnswer(value);
  };

  return (
    <BasicContainer>
      <Typography component="h2" variant="h5" className={classes.questionTitle}>
        {t(question.textKey, { lng: lang })}
      </Typography>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <ButtonBase
            disabled={currentAnswer === 0}
            className={clsx(classes.button, currentAnswer === 1 && classes.selectedButton)}
            onClick={() => onSetAnswer(1)}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <CheckCircleIcon className={clsx(classes.buttonIcon, classes.colorYes)} />
              </Grid>
              <Grid item>
                <Typography component="p" variant="subtitle1">
                  {t('training.answer-yes')}
                </Typography>
              </Grid>
            </Grid>
          </ButtonBase>
        </Grid>
        <Grid item xs={6}>
          <ButtonBase
            disabled={currentAnswer === 1}
            className={clsx(classes.button, currentAnswer === 0 && classes.selectedButton)}
            onClick={() => onSetAnswer(0)}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <CancelIcon className={clsx(classes.buttonIcon, classes.colorNo)} />
              </Grid>
              <Grid item>
                <Typography component="p" variant="subtitle1">
                  {t('training.answer-no')}
                </Typography>
              </Grid>
            </Grid>
          </ButtonBase>
        </Grid>
      </Grid>
      {currentAnswer != null && (
        <div className={classes.answerBox}>
          <Grid container direction="row" justify={isSmall ? 'center' : 'space-between'} alignItems="center" spacing={3}>
            <Grid item>
              <Typography variant="subtitle2" className={classes.answerBoxText}>
                {isAnswerCorrect ? (
                  <>
                    <CheckCircleIcon className={clsx(classes.answerBoxIcon, classes.colorYes)} /> {t('training.your-answer-is-correct')}
                  </>
                ) : (
                  <>
                    <CancelIcon className={clsx(classes.answerBoxIcon, classes.colorNo)} /> {t('training.your-answer-is-incorrect')}
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" size="large" className={classes.answerBoxButton} onClick={onNextQuestion}>
                {t('training.next')}
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </BasicContainer>
  );
};

export default TrainingQuestion;
