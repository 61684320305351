import React from 'react';
import { Typography, Dialog, DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStylesBase } from './BaseDailogStyles';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

export interface TrainingCertificateLoaderDialogProps {
  open: boolean;
}

const TrainingCertificateLoaderDialog = (props: TrainingCertificateLoaderDialogProps) => {
  const { open } = props;

  const classes = useStylesBase();
  const { t } = useTranslation();

  return (
    <Dialog maxWidth="xs" open={open}>
      <DialogContent className={classes.dialogContent}>
        <Typography component="p" variant="body2" align='center'>
          { t('training.certificate-generation-info') }
        </Typography>
        <LoadingIndicator />
      </DialogContent>
    </Dialog>
  );
};

export default TrainingCertificateLoaderDialog;
