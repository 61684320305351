import React from 'react';
import { makeStyles, Typography, Grid, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import LearnContent from '../../sdk/com/apiomat/frontend/mylearningplatform/LearnContent';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles(() => ({
  createdAt: {
    fontStyle: 'italic',
  },
  rightText: {
    textAlign: 'center',
  },
}));

export interface LearnContentInfoProps {
  item: LearnContent;
  onItemClick?: () => void;
}

const LearnContentInfo = (props: LearnContentInfoProps) => {
  const { item, onItemClick } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container spacing={2} alignItems="center" justify={isSmall ? 'center' : 'flex-end'}>
      <Grid item className={classes.rightText}>
        <Typography variant="body2" component="span" className={classes.createdAt}>
          {item.isArchived === 1
            ? t('admin.archieved', { date: moment(item.archivingDate).format('DD.MM.yyyy') })
            : t('admin.created-at', { date: moment(item.createdAt).format('DD.MM.yyyy') })}
        </Typography>
      </Grid>
      {Boolean(onItemClick) && (
        <Grid item>
          <IconButton color="primary" onClick={onItemClick}>
            <InfoOutlinedIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default LearnContentInfo;
