import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, Theme, TextField, CircularProgress, Chip, List, Typography } from '@material-ui/core';
import LPUser from '../../sdk/com/apiomat/frontend/mylearningplatform/LPUser';
import { getUserName } from '../../utils/users.utils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import UserSearchItem from './UserSearchItem';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { notificationActions } from '../../store/notification';
import uniq from 'lodash.uniq';
import debounce from 'lodash.debounce';
import { LPTeam } from '../../sdk/com/apiomat/frontend/mylearningplatform';

const SEARCH_LIMIT = 10;

const useStyles = makeStyles((theme: Theme) => ({
  search: {
    margin: theme.spacing(1, 0, 2),
  },
  searchInput: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ': {
      borderColor: theme.palette.primary.main,
    },
  },
  selectedSearch: {
    '& .MuiInputLabel-outlined': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  moreUsersLabel: {
    margin: theme.spacing(1),
    textAlign: 'center',
    fontStyle: 'italic',
  },
}));

export interface UserSelectionAutocompleteProps {
  learnContentId?: string;
  selectedUsers: LPUser[];
  setSelectedUsers?: (users: LPUser[]) => void;
  ignoredUserIds?: string[];
}

/* Keep track of the latest request by incrementing the counter */
let currentRequestCursor = 0;


const UserSelectionAutocomplete = (props: UserSelectionAutocompleteProps) => {
  const { selectedUsers, setSelectedUsers, ignoredUserIds } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<LPUser[]>([]);
  const [inputValue, setInputValue] = useState('');

  const clearLearnings = (user: LPUser) => {
    user['hashmap'].learnings = [];
    return user;
  };

  const onLoadUsers = async (filter?: string) => {
    const cursorHead = ++currentRequestCursor;

    setLoading(true);
    try {
      const queries = [];
      if (filter) {
        queries.push(`nameSearchField like "${filter}"`);
      }
      selectedUsers.forEach(user => {
        queries.push(`id!=id(${user.ID})`);
      });

      if (ignoredUserIds) {
        ignoredUserIds.forEach(id => queries.push(`id!=id(${id})`));
      }

      const options = await LPUser.getLPUsers(`${queries.join(' and ')} order by lastName limit ${SEARCH_LIMIT}`);

      /* Load all teams at once to improve performance */
      const teamIds = uniq(options.flatMap(option => option.teamIds ?? []));
      const teamQuery = `id in [${teamIds.map(id => `id(${id})`).join(', ')}]`;
      const teams = (await LPTeam.getLPTeams(teamQuery)).reduce((acc, curr) => {
        acc[curr.ID] = curr;
        return acc;
      }, {});

      for (const option of options) {
        const optionTeams = option.teamIds?.map(teamId => teams[teamId]).filter(it => !!it) ?? [];
        option['hashmap'].team = optionTeams;

        /* Hack: clear learnProgresses to prevent type error in autocomplete because it tries to JSON.stringify a circular structure */
        clearLearnings(option);
      }

      /* Prevent the excecution of the update if the current request is outdated (b/c of race condition) */
      if (cursorHead < currentRequestCursor) {
        return;
      }

      setOptions(options);

      /* Do not do this in a finally block, because we only want to do it once we set the options of the last request */
      setLoading(false);
    } catch (error) {
      dispatch(notificationActions.showError(error));
      setLoading(false);
    }
  };

  /* Hack: clear learnProgresses to prevent type error in autocomplete because it tries to JSON.stringify a circular structure */
  selectedUsers.forEach(clearLearnings)

  useEffect(() => {
    if (open === false) {
      setOptions([]);
    } else {
      loadUsers(inputValue);
    }
  }, [open]);

  const loadUsers = useCallback(debounce(onLoadUsers, 500), []);

  const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;

    const items = React.Children.toArray(children);

    return (
      <div ref={ref} {...other}>
        <List role="listbox">{children}</List>
        {items.length === SEARCH_LIMIT && (
          <Typography className={classes.moreUsersLabel}>{t('admin.assignment.search-users-more-label')}</Typography>
        )}
      </div>
    );
  });

  return (
    <Autocomplete
      multiple
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={(_event, value, reason) => {
        if (reason === 'clear') {
          setOpen(false);
        } else if (reason === 'input') {
          setInputValue(value);
          loadUsers(value);
        }
      }}
      inputValue={inputValue}
      filterOptions={() => options}
      ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      options={options}
      noOptionsText={t('errors.no-entry-found')}
      className={classes.search}
      loading={loading}
      value={selectedUsers}
      onChange={(_event, value: LPUser[]) => {
        setSelectedUsers(value);
        setInputValue('');
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => <Chip label={getUserName(option)} color="primary" {...getTagProps({ index })} />)
      }
      renderOption={option => <UserSearchItem item={option} />}
      renderInput={params => (
        <TextField
          {...params}
          className={clsx(classes.searchInput, selectedUsers.length > 0 && classes.selectedSearch)}
          variant="outlined"
          label={t('admin.assignment.search-users')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default UserSelectionAutocomplete;
