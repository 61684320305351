var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import "reflect-metadata";
import AOMHttpClient from "./AOMHttpClient";
import AOMCacheStrategy from "./AOMCacheStrategy";
import AOMOfflineHandler from "./offline/AOMOfflineHandler";
export var AOMAuthType;
(function (AOMAuthType) {
    AOMAuthType[AOMAuthType["GUEST"] = 0] = "GUEST";
    AOMAuthType[AOMAuthType["USERNAME_PASSWORD"] = 1] = "USERNAME_PASSWORD";
    AOMAuthType[AOMAuthType["OAUTH2_TOKEN"] = 2] = "OAUTH2_TOKEN";
    AOMAuthType[AOMAuthType["AUTHSERVICE"] = 3] = "AUTHSERVICE";
})(AOMAuthType || (AOMAuthType = {}));
export default class Datastore extends AOMHttpClient {
    constructor() {
        super();
        this._AOMAuthTypes = AOMAuthType;
        this._cacheStrategy = AOMCacheStrategy.NETWORK_ELSE_CACHE;
        this._offlineHandler = null;
        this._authServiceInstance = null;
        this._encryptionSecret = null;
        this._maxOfflineFileBytes = 15 * 1000 * 1000;
        this._requestTimeout = 0;
        Datastore._instance = this;
    }
    static get Instance() {
        if (Datastore._instance === null) {
            throw new Error("The datastore is not configured yet.");
        }
        return Datastore._instance;
    }
    static isInstantiated() {
        if (this._instance !== null) {
            return true;
        }
        else {
            return false;
        }
    }
    static configureWithSessionToken(sessionToken, baseURL = User.AOMBASEURL, apiKey = User.AOMAPIKEY, system = User.AOMSYS, version = User.AOMSDKVERSION) {
        Datastore._instance = new Datastore();
        Datastore.Instance.configure(baseURL, apiKey, system, version, undefined, undefined, sessionToken);
    }
    static configureWithUserSessionToken(user, baseURL = User.AOMBASEURL, apiKey = User.AOMAPIKEY, system = User.AOMSYS, version = User.AOMSDKVERSION) {
        this.configureWithSessionToken(user.sessionToken, baseURL, apiKey, system, version);
    }
    static configureWithAuthService(authService, baseURL = User.AOMBASEURL, apiKey = User.AOMAPIKEY, system = User.AOMSYS, version = User.AOMSDKVERSION) {
        Datastore._instance = new Datastore();
        Datastore.Instance.authServiceInstance = authService;
        Datastore.Instance.configure(baseURL, apiKey, system, version, undefined, undefined);
    }
    setCachingStrategy(cacheStrategy, offlineHandler = null, dbConfig) {
        return __awaiter(this, void 0, void 0, function* () {
            if (cacheStrategy === AOMCacheStrategy.CACHE_THEN_NETWORK) {
                throw new Error("CacheStrategy not implemented");
            }
            this._cacheStrategy = cacheStrategy;
            this._useETag = (cacheStrategy === AOMCacheStrategy.NETWORK_ELSE_CACHE);
            if (offlineHandler !== null) {
                yield this.initOfflineHandler(offlineHandler, dbConfig);
            }
        });
    }
    setEncryptionSecret(secret) {
        this._encryptionSecret = secret;
    }
    getEncryptionSecret() {
        return this._encryptionSecret;
    }
    encryptAttribute(plainText) {
        if (!this._encryptionSecret) {
            throw new AOMRequestError(AOMStatus.BAD_DATASTORE_CONFIG, 200, Datastore.NO_PASSPHRASE_MSG);
        }
        return encrypt(plainText, this._encryptionSecret);
    }
    decryptAttribute(cipherText) {
        if (!this._encryptionSecret) {
            throw new AOMRequestError(AOMStatus.BAD_DATASTORE_CONFIG, 200, Datastore.NO_PASSPHRASE_MSG);
        }
        return decrypt(cipherText, this._encryptionSecret);
    }
    initOfflineHandler(offlineHandler = null, dbConfig) {
        return __awaiter(this, void 0, void 0, function* () {
            if (offlineHandler !== null) {
                this._offlineHandler = offlineHandler;
            }
            else {
                this._offlineHandler = new AOMOfflineHandler(dbConfig);
                yield this._offlineHandler.init();
            }
        });
    }
    offlineHandlerExists() {
        return this._offlineHandler !== null;
    }
    isOfflineHandlerInitialized() {
        return this.offlineHandlerExists() === true && this.OfflineHandler.initialized === true;
    }
    getCachingStrategy() {
        return this._cacheStrategy;
    }
    get OfflineHandler() {
        if (!this._offlineHandler) {
            throw Error(Datastore.NO_OFF_HANDLING_MSG);
        }
        return this._offlineHandler;
    }
    get authServiceInstance() {
        return this._authServiceInstance;
    }
    set authServiceInstance(authServiceInstance) {
        this._authServiceInstance = authServiceInstance;
        this.setAuthType();
    }
    get maxOfflineFileBytes() {
        return this._maxOfflineFileBytes;
    }
    set maxOfflineFileBytes(maxOfflineFileBytes) {
        this._maxOfflineFileBytes = maxOfflineFileBytes;
    }
    static configureAsUser(user, baseURL = User.AOMBASEURL, apiKey = User.AOMAPIKEY, system = User.AOMSYS, version = User.AOMSDKVERSION) {
        Datastore._instance = new Datastore();
        Datastore.Instance.configure(baseURL, apiKey, system, version, user.userName, user.password);
    }
    static configureWithCredentials(userName, password, baseUrl = User.AOMBASEURL, apiKey = User.AOMAPIKEY, system = User.AOMSYS) {
        Datastore._instance = new Datastore();
        Datastore.Instance.configure(baseUrl, apiKey, system, User.AOMSDKVERSION, userName, password);
    }
    static configureAsGuest(baseUrl = User.AOMBASEURL, apikey = User.AOMAPIKEY, system = User.AOMSYS) {
        Datastore._instance = new Datastore();
        Datastore.Instance.configure(baseUrl, apikey, system, User.AOMSDKVERSION);
    }
    setCheckObjectState(checkObjectState) {
        this._checkObjectState = checkObjectState;
    }
    getCheckObjectState() {
        return this._checkObjectState;
    }
    get customHeaders() {
        return this._customHeaders;
    }
    set customHeaders(customHeaders) {
        this._customHeaders = customHeaders;
    }
    get requestTimeout() {
        return this._requestTimeout;
    }
    set requestTimeout(timeout) {
        this._requestTimeout = timeout;
    }
    get validateAttributes() {
        return this._validateAttributes;
    }
    set validateAttributes(validateAttributes) {
        this._validateAttributes = validateAttributes;
    }
    postOnServer(dataModel, href, isRef = false, usePersistentStorage, isRefCollection = false, parentHref) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!href) {
                href = this.createModelHref(dataModel);
            }
            const clazz = yield Datastore.getClassFromDataModel(dataModel);
            return yield this.sendRequest("POST", href, [200, 201], clazz, { data: dataModel.toJsonString(), dataModel, isRef, isRefCollection, parentHref }, undefined, usePersistentStorage);
        });
    }
    updateOnServer(_href, _json, usePersistentStorage, dataModel) {
        return __awaiter(this, void 0, void 0, function* () {
            const clazz = yield this.getClassFromJSON(_json);
            return yield this.sendRequest("PUT", _href, [200, 204], clazz, {
                data: _json,
                dataModel,
            }, undefined, usePersistentStorage);
        });
    }
    loadFromServer(modelHref, dataModel, withReferencedHrefs, usePersistentStorage, clazz, _query = "", isCollection = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const isReload = dataModel
                && modelHref === dataModel.href;
            const _args = {
                isReload,
                data: _query,
                withReferencedHrefs,
            };
            const res = yield this.sendRequest("GET", modelHref, [200, 304], clazz, _args, isCollection, usePersistentStorage);
            if (dataModel) {
                dataModel.fromJson(res.responseText);
                Datastore.cleanEmbeddedObjects(dataModel);
            }
            else if (isCollection) {
                if (res.responseText instanceof Array === false) {
                    res.responseText = [res.responseText];
                }
                return res.responseText;
            }
            return res;
        });
    }
    loadCountFromServer(_classOrHref, _refName, _query = "", usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            let url;
            if (typeof _classOrHref === "string" && typeof _refName !== "undefined") {
                url = _classOrHref + "/" + _refName;
            }
            else {
                url = this.createHrefFromClass(_classOrHref);
            }
            url += "/count";
            const _args = {
                data: _query,
            };
            const response = yield this.sendRequest("GET", url, [200], undefined, _args, false, usePersistentStorage);
            return JSON.parse(response.responseText);
        });
    }
    loadResource(href, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.sendRequest("GET", href, [200, 206, 304], undefined, {
                isByteData: true,
                headerDetails: {
                    acceptEncoding: "gzip, deflate",
                },
            }, false, usePersistentStorage);
            return response.responseText;
        });
    }
    loadFromPersistentStorage(clazz, whereWithPlaceholders = "", whereValues = [], orderBy = "", limit, offset) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.offlineHandlerExists() === false) {
                throw Error(Datastore.NO_OFF_HANDLING_MSG);
            }
            return yield PersistentStorage.Instance.query(clazz, whereWithPlaceholders, whereValues, orderBy, limit, offset);
        });
    }
    getCountFromPersistentStorage(clazz, whereWithPlaceholders = "", whereValues = []) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.offlineHandlerExists() === false) {
                throw Error(Datastore.NO_OFF_HANDLING_MSG);
            }
            return yield PersistentStorage.Instance.query(clazz, whereWithPlaceholders, whereValues, undefined, undefined, undefined, true);
        });
    }
    getCollectionCountFromPersistentStorage(clazz, refUrl, whereWithPlaceholders = "", whereValues = []) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.offlineHandlerExists() === false) {
                throw Error(Datastore.NO_OFF_HANDLING_MSG);
            }
            return yield PersistentStorage.Instance.getCollectionCountFromPersistentStorage(clazz, refUrl, whereWithPlaceholders, whereValues);
        });
    }
    postStaticDataOnServer(_data, fileName = "", fileFormat = "", _isImage = false, usePersistentStorage, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const href = this.createStaticDataHref(_isImage, options);
            return yield this.postStaticDataOnServerWithHref(_data, fileName, fileFormat, href, _isImage, usePersistentStorage);
        });
    }
    postStaticDataOnServerWithHref(_data, fileName = "", fileFormat = "", _href, _isImage, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.sendRequest("POST", _href, [201], undefined, {
                isByteData: true,
                fileName,
                fileFormat,
                data: _data,
                isImage: _isImage,
            }, false, usePersistentStorage);
            const ret = {
                href: response.returnedHref,
                offline: false,
            };
            if (typeof response.offline !== "undefined") {
                ret.offline = response.offline;
            }
            let json;
            try {
                json = JSON.parse(response.responseText);
            }
            catch (error) {
                json = {};
            }
            if (typeof json.id !== "undefined") {
                ret.id = json.id;
            }
            return ret;
        });
    }
    getStaticDataTokenUrl(href, validity, oneTime) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { validity, oneTime };
            let data = "";
            for (const prop in params) {
                if (prop) {
                    if (data !== "") {
                        data += "&";
                    }
                    data += prop + "=" + params[prop];
                }
            }
            const r = yield this.sendRequest("POST", href, [201], undefined, {
                headerDetails: {
                    contentType: "application/x-www-form-urlencoded;charset=utf-8",
                },
                data,
            });
            return r.returnedHref;
        });
    }
    deleteAllFromStorage(query, clazz) {
        return __awaiter(this, void 0, void 0, function* () {
            const href = this.createHrefFromClass(clazz) + "?q=" + encodeURIComponent(query);
            if (Datastore.Instance.offlineHandlerExists() === true) {
                yield PersistentStorage.Instance.removeCollection(href);
            }
            yield MemoryStorage.Instance.removeCollection(href);
        });
    }
    deleteAllFromStorageByClass(clazz) {
        return __awaiter(this, void 0, void 0, function* () {
            if (Datastore.Instance.offlineHandlerExists() === true) {
                yield PersistentStorage.Instance.removeAllObjects(clazz);
            }
            yield MemoryStorage.Instance.removeAllObjects(clazz);
        });
    }
    createHref(href) {
        if (href.substring(0, 4) === "http") {
            return href;
        }
        if (href.substring(0, 5) === "/apps") {
            return this._baseURL.substring(0, this._baseURL.indexOf("/apps")) + href;
        }
        return this._baseURL + "/" + href;
    }
    createStaticDataHref(isImage = false, options) {
        let href = this._baseURL + "/data/";
        href += isImage ? "images" : "files";
        href += "/";
        if (typeof options.dataModelId !== "undefined") {
            href += options.moduleName + "/";
            href += "v/" + SdkInfo.getVersion(options.moduleName) + "/";
            href += options.dataModelName + "/";
            href += options.dataModelId + "/";
            href += options.refName;
        }
        return href;
    }
    static cleanEmbeddedObjects(data, parent = null, name, isArray = false, seenObjects = []) {
        if (seenObjects.indexOf(data) !== -1) {
            return true;
        }
        seenObjects.push(data);
        if (parent !== null && data !== null && typeof data.dao !== "undefined" && data.dao !== null) {
            if (data.ID === data.dao.id && Datastore.getCleanTypeFromTypeString(data.type) === Datastore.getCleanTypeFromTypeString(data.dao["@type"])) {
                if (name && typeof parent[name] !== undefined && typeof parent.dao[name] !== "undefined") {
                    if (isArray) {
                        parent.hashmap[name] = [];
                    }
                    else {
                        parent.hashmap[name] = undefined;
                    }
                }
            }
        }
        if (typeof data === "object" && data !== null) {
            for (const property in data.hashmap) {
                if (property !== "parent" && property !== "dao") {
                    if (data[property] instanceof Array) {
                        for (const i in data[property]) {
                            if (typeof data[property][i] === "object") {
                                Datastore.cleanEmbeddedObjects(data[property][i], data, property, true, seenObjects);
                            }
                        }
                    }
                    else if (typeof data[property] === "object" && data[property] !== null && property !== "ObjectState") {
                        Datastore.cleanEmbeddedObjects(data[property], data, property, false, seenObjects);
                    }
                }
            }
        }
    }
    static getCleanTypeFromTypeString(typeStr) {
        if (typeStr && typeof typeStr === "string" && (typeStr.match(/\$/g) || []).length > 1) {
            const typeParts = typeStr.split("$");
            return typeParts[0] + "$" + typeParts[2];
        }
        return typeStr;
    }
    loadListFromServerWithClass(_class, _query, withReferencedHrefs = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            const list = yield this.sendRequest("GET", this.createHrefFromClass(_class), [
                200, 304,
            ], _class, {
                data: _query,
                withReferencedHrefs,
            }, true, usePersistentStorage);
            if (list instanceof Error) {
                throw list;
            }
            else if (typeof list.responseText.length === "undefined") {
                return [list.responseText];
            }
            else {
                return list.responseText;
            }
        });
    }
    getHeader(_isByteData, headerDetails, eTag, deltaSync) {
        const header = {};
        header["X-apiomat-system"] = this._system;
        header["X-apiomat-apikey"] = this._apiKey;
        if (headerDetails.contentType) {
            header["Content-Type"] = headerDetails.contentType;
        }
        else if (typeof _isByteData !== "undefined" && _isByteData) {
            header["Content-Type"] = "application/octet-stream";
        }
        else {
            header["Content-Type"] = "application/json";
            header.Accept = "application/json";
        }
        if (headerDetails.acceptEncoding) {
            header["Accept-Encoding"] = headerDetails.acceptEncoding;
        }
        if (this._useETag && typeof eTag !== "undefined" && eTag !== null) {
            header["if-none-match"] = eTag;
        }
        if (this._deltaSyncStrategy !== AOMDeltaSyncStrategy.NONE && typeof deltaSync !== "undefined" && deltaSync !== null) {
            header["X-apiomat-delta"] = deltaSync;
        }
        header["X-apiomat-fullupdate"] = "true";
        header["X-apiomat-sdkVersion"] = this._version;
        if (headerDetails.hasOwnProperty("authHeader") && headerDetails.authHeader === false) {
        }
        else if (this._authType === this._AOMAuthTypes.USERNAME_PASSWORD) {
            const creds = Buffer.from(this._username + ":" + this._password).toString("base64");
            header.Authorization = "Basic " + creds;
        }
        else if (this._authType === this._AOMAuthTypes.OAUTH2_TOKEN) {
            header.Authorization = "Bearer " + this._sessionToken;
        }
        else if (this._authType === this._AOMAuthTypes.AUTHSERVICE && this._authServiceInstance) {
            header.Authorization = "Bearer " + this._authServiceInstance.token;
        }
        if (typeof this._customHeaders === "object") {
            for (const key in this._customHeaders) {
                if (this._customHeaders[key]) {
                    header[key] = this._customHeaders[key];
                }
            }
        }
        return header;
    }
    processResponse(http, responseText, _expectedReturnCodes, _httpMethod, clazz, returnpost = false, _url, usePersistentStorage, isBinary, isReference, deltaSync, isCollection, _args) {
        return __awaiter(this, void 0, void 0, function* () {
            let errorOccured = false;
            this.responseHook(http, responseText);
            this.processOfflineHook(false);
            let elem;
            let returnedHref;
            try {
                const isSamlRedirect = false;
                const responseURL = http ? http.url : _url;
                const _usePersistentStorage = this.getUsePersistentStorageForClass(clazz, _url, usePersistentStorage);
                if (http && _expectedReturnCodes.indexOf(http.statusCode) > -1) {
                    elem = responseText;
                    if (this._useETag && typeof http.headers.etag !== "undefined") {
                        yield StorageFactory.ChooseStorageImpl(_usePersistentStorage, Datastore.Instance.offlineHandlerExists()).saveETag(_url, http.headers.etag);
                    }
                    let deltaDeleted = null;
                    const headerDeltaDeleted = http.headers["x-apiomat-delta-deleted"];
                    if (deltaSync !== null && headerDeltaDeleted) {
                        deltaDeleted = JSON.parse(headerDeltaDeleted);
                    }
                    let deltaRemoved = null;
                    const headerDeltaRemoved = http.headers["x-apiomat-delta-removed"];
                    if (deltaSync !== null && headerDeltaRemoved) {
                        deltaRemoved = JSON.parse(headerDeltaRemoved);
                    }
                    if (this._deltaSyncStrategy !== AOMDeltaSyncStrategy.NONE && elem && elem.startsWith("[") &&
                        this._cacheStrategy !== AOMCacheStrategy.NETWORK_ONLY && deltaSync !== null && typeof deltaSync !== "undefined" &&
                        http.statusCode !== 304 && (typeof headerDeltaDeleted !== "undefined" || typeof headerDeltaRemoved !== "undefined")) {
                        let combinedObjects = yield StorageFactory.ChooseStorageImpl(_usePersistentStorage, this.offlineHandlerExists())
                            .getCollectionObjects(_url, clazz);
                        if (combinedObjects !== null && typeof combinedObjects !== "undefined" && combinedObjects instanceof Array === false) {
                            combinedObjects = [combinedObjects];
                        }
                        if (combinedObjects !== null && typeof combinedObjects !== "undefined" && combinedObjects.length > 0
                            && (deltaDeleted !== null || deltaRemoved !== null)) {
                            if (deltaDeleted) {
                                for (const deletedId of deltaDeleted) {
                                    const key = combinedObjects.findIndex((el) => typeof el !== "undefined" && deletedId === el.id);
                                    if (key > -1) {
                                        const clazzOfObj = yield this.getClassFromJSON(combinedObjects[key]);
                                        yield MemoryStorage.Instance.removeObjectByHref(combinedObjects[key].href, clazzOfObj);
                                        if (this.offlineHandlerExists()) {
                                            yield PersistentStorage.Instance.removeObjectByHref(combinedObjects[key].href, clazzOfObj);
                                        }
                                        combinedObjects.splice(key, 1);
                                    }
                                    else if (this._deltaSyncStrategy === AOMDeltaSyncStrategy.OBJECT_BASED) {
                                        return new AOMRequestError(AOMStatus.ID_NOT_FOUND_OFFLINE, _expectedReturnCodes, responseText);
                                    }
                                }
                            }
                            if (deltaRemoved) {
                                for (const removeId of deltaRemoved) {
                                    const key = combinedObjects.findIndex((el) => typeof el !== "undefined" && removeId === el.id);
                                    if (key > -1) {
                                        yield StorageFactory.ChooseStorageImpl(_usePersistentStorage, this.offlineHandlerExists()).removeIdFromCollection(_url, removeId);
                                        combinedObjects.splice(key, 1);
                                    }
                                }
                            }
                            if (combinedObjects.length > 0) {
                                this.saveModelsInWasLoadedFromStorageMap(combinedObjects, _url);
                            }
                            elem = combinedObjects;
                        }
                        if (typeof responseText !== "undefined" && responseText.startsWith("[")) {
                            const updatedData = JSON.parse(responseText);
                            if (combinedObjects !== null && typeof combinedObjects !== "undefined" && combinedObjects.length > 0) {
                                for (const obj of updatedData) {
                                    const key = combinedObjects.findIndex((el) => typeof el !== "undefined" && obj.id === el.id);
                                    if (key > -1) {
                                        combinedObjects.splice(key, 1);
                                    }
                                    combinedObjects.push(obj);
                                }
                            }
                            else {
                                combinedObjects = updatedData;
                            }
                        }
                    }
                    if (_httpMethod === "GET" && elem.length > 0 && this._cacheStrategy !== AOMCacheStrategy.NETWORK_ONLY) {
                        const storageData = isBinary === true || elem instanceof Array ? elem : JSON.parse(elem);
                        yield StorageFactory.ChooseStorageImpl(_usePersistentStorage, this.offlineHandlerExists()).addOrUpdateCollection(_url, storageData);
                    }
                    if (http.statusCode === 304) {
                        elem = yield StorageFactory.ChooseStorageImpl(_usePersistentStorage, this.offlineHandlerExists())
                            .getCollectionObjects(_url, clazz);
                        if (elem == null || elem instanceof Array && elem.length === 0) {
                            return yield this.sendOnlineRequest(_httpMethod, _url, _expectedReturnCodes, clazz, _args, isCollection, usePersistentStorage, false);
                        }
                        if (isBinary === false) {
                            elem = JSON.stringify(elem);
                        }
                    }
                    if (_httpMethod === "DELETE") {
                        if (isReference) {
                            const collectionHref = _url.substring(0, _url.lastIndexOf("/"));
                            const refId = _url.substring(_url.lastIndexOf("/") + 1);
                            yield StorageFactory.ChooseStorageImpl(_usePersistentStorage, this.offlineHandlerExists()).removeIdFromCollection(collectionHref, refId);
                        }
                        else {
                            yield StorageFactory.ChooseStorageImpl(_usePersistentStorage, this.offlineHandlerExists()).removeObjectByHref(_url, clazz);
                        }
                    }
                    if (_httpMethod === "GET" && typeof clazz !== "undefined") {
                        const json = elem instanceof Array ? elem : JSON.parse(elem);
                        elem = yield this.createClassInstancesFromJSON(json, clazz);
                    }
                    if (_httpMethod === "POST") {
                        if (returnpost === false) {
                            returnedHref = http.headers.location;
                        }
                        const { isRefCollection } = _args;
                        if (this._cacheStrategy !== AOMCacheStrategy.NETWORK_ONLY && isReference && isRefCollection) {
                            let storageData = JSON.parse(_args.data);
                            if (storageData instanceof Array === false) {
                                storageData = [storageData];
                            }
                            yield StorageFactory.ChooseStorageImpl(_usePersistentStorage, this.offlineHandlerExists()).addOrUpdateCollection(_url, storageData, true);
                        }
                    }
                    if (_httpMethod === "PUT" && this._cacheStrategy !== AOMCacheStrategy.NETWORK_ONLY && _args.dataModel) {
                        const storageData = JSON.parse(_args.data);
                        yield StorageFactory.ChooseStorageImpl(_usePersistentStorage, this.offlineHandlerExists()).addOrUpdateCollection(_url, storageData);
                    }
                }
                else if (_httpMethod === "GET" && this._cacheStrategy === AOMCacheStrategy.NETWORK_ELSE_CACHE
                    && (_url.endsWith("/models/me") && http.statusCode >= 400) == false) {
                    if (typeof clazz !== "undefined") {
                        const json = yield StorageFactory.ChooseStorageImpl(_usePersistentStorage, this.offlineHandlerExists())
                            .getCollectionObjects(_url, clazz);
                        if (typeof json === "undefined") {
                            errorOccured = true;
                        }
                        else {
                            elem = yield this.createClassInstancesFromJSON(json, clazz);
                            this.saveModelsInWasLoadedFromStorageMap(elem, _url);
                        }
                    }
                    else {
                        errorOccured = true;
                    }
                }
                else {
                    errorOccured = true;
                }
            }
            catch (ex) {
                return ex;
            }
            if (errorOccured) {
                if (isBinary === true) {
                    const byteArray = Array.prototype.slice.call(responseText, 0);
                    responseText = String.fromCharCode.apply(null, new Uint8Array(byteArray));
                }
                const errorCode = http ? http.statusCode : AOMStatus.NO_NETWORK;
                const error = new AOMRequestError(errorCode, _expectedReturnCodes, responseText);
                return error;
            }
            else {
                const statusCode = http ? http.statusCode : AOMStatus.NO_NETWORK;
                return {
                    _httpMethod: _httpMethod,
                    returnpost: returnpost,
                    returnedHref: returnedHref || undefined,
                    responseText: elem,
                    status: statusCode,
                };
            }
        });
    }
    requestSessionToken(refreshToken, accessExpiration, refreshExpiration) {
        return __awaiter(this, void 0, void 0, function* () {
            let params;
            const appName = this._baseURL.substring(this._baseURL.lastIndexOf("/") + 1);
            if (typeof refreshToken !== "string") {
                if (this._authType !== AOMAuthType.USERNAME_PASSWORD) {
                    return Promise.reject(new AOMRequestError(AOMStatus.BAD_DATASTORE_CONFIG, 0));
                }
                else {
                    const username = this._username ? this._username : "";
                    const pw = this._password ? this._password : "";
                    params = {
                        grant_type: "aom_user",
                        client_id: appName,
                        client_secret: this._apiKey,
                        scope: "read write",
                        username: encodeURIComponent(username),
                        password: encodeURIComponent(pw),
                        app: encodeURIComponent(appName),
                        system: this._system,
                    };
                }
            }
            else {
                params = {
                    grant_type: "refresh_token",
                    client_id: appName,
                    client_secret: this._apiKey,
                    refresh_token: refreshToken,
                };
            }
            if (typeof accessExpiration !== "undefined") {
                params.access_expiration = accessExpiration;
            }
            if (typeof refreshExpiration !== "undefined") {
                params.refresh_expiration = refreshExpiration;
            }
            let data = "";
            for (const prop in params) {
                if (prop) {
                    if (data !== "") {
                        data += "&";
                    }
                    data += prop + "=" + params[prop];
                }
            }
            const url = this._baseURL.substring(0, this._baseURL.indexOf("yambas") + 6) + "/oauth/token";
            const retData = yield this.sendRequest("POST", url, [200], undefined, {
                data: data,
                returnpost: true,
                headerDetails: {
                    authHeader: false,
                    contentType: "application/x-www-form-urlencoded;charset=utf-8",
                },
            });
            const jsonRet = JSON.parse(retData.responseText) || {};
            const expireIn = jsonRet.expires_in || 0;
            return {
                sessionToken: jsonRet.access_token,
                refreshToken: jsonRet.refresh_token,
                expirationDate: new Date().getTime() + expireIn * 1000,
                module: jsonRet.aom_module,
                model: jsonRet.aom_model,
            };
        });
    }
    createHrefFromClass(_class) {
        let href = this._baseURL;
        href += "/models/";
        href += _class.staticGetModuleName();
        href += "/v/";
        href += SdkInfo.getVersion(_class.staticGetModuleName());
        href += "/";
        href += _class.staticGetModelName();
        return href;
    }
    createModelHref(_dataModel) {
        let href = this._baseURL;
        href += "/models/";
        href += _dataModel.moduleName;
        href += "/v/";
        href += SdkInfo.getVersion(_dataModel.moduleName);
        href += "/";
        href += _dataModel.modelName;
        return href;
    }
    deleteOnServer(_href, _dataModel, isRef = false, usePersistentStorage, isStaticData = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const clazz = yield Datastore.getClassFromDataModel(_dataModel);
            if (_href) {
                return yield this.sendRequest("DELETE", _href, [204], clazz, { isRef, dataModel: _dataModel, isStaticDataDelete: isStaticData }, undefined, usePersistentStorage);
            }
            else if (_dataModel && _dataModel.href) {
                return yield this.sendRequest("DELETE", _dataModel.href, [204], clazz, { isRef, dataModel: _dataModel }, undefined, usePersistentStorage);
            }
            else {
                return Promise.reject(new AOMRequestError(AOMStatus.HREF_NOT_FOUND, 204));
            }
        });
    }
    setOfflineUsageForClassWithName(className, moduleName, usePersistentStorage) {
        this._offlineMapping[moduleName + "$" + className] = usePersistentStorage;
    }
    setOfflineUsageForClass(clazz, usePersistentStorage) {
        const typeStr = this.getTypefromClass(clazz);
        if (typeStr) {
            this._offlineMapping[typeStr] = usePersistentStorage;
        }
    }
    getOfflineUsageForClass(clazz) {
        const typeStr = this.getTypefromClass(clazz);
        let result;
        if (typeStr) {
            result = this._offlineMapping[typeStr];
        }
        return result ? result : false;
    }
    getOfflineUsageForClassWithName(className, moduleName) {
        const result = this._offlineMapping[moduleName + "$" + className];
        return result ? result : false;
    }
    getTypefromClass(clazz) {
        if (clazz.staticGetModuleName() !== undefined && clazz.staticGetModelName() !== undefined) {
            return clazz.staticGetModuleName() + "$" + clazz.staticGetModelName();
        }
        return undefined;
    }
    static getTypeFromUrl(url, isCollection = false) {
        if (url.indexOf("images/") > -1 || url.indexOf("files/") > -1) {
            const sub = url.substring(url.indexOf("/apps/"), url.lastIndexOf("/"));
            const urlParts = sub.split("/");
            if (urlParts.length < 8) {
                return undefined;
            }
            let classNamePosition = 2 + urlParts.length % 8;
            let moduleNamePosition = 3 + urlParts.length % 8;
            ;
            const fileClassName = urlParts[urlParts.length - classNamePosition];
            const fileModuleName = urlParts[urlParts.length - moduleNamePosition];
            return fileModuleName + "$" + fileClassName;
        }
        let result = "";
        if (isCollection) {
            if (url.lastIndexOf("?") > -1) {
                result = url.substring(0, url.lastIndexOf("?"));
            }
            else {
                result = url;
            }
        }
        else {
            result = url.substring(0, url.lastIndexOf("/"));
        }
        const className = result.substring(result.lastIndexOf("/") + 1, result.length);
        result = result.substring(0, result.lastIndexOf("/"));
        result = result.substring(0, result.lastIndexOf("/"));
        result = result.substring(0, result.lastIndexOf("/"));
        const moduleName = result.substring(result.lastIndexOf("/") + 1, result.length);
        return moduleName + "$" + className;
    }
    static getClassFromDataModel(dataModel) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof dataModel !== "undefined") {
                try {
                    const { default: clazz } = yield import("./" + dataModel.moduleName.toLowerCase() + "/" + dataModel.modelName);
                    return clazz;
                }
                catch (error) {
                    return undefined;
                }
            }
            else {
                return undefined;
            }
        });
    }
    getClassFromJSON(json) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof json !== "undefined" && json["@type"] !== null && typeof json["@type"] !== "undefined") {
                const typeStr = Datastore.getCleanTypeFromTypeString(json["@type"]);
                if (!typeStr) {
                    return undefined;
                }
                const [moduleName, className] = typeStr.split("$");
                try {
                    const { default: clazz } = yield import("./" + moduleName.toLowerCase() + "/" + className);
                    return clazz;
                }
                catch (error) {
                    return undefined;
                }
            }
            else {
                if (typeof json !== "undefined" && typeof json.modelName !== "undefined" && typeof json.moduleName !== "undefined") {
                    const { default: clazz } = yield import("./" + json.moduleName.toLowerCase() + "/" + json.modelName);
                    return clazz;
                }
                else {
                    return undefined;
                }
            }
        });
    }
    createClassInstancesFromJSON(json, clazz, isCollection = false) {
        return __awaiter(this, void 0, void 0, function* () {
            let elem = json;
            if (json && json.length && json.length > 0) {
                elem = [];
                let i;
                for (i = 0; i < json.length; i++) {
                    let dynamicClazz;
                    if (typeof json[i] !== "undefined" && typeof json[i]["@type"] !== "undefined" ||
                        typeof json[i] !== "undefined" && typeof json[i].modelName !== "undefined" && typeof json[i].moduleName !== "undefined") {
                        dynamicClazz = yield this.getClassFromJSON(json[i]);
                    }
                    else {
                        dynamicClazz = clazz;
                    }
                    const tmpElem = new dynamicClazz();
                    tmpElem.fromJson(json[i]);
                    elem.push(tmpElem);
                }
                if (elem.length === 1) {
                    elem = elem[0];
                }
            }
            else {
                let dynamicClazz;
                const typeStr = Datastore.getCleanTypeFromTypeString(json["@type"]);
                if (isCollection && json instanceof AbstractClientDataModelDAO) {
                    if (typeof json !== "undefined" && typeof json["@type"] !== "undefined" ||
                        typeof json !== "undefined" && typeof json.modelName !== "undefined" && typeof json.moduleName !== "undefined") {
                        dynamicClazz = yield this.getClassFromJSON(json);
                    }
                    else {
                        dynamicClazz = clazz;
                    }
                    const tmp = new dynamicClazz();
                    tmp.fromJson(json);
                    Datastore.cleanEmbeddedObjects(tmp);
                    elem = [tmp];
                }
                else if (json["@type"] && typeStr && typeStr.split("$")[1] === clazz) {
                    dynamicClazz = yield this.getClassFromJSON(json);
                    elem = new dynamicClazz();
                    elem.fromJson(json);
                    Datastore.cleanEmbeddedObjects(elem);
                }
            }
            return elem;
        });
    }
    setAuthType() {
        if (typeof this._username !== "undefined" && typeof this._password !== "undefined") {
            this._authType = this._AOMAuthTypes.USERNAME_PASSWORD;
        }
        else if (typeof this._sessionToken !== "undefined") {
            this._authType = this._AOMAuthTypes.OAUTH2_TOKEN;
        }
        else if (this._authServiceInstance !== null) {
            this._authType = this._AOMAuthTypes.AUTHSERVICE;
        }
        else {
            this._authType = this._AOMAuthTypes.GUEST;
        }
    }
    configure(baseURL, apiKey, system, version, username, password, sessionToken) {
        this._baseURL = baseURL;
        this._apiKey = apiKey;
        this._system = system;
        this._version = version;
        this._username = username;
        this._password = password;
        this._sessionToken = sessionToken;
        this._checkObjectState = true;
        this.setAuthType();
    }
}
Datastore._instance = null;
Datastore.NO_PASSPHRASE_MSG = "In order to decrypt the attribute, you need to " +
    "set the encryption secret passphrase to the datastore first.";
Datastore.NO_OFF_HANDLING_MSG = "No offlinehandler is set. Please initialize the AOMOfflineHandler.";
import AbstractClientDataModelDAO from "./offline/AbstractClientDataModelDAO";
import AOMDeltaSyncStrategy from "./AOMDeltaSyncStrategy";
import AOMRequestError from "./AOMRequestError";
import AOMStatus from "./Status";
import { encrypt, decrypt } from "./CryptHelper";
import MemoryStorage from "./offline/MemoryStorage";
import PersistentStorage from "./offline/PersistentStorage";
import SdkInfo from "./SdkInfo";
import StorageFactory from "./offline/StorageFactory";
import User from "./basics/User";
