import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, Typography, TextField, Button, Paper, Grid, RadioGroup, FormControlLabel, Radio, List } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import AppHeader from '../../components/AppHeader/AppHeader';
import LearnContent from '../../sdk/com/apiomat/frontend/mylearningplatform/LearnContent';
import clsx from 'clsx';
import LearnContentInfoDialog from '../../components/Dialogs/LearnContentInfoDialog';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LearnContentSearchItem from '../../components/Admin/LearnContentSearchItem';
import LearnContentListItem from '../../components/Admin/LearnContentListItem';
import LPUser from '../../sdk/com/apiomat/frontend/mylearningplatform/LPUser';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import CustomDueDateDialog from '../../components/Dialogs/CustomDueDateDialog';
import LPTeam from '../../sdk/com/apiomat/frontend/mylearningplatform/LPTeam';
import LoadingIndicatorFullScreen from '../../components/LoadingIndicator/LoadingIndicatorFullScreen';
import { assignmentActions, DisplayPosition } from '../../store/assignment';
import BottomAppBar from '../../components/AppHeader/BottomAppBar';
import BasicContainer from '../../components/Container/BasicContainer';
import UserSelectionAutocomplete from '../../components/Admin/UserSelectionAutocomplete';
import UserAssignmentListItem from '../../components/Admin/UserAssignmentListItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import TeamSelectionAutocomplete from '../../components/Admin/TeamSelectionAutocomplete';
import TeamAssignmentListItem from '../../components/Admin/TeamAssignmentListItem';
import PositionSelectionAutocomplete from '../../components/Admin/PositionSelectionAutocomplete';
import PositionAssignmentListItem from '../../components/Admin/PositionAssignmentListItem';
import ApproveBaseDialog from '../../components/Dialogs/ApproveBaseDialog';
import { NoResultsListItem } from '../../components/NoResults/NoResultsListItem';

import NoAssignmentsIcon from '../../assets/img/no-assignments.png';
import { AssignDueDateContext } from '../../models/assign-due-date-context';
import { getAssignedUsersCountForLearnContent } from '../../utils/assignment.utils';
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";

const useStyles = makeStyles(theme => ({
  title: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  search: {
    margin: theme.spacing(1, 0, 2),
  },
  searchInput: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ': {
      borderColor: theme.palette.primary.main,
    },
  },
  selectedSearch: {
    '& .MuiInputLabel-outlined': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  tableHeader: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1),
  },
  loadMoreButton: {
    marginTop: theme.spacing(2),
  },
  assignButton: {
    marginBottom: theme.spacing(2),
  },
}));

type AssignedListShowType = 'by-user' | 'by-user-group' | 'by-position';

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    learnContents,
    users,
    usersCache,
    usersCount,
    teams,
    teamsCache,
    teamsCount,
    positions,
    positionsCount,
    loadingUsers,
    loadingUsersMore,
    loadingUsersCache,
    loadingTeams,
    loadingTeamsMore,
    loadingTeamsCache,
    loadingPositions,
    loadingPositionsMore,
    loadingLearnContents,
    loadingPost,
    userNameFilters,
    teamFilters,
    positionFilters,
    allPositions,
  } = useSelector((state: AppState) => state.assignment);

  const { isGlobalAdmin, user } = useSelector((state: AppState) => state.auth);

  const [selectedLearnContent, setSelectedLearnContent] = useState<LearnContent>(null);
  const [showLearnContentInfo, setShowLearnContentInfo] = useState<boolean>(false);
  const [assignListShow, setAssignListShow] = useState<AssignedListShowType>('by-user');
  const [showAssignDueDate, setShowAssignDueDate] = useState<AssignDueDateContext>(null);
  const [showAssignConfirmDialog, setShowAssignConfirmDialog] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [selectedUsersCache, setSelectedUsersCache] = useState<{ [userName: string]: LPUser }>({});
  const [selectedTeamsCache, setSelectedTeamsCache] = useState<{ [teamName: string]: LPTeam }>({});

  const { innerHeight, scrollThreshold } = useInfiniteScroll();

  const [userList, setUserList] = useState<HTMLElement>(null);
  const [teamList, setTeamList] = useState<HTMLElement>(null);
  const [positionList, setPositionList] = useState<HTMLElement>(null);

  const userListRef = useCallback(node => {
    setUserList(node)
  }, []);
  const teamListRef = useCallback(node => {
    setTeamList(node)
  }, []);
  const positionListRef = useCallback(node => {
    setPositionList(node)
  }, []);

  useEffect(() =>
    () => dispatch(assignmentActions.clearAllFilters())
  , [dispatch]);

  useEffect(() => {
    /* Learn contents an positions should be loaded only once, as these are expensive requests */
    dispatch(assignmentActions.loadLearnContents());

    /* Positions are also used by position autocomplete */
    dispatch(assignmentActions.loadAllPositions());
  }, [dispatch]);

  /* Load more users if whole user assignment list element is visible */
  useEffect(() => {
    if (loadingUsers !== 'pending' && loadingUsersMore !== 'pending' && usersCount > users?.length && isWholeElementVisible(userList)) {
      dispatch(assignmentActions.loadUsersMore());
    }
  }, [userList, assignListShow, loadingUsers, loadingUsersMore, usersCount, users, innerHeight, dispatch]);

  /* Load more teams if whole team assignment list element is visible */
  useEffect(() => {
    if (loadingTeams !== 'pending' && loadingTeamsMore !== 'pending' && teamsCount > teams?.length && isWholeElementVisible(teamList)) {
      dispatch(assignmentActions.loadTeamsMore());
    }
  }, [teamList, assignListShow, loadingTeams, loadingTeamsMore, teamsCount, teams, innerHeight, dispatch]);

  /* Load more positions if whole position assignment list element is visible */
  useEffect(() => {
    if (loadingPositions !== 'pending' && loadingPositionsMore !== 'pending' && positionsCount > positions?.length && isWholeElementVisible(positionList)) {
      dispatch(assignmentActions.loadPositionsMore());
    }
  }, [positionList, assignListShow, loadingPositions, loadingPositionsMore, positionsCount, positions, innerHeight, dispatch]);

  useEffect(() => {
    setSelectedUsersCache(usersCache.reduce((acc, user) => ({ ...acc, [user.userName]: user }), {}));
  }, [usersCache]);

  useEffect(() => {
    setSelectedTeamsCache(teamsCache.reduce((acc, team) => ({ ...acc, [team.ID]: team }), {}));
  }, [teamsCache]);

  const isWholeElementVisible = (element: HTMLElement): boolean => {
    const elementBottom = element?.getBoundingClientRect()?.bottom;
    return elementBottom <= window.innerHeight;
  }

  const onSelectLearnContent = (learnContent: LearnContent | null) => {
    setSelectedLearnContent(learnContent);
    if (Boolean(learnContent)) {
      dispatch(assignmentActions.loadUsers());
      dispatch(assignmentActions.loadTeams());
      dispatch(assignmentActions.loadPositions(learnContent.ID));
    }
  };

  const onAssignUsers = () => {
    setShowAssignDueDate({
      userIds: getUnassignedUsers(userNameFilters.map(userName => selectedUsersCache[userName])).map(user => user.ID),
      updateExisting: false,
    });
  };

  const onAssignTeams = () => {
    setShowAssignDueDate({
      teamIds: getNotFullyAssignedTeams(teamFilters.map(teamName => selectedTeamsCache[teamName])).map(team => team.ID),
      updateExisting: false,
      allowUpdateExistingChange: true,
    });
    setShowAssignConfirmDialog(true);
  };

  const onAssignPositions = () => {
    setShowAssignDueDate({
      positions: getNotFullyAssignedPositions(positionFilters, positions),
      updateExisting: false,
      allowUpdateExistingChange: true,
    });
  };

  const handleDueDateSave = (dueDate: Date, { userIds, teamIds, positions, updateExisting }: AssignDueDateContext) => {
    if (userIds) {
      dispatch(assignmentActions.assignUsers({ userIds, learnContentId: selectedLearnContent.ID, dueDate, updateExisting }));
    } else if (teamIds) {
      dispatch(assignmentActions.assignTeams({ teamIds, learnContentId: selectedLearnContent.ID, dueDate, updateExisting }));
    } else {
      dispatch(assignmentActions.assignPositions({ positions, learnContentId: selectedLearnContent.ID, dueDate, updateExisting }));
    }

    setShowAssignDueDate(null);
  };

  const onCancelAssignUser = (user: LPUser) => {
    dispatch(assignmentActions.cancelAssignUser({ user, learnContentId: selectedLearnContent.ID }));
  };

  const onCancelAssignTeam = (team: LPTeam) => {
    dispatch(assignmentActions.cancelAssignTeam({ team, learnContentId: selectedLearnContent.ID }));
    setShowConfirmDialog(true);
  };

  const onCancelAssignPosition = (position: string) => {
    dispatch(assignmentActions.cancelAssignPosition({ position, learnContentId: selectedLearnContent.ID }));
    setShowConfirmDialog(true);
  };

  const onAssignListShowChange = () => {
    switch (assignListShow) {
      case 'by-user':
        onAssignUsers();
        break;
      case 'by-user-group':
        onAssignTeams();
        break;
      case 'by-position':
        onAssignPositions();
        break;
    }
  };

  const getUnassignedUsers = (users: LPUser[]) => {
    return users.filter(user => !user.learnings.map(learning => learning.learnContentId).includes(selectedLearnContent.ID));
  };

  const getNotFullyAssignedTeams = (teams: LPTeam[]) => {
    return teams.filter(team => getAssignedUsersCountForLearnContent(team, selectedLearnContent.ID) < team.usersCount);
  };

  const getNotFullyAssignedPositions = (filters: string[], displayedPositions: DisplayPosition[]): string[] => {
    return filters.length === 0 ? [] : displayedPositions.filter(it => it.totalCount > it.assignedCount).map(it => it.name);
  };

  const isButtonDisabled = (): boolean => {
    switch (assignListShow) {
      case 'by-user':
        return getUnassignedUsers(userNameFilters.map(userName => selectedUsersCache[userName])).length === 0 || loadingUsersCache === 'pending';
      case 'by-user-group':
        return getNotFullyAssignedTeams(teamFilters.map(teamName => selectedTeamsCache[teamName])).length === 0 || loadingTeamsCache === 'pending';
      case 'by-position':
        return getNotFullyAssignedPositions(positionFilters, positions).length === 0;
    }
  };

  const handleUserSelectionChange = (selectedUsers: LPUser[] = []) => {
    dispatch(assignmentActions.loadUsersCacheSuccess(selectedUsers));
    setSelectedUsersCache(selectedUsers.reduce((acc, user) => ({ ...acc, [user.userName]: user }), {}));
    dispatch(assignmentActions.setUserNameFilters(selectedUsers.map(user => user.userName)));
  };

  const handleTeamSelectionChange = (selectedTeams: LPTeam[] = []) => {
    dispatch(assignmentActions.loadTeamsCacheSuccess(selectedTeams));
    setSelectedTeamsCache(selectedTeams.reduce((acc, team) => ({ ...acc, [team.ID]: team }), {}));
    dispatch(assignmentActions.setTeamFilters(selectedTeams.map(team => team.ID)));
  };

  const handlePositionSelectionChange = (selectedPositions: string[] = []) => {
    dispatch(assignmentActions.setPositionFilters({
        learnContentId: selectedLearnContent.ID,
        selectedPositions: selectedPositions
    }));
  };

  const infiniteScrollHasMoreByUser = () => {
    return usersCount > users.length;
  };

  const infiniteScrollHasMoreByTeam = () => {
    return teams.length > 0 ? teamsCount > teams.length : false;
  };

  const infiniteScrollHasMoreByPosition = () => {
    return positions.length > 0 ? positionsCount > positions.length : false;
  };

  return (
    <>
      <AppHeader tab="admin/learn-content-assignment" />
      <BasicContainer>
        {loadingLearnContents === 'pending' ? (
          <LoadingIndicator />
        ) : (
          <>
            <Typography variant="body2" className={classes.title}>
              {t('admin.assignment.choose-learn-content')}
            </Typography>
            <Autocomplete
              options={learnContents}
              noOptionsText={t('errors.no-entry-found')}
              className={classes.search}
              getOptionLabel={option => t(option.headlineKey)}
              value={selectedLearnContent}
              onChange={(_event, value: LearnContent) => onSelectLearnContent(value)}
              filterSelectedOptions
              renderOption={option => <LearnContentSearchItem item={option} />}
              renderInput={params => (
                <TextField
                  {...params}
                  className={clsx(classes.searchInput, Boolean(selectedLearnContent) && classes.selectedSearch)}
                  variant="outlined"
                  label={t('admin.assignment.search-learn-content')}
                />
              )}
            />
            {Boolean(selectedLearnContent) && (
              <>
                <Paper elevation={0}>
                  <LearnContentListItem item={selectedLearnContent} onItemClick={() => setShowLearnContentInfo(true)} />
                </Paper>
                <Grid container justify="space-between" alignItems="center" className={classes.tableHeader}>
                  <Grid item>
                    <Typography variant="body2">{t('admin.assignment.assign-for')}</Typography>
                  </Grid>
                  <Grid item>
                    <RadioGroup row value={assignListShow} onChange={(_event, value) => setAssignListShow(value as AssignedListShowType)}>
                      <FormControlLabel value="by-user" control={<Radio />} label={t('admin.assignment.by-user')} />
                      <FormControlLabel value="by-user-group" control={<Radio />} label={t('admin.assignment.by-user-group')} />
                      <FormControlLabel value="by-position" control={<Radio />} label={t('admin.assignment.by-position')} />
                    </RadioGroup>
                  </Grid>
                </Grid>
                {assignListShow === 'by-user' ? (
                  <UserSelectionAutocomplete
                    learnContentId={selectedLearnContent.ID}
                    selectedUsers={userNameFilters.map(userName => selectedUsersCache[userName])}
                    setSelectedUsers={handleUserSelectionChange}
                  />
                ) : assignListShow === 'by-user-group' ? (
                  <TeamSelectionAutocomplete
                    learnContentId={selectedLearnContent.ID}
                    selectedTeams={teamFilters.map(teamName => selectedTeamsCache[teamName])}
                    setSelectedTeams={handleTeamSelectionChange}
                  />
                ) : (
                  <PositionSelectionAutocomplete
                    allPositions={allPositions}
                    selectedPositions={positionFilters}
                    setSelectedPositions={handlePositionSelectionChange}
                  />
                )}
                <Grid container justify="flex-end">
                  <Grid item>
                    <Button
                      className={classes.assignButton}
                      color="primary"
                      variant="contained"
                      startIcon={<GroupAddIcon />}
                      disabled={isButtonDisabled()}
                      onClick={onAssignListShowChange}
                    >
                      {t('admin.assignment.assign-selection')}
                    </Button>
                  </Grid>
                </Grid>
                {loadingUsers === 'pending' || loadingTeams === 'pending' || loadingPositions === 'pending' ? (
                  <>
                    <LoadingIndicator />
                  </>
                ) : assignListShow === 'by-user' ? (
                  <>
                    <InfiniteScroll
                      style={{ overflow: 'visible' }}
                      dataLength={usersCount}
                      next={() => dispatch(assignmentActions.loadUsersMore())}
                      hasMore={infiniteScrollHasMoreByUser()}
                      scrollThreshold={scrollThreshold}
                      loader={<LoadingIndicator />}
                    >
                      <Paper elevation={0}>
                        <List ref={userListRef} disablePadding>
                          {users.map((item, i) => (
                            <UserAssignmentListItem
                              key={item.ID}
                              item={item}
                              divider={i !== users.length - 1}
                              learnContent={selectedLearnContent}
                              allowChanges={isGlobalAdmin || user.ID !== item.ID}
                              onItemDelete={() => onCancelAssignUser(item)}
                              onItemEditDueDate={originalDate =>
                                setShowAssignDueDate({ userIds: [item.ID], originalDate, updateExisting: true })
                              }
                            />
                          ))}
                        </List>
                      </Paper>
                    </InfiniteScroll>
                  </>
                ) : assignListShow === 'by-user-group' ? (
                  <InfiniteScroll
                    style={{ overflow: 'visible' }}
                    dataLength={teamsCount}
                    next={() => dispatch(assignmentActions.loadTeamsMore())}
                    hasMore={infiniteScrollHasMoreByTeam()}
                    scrollThreshold={scrollThreshold}
                    loader={<LoadingIndicator />}
                  >
                    <Paper elevation={0}>
                      <List ref={teamListRef} disablePadding>
                        {teams.map((item, i) => (
                          <TeamAssignmentListItem
                            key={item.ID}
                            learnContentId={selectedLearnContent.ID}
                            divider={i !== teams.length - 1}
                            item={item}
                            defaultDueDate={selectedLearnContent.dueDate}
                            onTeamCancel={() => onCancelAssignTeam(item)}
                            onUserCancel={user => onCancelAssignUser(user)}
                            onEditUserDueDate={(user, originalDate) =>
                              setShowAssignDueDate({ userIds: [user.ID], originalDate, updateExisting: true })
                            }
                          />
                        ))}
                      </List>
                    </Paper>
                  </InfiniteScroll>
                ) : assignListShow === 'by-position' ? (
                  <InfiniteScroll
                    style={{ overflow: 'visible' }}
                    dataLength={positionsCount}
                    next={() => dispatch(assignmentActions.loadPositionsMore(selectedLearnContent.ID))}
                    hasMore={infiniteScrollHasMoreByPosition()}
                    scrollThreshold={scrollThreshold}
                    loader={<LoadingIndicator />}
                  >
                    <Paper elevation={0}>
                      <List ref={positionListRef} disablePadding>
                        {positions.map((item, i) => (
                          <PositionAssignmentListItem
                            key={item.name}
                            learnContentId={selectedLearnContent.ID}
                            divider={i !== positions.length - 1}
                            item={item}
                            defaultDueDate={selectedLearnContent.dueDate}
                            onPositionCancel={() => onCancelAssignPosition(item.name)}
                            onUserCancel={user => onCancelAssignUser(user)}
                            onEditUserDueDate={(user, originalDate) =>
                              setShowAssignDueDate({ userIds: [user.ID], originalDate, updateExisting: true })
                            }
                          />
                        ))}
                      </List>
                    </Paper>
                  </InfiniteScroll>
                ) : (
                  <NoResultsListItem
                    imageSrc={NoAssignmentsIcon}
                    title="admin.assignment.no-assigned-user.title"
                    description="admin.assignment.no-assigned-user.description"
                  />
                )}
              </>
            )}
          </>
        )}
        <LearnContentInfoDialog open={showLearnContentInfo} onClose={() => setShowLearnContentInfo(false)} item={selectedLearnContent} />
        <CustomDueDateDialog
          open={!!showAssignDueDate}
          context={showAssignDueDate}
          onClose={() => setShowAssignDueDate(null)}
          onSave={handleDueDateSave}
        />
      </BasicContainer>
      <BottomAppBar tab="admin/learn-content-assignment" />
      <LoadingIndicatorFullScreen open={loadingPost === 'pending'} />
      <ApproveBaseDialog
        title="admin.assignment.confirm-dialog.title"
        open={loadingPost === 'succeeded' && showConfirmDialog}
        buttonTitle="admin.assignment.confirm-dialog.buttonText"
        descriptions={['admin.assignment.confirm-dialog.description']}
        onClose={() => setShowConfirmDialog(false)}
        onButtonClick={() => setShowConfirmDialog(false)}
      />
      <ApproveBaseDialog
        title="admin.assignment.confirm-dialog.title"
        open={loadingPost === 'succeeded' && showAssignConfirmDialog}
        buttonTitle="admin.assignment.confirm-dialog.buttonText"
        descriptions={['admin.assignment.confirm-dialog.assign-description']}
        onClose={() => setShowAssignConfirmDialog(false)}
        onButtonClick={() => setShowAssignConfirmDialog(false)}
      />
    </>
  );
};
