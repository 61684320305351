import React from 'react';
import { Typography, Dialog, DialogTitle, Divider, Button, DialogContent, DialogActions } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useStylesBase } from './BaseDailogStyles';
import LanguageSelectorList from './LanguageSelectorList';
import { languages } from '../../utils/i18n';

export interface LanguageInitialSelectorProps {
  open: boolean;
  isInitLangSelection: boolean;
  onClose?: () => void;
  onSave: (selectedLanguage: string) => void;
}

const LanguageInitialSelector = (props: LanguageInitialSelectorProps) => {
  const { open, isInitLangSelection, onClose, onSave } = props;

  const classesBase = useStylesBase();
  const { t, i18n } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState<string>(i18n.language);

  const onSelect = (value: string) => {
    setSelectedLanguage(value);
    i18n.changeLanguage(value);
  };

  return (
    <Dialog maxWidth="xs" onClose={onClose} open={open}>
      <DialogTitle className={classesBase.dialogContent}>
        <Typography component="span" variant="h5">
          <LanguageIcon color="primary" className={classesBase.dialogIcon} />
          {t('choose-language.title')}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent className={classesBase.dialogContent}>
        <Typography component="p" variant="body2">
          {t(isInitLangSelection ? 'choose-language.description-initial' : '')}
        </Typography>
        <LanguageSelectorList languages={languages} value={selectedLanguage} onSelect={onSelect} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classesBase.dialogSubmit}
          disabled={Boolean(!selectedLanguage)}
          onClick={() => onSave(selectedLanguage)}
        >
          {t(isInitLangSelection ? 'choose-language.select-language-start' : 'choose-language.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LanguageInitialSelector;
