export default class AbstractStorage {
    constructor() {
    }
    createIdArray(collection) {
        const idArray = [];
        collection.forEach((element) => {
            if (element.id !== undefined) {
                idArray.push(element.id);
            }
        });
        return idArray;
    }
    getIdFromHref(href) {
        if (href.indexOf(".img") > -1) {
            return href.substring(href.lastIndexOf("/") + 1, href.lastIndexOf(".img"));
        }
        else {
            return href.substring(href.lastIndexOf("/") + 1, href.length);
        }
    }
    getPotentialHref(href) {
        return href.substring(0, href.lastIndexOf("/"));
    }
}
