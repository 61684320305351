import React from 'react';
import {
  makeStyles,
  ListItem,
  Theme,
  ListItemAvatar,
  ListItemText,
  Typography,
  Button,
  Avatar,
  Hidden,
  useMediaQuery,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import LearnProgress from '../../sdk/com/apiomat/frontend/mylearningplatform/LearnProgress';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { Grid, IconButton, useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import AppLogoIcon from '../../assets/img/app_logo_icon.svg';
import TrainingLabel from './TrainingLabel';
import { DiamondIcon } from '../Icons/Icons';
import LearnContent from '../../sdk/com/apiomat/frontend/mylearningplatform/LearnContent';
import { getFileLink } from '../../utils/file.utils';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    padding: theme.spacing(2),
    alignItems: 'flex-start',
  },
  avatarWrapper: {
    position: 'relative',
  },
  avatar: {
    width: 54,
    height: 54,
    backgroundColor: theme.palette.background.default,
    borderRadius: 10,
    marginRight: theme.spacing(2),
  },
  successAvatarImage: {
    opacity: 0.3,
  },
  successAvatar: {
    color: '#ddb95c',
    borderColor: '#ddb95c',
    borderWidth: 2,
    borderStyle: 'solid',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: 54,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  primaryTitle: {
    display: 'flex',
  },
  progressLine: {
    height: 10,
    width: 100,
    borderRadius: 10,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
}));

export interface TrainingListItemProps {
  item: LearnProgress;
  learnContent: LearnContent;
  divider: boolean;
  onItemClick: () => void;
  onDownload?: () => void;
}

export default (props: TrainingListItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const { item, learnContent, divider, onItemClick, onDownload } = props;

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const onDownloadItem = (event: React.MouseEvent) => {
    event.stopPropagation();
    onDownload();
  };

  const calculateAchievedScore = (progress: LearnProgress, content: LearnContent): number => {
    if (!progress || !content) {
      return 0;
    }

    return (
      progress.achievedScoreKeys
        ?.map((key: number) => content.scores && content.scores[`${key}`])
        ?.reduce((sum: number, score: string) => sum + (Number.isNaN(Number(score)) ? 0 : Number(score)), 0)
        ?.toFixed(0) ?? 0
    );
  };

  const achievedScore = calculateAchievedScore(item, learnContent);

  return (
    <ListItem divider={divider} className={classes.item} button onClick={onItemClick}>
      <Grid container direction="row" wrap="nowrap" alignItems="center" spacing={2}>
        <Grid item container direction="row">
          <Grid item>
            <ListItemAvatar>
              <>
                <div className={classes.avatarWrapper}>
                  <Avatar
                    className={clsx(classes.avatar, Boolean(item.successDate) && classes.successAvatarImage)}
                    src={learnContent?.thumbnailURL ? getFileLink(learnContent, 'thumbnail') : AppLogoIcon}
                  ></Avatar>
                  {Boolean(item.successDate) && (
                    <div className={classes.successAvatar}>
                      <CheckIcon />
                    </div>
                  )}
                </div>

                <Hidden mdUp>
                  <TrainingLabel item={item} dueDate={item.dueDate} isSmall />
                </Hidden>
              </>
            </ListItemAvatar>
          </Grid>
          <Grid item xs>
            <ListItemText
              primary={
                <div className={classes.primaryTitle}>
                  <Typography component="span" variant="subtitle2" color="primary">
                    {t(learnContent?.headlineKey)}
                  </Typography>
                  <Hidden smDown>
                    <TrainingLabel item={item} dueDate={item.dueDate} />
                  </Hidden>
                </div>
              }
              secondary={<Typography variant="subtitle2">{t(learnContent?.descriptionKey)}</Typography>}
            />
          </Grid>
        </Grid>
        <Grid item>
          {!Boolean(item.lastAccess) && (
            <>
              <Hidden mdUp>
                <IconButton color="primary" onClick={onItemClick}>
                  <PlayArrowIcon />
                </IconButton>
              </Hidden>
              <Hidden smDown>
                <Button color="primary" startIcon={<PlayArrowIcon />} className={classes.noWrap} onClick={onItemClick}>
                  {t('dashboard.start')}
                </Button>
              </Hidden>
            </>
          )}
          {Boolean(item.successDate) && (
            <Grid container alignItems="center" spacing={isSmall ? 2 : 10} direction="row" wrap="nowrap">
              <Hidden xsDown>
                <Grid item container direction="row" wrap="nowrap" spacing={1}>
                  <Grid item>
                    <DiamondIcon fontSize="inherit" color="inherit" />
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" component="span" noWrap>
                      {achievedScore} LP
                    </Typography>
                  </Grid>
                </Grid>
              </Hidden>
              <Grid item>
                <Hidden mdUp>
                  <IconButton color="primary" onClick={event => onDownloadItem(event)}>
                    <GetAppIcon />
                  </IconButton>
                </Hidden>
                <Hidden smDown>
                  <Button color="primary" startIcon={<GetAppIcon />} className={classes.noWrap} onClick={event => onDownloadItem(event)}>
                    {t('dashboard.download')}
                  </Button>
                </Hidden>
              </Grid>
            </Grid>
          )}
          {Boolean(item.lastAccess) && Boolean(item.successDate) === false && (
            <>
              <Hidden mdUp>
                <IconButton color="primary" onClick={onItemClick}>
                  <TimelapseIcon />
                </IconButton>
              </Hidden>
              <Hidden smDown>
                <Button color="primary" startIcon={<TimelapseIcon />} className={classes.noWrap} onClick={onItemClick}>
                  {t('dashboard.in-progress')}
                </Button>
              </Hidden>
            </>
          )}
        </Grid>
      </Grid>
    </ListItem>
  );
};
