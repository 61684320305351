var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import AOMCacheStrategy from "./../AOMCacheStrategy";
import AOMRequestError from "./../AOMRequestError";
import Datastore from "./../Datastore";
import AOMStatus from "./../Status";
import AbstractClientDataModel from "./../AbstractClientDataModel";
export default class Translation extends AbstractClientDataModel {
    static getTranslations(query, withReferencedHrefs = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadListFromServerWithClass(Translation, query, withReferencedHrefs, usePersistentStorage);
        });
    }
    static getTranslationsCount(query, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadCountFromServer(Translation, undefined, query, usePersistentStorage);
        });
    }
    static staticGetModuleName() {
        return "Localisation";
    }
    static staticGetModelName() {
        return "Translation";
    }
    get customMetaForClass() {
        return "";
    }
    static removeAllFromStorage() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorageByClass(Translation);
        });
    }
    static deleteAllFromStorage(query) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorage(query, Translation);
        });
    }
    static getTranslationsFromPersistentStorage(whereWithPlaceholders = "", whereValues = [], orderBy = "", limit, offset) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadFromPersistentStorage(Translation, whereWithPlaceholders, whereValues, orderBy, limit, offset);
        });
    }
    static getTranslationsCountFromPersistentStorage(whereWithPlaceholders = "", whereValues = []) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.getCountFromPersistentStorage(Translation, whereWithPlaceholders, whereValues);
        });
    }
    constructor() {
        super();
        this.dao = new TranslationDAO();
    }
    get modelName() { return "Translation"; }
    get moduleName() { return "Localisation"; }
    get jsonFileUrlResource() {
        let url;
        this.refreshEmbedded();
        url = this.dao.jsonFileURL !== null ? this.dao.jsonFileURL : undefined;
        if (url) {
            url += ".img";
            return url;
        }
        else {
            return undefined;
        }
    }
    get jsonFileURL() {
        let url = this.jsonFileUrlResource;
        if (url) {
            url += "?apiKey=" + User.AOMAPIKEY + "&system=" + User.AOMSYS;
        }
        return url;
    }
    getJsonFileTokenURL(validity, oneTime) {
        return __awaiter(this, void 0, void 0, function* () {
            let url = this.dao.jsonFileURL;
            if (!url || typeof validity === "undefined" || typeof oneTime === "undefined") {
                return undefined;
            }
            url += ".key?apiKey=" + User.AOMAPIKEY + "&system=" + User.AOMSYS;
            return yield Datastore.Instance.getStaticDataTokenUrl(url, validity, oneTime);
        });
    }
    loadJsonFile(usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            const resUrl = this.jsonFileURL;
            if (typeof resUrl === "undefined") {
                return Promise.reject(new AOMRequestError(AOMStatus.ATTACHED_HREF_MISSING, 200));
            }
            return yield Datastore.Instance.loadResource(resUrl, usePersistentStorage);
        });
    }
    postJsonFile(_data, name = "", format = "", usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof _data === "undefined" || _data === null) {
                return Promise.reject(new AOMRequestError(AOMStatus.BAD_RESOURCE, 200));
            }
            if ((typeof this.href === "undefined" || this.href === "") && this.parent === null) {
                return Promise.reject(new AOMRequestError(AOMStatus.HREF_NOT_FOUND, 200));
            }
            let href;
            let response;
            const options = this.parent === null ? this.getOptionsForStaticData("jsonFile") : {};
            try {
                response = yield Datastore.Instance.postStaticDataOnServer(_data, name, format, false, usePersistentStorage, options);
                href = response.href;
            }
            catch (error) {
                return Promise.reject(error);
            }
            this.dao.jsonFileURL = href;
            if (this.href && Datastore.Instance.getCachingStrategy() !== AOMCacheStrategy.NETWORK_ONLY) {
                yield this.saveToStorage(usePersistentStorage);
            }
            if (this.parent !== null) {
                this.setDataToParentLocal(this);
                try {
                    yield this.save(false, usePersistentStorage);
                }
                catch (error) {
                    yield this.deleteJsonFile(usePersistentStorage);
                }
            }
            if (typeof response !== "undefined" && response.offline === true) {
                yield this.save(false, usePersistentStorage);
            }
            try {
                yield this.load(undefined, usePersistentStorage);
            }
            catch (error) {
                return Promise.reject(error);
            }
        });
    }
    deleteJsonFile(usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            const href = this.dao.jsonFileURL;
            if (href === null || href === undefined || href === "") {
                return Promise.reject(new AOMRequestError(AOMStatus.ATTACHED_HREF_MISSING, 200));
            }
            try {
                const response = yield Datastore.Instance.deleteOnServer(href, this, false, usePersistentStorage, true);
                this.dao.jsonFileURL = null;
                if (this.href && Datastore.Instance.getCachingStrategy() !== AOMCacheStrategy.NETWORK_ONLY) {
                    yield this.saveToStorage(usePersistentStorage);
                }
                if (typeof response !== "undefined" && response.offline === true) {
                    yield this.save(false, usePersistentStorage);
                }
                if (this.parent !== null) {
                    delete this.dao.jsonFileURL;
                    this.setDataToParentLocal(this);
                    yield this.save(false, usePersistentStorage);
                }
                yield this.load(undefined, usePersistentStorage);
            }
            catch (error) {
                return Promise.reject(error);
            }
        });
    }
    get jsonString() {
        return this.dao.jsonString !== null ? this.dao.jsonString : undefined;
    }
    set jsonString(_jsonString) {
        this.dao.jsonString = _jsonString;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get language() {
        return this.dao.language !== null ? this.dao.language : undefined;
    }
    set language(_language) {
        this.dao.language = _language;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
    get wording() {
        return this.dao.wording !== null ? this.dao.wording : undefined;
    }
    set wording(_wording) {
        this.dao.wording = _wording;
        if (this.parent !== null) {
            this.setDataToParentLocal(this);
        }
    }
}
import TranslationDAO from "./TranslationDAO";
import User from "./../basics/User";
