import { createCustomRestUrl } from './aom.utils';
import LPUser from '../sdk/com/apiomat/frontend/mylearningplatform/LPUser';
import { getRestDays } from './trainings.utils';
import LearnProgress from '../sdk/com/apiomat/frontend/mylearningplatform/LearnProgress';
import Datastore from '../sdk/com/apiomat/frontend/Datastore';
import axios from 'axios';
import PasswordResetRequest from '../sdk/com/apiomat/frontend/mylearningplatform/PasswordResetRequest';
import SdkInfo from '../sdk/com/apiomat/frontend/SdkInfo';
import LPTeam from '../sdk/com/apiomat/frontend/mylearningplatform/LPTeam';
import uniq from 'lodash.uniq';

export const hasOverdueTrainings = (user: LPUser): boolean => {
  return Boolean(user.learnings.find(item => Boolean(item.successDate) === false && getRestDays(item.dueDate) < 0));
};

export const hasExpiringTrainings = (user: LPUser): boolean => {
  return Boolean(user.learnings.find(item => Boolean(item.successDate) === false && getRestDays(item.dueDate) < 14));
};

export const getUserName = (user: LPUser): string => {
  return `${user.lastName || ''}, ${user.firstName || ''}`;
};

export const getPostion = (user?: LPUser): string => {
  return user?.position?.join(', ') || '';
};

export const getTeam = (user: LPUser): string => {
  return user.team?.map(el => el.name).join(', ');
};

export const userSort = (a: LPUser, b: LPUser) => {
  return getUserName(a) > getUserName(b) ? 1 : -1;
};

export const getLearnProgress = (user: LPUser, learnContentId: string): LearnProgress | undefined => {
  return user.learnings?.filter(learning => learning.learnContentId === learnContentId)[0];
};

export const userCompletedLearnContent = (user: LPUser, learnContentId: string) => {
  return user.learnings.filter(learning => learning.learnContentId === learnContentId).some(learning => Boolean(learning.successDate));
};

export const changePassword = (user: LPUser, newPassword: String): Promise<any> => {
  const employeeNo = user.foreignId;
  const url = createCustomRestUrl(`/lpUser/${employeeNo}/changePassword`);

  const body = newPassword;
  return axios.put(url, body, { headers: Datastore.Instance.getHeader(false, {}) });
};

export const checkPasswordResetRequest = (passwordResetToken: String): Promise<any> => {
  const url = createCustomRestUrl(`/passwordReset/${passwordResetToken}`);

  return axios.put(url, {}, { headers: Datastore.Instance.getHeader(false, {}) });
};

export const fetchUserCount = async (query: string): Promise<number> => {
  const moduleName = PasswordResetRequest.staticGetModuleName();
  const url = `${LPUser.AOMBASEURL.replace(
    'apps',
    `modules/${moduleName}/v/${SdkInfo.getVersion(moduleName)}`
  )}/spec/lpUserCount?q=${encodeURIComponent(query || '')}`;

  const response = await axios.get(url, { headers: Datastore.Instance.getHeader(false, {}) });

  return response.data.userCount;
};

export const isUserAssigned = (user: LPUser, learnContentId: string) => {
  return user.learnings?.some(it => it.learnContentId === learnContentId)
};

/**
 * Loads the teams defined in the teamIds array for every user in a way to reduce the amount of requests needed.
 * 
 * @param users the users the teams should be loaded for
 */
export const bulkLoadTeamsForUsers = async (users: LPUser[]): Promise<void> => {
  const teamIds = uniq(users.map(user => user.teamIds ?? []).flat())
  const query = `id in [${teamIds.map(id => `id(${id})`).join(', ')}]`

  const teams: LPTeam[] = await LPTeam.getLPTeams(query)

  const teamsByIds = teams.reduce((acc, curr) => {
    acc[curr.ID] = curr
    return acc
  }, {})

  for (const user of users) {
    const userTeams = user.teamIds?.map(id => teamsByIds[id]).filter(it => !!it)
    user['hashmap'].teams = userTeams
  }
}

export const getUsersCountForPosition = (position: string) => {
  return fetchUserCount(`position=="${position}"`);
};

export const getAssignedUsersCountForLearnContent = (position: string, learnContentId: string) => {
  return fetchUserCount(`position=="${position}" and learnings.learnContentId=="${learnContentId}"`)
};
