import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import GetAppIcon from '@material-ui/icons/GetApp';
import CheckIcon from '@material-ui/icons/Check';

export interface PdfViewerHeaderProps {
  hasSuccessDate: boolean;
  hasNoQuestions: boolean;
  isDownloadable: boolean;
  src: string;
  onStart: () => void;
}

const PdfViewerHeader = (props: PdfViewerHeaderProps) => {
  const { hasNoQuestions, hasSuccessDate, onStart, src, isDownloadable } = props;
  const { t } = useTranslation();

  const showButton = hasNoQuestions ? !hasSuccessDate : true;

  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item>
        {showButton && (
          <Button data-testid="start-btn" color="primary" startIcon={hasNoQuestions ? <CheckIcon /> : <PlayArrowIcon />} onClick={onStart}>
            {hasNoQuestions ? t('training.close-training') : t('training.start-training')}
          </Button>
        )}
      </Grid>
      {isDownloadable && (
        <Grid item>
          <Button data-testid="download-btn" color="primary" startIcon={<GetAppIcon />} href={src} target="blank">
            {t('training.download')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default PdfViewerHeader;
