var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export default class AOMHttpClient {
    constructor() {
        this._offlineMapping = {};
        this._wasLoadedFromStorage = {};
        this._useETag = true;
        this._deltaSyncStrategy = AOMDeltaSyncStrategy.NONE;
        this.responseHook = (http, rawResponseText) => {
            return http;
        };
        this.processOfflineHook = (flag) => {
            return flag;
        };
    }
    get deltaSyncStrategy() {
        return this._deltaSyncStrategy;
    }
    set deltaSyncStrategy(deltaSyncStrategy) {
        this._deltaSyncStrategy = deltaSyncStrategy;
    }
    sendRequest(_httpMethod = "GET", _url, _expectedReturnCodes, clazz, _args = {}, isCollection = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            this._wasLoadedFromStorage = {};
            const { data, withReferencedHrefs, isByteData = false, fileName = "", fileFormat = "", returnpost = false, isReload, headerDetails = {}, isRef, isImage, } = _args;
            if (isByteData && _httpMethod === "POST") {
                _args.data = new Buffer(data);
            }
            let withQuery = false;
            if (_httpMethod === "GET" && data) {
                _url += "?q=" + encodeURIComponent(data);
                withQuery = true;
            }
            if (fileName !== "") {
                if (withQuery === false) {
                    _url += "?name=";
                    withQuery = true;
                }
                else {
                    _url += "&name=";
                }
                _url += encodeURIComponent(fileName);
                if (fileFormat !== "") {
                    _url += "&format=" + encodeURIComponent(fileFormat);
                }
            }
            if (withReferencedHrefs) {
                if (withQuery === false) {
                    _url += "?";
                }
                else {
                    _url += "&";
                }
                _url += "withReferencedHrefs=true";
            }
            _url = this.createHref(_url);
            const cacheStrategy = Datastore.Instance.getCachingStrategy();
            if (_httpMethod !== "GET" && AOMNetworkHandler.isConnected === true || (AOMNetworkHandler.isConnected === true
                && cacheStrategy !== AOMCacheStrategy.CACHE_ELSE_NETWORK && cacheStrategy !== AOMCacheStrategy.CACHE_THEN_NETWORK)) {
                return yield this.sendOnlineRequest(_httpMethod, _url, _expectedReturnCodes, clazz, _args, isCollection, usePersistentStorage);
            }
            else if (cacheStrategy !== AOMCacheStrategy.NETWORK_ONLY && _httpMethod !== "GET"
                && Datastore.Instance.isOfflineHandlerInitialized() === true && AOMNetworkHandler.isConnected === false) {
                return yield Datastore.Instance.OfflineHandler.addTask(_httpMethod, _url, clazz, _args, isImage, isRef, usePersistentStorage);
            }
            else if (cacheStrategy !== AOMCacheStrategy.NETWORK_ONLY) {
                return yield this.getFromStorageOrNetwork(_httpMethod, _url, _expectedReturnCodes, clazz, _args, isCollection, usePersistentStorage);
            }
            else {
                return Promise.reject(new AOMRequestError(AOMStatus.NO_NETWORK, _expectedReturnCodes));
            }
        });
    }
    sendOnlineRequest(_httpMethod, _url, _expectedReturnCodes, clazz, _args = {}, isCollection = false, usePersistentStorage, withETag = true, redirectCount = 0) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data, withReferencedHrefs, isRef: isReference, isByteData = false, fileName = "", fileFormat = "", returnpost = false, isReload, headerDetails = {}, isImage, } = _args;
            const _usePersistentStorage = this.getUsePersistentStorageForClass(clazz, _url, usePersistentStorage);
            let eTag = null;
            if (this._useETag && withETag) {
                eTag = yield StorageFactory.ChooseStorageImpl(_usePersistentStorage, Datastore.Instance.offlineHandlerExists()).getETag(_url);
            }
            let deltaSync = null;
            const cacheStrategy = Datastore.Instance.getCachingStrategy();
            if (isCollection && cacheStrategy !== AOMCacheStrategy.NETWORK_ONLY) {
                if (this._deltaSyncStrategy === AOMDeltaSyncStrategy.OBJECT_BASED) {
                    let storageResult = null;
                    try {
                        storageResult = yield StorageFactory.ChooseStorageImpl(_usePersistentStorage, Datastore.Instance.offlineHandlerExists())
                            .getCollectionObjects(_url, clazz);
                    }
                    catch (error) {
                    }
                    if (storageResult != null) {
                        const hrefLMJsonObject = {};
                        if (storageResult instanceof Array) {
                            for (const acdm of storageResult) {
                                hrefLMJsonObject[acdm.id] = acdm.lastModifiedAt;
                            }
                        }
                        else {
                            hrefLMJsonObject[storageResult.id] = storageResult.lastModifiedAt;
                        }
                        deltaSync = JSON.stringify(hrefLMJsonObject);
                    }
                }
                else if (this._deltaSyncStrategy === AOMDeltaSyncStrategy.COLLECTION_BASED) {
                    const collLastUpdate = yield StorageFactory.ChooseStorageImpl(_usePersistentStorage, Datastore.Instance.offlineHandlerExists())
                        .getLastUpdateFromCollection(_url);
                    if (collLastUpdate != null) {
                        deltaSync = collLastUpdate;
                    }
                }
            }
            const headers = this.getHeader(isByteData, headerDetails, eTag, deltaSync);
            const isGzipEncoding = headers["content-encoding"] === "gzip";
            let responseType = "text";
            if (isByteData && isGzipEncoding === false) {
                responseType = "arraybuffer";
            }
            const axiosHttpMethod = _httpMethod;
            try {
                const options = {
                    url: _url,
                    method: axiosHttpMethod,
                    data: data,
                    headers: headers,
                    responseType: responseType,
                    transformResponse: (r) => {
                        return r;
                    },
                    validateStatus: (status) => {
                        return true;
                    },
                    timeout: Datastore.Instance.requestTimeout,
                };
                if (options.method === "GET" || options.method === "get") {
                    delete options.data;
                }
                const response = yield axios.request(options);
                response.url = _url;
                response.statusCode = response.status;
                if (redirectCount < 5 && response.status === 405 && _httpMethod === "DELETE") {
                    const responseUrl = response.request.res.responseUrl;
                    if (typeof responseUrl === "string" && _url !== responseUrl) {
                        return this.sendOnlineRequest(_httpMethod, responseUrl, _expectedReturnCodes, clazz, _args, isCollection, usePersistentStorage, withETag, redirectCount + 1);
                    }
                }
                const responseObj = yield this.processResponse(response, response.data, _expectedReturnCodes, _httpMethod, clazz, returnpost, _url, usePersistentStorage, isByteData, isReference, deltaSync, isCollection, _args);
                if (responseObj instanceof AOMRequestError || responseObj instanceof TypeError) {
                    return Promise.reject(responseObj);
                }
                else {
                    return responseObj;
                }
            }
            catch (error) {
                if (cacheStrategy === AOMCacheStrategy.NETWORK_ONLY) {
                    const responseObj = yield this.processResponse(null, error.message, _expectedReturnCodes, _httpMethod, clazz, returnpost, _url, usePersistentStorage, isByteData, isReference, deltaSync);
                    if (Object.keys(this._wasLoadedFromStorage).length === 0) {
                        return Promise.reject(responseObj);
                    }
                    else {
                        return responseObj;
                    }
                }
                else {
                    AOMNetworkHandler.isConnected = false;
                    if (_httpMethod !== "GET" && Datastore.Instance.isOfflineHandlerInitialized() === true) {
                        const response = yield Datastore.Instance.OfflineHandler.addTask(_httpMethod, _url, clazz, _args, isImage, isReference, usePersistentStorage);
                        return response;
                    }
                    else {
                        return yield this.getFromStorageOrNetwork(_httpMethod, _url, _expectedReturnCodes, clazz, _args, isCollection, usePersistentStorage);
                    }
                }
            }
        });
    }
    getFromStorageOrNetwork(_httpMethod, _url, _expectedReturnCodes, clazz, _args = {}, isCollection = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            this.processOfflineHook(true);
            const _usePersistentStorage = this.getUsePersistentStorageForClass(clazz, _url, usePersistentStorage);
            const json = yield StorageFactory.ChooseStorageImpl(_usePersistentStorage, Datastore.Instance.offlineHandlerExists())
                .getCollectionObjects(_url, clazz);
            const cacheStrategy = Datastore.Instance.getCachingStrategy();
            if (!json) {
                if (AOMNetworkHandler.isConnected && cacheStrategy === AOMCacheStrategy.CACHE_ELSE_NETWORK
                    || cacheStrategy === AOMCacheStrategy.CACHE_THEN_NETWORK) {
                    if (cacheStrategy === AOMCacheStrategy.CACHE_THEN_NETWORK) {
                    }
                    return yield this.sendOnlineRequest(_httpMethod, _url, _expectedReturnCodes, clazz, _args, isCollection, usePersistentStorage);
                }
                else {
                    return Promise.reject(new AOMRequestError(AOMStatus.ID_NOT_FOUND_OFFLINE, 201, "No data found offline"));
                }
            }
            else {
                let data;
                try {
                    data = yield this.createClassInstancesFromJSON(json, clazz, isCollection);
                }
                catch (error) {
                    data = json;
                }
                this.saveModelsInWasLoadedFromStorageMap(data, _url);
                const { returnpost = false } = _args;
                return Promise.resolve({
                    _httpMethod: _httpMethod,
                    returnpost: returnpost,
                    returnedHref: _url,
                    responseText: data,
                });
            }
        });
    }
    getUsePersistentStorageForClass(clazz, _url, usePersistentStorageFromMethod) {
        let usePersistentStorageFromClass;
        const type = typeof clazz !== "undefined" ? this.getTypefromClass(clazz) : Datastore.getTypeFromUrl(_url);
        if (typeof type !== "undefined" && this._offlineMapping[type] !== undefined) {
            usePersistentStorageFromClass = this._offlineMapping[type];
        }
        if (typeof usePersistentStorageFromMethod !== "undefined") {
            return usePersistentStorageFromMethod;
        }
        else if (typeof usePersistentStorageFromClass !== "undefined") {
            return usePersistentStorageFromClass;
        }
        else {
            return false;
        }
    }
    saveModelsInWasLoadedFromStorageMap(data, _url) {
        if (data instanceof Array) {
            data.forEach((el) => {
                const id = el.ID ? el.ID : el.id;
                this._wasLoadedFromStorage[id] = true;
            });
        }
        else {
            let id = data.ID ? data.ID : data.id;
            if (data.ID) {
                id = data.ID;
            }
            else if (data.id) {
                id = data.id;
            }
            else if (_url.indexOf(".img") > -1 || _url.indexOf("files/") > -1) {
                id = _url.substring(_url.lastIndexOf("/") + 1, _url.lastIndexOf(".img"));
            }
            this._wasLoadedFromStorage[id] = true;
        }
    }
    wasLoadedFromStorage(id) {
        return this._wasLoadedFromStorage.hasOwnProperty(id);
    }
}
import axios from "axios";
import AOMCacheStrategy from "./AOMCacheStrategy";
import AOMDeltaSyncStrategy from "./AOMDeltaSyncStrategy";
import AOMNetworkHandler from "./offline/AOMNetworkHandler";
import AOMStatus from "./Status";
import AOMRequestError from "./AOMRequestError";
import Datastore from "./Datastore";
import StorageFactory from "./offline/StorageFactory";
