import LearnContent from '../sdk/com/apiomat/frontend/mylearningplatform/LearnContent';
import { TranslatedString, TranslatedFile, FileData, LearnContentData, QuestionData, TopicData } from '../store/learnContents';
import i18n from 'i18next';
import axios from 'axios';
import Datastore from '../sdk/com/apiomat/frontend/Datastore';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { LearnContentTypes } from '../enums/LearnContentTypes';
import Question from '../sdk/com/apiomat/frontend/mylearningplatform/Question';
import LocalizedLearnContent from '../sdk/com/apiomat/frontend/mylearningplatform/LocalizedLearnContent';
import Translation from '../sdk/com/apiomat/frontend/localisation/Translation';
import LPUser from '../sdk/com/apiomat/frontend/mylearningplatform/LPUser';
import { getFileLink, getFileTypeName, getRestrictedFileLink } from './file.utils';
import AbstractClientDataModel from '../sdk/com/apiomat/frontend/AbstractClientDataModel';
import { deassignUser } from './assignment.utils';
import LPResource from '../sdk/com/apiomat/frontend/mylearningplatform/LPResource';
import { LPScoreKey } from '../enums/LPScoreKey';
import { getScore } from './trainings.utils';

export const getLanguages = (item: LearnContent): string[] => {
  return item.localizedLearnContents?.map(el => el.language) || [];
};

export const getTranslatedString = (key: string, langs: string[]): TranslatedString => {
  const translatedString = {};
  langs.forEach(lng => {
    translatedString[lng] = i18n.t(key, { lng });
  });
  return translatedString;
};

export const getResourceLink = async (localLearnContent: LocalizedLearnContent): Promise<string> => {
  if (Boolean(localLearnContent.resourceId) === false) {
    return null;
  }

  const rows = await LPResource.getLPResources(`id==id(${localLearnContent.resourceId})`);
  const resource = rows[0];
  if (Boolean(resource) === false) {
    return null;
  }

  if (resource.contentType === LearnContentTypes.pdf) {
    return await getRestrictedFileLink(resource, 'fileContent');
  } else {
    return resource.webhostingPath;
  }
};

export const getFileData = async (obj: AbstractClientDataModel, attribute: string, isRestricted: boolean): Promise<FileData> => {
  const url = isRestricted ? await getRestrictedFileLink(obj, attribute) : getFileLink(obj, attribute);
  const headers = Datastore.Instance.getHeader(true, { acceptEncoding: 'gzip, deflate' });
  try {
    const response = await axios.get(url, { responseType: 'arraybuffer', headers });

    return {
      name: (response.headers['content-disposition'] || '').replace('attachment; filename=', ''),
      data: response.data,
      imageUrl: url,
    } as FileData;
  } catch (error) {
    console.error(error);
  }

  return null;
};

export const getTranslatedFiles = async (item: LearnContent): Promise<TranslatedFile> => {
  const translatedFile = {};
  const files = await Promise.all(
    item.localizedLearnContents.map(async el => {
      if (Boolean(el.resourceId) === false) {
        return null;
      }
      const rows = await LPResource.getLPResources(`id==id(${el.resourceId})`);
      const recourse = rows[0];
      if (Boolean(recourse) === false) {
        return null;
      }
      return getFileData(recourse, 'fileContent', true);
    })
  );
  item.localizedLearnContents.forEach((el, i) => {
    translatedFile[el.language] = files[i];
  });
  return translatedFile;
};

export const archiveLearnContent = async (learnContent: LearnContent) => {
  learnContent.isArchived = 1;
  await learnContent.save();
  const users = await LPUser.getLPUsers(`learnings.learnContentId=="${learnContent.ID}"`);
  const filteredUsers = users.filter(user =>
    user.learnings.find(learning => learning.learnContentId === learnContent.ID && Boolean(learning.successDate) === false)
  );
  return Promise.all(filteredUsers.map(user => deassignUser(user, learnContent.ID)));
};

export const getLearnContentData = async (item: LearnContent): Promise<LearnContentData> => {
  const langs = getLanguages(item);

  const thumbnail = item.thumbnailURL ? await getFileData(item, 'thumbnail', false) : null;
  const files = await getTranslatedFiles(item);

  const questions = [];
  item.questions.forEach(question => {
    questions[question.orderNumber] = {
      id: uuid(),
      title: getTranslatedString(question.textKey, langs),
      value: Boolean(question.isAnswerTrue),
    } as QuestionData;
  });

  return {
    langs,
    header: getTranslatedString(item.headlineKey, langs),
    description: getTranslatedString(item.descriptionKey, langs),
    learnContentType: item.learnContentType as LearnContentTypes,
    questionsDefined: item.questions.length > 0,
    topicsDefined: (item.subTopicKeys || []).length > 0,
    lp: getScore(item, LPScoreKey.BASIC),
    lives: item.lives,
    dueDate: Boolean(item.dueDate) ? moment(item.dueDate) : null,
    questions,
    topics: (item.subTopicKeys || []).map(
      (el: string) =>
        ({
          id: uuid(),
          title: getTranslatedString(el, langs),
        } as TopicData)
    ),
    files,
    thumbnail,
    downloadable: Boolean(item.downloadable)
  };
};

export const createLearnContent = async (learnContentData: LearnContentData): Promise<LearnContent> => {
  const foreignId = uuid();
  const learnContent = new LearnContent();
  learnContent.foreignId = foreignId;
  learnContent.headlineKey = `${foreignId}.headline`;
  learnContent.descriptionKey = `${foreignId}.description`;
  learnContent.learnContentType = learnContentData.learnContentType;
  learnContent.lives = learnContentData.lives;
  learnContent.scores = { [LPScoreKey.BASIC]: `${learnContentData.lp || 0}` };
  learnContent.dueDate = learnContentData.dueDate ? learnContentData.dueDate.toDate() : null;
  learnContent.subTopicKeys = learnContentData.topics.map((_topic, i) => `${foreignId}.topics.${i}`);
  learnContent.downloadable = learnContentData.downloadable ? 1 : 0;

  await learnContent.save();

  /** add thumbnail */
  if (learnContentData.thumbnail?.data) {
    const file = learnContentData.thumbnail;
    const nameArr = file.name.split('.');
    const fileType = nameArr.pop();
    const fileName = nameArr.join('');

    await learnContent.postThumbnail(file.data, fileName, fileType);
  }

  /** add questions */
  if (learnContentData.learnContentType === LearnContentTypes.pdf && learnContentData.questionsDefined) {
    const questions = learnContentData.questions.map((questionData, i) => {
      const question = new Question();
      question.foreignId = questionData.id;
      question.textKey = `${foreignId}.questions.${questionData.id}`;
      question.isAnswerTrue = questionData.value ? 1 : 0;
      question.orderNumber = i;
      return question;
    });
    for (const question of questions) {
      await learnContent.postQuestions(question);
    }
  }

  /** create translation json */
  const translations = learnContentData.langs.map(lang => {
    const translation = new Translation();

    const wording = {};
    wording[`${foreignId}.headline`] = learnContentData.header[lang];
    wording[`${foreignId}.description`] = learnContentData.description[lang];

    learnContentData.questions.forEach(question => {
      wording[`${foreignId}.questions.${question.id}`] = question.title[lang];
    });

    learnContentData.topics.forEach((topic, i) => {
      wording[`${foreignId}.topics.${i}`] = topic.title[lang];
    });

    translation.language = lang;
    translation.wording = wording;
    return translation;
  });
  await Promise.all(translations.map(translation => translation.save()));

  /** add localized learn contents */
  const localLearnContents = await Promise.all(
    learnContentData.langs.map(async lang => {
      const file = learnContentData.files[lang];
      const { fileType, fileName } = getFileTypeName(file.name);
      const resource = new LPResource();
      resource.fileType = fileType;
      resource.fileName = fileName;
      resource.contentType = learnContent.learnContentType;
      await resource.save();
      await resource.postFileContent(file.data, fileName, fileType);

      const localLearnContent = new LocalizedLearnContent();
      localLearnContent.language = lang;
      localLearnContent.learnContentType = learnContentData.learnContentType;
      localLearnContent.resourceId = resource.ID;
      localLearnContent.translationId = translations.find(t => t.language === localLearnContent.language)?.ID;
      return localLearnContent;
    })
  );
  for (const localLearnContent of localLearnContents) {
    await learnContent.postLocalizedLearnContents(localLearnContent);
  }

  /** reload current language translations */
  i18n.reloadResources();

  return learnContent;
};
