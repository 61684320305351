import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import configureStore from './store';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './store/history';
import Root from './containers/Root';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme/theme';
import { LinearProgress, CssBaseline } from '@material-ui/core';
import NotistackProvider from './components/Notifier/NotistackProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

export default () => {
  const { store, persistor } = configureStore();

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Suspense fallback={<LinearProgress />}>
                <NotistackProvider>
                  <Root />
                </NotistackProvider>
              </Suspense>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </PersistGate>
      </ConnectedRouter>
    </Provider>
  );
};
