var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import AbstractStorage from "./AbstractStorage";
import MemoryStorage from "./MemoryStorage";
import PersistentStorage from "./PersistentStorage";
export default class MemoryElsePersistentStorage extends AbstractStorage {
    static get Instance() {
        return this._instance || (this._instance = new this());
    }
    constructor() {
        super();
    }
    addOrUpdateCollection(href, responseData, useCollectionMerging = false) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield MemoryStorage.Instance.addOrUpdateCollection(href, responseData, useCollectionMerging);
        });
    }
    getCollectionObjects(href, clazz) {
        return __awaiter(this, void 0, void 0, function* () {
            let data = yield MemoryStorage.Instance.getCollectionObjects(href, clazz);
            if (typeof data === "undefined" || (data instanceof Array && data.length === 0)) {
                data = yield PersistentStorage.Instance.getCollectionObjects(href, clazz);
            }
            return data;
        });
    }
    removeObjectByHref(href, clazz) {
        return __awaiter(this, void 0, void 0, function* () {
            yield MemoryStorage.Instance.removeObjectByHref(href, clazz);
            yield PersistentStorage.Instance.removeObjectByHref(href, clazz);
        });
    }
    clearStorage() {
        return __awaiter(this, void 0, void 0, function* () {
            yield MemoryStorage.Instance.clearStorage();
            yield PersistentStorage.Instance.clearStorage();
        });
    }
    getObject(id, href, clazz, isCollection) {
        return __awaiter(this, void 0, void 0, function* () {
            let data = MemoryStorage.Instance.getObject(id);
            if (typeof data === "undefined") {
                data = yield PersistentStorage.Instance.getObject(id, href, clazz, isCollection);
            }
            return data;
        });
    }
    getStaticData(id) {
        return __awaiter(this, void 0, void 0, function* () {
            let data = MemoryStorage.Instance.getObject(id);
            if (typeof data === "undefined") {
                data = yield PersistentStorage.Instance.getStaticData(id);
            }
            return data;
        });
    }
    getCollection(href) {
        return __awaiter(this, void 0, void 0, function* () {
            let data = MemoryStorage.Instance.getCollection(href);
            if (typeof data === "undefined" || (data instanceof Array && data.length === 0)) {
                const collDAOs = yield PersistentStorage.Instance.getCollection(href);
                data = [];
                for (const dao of collDAOs) {
                    data.push(dao.id);
                }
            }
            return data;
        });
    }
    removeCollection(href) {
        return __awaiter(this, void 0, void 0, function* () {
            yield MemoryStorage.Instance.removeCollection(href);
            yield PersistentStorage.Instance.removeCollection(href);
        });
    }
    removeAllObjects(clazz) {
        return __awaiter(this, void 0, void 0, function* () {
            yield MemoryStorage.Instance.removeAllObjects(clazz);
            yield PersistentStorage.Instance.removeAllObjects(clazz);
        });
    }
    removeIdFromCollection(href, id) {
        return __awaiter(this, void 0, void 0, function* () {
            yield MemoryStorage.Instance.removeIdFromCollection(href, id);
            yield PersistentStorage.Instance.removeIdFromCollection(href, id);
        });
    }
    getETag(url) {
        return __awaiter(this, void 0, void 0, function* () {
            let data = yield MemoryStorage.Instance.getETag(url);
            if (typeof data === "undefined") {
                data = yield PersistentStorage.Instance.getETag(url);
            }
            return data;
        });
    }
    saveETag(url, eTag) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield MemoryStorage.Instance.saveETag(url, eTag);
        });
    }
    getLastUpdateFromCollection(href) {
        return __awaiter(this, void 0, void 0, function* () {
            let lastUpdate = yield MemoryStorage.Instance.getLastUpdateFromCollection(href);
            if (lastUpdate === null || typeof lastUpdate === "undefined") {
                lastUpdate = yield PersistentStorage.Instance.getLastUpdateFromCollection(href);
            }
            return lastUpdate;
        });
    }
}
