import React, { useState } from 'react';
import {
  Typography,
  Dialog,
  DialogTitle,
  Divider,
  Button,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  IconButton,
  List,
  ListItemText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useStylesBase } from './BaseDailogStyles';

export interface InitPasswordDialogProps {
  open: boolean;
  initPassword: string;
  onSave: (password: string) => void;
}

const PASSWORD_MIN_LENGTH = 8;

const InitPasswordDialog = (props: InitPasswordDialogProps) => {
  const { open, initPassword, onSave } = props;

  const { user } = useSelector((state: AppState) => state.auth);

  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const classes = useStylesBase();
  const { t } = useTranslation();

  const passwordDifferent = (): any | null => {
    let error = null;
    if (password === initPassword) {
      error = { 'old-and-new-passwords-cannot-be-identical': true };
    }

    return error;
  };

  const minLength = (): any | null => {
    let error = null;
    if (!password || password.length < PASSWORD_MIN_LENGTH) {
      error = { 'password-too-short': true };
    }

    return error;
  };

  const nameInPassword = (): any | null => {
    let error = null;
    if (user != null) {
      const firstName = user.firstName?.toLowerCase();
      const lastName = user.lastName?.toLowerCase();
      const lowerCasePassword = password.toLowerCase();
      if (lowerCasePassword.includes(firstName) || lowerCasePassword.includes(lastName)) {
        error = { 'name-in-password': true };
      }
    }
    return error;
  };

  const smallCapitalLetterNumber = (): any | null => {
    let error = null;
    const regex = new RegExp('(?=.*[0-9])(?=.*[a-zöäü])(?=.*[A-ZÄÜÖ]).{3,}');
    if (!regex.test(password)) {
      error = { 'no-capital-or-small-letter': true };
    }

    return error;
  };

  const validate = (): any | null => {
    const validators = [passwordDifferent, minLength, nameInPassword, smallCapitalLetterNumber];

    const validationErrors = validators.map(validator => validator()).filter(error => !!error);

    return validationErrors.length === 0 ? null : validationErrors.reduce((acc, error) => ({ ...acc, ...error }), {});
  };

  const errors = validate();

  return (
    <Dialog maxWidth="xs" open={open}>
      <DialogTitle className={classes.dialogContent}>
        <Typography component="span" variant="h5">
          <VpnKeyIcon className={classes.dialogIcon} />
          {t('login.init-password.title')}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <Typography component="p" variant="body2">
          {t('login.init-password.description', { name: user?.firstName })}
        </Typography>
        <List>
          <ListItemText>
            <Typography component="p" variant="caption">
              {t('login.init-password.guideline1')}
            </Typography>
          </ListItemText>
          <ListItemText>
            <Typography component="p" variant="caption">
              {t('login.init-password.guideline2')}
            </Typography>
          </ListItemText>
          <ListItemText>
            <Typography component="p" variant="caption">
              {t('login.init-password.guideline3')}
            </Typography>
          </ListItemText>
        </List>
        <Divider className={classes.dialogDivider} />
        <TextField
          name="email"
          label={t('login.init-password.new-password')}
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={event => setPassword(event.target.value)}
          variant="outlined"
          margin="normal"
          fullWidth
          error={!!errors}
          helperText={!!errors && t(`errors.${Object.keys(errors)[0]}`)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        ></TextField>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.dialogSubmit}
          disabled={Boolean(password) === false || !!errors}
          onClick={() => onSave(password)}
        >
          {t('login.init-password.activate')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InitPasswordDialog;
