import React from 'react';
import { makeStyles, ListItem, Theme, Grid } from '@material-ui/core';
import LearnContent from '../../sdk/com/apiomat/frontend/mylearningplatform/LearnContent';
import LearnContentAvatar from './LearnContentAvatar';
import LearnContentTitle from './LearnContentTitle';
import LearnContentInfo from './LearnContentInfo';
import AppLogoIcon from '../../assets/img/app_logo_icon.svg';
import { LearnContentTypes } from '../../enums/LearnContentTypes';
import { getFileLink } from '../../utils/file.utils';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    padding: theme.spacing(2),
  },
}));

export interface LearnContentListItemProps {
  item: LearnContent;
  divider?: boolean;
  onItemClick: () => void;
}

export default (props: LearnContentListItemProps) => {
  const classes = useStyles();

  const { item, divider, onItemClick } = props;

  return (
    <ListItem divider={divider} button key={item.ID} className={classes.item} onClick={onItemClick}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item container wrap="nowrap" xs={8}>
          <Grid item>
            <LearnContentAvatar
              isArchived={item.isArchived === 1}
              thumbnailURL={item?.thumbnailURL ? getFileLink(item, 'thumbnail') : AppLogoIcon}
              defaultThumbnailUrl={AppLogoIcon}
              learnContentType={item.learnContentType as LearnContentTypes}
              dueDate={item.dueDate}
            />
          </Grid>
          <Grid item zeroMinWidth>
            <LearnContentTitle item={item} />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <LearnContentInfo item={item} onItemClick={onItemClick} />
        </Grid>
      </Grid>
    </ListItem>
  );
};
