var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import AOMRequestError from "./../AOMRequestError";
import Datastore from "./../Datastore";
import AOMStatus from "./../Status";
import AbstractClientDataModel from "./../AbstractClientDataModel";
export default class LPUserFilter extends AbstractClientDataModel {
    static getLPUserFilters(query, withReferencedHrefs = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadListFromServerWithClass(LPUserFilter, query, withReferencedHrefs, usePersistentStorage);
        });
    }
    static getLPUserFiltersCount(query, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadCountFromServer(LPUserFilter, undefined, query, usePersistentStorage);
        });
    }
    static staticGetModuleName() {
        return "MyLearningPlatform";
    }
    static staticGetModelName() {
        return "LPUserFilter";
    }
    get customMetaForClass() {
        return "";
    }
    static removeAllFromStorage() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorageByClass(LPUserFilter);
        });
    }
    static deleteAllFromStorage(query) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorage(query, LPUserFilter);
        });
    }
    static getLPUserFiltersFromPersistentStorage(whereWithPlaceholders = "", whereValues = [], orderBy = "", limit, offset) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadFromPersistentStorage(LPUserFilter, whereWithPlaceholders, whereValues, orderBy, limit, offset);
        });
    }
    static getLPUserFiltersCountFromPersistentStorage(whereWithPlaceholders = "", whereValues = []) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.getCountFromPersistentStorage(LPUserFilter, whereWithPlaceholders, whereValues);
        });
    }
    constructor() {
        super();
        this.dao = new LPUserFilterDAO();
        this.hashmap.users = [];
    }
    get modelName() { return "LPUserFilter"; }
    get moduleName() { return "MyLearningPlatform"; }
    get users() {
        if (this.hashmap.users.length === 0) {
            const data = this.getDataOrKeyFromParent();
            if (typeof data !== "undefined" && this.dao.users instanceof Array && this.dao.users.length > 0) {
                const objects = [];
                this.dao.users.forEach((element) => {
                    const el = new LPUser().fromJson(element);
                    el.parent = this;
                    el.parentAttrName = "users";
                    el.parentAttrKey = objects.push(el) - 1;
                });
                this.hashmap.users = objects;
            }
        }
        return this.hashmap.users;
    }
    loadUsers(withRefHref = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.load(undefined, usePersistentStorage);
                this.hashmap.users = [];
                return this.users;
            }
            catch (error) {
                if (error.statusCode === 204 || error.statusCode === AOMStatus.ID_NOT_FOUND_OFFLINE
                    || error.statusCode === AOMStatus.ID_NOT_FOUND) {
                    this.hashmap.users = undefined;
                }
                return Promise.reject(error);
            }
        });
    }
    loadUsersAndRefHref(usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.loadUsers(true, usePersistentStorage);
        });
    }
    postUsers(_embData, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (_embData instanceof LPUser === false) {
                return Promise.reject(new AOMRequestError(AOMStatus.WRONG_REF_TYPE, 201));
            }
            if (typeof _embData.href !== "undefined" || _embData.parent !== null) {
                return Promise.reject(new AOMRequestError(AOMStatus.EMBEDDED_POST_EXISTING_OBJECT, 201));
            }
            if (typeof this.dao.users === "undefined" || this.dao.users === null) {
                this.dao.users = [];
            }
            if (typeof _embData.ID === "undefined" || _embData.ID === null) {
                _embData["dao"].embeddedCollectionId = Date.now().toString() + (Math.floor(Math.random() * 9000) + 1000).toString();
            }
            const parentAttrKey = this.dao.users.push(_embData.toJson()) - 1;
            this.hashmap.users = [];
            _embData.parentAttrKey = parentAttrKey;
            _embData.parentAttrName = "users";
            _embData.parent = this;
            const ret = yield this.setDataToParent(_embData, usePersistentStorage);
            const data = _embData.getDataOrKeyFromParent();
            this.dao.users[parentAttrKey] = data;
            _embData = _embData.fromJson(data);
            return ret;
        });
    }
    removeUsers(_embData, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (_embData instanceof LPUser === false) {
                return Promise.reject(new AOMRequestError(AOMStatus.WRONG_REF_TYPE, 204));
            }
            if (_embData.parent === this) {
                return yield _embData.delete(usePersistentStorage);
            }
            else if (_embData.parent !== null && _embData.parent.ID === this.ID) {
                const ret = yield _embData.delete(usePersistentStorage);
                yield this.load(undefined, usePersistentStorage);
                return ret;
            }
            else {
                return Promise.reject(new AOMRequestError(AOMStatus.NOT_EMBEDDED_CHILD, 204));
            }
        });
    }
    getUsersCount(usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.load(undefined, usePersistentStorage);
            return this.users.length;
        });
    }
}
import LPUserFilterDAO from "./LPUserFilterDAO";
import LPUser from "./../mylearningplatform/LPUser";
