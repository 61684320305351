import React from 'react';
import { makeStyles, Typography, ListItem, List, ListItemAvatar, Avatar, ListItemText, Theme } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  dialogLanguage: {
    marginTop: theme.spacing(1),
    borderRadius: 10,
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: 'solid',
  },
  dialogLanguageImage: {
    width: 24,
    height: 24,
    borderRadius: '50%',
  },
}));

export interface LanguageSelectorListProps {
  languages: string[];
  value: string;
  onSelect: (selectedLanguage: string) => void;
}

const LanguageSelectorList = (props: LanguageSelectorListProps) => {
  const { languages, value, onSelect } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <List>
      {languages.map(el => (
        <ListItem key={el} button className={classes.dialogLanguage} selected={value === el} onClick={() => onSelect(el)}>
          <ListItemAvatar>
            <Avatar className={classes.dialogLanguageImage} src={require(`../../assets/img/langs/${el}.svg`)} />
          </ListItemAvatar>
          <ListItemText>
            <Typography color={value === el ? 'primary' : 'initial'} variant="subtitle2">
              {t(`languages.${el}`)}
            </Typography>
          </ListItemText>
          {value === el && <CheckCircleIcon color="primary" />}
        </ListItem>
      ))}
    </List>
  );
};

export default LanguageSelectorList;
