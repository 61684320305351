import React from 'react';
import { makeStyles, Typography, Theme, Button, Grid, LinearProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LearnProgress from '../../sdk/com/apiomat/frontend/mylearningplatform/LearnProgress';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme: Theme) => ({
  progress: {
    height: 10,
    borderRadius: 10,
  },
  bar: {
    borderRadius: 10,
  },
  lives: {
    fontSize: 18,
    color: theme.palette.primary.main,
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
  livesIcon: {
    marginRight: theme.spacing(1),
    marginTop: 2,
  },
  showTrainingDocumentButton: {
    marginTop: theme.spacing(1),
  },
}));

export interface TrainingQuestionHeaderProps {
  currentTraining: LearnProgress;
  currentLives: number;
  progress: number;
  setReadingMode: (readingMode: boolean) => void;
}

const TrainingQuestionHeader = (props: TrainingQuestionHeaderProps) => {
  const { currentTraining, setReadingMode, currentLives, progress } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid container direction="row" justify="space-between" alignItems="center" spacing={3}>
        <Grid item xs>
          <LinearProgress variant="determinate" value={progress} className={classes.progress} classes={{ bar: classes.bar }} />
        </Grid>
        {Boolean(currentTraining.successDate) === false && (
          <Grid item zeroMinWidth>
            <Typography component="span" variant="subtitle2" className={classes.lives}>
              <FavoriteIcon className={classes.livesIcon} /> {currentLives}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Button
        color="primary"
        className={classes.showTrainingDocumentButton}
        startIcon={<ArrowForwardIcon />}
        onClick={() => setReadingMode(true)}
      >
        {t('training.show-training-documents')}
      </Button>
    </>
  );
};

export default TrainingQuestionHeader;
