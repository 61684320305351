var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import AOMRequestError from "./../AOMRequestError";
import Datastore from "./../Datastore";
import AOMStatus from "./../Status";
import AbstractClientDataModel from "./../AbstractClientDataModel";
export default class LPSupervisorView extends AbstractClientDataModel {
    static getLPSupervisorViews(query, withReferencedHrefs = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadListFromServerWithClass(LPSupervisorView, query, withReferencedHrefs, usePersistentStorage);
        });
    }
    static getLPSupervisorViewsCount(query, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadCountFromServer(LPSupervisorView, undefined, query, usePersistentStorage);
        });
    }
    static staticGetModuleName() {
        return "MyLearningPlatform";
    }
    static staticGetModelName() {
        return "LPSupervisorView";
    }
    get customMetaForClass() {
        return "";
    }
    static removeAllFromStorage() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorageByClass(LPSupervisorView);
        });
    }
    static deleteAllFromStorage(query) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.deleteAllFromStorage(query, LPSupervisorView);
        });
    }
    static getLPSupervisorViewsFromPersistentStorage(whereWithPlaceholders = "", whereValues = [], orderBy = "", limit, offset) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadFromPersistentStorage(LPSupervisorView, whereWithPlaceholders, whereValues, orderBy, limit, offset);
        });
    }
    static getLPSupervisorViewsCountFromPersistentStorage(whereWithPlaceholders = "", whereValues = []) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.getCountFromPersistentStorage(LPSupervisorView, whereWithPlaceholders, whereValues);
        });
    }
    constructor() {
        super();
        this.dao = new LPSupervisorViewDAO();
        this.hashmap.staffMember = [];
        this.hashmap.supervisor = [];
    }
    get modelName() { return "LPSupervisorView"; }
    get moduleName() { return "MyLearningPlatform"; }
    get staffMember() {
        return this.hashmap.staffMember;
    }
    loadStaffMember(query = "", withRefHref = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            let refUrl = this.dao.staffMemberHref;
            if ((typeof refUrl === "undefined" || refUrl === null) && this.offline === true) {
                refUrl = this.href + "/staffMember";
            }
            if (typeof refUrl === "undefined" || refUrl === null) {
                return Promise.reject(new AOMRequestError(AOMStatus.ATTACHED_HREF_MISSING, 200));
            }
            try {
                this.hashmap.staffMember = yield Datastore.Instance.loadFromServer(refUrl, undefined, withRefHref, usePersistentStorage, LPSupervisorView, query, true);
                return this.hashmap.staffMember;
            }
            catch (error) {
                if (error.statusCode === 204 || error.statusCode === AOMStatus.ID_NOT_FOUND_OFFLINE
                    || error.statusCode === AOMStatus.ID_NOT_FOUND) {
                    this.hashmap.staffMember = undefined;
                }
                return Promise.reject(error);
            }
        });
    }
    loadStaffMemberAndRefHref(query = "", usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.loadStaffMember(query, true, usePersistentStorage);
        });
    }
    getStaffMemberCountFromPersistentStorage(whereWithPlaceholders = "", whereValues = []) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.getCollectionCountFromPersistentStorage(LPSupervisorView, this.href + "/StaffMember", whereWithPlaceholders, whereValues);
        });
    }
    postStaffMember(_refData, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.parent !== null) {
                return Promise.reject(new AOMRequestError(AOMStatus.EMBEDDED_REF, 201));
            }
            if (typeof _refData.href === "undefined") {
                return Promise.reject(new AOMRequestError(AOMStatus.SAVE_REFERENECE_BEFORE_REFERENCING, 201));
            }
            try {
                let href = this.href + "/staffMember";
                if (typeof this.dao.staffMemberHref !== "undefined"
                    && this.dao.staffMemberHref !== null) {
                    href = this.dao.staffMemberHref;
                }
                const { returnedHref: refHref, offline } = yield Datastore.Instance.postOnServer(_refData, href, true, usePersistentStorage, true, "staffMember");
                if (offline === true) {
                    this.dao.staffMemberHref = href;
                    yield this.save(false, usePersistentStorage);
                }
                if (refHref) {
                    if (this.hashmap.staffMember.filter((_elem) => _elem.href && refHref && _elem.href === refHref).length < 1) {
                        this.hashmap.staffMember.push(_refData);
                    }
                    return refHref;
                }
            }
            catch (error) {
                return Promise.reject(error);
            }
        });
    }
    removeStaffMember(_refData, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (_refData === null || typeof _refData === "undefined" || typeof _refData.href === "undefined") {
                return Promise.reject(new AOMRequestError(AOMStatus.HREF_NOT_FOUND, 204));
            }
            const id = _refData.href.substring(_refData.href.lastIndexOf("/") + 1);
            const deleteHref = this.dao.staffMemberHref + "/" + id;
            try {
                const response = yield Datastore.Instance.deleteOnServer(deleteHref, _refData, true, usePersistentStorage);
                if (typeof this.hashmap.staffMember !== "undefined" && typeof this.hashmap.staffMember.length !== "undefined"
                    && this.hashmap.staffMember.length > 0) {
                    const i = this.hashmap.staffMember.findIndex((el) => el.href === _refData.href);
                    if (i !== -1) {
                        this.hashmap.staffMember.splice(i, 1);
                    }
                }
                return response;
            }
            catch (error) {
                return Promise.reject(error);
            }
        });
    }
    getStaffMemberCount(_query = "", usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadCountFromServer(this.href, "staffMember", _query, usePersistentStorage);
        });
    }
    get supervisor() {
        return this.hashmap.supervisor;
    }
    loadSupervisor(query = "", withRefHref = false, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            let refUrl = this.dao.supervisorHref;
            if ((typeof refUrl === "undefined" || refUrl === null) && this.offline === true) {
                refUrl = this.href + "/supervisor";
            }
            if (typeof refUrl === "undefined" || refUrl === null) {
                return Promise.reject(new AOMRequestError(AOMStatus.ATTACHED_HREF_MISSING, 200));
            }
            try {
                this.hashmap.supervisor = yield Datastore.Instance.loadFromServer(refUrl, undefined, withRefHref, usePersistentStorage, LPSupervisorView, query, true);
                return this.hashmap.supervisor;
            }
            catch (error) {
                if (error.statusCode === 204 || error.statusCode === AOMStatus.ID_NOT_FOUND_OFFLINE
                    || error.statusCode === AOMStatus.ID_NOT_FOUND) {
                    this.hashmap.supervisor = undefined;
                }
                return Promise.reject(error);
            }
        });
    }
    loadSupervisorAndRefHref(query = "", usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.loadSupervisor(query, true, usePersistentStorage);
        });
    }
    getSupervisorCountFromPersistentStorage(whereWithPlaceholders = "", whereValues = []) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.getCollectionCountFromPersistentStorage(LPSupervisorView, this.href + "/Supervisor", whereWithPlaceholders, whereValues);
        });
    }
    postSupervisor(_refData, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.parent !== null) {
                return Promise.reject(new AOMRequestError(AOMStatus.EMBEDDED_REF, 201));
            }
            if (typeof _refData.href === "undefined") {
                return Promise.reject(new AOMRequestError(AOMStatus.SAVE_REFERENECE_BEFORE_REFERENCING, 201));
            }
            try {
                let href = this.href + "/supervisor";
                if (typeof this.dao.supervisorHref !== "undefined"
                    && this.dao.supervisorHref !== null) {
                    href = this.dao.supervisorHref;
                }
                const { returnedHref: refHref, offline } = yield Datastore.Instance.postOnServer(_refData, href, true, usePersistentStorage, true, "supervisor");
                if (offline === true) {
                    this.dao.supervisorHref = href;
                    yield this.save(false, usePersistentStorage);
                }
                if (refHref) {
                    if (this.hashmap.supervisor.filter((_elem) => _elem.href && refHref && _elem.href === refHref).length < 1) {
                        this.hashmap.supervisor.push(_refData);
                    }
                    return refHref;
                }
            }
            catch (error) {
                return Promise.reject(error);
            }
        });
    }
    removeSupervisor(_refData, usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (_refData === null || typeof _refData === "undefined" || typeof _refData.href === "undefined") {
                return Promise.reject(new AOMRequestError(AOMStatus.HREF_NOT_FOUND, 204));
            }
            const id = _refData.href.substring(_refData.href.lastIndexOf("/") + 1);
            const deleteHref = this.dao.supervisorHref + "/" + id;
            try {
                const response = yield Datastore.Instance.deleteOnServer(deleteHref, _refData, true, usePersistentStorage);
                if (typeof this.hashmap.supervisor !== "undefined" && typeof this.hashmap.supervisor.length !== "undefined"
                    && this.hashmap.supervisor.length > 0) {
                    const i = this.hashmap.supervisor.findIndex((el) => el.href === _refData.href);
                    if (i !== -1) {
                        this.hashmap.supervisor.splice(i, 1);
                    }
                }
                return response;
            }
            catch (error) {
                return Promise.reject(error);
            }
        });
    }
    getSupervisorCount(_query = "", usePersistentStorage) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Datastore.Instance.loadCountFromServer(this.href, "supervisor", _query, usePersistentStorage);
        });
    }
}
import LPSupervisorViewDAO from "./LPSupervisorViewDAO";
