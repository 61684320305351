import React, { useState, useEffect } from 'react';
import {
  Typography,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Grid,
  Button,
  FormControlLabel,
  DialogActions,
  SvgIconTypeMap,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStylesBase } from './BaseDailogStyles';
import { IOSSwitch } from '../Switch/IOSSwitch';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

export interface ApproveBaseDialogProps {
  open: boolean;
  title: string;
  descriptions: string[];
  toggleTitle?: string;
  buttonTitle: string;
  Icon?: OverridableComponent<SvgIconTypeMap>;
  onClose: () => void;
  onButtonClick: () => void;
}

const ApproveBaseDialog = (props: ApproveBaseDialogProps) => {
  const { open, title, descriptions, toggleTitle, buttonTitle, Icon, onClose, onButtonClick } = props;
  const classesBase = useStylesBase();
  const { t } = useTranslation();

  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    setIsChecked(false);
  }, [open]);

  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle className={classesBase.dialogContent}>
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={3}>
          <Grid item xs>
            <Typography component="span" variant="h5">
              {Icon && <Icon className={classesBase.dialogIcon} />}
              {t(title)}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent className={classesBase.dialogContent}>
        <Grid container direction="column" spacing={1}>
          {descriptions.map(desc => (
            <Grid item key={desc}>
              <Typography component="p" variant="body2">
                {t(desc)}
              </Typography>
            </Grid>
          ))}
        </Grid>
        {Boolean(toggleTitle) && (
          <>
            <Divider className={classesBase.dialogDivider} />
            <FormControlLabel
              className={classesBase.dialogSwitchControl}
              control={<IOSSwitch checked={isChecked} onChange={() => setIsChecked(!isChecked)} />}
              label={
                <Typography component="label" variant="body2">
                  {t(toggleTitle)}
                </Typography>
              }
              labelPlacement="start"
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classesBase.dialogSubmit}
          disabled={Boolean(toggleTitle) ? isChecked === false : false}
          onClick={onButtonClick}
        >
          {t(buttonTitle)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveBaseDialog;
