import React from 'react';
import { Container, makeStyles, ContainerProps } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(8),
      marginTop: theme.spacing(2),
    },
  },
}));

const BasicContainer = (props: ContainerProps) => {
  const classes = useStyles();

  return (
    <Container maxWidth="md" className={classes.container} {...props}>
      {props.children}
    </Container>
  );
};

export default BasicContainer;
