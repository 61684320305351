import React from 'react';
import { Paper, List } from '@material-ui/core';
import LearnContent from '../../sdk/com/apiomat/frontend/mylearningplatform/LearnContent';
import LearnContentListItem from './LearnContentListItem';

export interface LearnContentListProps {
  items: LearnContent[];
  onItemClick: (item: LearnContent) => void;
}

export default (props: LearnContentListProps) => {
  const { items, onItemClick } = props;

  return (
    <Paper elevation={0}>
      <List disablePadding>
        {items.map((item, i) => (
          <LearnContentListItem key={i} item={item} divider={i !== items.length - 1} onItemClick={() => onItemClick(item)} />
        ))}
      </List>
    </Paper>
  );
};
