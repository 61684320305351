var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, PrimaryColumn, Column } from "../OfflineAdapter";
let TaskInfoDAO = class TaskInfoDAO {
    constructor(taskInfoData = {}) {
        this.fileKey = taskInfoData.fileKey;
        this.httpMethod = taskInfoData.httpMethod;
        this.url = taskInfoData.url;
        this.type = taskInfoData.type;
        this.localId = taskInfoData.localId;
        this.refName = taskInfoData.refName;
        this.isImage = taskInfoData.isImage;
        this.usePersistentStorage = taskInfoData.usePersistentStorage;
        this.isRef = taskInfoData.isRef;
        this.isRefCollection = taskInfoData.isRefCollection;
        this.timestamp = new Date().getTime();
    }
};
__decorate([
    PrimaryColumn(),
    __metadata("design:type", String)
], TaskInfoDAO.prototype, "fileKey", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], TaskInfoDAO.prototype, "httpMethod", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Number)
], TaskInfoDAO.prototype, "timestamp", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], TaskInfoDAO.prototype, "url", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], TaskInfoDAO.prototype, "type", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], TaskInfoDAO.prototype, "localId", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", String)
], TaskInfoDAO.prototype, "refName", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Boolean)
], TaskInfoDAO.prototype, "isImage", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Boolean)
], TaskInfoDAO.prototype, "usePersistentStorage", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Boolean)
], TaskInfoDAO.prototype, "isRef", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Boolean)
], TaskInfoDAO.prototype, "isRefCollection", void 0);
TaskInfoDAO = __decorate([
    Entity("TaskInfo"),
    __metadata("design:paramtypes", [Object])
], TaskInfoDAO);
export default TaskInfoDAO;
