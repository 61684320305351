import React from 'react';
import { Paper, List } from '@material-ui/core';
import LearnProgress from '../../sdk/com/apiomat/frontend/mylearningplatform/LearnProgress';
import LearnContent from '../../sdk/com/apiomat/frontend/mylearningplatform/LearnContent';
import TrainingListItem from './TrainingListItem';

import NoResultsIcon from '../../assets/img/certificate.png';
import { NoResultsListItem } from '../NoResults/NoResultsListItem';

export interface TrainingListProps {
  items: LearnProgress[];
  learnContentsMap: Map<string, LearnContent>;
  showNoResultsMessage?: boolean;
  onItemClick: (item: LearnProgress) => void;
  onDownload?: (item: LearnProgress) => void;
}

export default (props: TrainingListProps) => {
  const { items, learnContentsMap, onItemClick, onDownload, showNoResultsMessage = false } = props;

  return (
    <>
      <Paper elevation={0}>
        <List disablePadding>
          {items.map((item, i) => (
            <TrainingListItem
              key={item.ID}
              divider={i !== items.length - 1}
              item={item}
              learnContent={learnContentsMap.get(item.learnContentId)}
              onItemClick={() => onItemClick(item)}
              onDownload={() => onDownload(item)}
            />
          ))}
        </List>
      </Paper>
      {items.length === 0 && showNoResultsMessage ? (
        <NoResultsListItem 
          imageSrc={NoResultsIcon}
          title="certificates.no-results-title"
          description="certificates.no-results-description"
        />
      ) : null}
    </>
  );
};
