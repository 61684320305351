import React, { ChangeEvent } from 'react';
import { makeStyles, Theme, Grid, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TranslatedFile, FileData } from '../../../store/learnContents';
import PublishIcon from '@material-ui/icons/Publish';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme: Theme) => ({
  languageImage: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    margin: theme.spacing(0, 1),
  },
  inputButton: {
    padding: theme.spacing(2),
    borderRadius: 5,
    color: '#A8B3B3',
    borderStyle: 'dashed',
  },
  inputButtonSelected: {
    color: theme.palette.secondary.main,
    borderStyle: 'solid',
  },
  inputButtonIcon: {
    margin: theme.spacing(0, 1),
  },
}));

export interface TranslatedFileFormProps {
  langs: string[];
  fileTypesAccept: string;
  placeholderKey: string;
  translatedFile: TranslatedFile;
  setTranslatedFile: (translatedFile: TranslatedFile) => void;
}

const TranslatedFileForm = (props: TranslatedFileFormProps) => {
  const { langs, placeholderKey, fileTypesAccept, translatedFile, setTranslatedFile } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const reader = new FileReader();

  const onUploadFile = (lang: string, event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (event.target.files !== undefined) {
      reader.onloadend = () => {
        if (event.target.files !== undefined) {
          const file = {
            name: event.target.files[0].name,
            data: reader.result,
            imageUrl: URL.createObjectURL(event.target.files[0]),
          } as FileData;
          setTranslatedFile({ ...translatedFile, [lang]: file });
        }
      };
      if (event.target.files.length !== 0) {
        reader.readAsArrayBuffer(event.target.files[0]);
      }
    }
  };

  return (
    <Grid container direction="column" spacing={2}>
      {langs.map(lang => (
        <Grid item key={lang}>
          <input
            accept={fileTypesAccept}
            style={{ display: 'none' }}
            id={`button-file-${lang}`}
            type="file"
            onChange={event => onUploadFile(lang, event)}
          />
          <label htmlFor={`button-file-${lang}`}>
            <Button
              variant="outlined"
              component="span"
              fullWidth
              className={clsx(classes.inputButton, Boolean(translatedFile[lang]) && classes.inputButtonSelected)}
              startIcon={
                Boolean(translatedFile[lang]) ? (
                  <CheckCircleIcon color="primary" className={classes.inputButtonIcon} />
                ) : (
                  <PublishIcon color="secondary" className={classes.inputButtonIcon} />
                )
              }
              endIcon={
                <img
                  className={classes.languageImage}
                  src={require(`../../../assets/img/langs/${lang}.svg`)}
                  alt={t(`languages.${lang}`)}
                />
              }
            >
              <Grid container>
                <Typography>{Boolean(translatedFile[lang]) ? translatedFile[lang].name : t(placeholderKey, { lng: lang })}</Typography>
              </Grid>
            </Button>
          </label>
        </Grid>
      ))}
    </Grid>
  );
};

export default TranslatedFileForm;
