import React from 'react';
import {
  makeStyles,
  Theme,
  Typography,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  LinearProgress,
  Hidden,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import { LearnContentData, TranslatedString } from '../../store/learnContents';
import LanguageIcon from '@material-ui/icons/Language';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { LearnContentTypes } from '../../enums/LearnContentTypes';
import FavoriteIcon from '@material-ui/icons/Favorite';
import TrainingLabel from '../Dashboard/TrainingLabel';
import LearnContentAvatar from './LearnContentAvatar';
import { DiamondIcon } from '../Icons/Icons';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginBottom: theme.spacing(1),
  },
  headerTitle: {
    marginTop: theme.spacing(1),
  },
  tableTitle: {
    padding: theme.spacing(1),
  },
  iconButton: {
    backgroundColor: theme.palette.common.white,
  },
  primaryTitle: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(-1),
    },
  },
  text: {
    wordBreak: 'break-word',
  },
  progress: {
    height: 10,
    borderRadius: 10,
    margin: theme.spacing(4, 0, 8),
  },
  bar: {
    borderRadius: 10,
  },
  smallIcon: {
    color: '#A8B3B3',
    fontSize: theme.typography.body2.fontSize,
    display: 'block',
    marginTop: 3,
    marginRight: theme.spacing(1),
  },
  titleBadge: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.success.main,
    borderRadius: 5,
    marginLeft: theme.spacing(2),
    padding: '2px 10px',
    minWidth: 100,
  },
}));

export interface LearnContentCreationHeaderProps {
  learnContent: LearnContentData;
  stepHeader: string;
  step: number;
  stepsNumber: number;
}

export default (props: LearnContentCreationHeaderProps) => {
  const { learnContent, stepHeader, step, stepsNumber } = props;

  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const getLangValue = (translatedString: TranslatedString = {}): string => {
    if (Boolean(translatedString[i18n.language])) {
      return translatedString[i18n.language];
    }
    if (Boolean(translatedString[learnContent.langs[0]])) {
      return translatedString[learnContent.langs[0]];
    }
    return Object.values(translatedString).filter(el => Boolean(el))[0];
  };

  return (
    <>
      <Grid container justify="space-between" alignItems="center" spacing={3} className={classes.header}>
        <Grid item xs>
          <Typography component="span" variant="subtitle2" color="primary">
            {t('admin.create-learn-content.title')}
          </Typography>
          <Typography component="h1" variant="h5" className={classes.headerTitle}>
            {step + 1}. {t(`admin.create-learn-content.${stepHeader}.title`)}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton aria-label="logout" className={classes.iconButton} onClick={() => history.push('/admin/learn-content-management')}>
            <CloseIcon color="primary" />
          </IconButton>
        </Grid>
      </Grid>
      <Typography variant="body2" className={classes.tableTitle}>
        {t('admin.create-learn-content.preview')}
      </Typography>
      <Paper elevation={0}>
        <List>
          <ListItem>
            <Grid container spacing={2}>
              <Grid item container wrap="nowrap" xs={12} md={7}>
                <Grid item>
                  <LearnContentAvatar
                    thumbnailURL={learnContent.thumbnail?.imageUrl}
                    dueDate={learnContent.dueDate?.toDate()}
                    learnContentType={learnContent.learnContentType}
                  />
                </Grid>
                <Grid item zeroMinWidth>
                  <ListItemText
                    primary={
                      <div className={classes.primaryTitle}>
                        <Typography component="span" variant="subtitle2" color="primary" className={classes.text}>
                          {getLangValue(learnContent.header) || t('admin.create-learn-content.preview-header')}
                        </Typography>
                        <Hidden smDown>
                          <TrainingLabel dueDate={learnContent.dueDate?.toDate()} />
                        </Hidden>
                      </div>
                    }
                    secondary={
                      <Typography variant="subtitle2" className={classes.text}>
                        {getLangValue(learnContent.description) || t('admin.create-learn-content.preview-description')}
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={5}>
                <Grid container spacing={isSmall ? 1 : 2} wrap="nowrap">
                  <Grid item xs={5}>
                    {learnContent.learnContentType === LearnContentTypes.pdf && (
                      <Grid container direction="column" spacing={1}>
                        {Boolean(learnContent.questions?.length) && (
                          <Grid item container alignItems="flex-start" wrap="nowrap">
                            <CheckCircleIcon className={classes.smallIcon} />
                            <Typography variant="body2" component="span" noWrap>
                              {t('admin.questions', { count: learnContent.questions?.length })}
                            </Typography>
                          </Grid>
                        )}
                        {Boolean(learnContent.lives) && (
                          <Grid item container alignItems="flex-start" wrap="nowrap">
                            <FavoriteIcon className={classes.smallIcon} />
                            <Typography variant="body2" component="span" noWrap>
                              {t('admin.lives', { count: learnContent.lives })}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    {learnContent.langs?.length > 0 && (
                      <Grid item container alignItems="flex-start" wrap="nowrap">
                        <LanguageIcon className={classes.smallIcon} />
                        <Typography variant="body2" component="span">
                          {learnContent.langs.join(', ').toUpperCase()}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    {Boolean(learnContent.lp) && (
                      <Grid item container alignItems="flex-start" wrap="nowrap">
                        <DiamondIcon className={classes.smallIcon} />
                        <Typography variant="body2" component="span">
                          {learnContent.lp} LP
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Paper>
      <LinearProgress
        variant="determinate"
        value={((step + 1) / stepsNumber) * 100}
        className={classes.progress}
        classes={{ bar: classes.bar }}
      />
    </>
  );
};
