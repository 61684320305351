import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  ListItem,
  Theme,
  Typography,
  Grid,
  List,
  Button,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LPUser from '../../sdk/com/apiomat/frontend/mylearningplatform/LPUser';
import GroupIcon from '@material-ui/icons/Group';
import CloseIcon from '@material-ui/icons/Close';
import TrainingLabel from '../Dashboard/TrainingLabel';
import { IconButton, Hidden } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LPTeam from '../../sdk/com/apiomat/frontend/mylearningplatform/LPTeam';
import { getUserName, getLearnProgress, userCompletedLearnContent, isUserAssigned } from '../../utils/users.utils';
import { useDispatch } from 'react-redux';
import { notificationActions } from '../../store/notification';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import CheckIcon from '@material-ui/icons/Check';
import { getAssignedUsersCountForLearnContent } from '../../utils/assignment.utils';

const ROWS_PER_PAGE = 10;

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    padding: theme.spacing(2),
  },
  icon: {
    fontSize: theme.typography.body2.fontSize,
    marginRight: theme.spacing(1),
  },
  secondList: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
  },
  completedMessage: {
    cursor: 'auto',
    color: theme.palette.success.main,
  },
}));

export interface TeamAssignmentListItemProps {
  learnContentId: string;
  item: LPTeam;
  divider: boolean;
  defaultDueDate: Date;
  onTeamCancel: () => void;
  onUserCancel: (user: LPUser) => void;
  onEditUserDueDate: (user: LPUser, originalDate?: Date) => void;
}

const TeamAssignmentListItem = (props: TeamAssignmentListItemProps) => {
  const { learnContentId, item, divider, onTeamCancel, onUserCancel, onEditUserDueDate } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [isActiveTeam, setIsActiveTeam] = useState<boolean>(false);
  const [users, setUsers] = useState<LPUser[]>([]);
  const [hasMoreUsers, setHasMoreUsers] = useState<boolean>(false);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);

  useEffect(() => {
    setIsActiveTeam(false);
    setHasMoreUsers(false);
    setLoadingUsers(false);
    setUsers([]);
  }, [item]);

  const onLoadUsers = async () => {
    setUsers([]);
    setLoadingUsers(true);
    try {
      const rows = await LPUser.getLPUsers(`teamIds=="${item.ID}" order by lastName limit ${ROWS_PER_PAGE}`);
      setUsers(rows);
      setHasMoreUsers(rows.length === ROWS_PER_PAGE);
    } catch (error) {
      dispatch(notificationActions.showError(error));
    } finally {
      setLoadingUsers(false);
    }
  };

  const onLoadMoreUser = async () => {
    setLoadingUsers(true);
    try {
      const rows = await LPUser.getLPUsers(`teamIds=="${item.ID}" order by lastName offset ${users.length} limit ${ROWS_PER_PAGE}`);
      setUsers([...users, ...rows]);
      setHasMoreUsers(rows.length === ROWS_PER_PAGE);
    } catch (error) {
      dispatch(notificationActions.showError(error));
    } finally {
      setLoadingUsers(false);
    }
  };

  useEffect(() => {
    if (isActiveTeam) {
      onLoadUsers();
    }
  }, [isActiveTeam]);

  const assignedUsersCount = getAssignedUsersCountForLearnContent(item, learnContentId);

  return (
    <>
      <ListItem divider={divider} className={classes.item}>
        <ListItemIcon>
          <IconButton onClick={() => setIsActiveTeam(!isActiveTeam)}>
            {isActiveTeam ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography component="span" variant="subtitle2">
              {item.name}
            </Typography>
          }
          secondary={
            <Grid container alignItems="center" component="span">
              <GroupIcon className={classes.icon} />
              <Typography variant="body2" component="span">
                {t('admin.assignment.assigned-employees', { count: item.usersCount || 0, assigneesCount: assignedUsersCount || 0 })}
              </Typography>
            </Grid>
          }
        />
        {assignedUsersCount !== null && (
          <ListItemSecondaryAction>
            <Hidden mdUp>
              <IconButton color="primary" onClick={onTeamCancel}>
                <CloseIcon />
              </IconButton>
            </Hidden>
            <Hidden smDown>
              <Button color="primary" startIcon={<CloseIcon />} onClick={onTeamCancel}>
                {t('admin.assignment.delete-assignment')}
              </Button>
            </Hidden>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      {isActiveTeam && (
        <div className={classes.secondList}>
          <List disablePadding>
            {users.map(user => (
              <ListItem key={user.ID} className={classes.item}>
                <ListItemText
                  primary={
                    <div>
                      <Typography component="span" variant="subtitle2">
                        {getUserName(user)}
                      </Typography>
                      {isUserAssigned(user, learnContentId) && !userCompletedLearnContent(user, learnContentId) ? (
                        <TrainingLabel
                          onClick={() => onEditUserDueDate(user, getLearnProgress(user, learnContentId)?.dueDate)}
                          dueDate={getLearnProgress(user, learnContentId)?.dueDate}
                          editable={true}
                          isSmall={isSmall}
                          background="white"
                        />
                      ) : null}
                    </div>
                  }
                />
                <ListItemSecondaryAction>
                  {isUserAssigned(user, learnContentId) && !userCompletedLearnContent(user, learnContentId) ? (
                    <>
                      <Hidden mdUp>
                        <IconButton color="primary" onClick={() => onUserCancel(user)}>
                          <CloseIcon />
                        </IconButton>
                      </Hidden>
                      <Hidden smDown>
                        <Button color="primary" startIcon={<CloseIcon />} onClick={() => onUserCancel(user)}>
                          {t('admin.assignment.delete-assignment')}
                        </Button>
                      </Hidden>
                    </>
                  ) : isUserAssigned(user, learnContentId) && userCompletedLearnContent(user, learnContentId) ? (
                    <>
                      <Hidden mdUp>
                        <IconButton className={classes.completedMessage} color="inherit">
                          <CheckIcon />
                        </IconButton>
                      </Hidden>
                      <Hidden smDown>
                        <Button className={classes.completedMessage} color="inherit" startIcon={<CheckIcon />}>
                          {t('admin.assignment.completed')}
                        </Button>
                      </Hidden>
                    </>
                  ) : null}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          {loadingUsers === false && hasMoreUsers && (
            <Grid container justify="center">
              <Button color="primary" variant="contained" onClick={onLoadMoreUser}>
                {t('admin.assignment.show-more-users')}
              </Button>
            </Grid>
          )}
          {loadingUsers && (
            <Grid container justify="center">
              <LoadingIndicator />
            </Grid>
          )}
        </div>
      )}
    </>
  );
};

export default TeamAssignmentListItem;
