import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/index';
import { authActions } from '../store/auth';
import { useTranslation } from 'react-i18next';
import AppLogo from '../assets/img/app_logo.svg';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useHistory } from 'react-router-dom';
import InitPasswordDialog from '../components/Dialogs/InitPasswordDialog';
import LanguageInitialSelector from '../components/Dialogs/LanguageInitialSelector';
import ForgotPasswordButton from '../components/ForgotPassword/ForgotPasswordButton';
import { useQuery } from '../utils/router.utils';
import { checkPasswordResetRequest } from '../utils/users.utils';
import { notificationActions } from '../store/notification';
import { maintenanceActions } from '../store/maintenance';
import Imprint from '../components/Imprint/Imprint';
import { LoginState } from '../enums/LoginState';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  appLogo: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(6)
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3)
    },
    width: 230
  },
  title: {
    padding: theme.spacing(3)
  },
  description: {
    marginBottom: theme.spacing(2)
  },
  card: {
    marginTop: theme.spacing(6),
    maxWidth: 350
  },
  form: {
    padding: theme.spacing(3)
  },
  submit: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    textTransform: 'none'
  },
  button: {
    padding: 0
  },
  languageImage: {
    width: 24,
    height: 24,
    borderRadius: '50%'
  }
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const query = useQuery();

  const { user, loading, redirectAfterAuth, missingInitialization, isAuthenticated } =
    useSelector((state: AppState) => state.auth);

  const [ currentLang, setCurrentLang ] = useState<string>(i18n.language);
  const [ showLangSelector, setShowLangSelector ] = useState<boolean>(false);
  const [ resetPasswordRequestPending, setResetPasswordRequestPending ] = useState<boolean>(false);

  const [ userName, setUserName ] = useState<string>('');
  const [ password, setPassword ] = useState<string>('');
  const [ showPassword, setShowPassword ] = useState<boolean>(false);

  const passwordResetToken = query.get('passwordResetToken');

  const resolveLoginState = (): LoginState => {
    if (loading === 'pending') {
      return LoginState.NONE;
    } else if (missingInitialization?.includes(LoginState.LANGUAGE) || showLangSelector) {
      return LoginState.LANGUAGE;
    } else if (missingInitialization?.includes(LoginState.PASSWORD)) {
      return LoginState.PASSWORD;
    } else if (isAuthenticated) {
      return LoginState.REDIRECT;
    } else {
      return LoginState.NONE;
    }
  };

  const currentState = resolveLoginState();

  const redirectToDashboard = () => {
    if (Boolean(redirectAfterAuth)) {
      history.push(redirectAfterAuth);
    } else {
      history.push('/');
    }
  };

  const updateUserLang = (lang: string) => {
    setShowLangSelector(false);
    setCurrentLang(lang);

    if (Boolean(user)) {
      user.defaultLanguage = lang;
      dispatch(authActions.updateUser(user));
    }
  };

  const onUpdatePassword = (newPassword: string) => {
    dispatch(authActions.updateUserPassword({ user, newPassword }));
  };

  const onLogin = event => {
    dispatch(authActions.loginWithCredentials({ userName, password }));
    event.preventDefault();
  };

  useEffect(() => {
    const handlePasswordResetToken = async (passwordResetToken: string) => {
      setResetPasswordRequestPending(true);
      try {
        await checkPasswordResetRequest(passwordResetToken);
        dispatch(notificationActions.showSuccessMessage(t('forgot-password.success')));
      } catch (error) {
        dispatch(notificationActions.showError(t('forgot-password.fail')));
      } finally {
        setResetPasswordRequestPending(false);
      }
    };
    if (passwordResetToken) {
      handlePasswordResetToken(passwordResetToken);
    }
  }, [ passwordResetToken ]);

  if (currentState === 'redirect') {
    redirectToDashboard();
  }

  return (
    <Container component="main" maxWidth="xs" className={ classes.container }>
      <img src={ AppLogo } className={ classes.appLogo } alt="App Logo"/>
      <Typography>{ t('login.app-slogan') }</Typography>
      <Paper elevation={ 0 } className={ classes.card }>
        <Grid container justify="space-between" alignItems="center" className={ classes.title }>
          <Grid item>
            <Typography component="h1" variant="h5">
              { t('login.login') }
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={ () => setShowLangSelector(true) } className={ classes.button }>
              <Avatar className={ classes.languageImage } src={ require(`../assets/img/langs/${ currentLang }.svg`) }/>
            </IconButton>
          </Grid>
        </Grid>
        <Divider/>
        <form className={ classes.form } onSubmit={ onLogin }>
          <TextField
            name="userName"
            label={ t('login.email') }
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={ e => setUserName(e.target.value) }
          ></TextField>
          <TextField
            name="password"
            type={ showPassword ? 'text' : 'password' }
            variant="outlined"
            label={ t('login.password') }
            margin="normal"
            fullWidth
            onChange={ e => setPassword(e.target.value) }
            InputProps={ {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={ () => setShowPassword(!showPassword) }>
                    { showPassword ? <Visibility/> : <VisibilityOff/> }
                  </IconButton>
                </InputAdornment>
              )
            } }
          ></TextField>
          <ForgotPasswordButton/>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            className={ classes.submit }
            disabled={ loading === 'pending' || resetPasswordRequestPending }
          >
            { t('login.sign-in') }
          </Button>
        </form>
      </Paper>
      <Imprint/>
      <LanguageInitialSelector open={ currentState === LoginState.LANGUAGE }
                               isInitLangSelection={ Boolean(user) }
                               onSave={ updateUserLang }
      />
      <InitPasswordDialog open={ currentState === LoginState.PASSWORD }
                          initPassword={ password }
                          onSave={ onUpdatePassword }
      />
    </Container>
  );
};
