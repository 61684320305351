import React from 'react';
import { Grid, IconButton, Typography, makeStyles, Theme, Hidden } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import LearnContent from '../../sdk/com/apiomat/frontend/mylearningplatform/LearnContent';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginBottom: theme.spacing(1),
  },
  headerTitle: {
    marginTop: theme.spacing(1),
  },
  iconButton: {
    backgroundColor: theme.palette.common.white,
  },
}));

export interface TrainingHeaderProps {
  learnContent: LearnContent;
  isReadingMode: boolean;
  isFullScreen: boolean;
  setIsFullScreen: (isFullScreen: boolean) => void;
  backRoute?: string;
}

const TrainingHeader = (props: TrainingHeaderProps) => {
  const { learnContent, isReadingMode, isFullScreen, setIsFullScreen } = props;

  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const close = () => {
    history.push(props.backRoute || '/')
  }

  return (
    <Grid container justify="space-between" alignItems="center" spacing={3} className={classes.header}>
      <Grid item>
        <Typography component="span" variant="h5" color="primary">
          {t(learnContent?.headlineKey)}
        </Typography>
        <Typography component="h1" variant="subtitle2" className={classes.headerTitle}>
          {t(learnContent?.descriptionKey)}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          {isReadingMode && (
            <Hidden smDown>
              <Grid item>
                <IconButton
                  color="primary"
                  aria-label="zoom-in"
                  className={classes.iconButton}
                  onClick={() => setIsFullScreen(!isFullScreen)}
                >
                  {isFullScreen ? <ZoomOutIcon /> : <ZoomInIcon />}
                </IconButton>
              </Grid>
            </Hidden>
          )}
          <Grid item>
            <IconButton aria-label="logout" className={classes.iconButton} onClick={close}>
              <CloseIcon color="primary" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TrainingHeader;
