import React, { useState, useEffect } from 'react';
import { useStylesBase } from './BaseDailogStyles';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, Typography, DialogActions, Button, CssBaseline, ThemeProvider, makeStyles, Theme } from '@material-ui/core';
import { theme } from '../../theme/theme';

const useStyles = makeStyles((theme: Theme) => ({
  dialogButton: {
    margin: theme.spacing(0, 2, 2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
}));

interface DialogPromptProps {
  message: string;
  callback: (ok: boolean) => void;
}

const DialogPrompt = (props: DialogPromptProps) => {
  const { message, callback } = props;
  const classes = useStyles();
  const classesBase = useStylesBase();
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(true);

  useEffect(() => {
    setOpen(true);
  }, [message, callback]);

  const onNoClick = () => {
    setOpen(false);
    callback(false);
  };

  const onYesClick = () => {
    setOpen(false);
    callback(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Dialog maxWidth="sm" open={open} onClose={onNoClick}>
        <DialogContent className={classesBase.dialogContent}>
          <Typography component="p" variant="body2">
            {t(message)}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" fullWidth className={classes.dialogButton} onClick={onNoClick}>
            {t('prompt-dialog.no')}
          </Button>
          <Button variant="contained" fullWidth className={classes.dialogButton} onClick={onYesClick}>
            {t('prompt-dialog.yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default DialogPrompt;
