import React, { useEffect } from 'react';
import { AppState } from '../store/index';
import AppRouter from '../routers/AppRouter';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../store/auth';
import { LinearProgress } from '@material-ui/core';
import { useState } from 'react';
import { maintenanceActions } from '../store/maintenance';
import { appConfigActions } from '../store/appConfig';

export default () => {
  const { token, loadingByToken } = useSelector((state: AppState) => state.auth);
  const dispatch = useDispatch();
  const [firstLoading, setFirstLoading] = useState<boolean>(true);
  const timeIntervalCheckMaintenanceInMs = 60000;

  useEffect(() => {
    dispatch(appConfigActions.loadAppConfig());
    if (token) {
      dispatch(authActions.loginWithToken());
    }
    setFirstLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let intervalCheckMaintenance;

    if (token) {
      dispatch(maintenanceActions.loadMaintenance());

      intervalCheckMaintenance = setInterval(() => {
        dispatch(maintenanceActions.loadMaintenance());
      }, timeIntervalCheckMaintenanceInMs);
    }

    return () => {
      if (intervalCheckMaintenance) {
        clearInterval(intervalCheckMaintenance);
      }
    }
  }, [dispatch, token])

  if (token && (firstLoading || loadingByToken === 'pending')) {
    return <LinearProgress color="primary" />;
  }

  return (
    <>
      <AppRouter></AppRouter>
    </>
  );
};
