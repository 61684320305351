import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, call, all, takeLatest, select } from 'redux-saga/effects';
import { notificationActions } from './notification';
import LPUser from '../sdk/com/apiomat/frontend/mylearningplatform/LPUser';
import LearnContent from '../sdk/com/apiomat/frontend/mylearningplatform/LearnContent';
import LPTeam from '../sdk/com/apiomat/frontend/mylearningplatform/LPTeam';
import {
  assignTeam,
  deassignTeam,
  deassignUser,
  bulkAssignUsers,
  assignPositions,
  deassignPosition,
  fetchAllPositions,
} from '../utils/assignment.utils';
import { authActions } from './auth';
import { AppState } from '.';
import { bulkLoadTeamsForUsers, getUsersCountForPosition, getAssignedUsersCountForLearnContent } from '../utils/users.utils';
import { ApiRequestState } from '../models/api-request-state';
import { DFHExceptionStatus } from '../enums/DFHExceptionStatus';
import i18n from '../utils/i18n';

const ROWS_PER_PAGE = 10;

/** STATE */
export interface AssignmentState {
  users: LPUser[];
  usersCache: LPUser[];
  usersCount: number;
  teams: LPTeam[];
  teamsCount: number;
  teamsCache: LPTeam[];
  /* stores all possible positions */
  allPositions: string[];
  /* holds the currently displayed positions with their respective counts */
  positions: DisplayPosition[];
  positionsCount: number;
  learnContents: LearnContent[];
  loadingAssignedUsers: ApiRequestState;
  loadingUsers: ApiRequestState;
  loadingUsersMore: ApiRequestState;
  loadingUsersCache: ApiRequestState;
  loadingLearnContents: ApiRequestState;
  loadingTeams: ApiRequestState;
  loadingTeamsCache: ApiRequestState;
  loadingTeamsMore: ApiRequestState;
  loadingPositions: ApiRequestState;
  loadingPositionsMore: ApiRequestState;
  loadingPost: ApiRequestState;
  userNameFilters: string[];
  teamFilters: string[];
  positionFilters: string[];
}

const initialState: AssignmentState = {
  users: [],
  usersCache: [],
  usersCount: 0,
  teams: [],
  teamsCache: [],
  teamsCount: 0,
  allPositions: [],
  positions: [],
  positionsCount: 0,
  learnContents: [],
  loadingAssignedUsers: 'idle',
  loadingUsers: 'idle',
  loadingUsersMore: 'idle',
  loadingUsersCache: 'idle',
  loadingTeams: 'idle',
  loadingTeamsCache: 'idle',
  loadingTeamsMore: 'idle',
  loadingPositions: 'idle',
  loadingPositionsMore: 'idle',
  loadingLearnContents: 'idle',
  loadingPost: 'idle',
  userNameFilters: [],
  teamFilters: [],
  positionFilters: [],
};

/** TYPES */
export interface AssignUsersObject {
  userIds: string[];
  learnContentId: string;
  dueDate?: Date;
  updateExisting?: boolean;
}

export interface CancelAssignUserObject {
  user: LPUser;
  learnContentId: string;
  dueDate?: Date;
}

export interface AssignTeamsObject {
  teamIds: string[];
  learnContentId: string;
  dueDate?: Date;
  updateExisting?: boolean;
}

export interface CancelAssignTeamObject {
  team: LPTeam;
  learnContentId: string;
  dueDate?: Date;
}

export interface AssignPositionsObject {
  positions: string[];
  learnContentId: string;
  dueDate?: Date;
  updateExisting?: boolean;
}

export interface CancelAssignPositionObject {
  position: string;
  learnContentId: string;
  dueDate?: Date;
}

export interface DisplayPosition {
  name: string; 
  totalCount: number; 
  assignedCount: number;
}

export interface SetPositionFilter {
  learnContentId: LearnContentId;
  selectedPositions: string[];
}

export type LearnContentId = string;

/** SLICE */
const assignmentSlice = createSlice({
  name: 'assignment',
  initialState,
  reducers: {
    loadUsers: (state, _action: PayloadAction<string>) => {
      state.loadingUsers = 'pending';
    },
    loadUsersSuccess: (state, action: PayloadAction<LPUser[]>) => {
      const users = action.payload;
      state.loadingUsers = 'succeeded';
      state.users = action.payload;
      state.usersCount = users.length === ROWS_PER_PAGE ? users.length + 1 : users.length;
    },
    loadUsersFailure: state => {
      state.loadingUsers = 'failed';
    },
    loadUsersMore: (state, _action: PayloadAction<string>) => {
      state.loadingUsersMore = 'pending';
    },
    loadUsersMoreSuccess: (state, action: PayloadAction<LPUser[]>) => {
      const moreUsers = action.payload;
      const combinedUsers = [...state.users, ...moreUsers];
      state.loadingUsersMore = 'succeeded';
      state.users = combinedUsers;
      state.usersCount = moreUsers.length === ROWS_PER_PAGE ? combinedUsers.length + 1 : combinedUsers.length;
    },
    loadUsersMoreFailure: state => {
      state.loadingUsersMore = 'failed';
    },
    loadUsersCache: state => {
      state.loadingUsersCache = 'pending';
    },
    loadUsersCacheSuccess: (state, action: PayloadAction<LPUser[]>) => {
      state.loadingUsersCache = 'succeeded';
      state.usersCache = action.payload;
    },
    loadUsersCacheFailure: state => {
      state.loadingUsersCache = 'failed';
    },
    loadLearnContents: state => {
      state.loadingLearnContents = 'pending';
    },
    loadLearnContentsSuccess: (state, action: PayloadAction<LearnContent[]>) => {
      state.loadingLearnContents = 'succeeded';
      state.learnContents = action.payload;
    },
    loadLearnContentsFailure: state => {
      state.loadingLearnContents = 'failed';
    },
    loadTeams: (state, _action: PayloadAction<string>) => {
      state.loadingTeams = 'pending';
    },
    loadTeamsSuccess: (state, action: PayloadAction<LPTeam[]>) => {
      const teams = action.payload;
      state.loadingTeams = 'succeeded';
      state.teams = teams;
      state.teamsCount = teams.length === ROWS_PER_PAGE ? teams.length + 1 : teams.length;
    },
    loadTeamsFailure: state => {
      state.loadingTeams = 'failed';
    },
    loadTeamsMore: (state, _action: PayloadAction<string>) => {
      state.loadingTeamsMore = 'pending';
    },
    loadTeamsCache: state => {
      state.loadingTeamsCache = 'pending';
    },
    loadTeamsCacheSuccess: (state, action: PayloadAction<LPTeam[]>) => {
      state.loadingTeamsCache = 'succeeded';
      state.teamsCache = action.payload;
    },
    loadTeamsCacheFailure: state => {
      state.loadingTeamsCache = 'failed';
    },
    loadTeamsMoreSuccess: (state, action: PayloadAction<LPTeam[]>) => {
      const teams = action.payload;
      const combinedTeams = [...state.teams, ...teams];
      state.loadingTeamsMore = 'succeeded';
      state.teams = combinedTeams;
      state.teamsCount = teams.length === ROWS_PER_PAGE ? combinedTeams.length + 1 : combinedTeams.length;
    },
    loadTeamsMoreFailure: state => {
      state.loadingTeamsMore = 'failed';
    },
    loadAllPositions: state => {
      state.loadingPositions = 'pending';
    },
    loadAllPositionsFailure: (state, action: PayloadAction<{ positions: string[] }>) => {
      const { positions } = action.payload;
      state.loadingPositions = 'failed';
      state.allPositions = positions;
    },
    loadAllPositionsSuccess: (state, action: PayloadAction<{ positions: string[] }>) => {
      const { positions } = action.payload;
      state.loadingPositions = 'succeeded';
      state.allPositions = positions;
    },
    loadPositions: (state, action: PayloadAction<LearnContentId>) => {
      state.loadingPositions = 'pending';
    },
    loadPositionsSuccess: (state, action: PayloadAction<DisplayPosition[]>) => {
      const positions = action.payload;
      state.positions = positions;
      state.positionsCount = positions.length === ROWS_PER_PAGE ? positions.length + 1 : positions.length;
      state.loadingPositions = 'succeeded';
    },
    loadPositionsFailure: state => {
      state.loadingPositions = 'failed';
    },
    loadPositionsMore: (state, _action: PayloadAction<LearnContentId>) => {
      state.loadingPositionsMore = 'pending';
    },
    loadPositionsMoreSuccess: (state, action: PayloadAction<DisplayPosition[]>) => {
      const positions = action.payload;
      const combinedPositions = [...state.positions, ...positions];
      state.loadingPositionsMore = 'succeeded';
      state.positions = combinedPositions;
      state.positionsCount = positions.length === ROWS_PER_PAGE ? combinedPositions.length + 1 : combinedPositions.length;
    },
    loadPositionsMoreFailure: state => {
      state.loadingPositionsMore = 'failed';
    },
    assignUsers: (state, _action: PayloadAction<AssignUsersObject>) => {
      state.loadingPost = 'pending';
    },
    assignUsersSuccess: state => {
      state.loadingPost = 'succeeded';
    },
    assignUsersFailure: state => {
      state.loadingPost = 'failed';
    },
    cancelAssignUser: (state, _action: PayloadAction<CancelAssignUserObject>) => {
      state.loadingPost = 'pending';
    },
    cancelAssignUserSuccess: state => {
      state.loadingPost = 'succeeded';
    },
    cancelAssignUserFailure: state => {
      state.loadingPost = 'failed';
    },
    assignTeams: (state, _action: PayloadAction<AssignTeamsObject>) => {
      state.loadingPost = 'pending';
    },
    assignTeamsSuccess: state => {
      state.loadingPost = 'succeeded';
    },
    assignTeamsFailure: state => {
      state.loadingPost = 'failed';
    },
    cancelAssignTeam: (state, _action: PayloadAction<CancelAssignTeamObject>) => {
      state.loadingPost = 'pending';
    },
    cancelAssignTeamSuccess: state => {
      state.loadingPost = 'succeeded';
    },
    cancelAssignTeamFailure: state => {
      state.loadingPost = 'failed';
    },
    assignPositions: (state, _action: PayloadAction<AssignPositionsObject>) => {
      state.loadingPost = 'pending';
    },
    assignPositionsSuccess: state => {
      state.loadingPost = 'succeeded';
    },
    assignPositionsFailure: state => {
      state.loadingPost = 'failed';
    },
    cancelAssignPosition: (state, _action: PayloadAction<CancelAssignPositionObject>) => {
      state.loadingPost = 'pending';
    },
    cancelAssignPositionSuccess: state => {
      state.loadingPost = 'succeeded';
    },
    cancelAssignPositionFailure: state => {
      state.loadingPost = 'failed';
    },
    setUserNameFilters: (state, action: PayloadAction<string[]>) => {
      state.loadingUsers = 'pending';
      state.users = initialState.users;
      state.userNameFilters = action.payload;
    },
    clearUserNameFilters: state => {
      state.users = initialState.users;
      state.userNameFilters = [...initialState.userNameFilters];
    },
    setTeamFilters: (state, action: PayloadAction<string[]>) => {
      state.loadingTeams = 'pending';
      state.teams = initialState.teams;
      state.teamFilters = action.payload;
    },
    clearTeamFilters: state => {
      state.teams = initialState.teams;
      state.teamFilters = [...initialState.teamFilters];
    },
    setPositionFilters: (state, action: PayloadAction<SetPositionFilter>) => {
      state.loadingPositions = 'pending';
      state.positions = initialState.positions;
      state.positionFilters = action.payload.selectedPositions;
    },
    clearPositionFilters: state => {
      state.positions = initialState.positions;
      state.positionFilters = [...initialState.positionFilters];
    },
    clearAllFilters: state => {
      state.users = initialState.users;
      state.userNameFilters = [...initialState.userNameFilters];
      state.teams = initialState.teams;
      state.teamFilters = [...initialState.teamFilters];
      state.positions = initialState.positions;
      state.positionFilters = [...initialState.positionFilters];
    }
  },
});

export const assignmentActions = assignmentSlice.actions;
export const assignmentReducer = assignmentSlice.reducer;

const createUserNameQueryPartial = (userNames: string[]): string => {
  // prettier-ignore
  return userNames.length > 0 
    ? `userName in [${userNames.map(userName => `"${userName}"`).join(', ')}]` 
    : '';
};

const createTeamQueryPartial = (teamIds: string[]): string => {
  // prettier-ignore
  return teamIds.length > 0 
    ? `id in [${teamIds.map(teamId => `id(${teamId})`).join(', ')}]` 
    : '';
};

/** SAGAS */
function* onLoadUsers() {
  const { userNameFilters }: AssignmentState = yield select((state: AppState) => state.assignment);
  const query = [userNameFilters.length > 0 ? createUserNameQueryPartial(userNameFilters) : '']
    .filter(partial => partial !== '')
    .join(' AND ');

  try {
    const users: LPUser[] = yield call(() => LPUser.getLPUsers(`${query} order by lastName limit ${ROWS_PER_PAGE}`));
    yield call(() => bulkLoadTeamsForUsers(users));
    yield put(assignmentActions.loadUsersSuccess(users));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(assignmentActions.loadUsersFailure());
  }
}

function* onLoadUsersMore() {
  const savedItems: LPUser[] = yield select((state: AppState) => state.assignment.users);
  const { userNameFilters }: AssignmentState = yield select((state: AppState) => state.assignment);
  const query = [userNameFilters.length > 0 ? createUserNameQueryPartial(userNameFilters) : ''];

  try {
    const users: LPUser[] = yield call(() =>
      LPUser.getLPUsers(`${query} order by lastName offset ${savedItems.length} limit ${ROWS_PER_PAGE}`)
    );
    yield call(() => bulkLoadTeamsForUsers(users));
    yield put(assignmentActions.loadUsersMoreSuccess(users));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(assignmentActions.loadUsersMoreFailure());
  }
}

function* onLoadUserCache() {
  const { usersCache }: AssignmentState = yield select((state: AppState) => state.assignment);

  const query = usersCache.reduce((previousValue, currentValue, currentIndex) =>
    previousValue + `id == id(${currentValue.ID})${currentIndex !== usersCache.length - 1 ? ' OR ' : ''}`, '');
  try {
    const users: LPUser[] = yield call(() => LPUser.getLPUsers(query));
    yield call(() => bulkLoadTeamsForUsers(users));
    yield put(assignmentActions.loadUsersCacheSuccess(users));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(assignmentActions.loadUsersCacheFailure());
  }
}

function* onLoadTeams() {
  const { teamFilters }: AssignmentState = yield select((state: AppState) => state.assignment);
  const query = [teamFilters.length > 0 ? createTeamQueryPartial(teamFilters) : ''].filter(partial => partial !== '').join(' AND ');

  try {
    const teams: LPTeam[] = yield call(() => LPTeam.getLPTeams(`${query} order by name limit ${ROWS_PER_PAGE}`));
    yield put(assignmentActions.loadTeamsSuccess(teams));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(assignmentActions.loadTeamsFailure());
  }
}

function* onLoadTeamsMore(action: PayloadAction<string>) {
  const savedItems: LPTeam[] = yield select((state: AppState) => state.assignment.teams);
  const { teamFilters }: AssignmentState = yield select((state: AppState) => state.assignment);
  const query = [teamFilters.length > 0 ? createTeamQueryPartial(teamFilters) : ''].filter(partial => partial !== '').join(' AND ');

  try {
    const teams: LPTeam[] = yield call(() =>
      LPTeam.getLPTeams(`${query} order by name offset ${savedItems.length} limit ${ROWS_PER_PAGE}`)
    );
    yield put(assignmentActions.loadTeamsMoreSuccess(teams));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(assignmentActions.loadTeamsMoreFailure());
  }
}

function* onLoadTeamCache() {
  const { teamsCache }: AssignmentState = yield select((state: AppState) => state.assignment);

  const query = teamsCache.reduce((previousValue, currentValue, currentIndex) =>
    previousValue + `id == id(${currentValue.ID})${currentIndex !== teamsCache.length - 1 ? ' OR ' : ''}`, '');
  try {
    const teams: LPTeam[] = yield call(() => LPTeam.getLPTeams(query));
    yield put(assignmentActions.loadTeamsCacheSuccess(teams));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(assignmentActions.loadTeamsCacheFailure());
  }
}

function* onLoadAllPositions() {
  try {
    /* On inital request we fetch all available position from the api - we cannot page here */
    const positions: string[] = yield call(() => fetchAllPositions());
    positions.sort((a, b) => a.localeCompare(b));

    /* We cache the result for all subsequent requests */
    yield put(assignmentActions.loadAllPositionsSuccess({ positions }));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(assignmentActions.loadPositionsFailure());
  }
}

function* onLoadPositions(action: PayloadAction<LearnContentId>) {
  const learnContentId = action.payload;
  const { positionFilters }: AssignmentState = yield select((state: AppState) => state.assignment);
  const cachedPositions: string[] = yield select((state: AppState) => state.assignment.allPositions);

  try {
    const positions = cachedPositions
      .filter(it => positionFilters.length > 0 ? positionFilters.includes(it) : true)
      .slice(0, ROWS_PER_PAGE);


    const countCalls = positions.map(async position => {
      const totalCount = await getUsersCountForPosition(position);
      const assignedCount = await getAssignedUsersCountForLearnContent(position, learnContentId);

      return {
        name: position,
        totalCount,
        assignedCount
      }
    })

    const displayPositions = yield call(() => Promise.all(countCalls))

    yield put(assignmentActions.loadPositionsSuccess(displayPositions));

  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(assignmentActions.loadPositionsMoreFailure());
  }
}

function* onLoadSetPositionFilter(action: PayloadAction<SetPositionFilter>) {
  const payload = {
    payload: action.payload.learnContentId,
    type: action.type
  };

  yield* onLoadPositions(payload);
}

function* onLoadPositionsMore(action: PayloadAction<LearnContentId>) {
  const learnContentId = action.payload;
  const { positionFilters }: AssignmentState = yield select((state: AppState) => state.assignment);
  const savedItems: string[] = yield select((state: AppState) => state.assignment.positions);
  const cachedPositions: string[] = yield select((state: AppState) => state.assignment.allPositions);

  try {
    /* Take the next page of the cached position results */
    const startIndex = savedItems.length;
    const positions = cachedPositions
      .filter(it => positionFilters.length > 0 ? positionFilters.includes(it) : true)
      .slice(startIndex, startIndex + ROWS_PER_PAGE);

      const countCalls = positions.map(async position => {
        const totalCount = await getUsersCountForPosition(position);
        const assignedCount = await getAssignedUsersCountForLearnContent(position, learnContentId);
  
        return {
          name: position,
          totalCount,
          assignedCount
        }
      })
  
    const displayPositions = yield call(() => Promise.all(countCalls));

    yield put(assignmentActions.loadPositionsMoreSuccess(displayPositions));

  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(assignmentActions.loadPositionsMoreFailure());
  }
}

function* onLoadLearnContents() {
  try {
    const learnContents: LearnContent[] = yield call(() => LearnContent.getLearnContents(`isArchived!=1`));
    yield put(assignmentActions.loadLearnContentsSuccess(learnContents));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(assignmentActions.loadLearnContentsFailure());
  }
}

function* onAssignUsers(action: PayloadAction<AssignUsersObject>) {
  const { userIds, learnContentId, dueDate, updateExisting } = action.payload;

  try {
    yield call(() => bulkAssignUsers(learnContentId, userIds, dueDate, updateExisting));
    yield put(assignmentActions.assignUsersSuccess());
    yield put(assignmentActions.loadTeams(learnContentId));
    yield put(assignmentActions.loadPositions());
    yield put(assignmentActions.loadUsers(learnContentId));
    yield put(assignmentActions.loadUsersCache());
    yield put(authActions.reloadUser());
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(assignmentActions.assignUsersFailure());
  }
}

function* onCancelAssignUser(action: PayloadAction<CancelAssignUserObject>) {
  const { user, learnContentId } = action.payload;

  try {
    yield call(() => deassignUser(user, learnContentId));
    yield put(assignmentActions.cancelAssignUserSuccess());
    yield put(assignmentActions.loadTeams(learnContentId));
    yield put(assignmentActions.loadTeamsCache());
    yield put(assignmentActions.loadPositions(learnContentId));
    yield put(assignmentActions.loadUsers(learnContentId));
    yield put(assignmentActions.loadUsersCache());
    yield put(authActions.reloadUser());
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(assignmentActions.cancelAssignUserFailure());
  }
}

function* onAssignTeams(action: PayloadAction<AssignTeamsObject>) {
  const { teamIds, learnContentId, dueDate, updateExisting } = action.payload;

  try {
    yield all(teamIds.map(teamId => assignTeam(teamId, learnContentId, dueDate, updateExisting)));
    yield put(assignmentActions.assignTeamsSuccess());
    yield put(assignmentActions.loadTeams(learnContentId));
    yield put(assignmentActions.loadTeamsCache());
    yield put(assignmentActions.loadPositions(learnContentId));
    yield put(assignmentActions.loadUsers(learnContentId));
    yield put(authActions.reloadUser());
  } catch (error) {
    if (error?.response?.data?.status === DFHExceptionStatus.ASSIGN_FAIL) {
      yield put(notificationActions.showError(i18n.t('errors.assign-all-users')));
    } else {
      yield put(notificationActions.showError(error));
    }

    yield put(assignmentActions.assignTeamsFailure());
  }
}

function* onCancelAssignTeam(action: PayloadAction<CancelAssignTeamObject>) {
  const { team, learnContentId } = action.payload;

  try {
    yield call(() => deassignTeam(team.ID, learnContentId));
    yield put(assignmentActions.cancelAssignTeamSuccess());
    yield put(assignmentActions.loadTeams(learnContentId));
    yield put(assignmentActions.loadTeamsCache());
    yield put(assignmentActions.loadPositions(learnContentId));
    yield put(assignmentActions.loadUsers(learnContentId));
    yield put(authActions.reloadUser());
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(assignmentActions.cancelAssignTeamFailure());
  }
}

function* onAssignPositions(action: PayloadAction<AssignPositionsObject>) {
  const { positions, learnContentId, dueDate, updateExisting } = action.payload;

  try {
    yield call(() => assignPositions(learnContentId, positions, dueDate, updateExisting));
    yield put(assignmentActions.assignPositionsSuccess());
    yield put(assignmentActions.loadTeams(learnContentId));
    yield put(assignmentActions.loadPositions(learnContentId));
    yield put(assignmentActions.loadUsers(learnContentId));
    yield put(authActions.reloadUser());
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(assignmentActions.assignPositionsFailure());
  }
}

function* onCancelAssignPosition(action: PayloadAction<CancelAssignPositionObject>) {
  const { position, learnContentId } = action.payload;

  try {
    yield call(() => deassignPosition(position, learnContentId));
    yield put(assignmentActions.cancelAssignPositionSuccess());
    yield put(assignmentActions.loadTeams(learnContentId));
    yield put(assignmentActions.loadPositions(learnContentId));
    yield put(assignmentActions.loadUsers(learnContentId));
    yield put(authActions.reloadUser());
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(assignmentActions.cancelAssignPositionFailure());
  }
}

export function* assignmentSaga() {
  yield all([
    takeLatest(
      [assignmentActions.loadUsers.type, assignmentActions.setUserNameFilters.type, assignmentActions.clearUserNameFilters.type],
      onLoadUsers
    ),
    takeLatest(assignmentActions.loadUsersMore, onLoadUsersMore),
    takeLatest(assignmentActions.loadUsersCache, onLoadUserCache),
    takeLatest(
      [assignmentActions.loadTeams.type, assignmentActions.setTeamFilters.type, assignmentActions.clearTeamFilters.type],
      onLoadTeams
    ),
    takeLatest(assignmentActions.loadTeamsMore, onLoadTeamsMore),
    takeLatest(assignmentActions.loadTeamsCache, onLoadTeamCache),
    takeLatest(assignmentActions.loadAllPositions.type, onLoadAllPositions),
    takeLatest([assignmentActions.loadPositions.type, assignmentActions.clearPositionFilters.type], onLoadPositions),
    takeLatest(assignmentActions.setPositionFilters.type, onLoadSetPositionFilter),
    takeLatest(assignmentActions.loadPositionsMore, onLoadPositionsMore),
    takeLatest(assignmentActions.loadLearnContents, onLoadLearnContents),
    takeLatest(assignmentActions.assignUsers, onAssignUsers),
    takeLatest(assignmentActions.cancelAssignUser, onCancelAssignUser),
    takeLatest(assignmentActions.assignTeams, onAssignTeams),
    takeLatest(assignmentActions.cancelAssignTeam, onCancelAssignTeam),
    takeLatest(assignmentActions.assignPositions, onAssignPositions),
    takeLatest(assignmentActions.cancelAssignPosition, onCancelAssignPosition),
  ]);
}
