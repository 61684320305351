import { notificationActions } from './notification';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import MaintenanceInterval from '../sdk/com/apiomat/frontend/maintenancemodule/MaintenanceInterval';
import User from '../sdk/com/apiomat/frontend/basics/User';
import { ApiRequestState } from '../models/api-request-state';

/** STATE */
export interface MaintenanceState {
  loading: ApiRequestState;
  showMaintenance: boolean;
  maintenance: MaintenanceInterval | Object;
}

const initialState: MaintenanceState = {
  loading: 'idle',
  showMaintenance: false,
  maintenance: null,
};

/** SLICE */
const maintenanceSlice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    loadMaintenance: state => {
      state.loading = 'pending';
    },
    loadMaintenanceSuccess: (state, action: PayloadAction<Object>) => {
      state.maintenance = action.payload;
      state.showMaintenance = false;
      state.loading = 'succeeded';
    },
    loadMaintenanceFailure: state => {
      state.loading = 'failed';
      state.maintenance = null;
      state.showMaintenance = false;
    },
    resetMaintenance: state => {
      state.maintenance = null;
      state.showMaintenance = false;
      state.loading = 'succeeded';
    },
    hideMaintenanceInfo: state => {
      state.showMaintenance = false;
    },
    showMaintenanceInfo: state => {
      state.showMaintenance = true;
    },
  },
});

export const maintenanceActions = maintenanceSlice.actions;
export const maintenanceReducer = maintenanceSlice.reducer;

/** SAGAS */
function* onLoadMaintenance() {
  try {
    const currentDate = new Date().getTime();
    const query = `displayStartDate < date(${currentDate}) and startDate > date(${currentDate}) order by startDate limit 1`;
    const maintenanceIntervals: MaintenanceInterval[] = yield call(() => MaintenanceInterval.getMaintenanceIntervals(query));

    const getMaintenanceCustomRestUrl = () => {
      const moduleName = MaintenanceInterval.staticGetModuleName();
      return `${User.AOMBASEURL.replace('apps', `modules/${moduleName}`)}/spec/maintenance`;
    };

    if (maintenanceIntervals.length !== 0) {
      const currentMaintenanceInterval = maintenanceIntervals[0];
      const currentMaintenanceInfos = {
        startDate: currentMaintenanceInterval.startDate,
        endDate: currentMaintenanceInterval.endDate,
        displayText: currentMaintenanceInterval.displayText,
        url: getMaintenanceCustomRestUrl(),
      };
      yield put(maintenanceActions.loadMaintenanceSuccess(currentMaintenanceInfos));
    } else {
      yield put(maintenanceActions.resetMaintenance());
    }
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(maintenanceActions.loadMaintenanceFailure());
  }
}

export function* maintenanceSaga() {
  yield all([takeLatest(maintenanceActions.loadMaintenance, onLoadMaintenance)]);
}
